import {
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";

export class ProcessInputOutputParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: Setting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      variable: [],
      value: setting.value?.map(
        (val: { id: number; subprocess: string; process: string }) => {
          const subprocesVar = this.parseControlValueToParameter(
            val.subprocess
          );
          const processVar = this.parseControlValueToParameter(val.process);

          const source =
            setting.type === SettingType.PROCESS_INPUT
              ? {
                  value: processVar.value,
                  variable: processVar.variable,
                }
              : {
                  value: subprocesVar.value,
                  variable: subprocesVar.variable,
                };

          const destination =
            setting.type === SettingType.PROCESS_INPUT
              ? {
                  ...subprocesVar.variable[0],
                }
              : {
                  ...processVar.variable[0],
                };

          return {
            id: val.id,
            // only needed for debuging purpose
            ...(process.env.NODE_ENV === "development" && {
              slugId: setting.type,
            }),
            source,
            destination,
          };
        }
      ),
    };

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (Array.isArray(parameter.value)) {
        parameter.value.forEach((value) => {
          if (value.source && Array.isArray(value.source.variable)) {
            variables.push(...value.source.variable);
          }

          if (value.destination) {
            variables.push(value.destination);
          }
        });
      }
    });

    return variables;
  }
}
