<template>
  <div class="pup-c-payload-body">
    <pds-radio
      :options="bodyTypes"
      class="pup-c-payload-body--radios pds-u-m--b--16"
      variation="tiny"
      direction="horizontal"
      v-model="value.type"
      :disabled="disabled"
    />

    <pds-select
      v-if="value.type === payloadBodyTypes.RAW"
      v-model="typeFormat"
      :options="typeFormats"
      :disabled="disabled"
      class="pup-c-payload-body--raw--select pds-u-m--t--8"
    />

    <component
      :is="component"
      :label="componentLabel"
      :processVariables="processVariables"
      :settings="genericTextSetting"
      v-model="typeValue"
      :status="genericTextStatus"
      :config="tableConfig"
      :parent="parent"
      :lang="typeFormat"
      :autoformat="true"
      newRowAutopopulate
      :disabled="disabled"
      :fullScreenTitle="fullScreenTitle"
      :class="[
        'pup-c-payload-body--tab--table',
        value.type && 'pup-c-payload-body--tab--' + value.type.toLowerCase(),
      ]"
    >
      <template
        v-slot:key="{ row }"
        v-if="value.type === payloadBodyTypes.FORM_DATA"
      >
        <pup-typed-input :value="row" :disabled="disabled" />
      </template>
      <template v-slot:value="{ row }">
        <pup-expandable-input
          v-model="row.value"
          :inlineInputType="inlineInputTypes.GENERIC_TEXT"
          :processVariables="processVariables"
          :placeholder="
            row.type === tabsPayloadItemTypes.FILE
              ? 'Select file variable'
              : 'Insert value'
          "
          :parent="parent"
          :disabled="disabled"
          :settings="
            settings && row.type === tabsPayloadItemTypes.FILE
              ? {
                  ...settings,
                  dataTypeId: nonPrimitives.FILE,
                }
              : settings
          "
          :fullScreenTitle="fullScreenTitle"
          :oneVariableOnly="row.type === tabsPayloadItemTypes.FILE"
        />
      </template>
      <template v-slot:actions-per-row="props" v-if="!disabled">
        <div class="pup-c-tabs-payload--tab--table--actions">
          <pds-icon
            v-if="props.index < tableConfig.items.length - 1 && !disabled"
            icon="clear"
            @click="deleteRow(props.index)"
            class="pup-c-tabs-payload--tab--table--actions--delete"
          />
        </div>
      </template>

      <template v-slot:fullScreenEditorPrepend>
        <pds-select
          v-if="value.type === payloadBodyTypes.RAW"
          v-model="typeFormat"
          :options="typeFormats"
          :disabled="disabled"
          class="pup-c-payload-body--raw--select pds-u-m--t--16 pds-u-m--l--16"
        />
      </template>
    </component>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model } from "vue-property-decorator";

import {
  Direction,
  Node,
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import {
  RadioboxComponent,
  IconComponent,
  SelectComponent,
  InputComponent,
} from "@procesio/procesio-design-system";
import {
  getInitialPayloadBody,
  PayloadBody,
  PayloadBodyTypeLabels,
  PayloadBodyTypes,
  RawBodyPayloadValue,
  TabsPayloadItem,
  TabsPayloadItemType,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";
import GenericTextComponent from "../../GenericText/GenericText.component.vue";
import Editor from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.component.vue";
import { NonPrimitives } from "@/utils/dataTypeMapper";
import { createGuid } from "@/utils/type/guid";
import TInput from "../../TableInput/TInput.component.vue";
import TypedInputComponent from "@/modules/ProcessDesigner/components/Controls/TabsPayload/Inputs/TypedInput/TypedInput.component.vue";
import ExpandableInputComponent from "../../ExpandableInput/ExpandableInput.component.vue";
import { InlineInputTypes } from "../../ExpandableInput/ExpandableInput.model";
import { EditorLanguage } from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.model";

@Component({
  components: {
    "pds-radio": RadioboxComponent,
    "pds-icon": IconComponent,
    "pds-select": SelectComponent,
    "pds-input": InputComponent,
    "pup-generic-text": GenericTextComponent,
    "pup-editor": Editor,
    "pup-table-input": TInput,
    "pup-typed-input": TypedInputComponent,
    "pup-expandable-input": ExpandableInputComponent,
  },
})
export default class PayloadBodyComponent extends Vue {
  @Model("update-input") value!: PayloadBody;

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  payloadBodyTypes = PayloadBodyTypes;

  multilineEditingRowId: string | null = null;

  multilineEditingColumn: string | null = null;

  hoveredRowId: string | null = null;

  inlineInputTypes = InlineInputTypes;

  tabsPayloadItemTypes = TabsPayloadItemType;

  nonPrimitives = NonPrimitives;

  created() {
    if (!this.value || typeof this.value === "string") {
      const value = getInitialPayloadBody();
      (value.value.RAW as RawBodyPayloadValue).value = this.value || "";
      this.$emit("update-input", value);
    }
  }

  bodyTypes = [
    {
      name: PayloadBodyTypeLabels.BINARY,
      value: PayloadBodyTypes.BINARY,
    },
    {
      name: PayloadBodyTypeLabels.FORM_DATA,
      value: PayloadBodyTypes.FORM_DATA,
    },
    {
      name: PayloadBodyTypeLabels.RAW,
      value: PayloadBodyTypes.RAW,
    },
    {
      name: PayloadBodyTypeLabels.X_WWW_FORM_URLENCODED,
      value: PayloadBodyTypes.X_WWW_FORM_URLENCODED,
    },
  ];

  typeFormats = [
    {
      name: "HTML",
      value: EditorLanguage.HTML,
    },
    {
      name: "JavaScript",
      value: EditorLanguage.JAVASCRIPT,
    },
    {
      name: "JSON",
      value: EditorLanguage.JSON,
    },
    {
      name: "Text",
      value: EditorLanguage.TEXT,
    },
    {
      name: "XML",
      value: EditorLanguage.XML,
    },
  ];

  itemTypes = [
    {
      name: "Text",
      value: TabsPayloadItemType.TEXT,
    },
    {
      name: "File",
      value: TabsPayloadItemType.FILE,
    },
  ];

  get component() {
    switch (this.value.type) {
      case PayloadBodyTypes.FORM_DATA:
      case PayloadBodyTypes.X_WWW_FORM_URLENCODED:
        return TInput;
      case PayloadBodyTypes.BINARY:
        return GenericTextComponent;
      case PayloadBodyTypes.RAW:
        return Editor;
      default:
        return null;
    }
  }

  get tableConfig() {
    if (
      [
        PayloadBodyTypes.FORM_DATA,
        PayloadBodyTypes.X_WWW_FORM_URLENCODED,
      ].includes(this.value.type)
    ) {
      return {
        columns: [
          {
            key: "key",
            label: "Key",
            component: "pds-input",
            placeholder: "Insert key",
          },
          {
            key: "value",
            label: "Value",
            placeholder: "Insert value",
            component: "pup-expandable-input",
          },
        ],
        items: this.typeValue || [],
      };
    }

    // return null;
    return {
      columns: [],
      items: [],
    };
  }

  get genericTextSetting(): Setting | null {
    if (this.value.type === PayloadBodyTypes.BINARY) {
      return {
        id: createGuid(),
        type: SettingType.FILE,
        label: "",
        value: null,
        direction: Direction.NONE,
        isList: false,
        dataTypeId: NonPrimitives.FILE,
      };
    }

    return {
      ...this.settings,
      dataTypeId: "",
    };
  }

  get genericTextStatus() {
    if (this.value.type === PayloadBodyTypes.BINARY) {
      return {
        type: "danger",
        message: [],
      };
    }

    return null;
  }

  get componentLabel() {
    switch (this.value.type) {
      case PayloadBodyTypes.BINARY:
        return "File Input";
      case PayloadBodyTypes.RAW:
        return "Body";
      default:
        return null;
    }
  }

  get typeValue() {
    if (!(this.value && this.value.value)) {
      return "";
    }

    const value = this.value.value[this.value.type];

    if (
      typeof value === "object" &&
      typeof (value as RawBodyPayloadValue).format !== "undefined"
    ) {
      return (value as RawBodyPayloadValue).value;
    }

    return value;
  }

  set typeValue(value) {
    if (
      typeof this.value.value[this.value.type] === "object" &&
      typeof (this.value.value[this.value.type] as RawBodyPayloadValue)
        .format !== "undefined"
    ) {
      (this.value.value[
        this.value.type
      ] as RawBodyPayloadValue).value = value as string;
      return;
    }

    this.value.value[this.value.type] = value;
  }

  get typeFormat() {
    if (
      typeof (this.value.value[this.value.type] as RawBodyPayloadValue)
        .format === "string"
    ) {
      return ((this.value.value[this.value.type] as RawBodyPayloadValue)
        .format as EditorLanguage).toLowerCase();
    }

    return null;
  }

  set typeFormat(value) {
    if (
      typeof (this.value.value[this.value.type] as RawBodyPayloadValue)
        .format !== "undefined"
    ) {
      (this.value.value[
        this.value.type
      ] as RawBodyPayloadValue).format = value as EditorLanguage;
    }
  }

  get fullScreenTitle() {
    return (
      this.parent.name + " | Body | " + PayloadBodyTypeLabels[this.value.type]
    );
  }

  deleteRow(index: number) {
    if (Array.isArray(this.typeValue)) {
      this.typeValue.splice(index, 1);
    }
  }

  toggleEditor(row: { id: string }, column: string) {
    if (
      this.multilineEditingRowId === row.id &&
      this.multilineEditingColumn === column
    ) {
      this.multilineEditingRowId = null;
      this.multilineEditingColumn = null;
      return;
    }

    this.multilineEditingRowId = row.id;
    this.multilineEditingColumn = column;
  }

  getItemTypeTooltip(itemType: TabsPayloadItemType) {
    if (!itemType) {
      return null;
    }

    const item = this.itemTypes.find((item) => item.value === itemType);

    return item ? item.name : null;
  }

  onItemTypeUpdate(item: TabsPayloadItem, itemType: TabsPayloadItemType) {
    item.value = "";
    item.type = itemType;
  }
}
</script>

<style lang="scss">
@import "./PayloadBody.component.scss";
</style>
