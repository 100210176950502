import Vue from "vue";
import { Component } from "vue-property-decorator";

import {
  Setting,
  SettingTab,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

interface CallbackFn {
  (setting: Setting): void;
}

@Component
export class Controls extends Vue {
  protected traverseTabs(controls: Setting[], cb: CallbackFn): void {
    return controls.forEach((control) => {
      switch (control.type) {
        case SettingType.SIDEPANEL:
        case SettingType.TABS:
        case SettingType.TAB:
        case SettingType.GROUP_COLLAPSABLE:
          return this.traverseTabs(control.value, cb);

        default:
          return cb(control);
      }
    });
  }

  traverseControls(tabs: SettingTab[], cb: CallbackFn) {
    tabs.forEach((tab) => this.traverseTabs(tab.settings, cb));
  }
}
