<template>
  <div>test</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

@Component({})
export default class UploadSettingComponent extends Vue {
  @Prop() icon!: string;
}
</script>
