<template>
  <div class="pup-c-delay--type-radio pds-u-p--b--8">
    <pds-radio
      :options="options"
      :value="internalValue"
      @input="$emit('update-input', $event)"
      variation="tiny"
      :disabled="disabled"
    />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { RadioboxComponent } from "@procesio/procesio-design-system";
import { DelayType } from "../Delay.model";

@Component({
  components: {
    "pds-radio": RadioboxComponent,
  },
  model: {
    event: "update-input",
  },
})
export default class DelayTypeRadio extends Vue {
  @Prop({ default: DelayType.WAIT_FOR }) value!: DelayType;

  @Prop({ default: [] }) options!: { value: DelayType; name: string }[];

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  internalValue = DelayType.WAIT_FOR;

  @Watch("value", { immediate: true })
  onValueChange(value: DelayType | null) {
    let internalValue = DelayType.WAIT_FOR;
    if (value) {
      internalValue = value;
    }

    this.internalValue = +internalValue;
  }
}
</script>

<style lang="scss">
@import "./DelayTypeRadio.component.scss";
</style>
