<template>
  <div>
    <div
      :class="[
        'pup-c-condition',
        condition.checked && selectable && 'pup-c-condition--check',
      ]"
      :data-level="level"
    >
      <div class="col-sm-12 pup-c-group--row--button col-md-1 pup-c-draggable">
        <pds-icon
          class="pup-c-case-configuration--icon pup-c-case-configuration--drag-handle"
          icon="drag_indicator"
          :isOutlined="true"
          v-if="draggable && !disabled"
        />
        <div
          @mouseover="onRowNumberMouseOver"
          @mouseleave="onRowNumberMouseLeave"
        >
          <span
            v-if="(!hover && !condition.checked) || !selectable"
            class="pup-c-group--index pds-u-m--r--4"
          >
            {{ index + 1 }}
          </span>

          <pds-checkbox
            v-if="(hover || condition.checked) && selectable && !disabled"
            class="pup-c-group--index pds-u-m--r--4"
            variation="tiny"
            v-model="condition.checked"
          />
        </div>
      </div>

      <pup-generic
        class="pup-c-condition--left-selector pup-c-condition--input col-sm-12 col-md-4 pup-c-condition--no-padding"
        :settings="{ ...settings, isList: false }"
        :parent="parent"
        :processVariables="processVariables"
        :parentDataModelId="parentDataModelId"
        :parentVariableId="parentVariableId"
        :showValidationMessage="false"
        :status="prepareStatusObject('left', condition.id)"
        @focus.native.capture="
          fieldFocusHandler('left_operator_' + level + '_' + condition.id)
        "
        @blur="fieldBlurHandler('left_operator_' + level + '_' + condition.id)"
        v-model="condition.leftOperator.value"
        :hideCreate="hideNewVariableCreate"
        :hasInternalTypeValidation="false"
        :fullScreenTitle="fullScreenTitle"
        :disabled="disabled"
      />

      <pup-operator
        class="col-sm-12 col-md-2"
        v-model="condition.operator"
        :selectedDataType="getCurrentDataType(condition)"
        :isList="getIsVariableList(condition)"
        @update-conditions="changeUnaryLocal"
        :status="prepareStatusObject('', condition.id)"
        @focus.native.capture="
          fieldFocusHandler('operator_' + level + '_' + condition.id)
        "
        @blur="fieldBlurHandler('operator_' + level + '_' + condition.id)"
        :disabled="disabled"
      />

      <pup-generic
        v-if="operator == operatorType.BINARY"
        class="col-sm-12 col-md-4 pup-c-condition--no-padding pup-c-condition--input"
        :settings="{ ...settings, isList: rightOperandAsListRequired }"
        :parent="parent"
        :processVariables="processVariables"
        :parentDataModelId="parentDataModelId"
        :parentVariableId="parentVariableId"
        :showValidationMessage="false"
        :status="prepareStatusObject('right', condition.id)"
        @focus.native.capture="
          fieldFocusHandler('right_operator_' + level + '_' + condition.id)
        "
        @blur="fieldBlurHandler('right_operator_' + level + '_' + condition.id)"
        v-model="condition.rightOperator.value"
        :hideCreate="hideNewVariableCreate"
        :hasInternalTypeValidation="false"
        :fullScreenTitle="fullScreenTitle"
        :disabled="disabled"
      />

      <template v-if="operator == operatorType.TERNARY">
        <pup-generic
          class="pup-c-condition--operator--ternary--first pup-c-condition--input col-sm-12 col-md-2"
          :settings="{ ...settings, isList: false }"
          :parent="parent"
          :processVariables="processVariables"
          :parentDataModelId="parentDataModelId"
          :parentVariableId="parentVariableId"
          :showValidationMessage="false"
          :status="prepareStatusObject('right', condition.id)"
          @focus.native.capture="
            fieldFocusHandler('right_operator_' + level + '_' + condition.id)
          "
          @blur="
            fieldBlurHandler('right_operator_' + level + '_' + condition.id)
          "
          v-model="condition.rightOperator.value"
          :fullScreenTitle="fullScreenTitle"
          :disabled="disabled"
        />
        <pup-generic
          class="pup-c-condition--operator--ternary--last pup-c-condition--input col-sm-12 col-md-2"
          :settings="{ ...settings, isList: false }"
          :key="auxKey"
          :parent="parent"
          :processVariables="processVariables"
          :parentDataModelId="parentDataModelId"
          :parentVariableId="parentVariableId"
          :showValidationMessage="false"
          :status="prepareStatusObject('aux', condition.id)"
          @focus.native.capture="
            fieldFocusHandler('aux_operator_' + condition.id)
          "
          @blur="fieldBlurHandler('aux_operator_' + condition.id, condition)"
          v-model="condition.auxOperator.value"
          :fullScreenTitle="fullScreenTitle"
          :disabled="disabled"
        />
      </template>

      <pds-button
        icon="delete"
        type="link"
        color="neutral"
        class="col-sm-12 pup-c-group--row--button col-md-1"
        :class="[operator == operatorType.UNARY && 'offset-md-4']"
        :isIconOutlined="true"
        @click="$emit('remove-condition', index)"
        v-if="!disabled"
      />
    </div>
    <pup-logic-operator
      :disabled="disabled"
      v-model="condition.logicOperator"
      v-if="index !== conditionsLength - 1"
    />
  </div>
</template>

<script lang="ts">
import { Prop, Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  SelectComponent,
  ButtonComponent,
  IconComponent,
  CheckboxComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";
import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import LogicOperator from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/LogicOperator/LogicOperator.component.vue";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { FormBuilder } from "@/utils/ReactiveForm";
import ComperisonOperator from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/ComparisonOperator/ComparisonOperator.component.vue";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { OperatorType } from "@/services/actionlist/ActionList.service";
import { LazyPrimities, NonPrimitives } from "@/utils/dataTypeMapper";
import {
  Condition,
  relationalOperators,
} from "@/services/condition/Condition.model";
import { createGuid } from "@/utils/type/guid";
import { DataModel } from "@/services/datamodel/DataModel.model";
import { DecisionalCardValue } from "../ConditionBuilder.model";

@Component({
  components: {
    "pup-generic": GenericText,
    "pds-select": SelectComponent,
    "pup-logic-operator": LogicOperator,
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
    "pds-checkbox": CheckboxComponent,
    "pup-operator": ComperisonOperator,
  },
})
export default class ConditonRow extends mixins(Variable) {
  @Prop({ default: 0 }) index!: number;

  @Prop({ default: 0 }) level!: number;

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: null }) parentDataModelId!: string | null;

  @Prop({ default: null }) parentVariableId!: string | null;

  @Prop() form!: FormBuilder;

  @Prop() value!: DecisionalCardValue;

  @Prop() condition!: Condition;

  @Prop() conditionsLength!: number;

  @Prop({ default: true, type: Boolean }) draggable!: boolean;

  @Prop({ default: true, type: Boolean }) selectable!: boolean;

  @Prop({ default: false, type: Boolean }) hideNewVariableCreate!: boolean;

  @Prop({ default: false, type: Boolean }) forceValidation!: boolean;

  @Prop({ default: () => ({}) }) initialStatus!: {
    [key: string]: PdsTypes.InputStatus;
  };

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: "" }) fullScreenTitle!: string;

  auxKey = createGuid();

  blurredFields: Set<string> = new Set();

  relationalOperators = relationalOperators;

  operator: OperatorType = OperatorType.BINARY;

  hover = false;

  operatorType = OperatorType;

  rightOperandAsListRequired = false;

  operandsAsListOptional = false;

  getCurrentDataType(condition: Condition) {
    let leftOperator: string | undefined;
    let rightOperator;

    if (condition.leftOperator?.value) {
      leftOperator = this.getVariableDataType(
        condition.leftOperator.value.replaceAll(" ", "")
      )?.id;
    }

    if (leftOperator) {
      this.$store.getters.dataTypes.forEach((type: DataModel) => {
        if (type.isPrimaryType && type.isProcesio) {
          return;
        } else if (type.id === leftOperator) {
          leftOperator = NonPrimitives.JSON;
        }
      });
      console.log("1", leftOperator);
      return leftOperator;
    }

    if (condition.rightOperator?.value) {
      rightOperator = this.getVariableDataType(
        condition.rightOperator.value.replaceAll(" ", "")
      )?.id;
    }

    if (rightOperator) {
      console.log("2", rightOperator);

      return rightOperator;
    }

    if (condition.leftOperator?.value) {
      const typeofLeftOperator = typeof condition.leftOperator?.value;

      leftOperator = LazyPrimities[typeofLeftOperator];
      console.log("3", leftOperator);

      return leftOperator;
    }
  }

  getIsVariableList(condition: Condition): boolean {
    let leftOperator = false;

    if (condition.leftOperator?.value) {
      leftOperator = this.isVariableList(
        condition.leftOperator.value.replaceAll(" ", "")
      );
    }

    return leftOperator;
  }

  changeUnaryLocal(result: {
    updatedValue: OperatorType;
    operandsAsListOptional: boolean;
    rightOperandAsListRequired: boolean;
  }): void {
    if (result.updatedValue != null) {
      this.operator = result.updatedValue;
    }

    this.rightOperandAsListRequired = result.rightOperandAsListRequired;
    this.$set(
      this.condition,
      "rightOperandAsListRequired",
      result.rightOperandAsListRequired
    );

    this.operandsAsListOptional = result.operandsAsListOptional;
    this.$set(
      this.condition,
      "operandsAsListOptional",
      result.operandsAsListOptional
    );

    if (
      [OperatorType.UNARY, OperatorType.BINARY].includes(result.updatedValue)
    ) {
      result.updatedValue === OperatorType.UNARY &&
        this.$set(this.condition, "rightOperator", this.getEmptyValue());
      this.$set(this.condition, "auxOperator", this.getEmptyValue());
    }
  }

  getEmptyValue() {
    return {
      variable: "",
      attribute: {
        id: "",
        nextAttribute: null,
      },
      value: "",
    };
  }

  // reason: validation on blur is needed
  fieldFocusHandler(field: string) {
    const blurredFields = new Set(this.blurredFields);

    blurredFields.delete(field);

    this.blurredFields = blurredFields;
  }

  fieldBlurHandler(field: string) {
    const blurredFields = new Set(this.blurredFields);

    blurredFields.add(field);

    this.blurredFields = blurredFields;
  }

  prepareStatusObject(position: string, id: number) {
    const namePrefix =
      (position.length > 0 ? `${position}_` : "") + "operator_";
    const name = namePrefix + this.level + "_" + id;

    let errors: string[] = [];

    if (
      (this.initialStatus && !!this.initialStatus[name]) ||
      this.blurredFields.has(name) ||
      this.forceValidation
    ) {
      const isListImposedRight =
        `${position}_operator_` +
        this.level +
        "_" +
        id +
        "_rightOperandAsListRequired";

      const isListImposedLeft =
        `${position}_operator_` +
        this.level +
        "_" +
        id +
        "_operandsAsListOptional";

      errors = this.form.controls[name] && this.form.controls[name].errors;
      let errorsImposedRight =
        this.form.controls[isListImposedRight] &&
        this.form.controls[isListImposedRight].errors;
      let errorsImposedLeft =
        this.form.controls[isListImposedLeft] &&
        this.form.controls[isListImposedLeft].errors;

      if (this.value && this.value.status) {
        errors = this.value.status[name]
          ? [...this.value.status[name].message]
          : [];

        errorsImposedRight =
          this.value.status[isListImposedRight] &&
          this.value.status[isListImposedRight].message;
        errorsImposedLeft =
          this.value.status[isListImposedLeft] &&
          this.value.status[isListImposedLeft].message;
      }

      if (errorsImposedRight) {
        errors.push(...errorsImposedRight);
      }

      if (errorsImposedLeft) {
        errors.push(...errorsImposedLeft);
      }
    }

    return {
      type: "danger",
      message: errors || [],
    };
  }

  onRowNumberMouseOver() {
    this.hover = true;
  }

  onRowNumberMouseLeave() {
    this.hover = false;
  }
}
</script>

<style lang="scss">
@import "./ConditionRow.component.scss";
</style>
