import { FormData } from "./Form.model";
import { FormAssignee } from "./FormInstance.model";

export enum FormTemplateType {
  USER_TASK = 0,
  GENERAL = 1,
}

export enum FormTemplateStatus {
  DRAFT = 0,
  PUBLISHED = 1,
}

export interface FormTemplateListItem {
  id: string;
  name: string;
  type: FormTemplateType;
  status: FormTemplateStatus;
  isPrivate: boolean;
  state: boolean;
  createdOn?: string;
  createdBy?: string;
  updatedOn?: string;
  updatedBy?: string;
  assignees?: FormAssignee[];
}

export interface FormTemplateBasic {
  id: string;
  name: string;
  isPrivate: boolean;
  type: FormTemplateType;
  status: FormTemplateStatus;
  state: boolean;
}

export interface FormTemplate extends FormTemplateListItem {
  data: FormData;
  workspaceId: string;
}
