<template>
  <div class="pup-c-input-group--holder">
    <pds-icon
      v-if="tooltip"
      class="material-icons--grey pds-u-m--r--8"
      icon="info"
      :isOutlined="true"
      v-tooltip="{
        content: tooltip,
      }"
    />

    <pds-table
      :config="{ ...config, items }"
      :isConfigurable="false"
      :class="[
        'pup-c-tinput',
        disabled && 'pup-c-tinput--disabled',
        isFullScreen && 'pup-c-tinput--fullscreen',
      ]"
    >
      <template v-for="column in config.columns" v-slot:[column.key]="{ row }">
        <slot :name="column.key" v-bind:row="row">
          <component
            :key="row.id"
            :class="[
              'pup-c-tinput--element',
              column.component === 'pds-input' &&
                'pup-c-tinput--element--input',
            ]"
            :is="column.component"
            :type="getComponentType(row)"
            :keyProp="row.id"
            v-model="row[column.key]"
            :placeholder="column.placeholder"
            :isTextOnBlur="true"
            :parent="parent"
            :settings="settings"
            :dataType="column.component === 'pup-data-type-badge' ? row : ''"
            :showLabel="false"
            :processVariables="processVariables"
            :inlineInputType="inlineInputTypes.GENERIC_TEXT"
            :disabled="disabled"
            :fullScreenTitle="parent && label && parent.name + ' | ' + label"
            @blur="handleBlurDuplicates"
          />
        </slot>
      </template>
      <template v-slot:actions-per-row="props">
        <slot name="actions-per-row" v-bind="props" />
      </template>
    </pds-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  ButtonComponent,
  InputComponent,
  IconComponent,
  GeneralTableComponent,
} from "@procesio/procesio-design-system";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { createGuid } from "@/utils/type/guid";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import DataTypeBadgeComponent from "@/modules/ProcessDesigner/components/Badge/DataTypeBadge/DataTypeBadge.component.vue";
import { isCustomTypeAllowed } from "@/utils/dataTypeMapper";
import {
  DataModel,
  isCustomDataModel,
} from "@/services/datamodel/DataModel.model";
import { TabsPayloadItemType } from "@/services/apiCall/tabsPayload/TabsPayload.model";
import ExpandableInputComponent from "../ExpandableInput/ExpandableInput.component.vue";
import { InlineInputTypes } from "../ExpandableInput/ExpandableInput.model";
import { EventBus, Events } from "@/utils/eventBus";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-input": InputComponent,
    "pup-expandable-input": ExpandableInputComponent,
    "pup-data-type-badge": DataTypeBadgeComponent,
    "pds-icon": IconComponent,
    "pds-table": GeneralTableComponent,
  },
  name: "pup-table-input",
})
export default class TInput extends Vue {
  @Prop() settings!: Setting;

  @Prop({ default: "" }) label!: string;

  @Prop() config!: any;

  @Prop() tooltip?: string;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) newRowAutopopulate!: boolean;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  inlineInputTypes = InlineInputTypes;

  isFullScreen = false;

  mounted() {
    EventBus.$on(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (
          (this.settings && this.settings.id === settingId) ||
          (!this.settings && !settingId)
        ) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  beforeDestoy() {
    EventBus.$off(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (
          (this.settings && this.settings.id === settingId) ||
          (!this.settings && !settingId)
        ) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  @Watch("config", { immediate: true })
  onConfigUpdate() {
    if (!this.config) {
      return;
    }

    this.config.columns.forEach((column: any) => (column.show = true));
  }

  get items() {
    if (!this.config) {
      return [];
    }

    const items = this.config.items;

    if (items.length) {
      if (
        items[items.length - 1].value != "" ||
        items[items.length - 1].key != ""
      ) {
        if (this.newRowAutopopulate) {
          items.push({
            key: "",
            value: "",
            id: createGuid(),
            type: TabsPayloadItemType.TEXT,
          });
        }
      }
    } else {
      items.push({
        key: "",
        value: "",
        id: createGuid(),
        type: TabsPayloadItemType.TEXT,
      });
    }

    return items;
  }

  getComponentType({ typeRef }: { typeRef: DataModel }) {
    return typeRef &&
      (isCustomDataModel(typeRef) || isCustomTypeAllowed(typeRef.id))
      ? "textarea"
      : "text";
  }

  handleBlurDuplicates() {
    console.log("we are bluring");

    const items = this.config.items;

    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        const sameItem = items.find(
          (it: { key: string; value: string; id: string }) =>
            it.key === items[i].key && it.key != "" && it.id !== items[i].id
        );

        if (sameItem) {
          items.splice(i, 1);
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "./TInput.component.scss";
</style>
