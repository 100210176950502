<template>
  <pup-tabs
    :processVariables="processVariables"
    :parent="parent"
    v-model="tabs"
    :disabled="disabled"
  ></pup-tabs>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Tabs from "@/modules/ProcessDesigner/components/Controls/Tabs/Tabs.component.vue";
import {
  CodeEditoreSetting,
  Direction,
  SettingType,
  TableSetting,
  TabSetting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ControlsBus } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.component.vue";

import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import {
  PayloadBodyTypes,
  TabsPayloadOldValue,
  TabsPayloadValue,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";

@Component({
  components: {
    "pup-tabs": Tabs,
  },
})
export default class TabsPayloadOldComponent extends Vue {
  @Prop({
    default: () => ({
      queryParams: [],
      headers: [],
      body: "",
    }),
  })
  value!: TabsPayloadOldValue;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  tabs: TabSetting[] = [
    {
      id: "577ac224-45d6-4b1e-b87e-a146c2b1f10a",
      label: "Query Params",
      type: SettingType.TAB,
      rowId: 0,
      columnId: 0,
      direction: Direction.NONE,
      dataTypeId: "",
      isList: false,
      limits: { min: 0, max: 0 },
      value: [
        {
          id: "1b09c4b1-723f-48bb-9906-32fee1673eb7",
          rowId: 0,
          columnId: 0,
          type: SettingType.TABLE,
          label: "",
          direction: Direction.NONE,
          dataTypeId: "",
          isList: false,
          limits: { min: 0, max: 0 },
          columns: [
            {
              key: "key",
              label: "Key",
              placeholder: "Insert key",
              component: "pds-input",
              id: "77b41447-8629-475f-a3e9-05c54b6aebd8",
            },
            {
              key: "value",
              label: "Value",
              placeholder: "Insert value",
              component: "pds-input-select",
              id: "3f308ad4-69cf-483e-9e47-a1300a3b1d40",
            },
          ],
          value: [],
        },
      ],
    },
    {
      id: "577ac224-45d6-4b1e-b87e-a146c2b1f103",
      label: "Headers",
      rowId: 0,
      columnId: 0,
      type: SettingType.TAB,
      direction: Direction.NONE,
      dataTypeId: "",
      isList: false,
      limits: { min: 0, max: 0 },
      value: [
        {
          id: "1b09c4b1-723f-48bb-9906-32fee1673eb7",
          type: SettingType.TABLE,
          rowId: 0,
          columnId: 0,
          label: "",
          direction: Direction.NONE,
          dataTypeId: "",
          isList: false,
          limits: { min: 0, max: 0 },
          columns: [
            {
              key: "key",
              label: "Key",
              placeholder: "Insert key",
              component: "pds-input",
              id: "5b7de1f7-11e4-4c4d-9ab2-de7fe30822cd",
            },
            {
              key: "value",
              label: "Value",
              placeholder: "Insert value",
              component: "pds-input-select",
              id: "f9035fec-9fe6-487c-9f34-a4480e0f1940",
            },
          ],
          value: [],
        },
      ],
    },
    {
      id: "577ac224-45d6-4b1e-b87e-a146c2b1f102",
      label: "Body",
      type: SettingType.TAB,
      rowId: 0,
      columnId: 0,
      direction: Direction.NONE,
      dataTypeId: "",
      isList: false,
      limits: { min: 0, max: 0 },
      value: [
        {
          label: "",
          type: SettingType.CODE_EDITOR,
          rowId: 0,
          columnId: 0,
          id: "1b09c4b1-723f-48bb-0011-32fee1673eb7",
          lang: "json",
          direction: Direction.NONE,
          dataTypeId: "",
          isList: false,
          limits: { min: 0, max: 0 },
          value: `
{
    "glossarys": {
        "title": "example glossary",
    "GlossDiv": {
            "title": "S",
      "GlossList": {
                "GlossEntry": {
                    "ID": "SGML",
          "SortAs": "SGML",
          "GlossTerm": "Standard Generalized Markup Language",
          "Acronym": "SGML",
          "Abbrev": "ISO 8879:1986",
          "GlossDef": {
                        "para": "A meta-markup language, used to create markup languages such as DocBook.",
            "GlossSeeAlso": ["GML", "XML"]
                    },
          "GlossSee": "markup"
                }
            }
        }
    }
}
      `,
        },
      ],
    },
  ];

  mounted() {
    this.updateValues(this.value);

    ControlsBus.$on("change:editor", this.updateBody);
  }

  destroyed() {
    ControlsBus.$off("change:editor", this.updateBody);
  }

  updateBody(val: string) {
    this.value.body = val;

    this.updateValues(this.value);
  }

  updateValues(value: TabsPayloadOldValue | TabsPayloadValue) {
    const { queryParams, headers, body } = value || {
      queryParams: [],
      headers: [],
      body: "",
    };

    const queryParamsTable = this.tabs[0].value[0] as TableSetting;

    const headersTable = this.tabs[1].value[0] as TableSetting;

    const bodyEditor = this.tabs[2].value[0] as CodeEditoreSetting;

    queryParamsTable.value = queryParams;

    headersTable.value = headers;

    // backward compatibility for body value
    let bodyValue = "";
    if (typeof body === "object" && typeof body.value === "object") {
      bodyValue = body.value[PayloadBodyTypes.RAW] as string;
    } else {
      bodyValue = (body as string) || "";
    }
    bodyEditor.value = bodyValue;
  }
}
</script>
