/**
 * guidSuffixRegexRaw pattern
 * Use this pattern to concatenate with specific guid to find any guid with guids concatenate through dot
 *
 * Example:
 * const variableId = "9e622939-604e-4775-ae68-a1abe74d7788";
 * const variableGuidRegex = new RegExp(variableId + guidSuffixRegexRaw, "g");
 *
 * It works for every mention, e.g. "9e622939-604e-4775-ae68-a1abe74d7788", "9e622939-604e-4775-ae68-a1abe74d7788.32924cca-a4ff-480f-a245-45ff50289159", and so on
 *
 * */
export const guidSuffixRegexRaw =
  "(.[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})*";

/**
 * guidRegexRaw pattern
 * Use this pattern to find any guid with guids concatenate through dot
 *
 * Use helper method isGuid
 * */
export const guidRegexRaw = `([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}${guidSuffixRegexRaw})`;

export const guidRegex = new RegExp(guidRegexRaw);

export const nullableGuid = "00000000-0000-0000-0000-000000000000";

export const isGuid = (value: string): boolean => {
  if (typeof value !== "string") {
    return false;
  }
  return guidRegex.test(value.trim());
};

export const createGuid = (): string => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
