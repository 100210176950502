<template>
  <div>
    <div class="pup-c-delay-definition--wait-for">
      <pds-input
        class="pup-c-delay-definition--wait-for--value pds-u-m--r--8"
        placeholder="Value"
        type="number"
        min="0"
        :required="true"
        :value="value ? value.value : null"
        @update-input="onInputUpdate($event, 'value')"
        :disabled="disabled"
      />
      <pds-select
        class="pup-c-delay-definition--wait-for--interval"
        placeholder="Interval"
        :options="options"
        :required="true"
        :value="value ? value.interval : null"
        @update-input="onInputUpdate($event, 'interval')"
        :disabled="disabled"
      />
    </div>
    <pds-validation-message :status="status" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import {
  InputComponent,
  PdsTypes,
  SelectComponent,
  ValidationMessage,
} from "@procesio/procesio-design-system";

import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { Definition } from "../../Delay.model";

@Component({
  components: {
    "pds-input": InputComponent,
    "pds-select": SelectComponent,
    "pds-validation-message": ValidationMessage,
  },
  model: {
    event: "update-input",
  },
})
export default class WaitFor extends Vue {
  @Prop({
    default: () => ({
      value: null,
      interval: null,
    }),
  })
  value!: Definition;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: [] }) options!: { value: number; name: string }[];

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  internalValue: Definition | null = null;

  @Watch("value", { immediate: true, deep: true })
  onValueChange(value: Definition | null) {
    let internalValue = {
      value: null,
      interval: null,
    } as Definition;

    if (value) {
      internalValue = value;
    }

    this.internalValue = internalValue;
  }

  @Emit("update-input")
  onInputUpdate(value: any, field: string) {
    const internalValue = { ...this.internalValue } as any;

    internalValue[field] = value;

    return internalValue;
  }
}
</script>

<style lang="scss">
@import "./WaitFor.component.scss";
</style>
