<template>
  <div class="pup-c-upload">
    <div class="pup-c-upload--file" v-if="value">
      <span
        class="pup-c-upload--file--title"
        v-tooltip="{
          content: fileNames,
          boundariesElement: 'window',
        }"
        >{{ fileNames }}</span
      >
      <pds-icon
        @click="deleteFile"
        class="pup-c-upload--close-but pds-u-m--l--4"
        icon="close"
        size="tiny"
      />
    </div>

    <label v-if="!value || isMultiple">
      <pds-button
        color="primary"
        type="ghost"
        size="small"
        @click="clickHandler"
      >
        Choose file
      </pds-button>
      <input
        multiple="multiple"
        ref="upload-input"
        class="pup-c-upload--input"
        type="file"
        @change="fileChangeHandler"
      />
    </label>
  </div>
</template>

<script lang="ts">
/** VUE RELATED */
import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";

import {
  ButtonComponent,
  IconComponent,
} from "@procesio/procesio-design-system";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
  },
})
export default class UploadComponent extends Vue {
  @Model("update-input") value!: File[];

  @Prop({ default: false, type: Boolean }) isMultiple!: boolean;

  get fileNames() {
    if (!this.value || !this.value.length) {
      return "";
    }

    const names = [];

    for (let index = 0; index < this.value.length; index++) {
      const element = this.value[index];
      names.push(element.name);
    }

    return names.join(", ");
  }

  clickHandler() {
    const uploadInput = this.$refs["upload-input"] as HTMLInputElement;

    uploadInput.click();
  }

  fileChangeHandler(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    this.$emit("update-input", [...(this.value || []), ...(files || [])]);
  }

  deleteFile() {
    this.$emit("update-input", null);
  }
}
</script>

<style lang="scss">
@import "./Upload.component.scss";
</style>
