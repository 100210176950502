import { actions, ActionTypes } from "./signalR.actions";
import { mutations } from "./signalR.mutations";
import getters from "./signalR.getters";
import { HubConnection } from "@microsoft/signalr";
import { SignalRImplementation } from "@/utils/signalR";
import { store } from "@/store";

export interface SignalRState {
  connection: HubConnection | null;
  connectionId: string | null;
}

const connection = SignalRImplementation.initSignalRConnection();

if (connection) {
  connection.onreconnected((id: string | undefined) => {
    store.dispatch("signalR/" + ActionTypes.SET_CONNECTION_ID, id);
  });

  connection.onclose(() => {
    store.dispatch(
      "signalR/" + ActionTypes.SET_CONNECTION,
      SignalRImplementation.initSignalRConnection()
    );
    store.dispatch("signalR/" + ActionTypes.SET_CONNECTION_ID, null);
  });
}

export const SignalRStore = {
  namespaced: true,
  state: {
    connection,
    connectionId: null,
  },
  actions,
  mutations,
  getters,
};
