<template>
  <div>
    <div class="pup-c-d-manager--line" />
    <pup-card-button
      :isDefault="true"
      :disabled="disabled"
      :value="value"
      :parent="parent"
      :settings="settings"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import language from "@/utils/locale/en.json";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import ConditionCardButton from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/card/button/Button.component.vue";

@Component({
  components: {
    "pup-card-button": ConditionCardButton,
  },
})
export default class DecisionalDefault extends Vue {
  @Prop() value!: string;

  @Prop() parent!: Node;

  @Prop() settings!: Setting;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  translation: Record<string, string> = language;
}
</script>

<style scoped lang="scss">
@import "./DecisionalManager.component.scss";
</style>
