import { store } from "@/store";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";
import { getKeyboardEventCode, KeyboardCode } from "@/utils/keyboard";

document.body.addEventListener("keydown", (event: KeyboardEvent) => {
  if (event.repeat) {
    return;
  }

  store.dispatch(
    UIActionTypes.ADD_PRESSED_KEYBOARD_CODE,
    getKeyboardEventCode(event)
  );
});

document.body.addEventListener("keyup", (event: KeyboardEvent) => {
  if (event.repeat || !event.key) {
    return;
  }

  // in macos the keyup event is not fired when the Meta key is held down.
  // so, when Meta key is lifted, assume other keys have also been lifted as well
  if (event.key.toLowerCase() == KeyboardCode.META) {
    store.dispatch(UIActionTypes.CLEAR_PRESSED_KEYBOARD_CODES);
  } else {
    store.dispatch(
      UIActionTypes.DELETE_PRESSED_KEYBOARD_CODE,
      getKeyboardEventCode(event)
    );
  }
});

window.addEventListener("blur", () => {
  store.dispatch(UIActionTypes.CLEAR_PRESSED_KEYBOARD_CODES);
});
