import RestService, { RestResponse } from "../Rest.service";
import { SettingTab } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import RestUrls from "../Rest.paths";
import { Action, Parameter, Variable } from "../crud/Orchestration.service";

export interface ActionResponseLazy {
  internalId?: string;
  actionId: string;
  caption: string;
  description: string;
  icon: string;
  id: string;
  isCustom: boolean;
  isTestable: boolean;
  updatedOn: string;
  permissions: {
    canDelete: boolean;
    canDuplicate: boolean;
    canAddFromToolbar: boolean;
    canSaveActionAsTemplate: boolean;
  };
  name: string;
  inputPorts: number;
  outputPorts: number;
  canDuplicate: boolean;
  canDelete: boolean;
  shape: string;
  submitedBy: string;
  title: string;
  tooltip: string;
  isProcesioAction: boolean;
  configuration?: SettingTab[];
}

enum GroupType {
  action = 0,
  node = 1,
  prototype = 2,
}
export interface Groups {
  type: GroupType;
  id?: string;
  nodeId: string;
  order: number;
  name: string | null;
  children: Groups[] | null;
}

export interface GroupedActions {
  grouping: Groups[];
  prototypeActions: any[];
  actions: ActionResponseLazy[];
}

export enum OperatorType {
  UNARY = "UNARY",
  BINARY = "BINARY",
  TERNARY = "TERNARY",
}

export interface Operator {
  dataTypes: Array<{ id: string; name: string }>;
  rightOperandAsListRequired: boolean;
  operandsAsListOptional: boolean;
  operatorIcon: string;
  operatorName: string;
  operatorType: OperatorType;
}
export interface TestActionPayload {
  variables: Variable[];
  testValues: Array<{
    id?: string;
    variableId: string;
    value: string;
    attribute?: { attributeId: string; nextAttribute: unknown } | null;
  }>;
  action: {
    gtid: string;
    parameters: Parameter[];
  };
  connectionId: string;
}

interface ActionPrototype {
  id: string;
  name: string;
  parentNodeId: string;
  actionTemplate: Action;
  isProcesio: boolean;
  icon?: string;
}

export interface ActionResponseFull extends ActionResponseLazy {
  configuration: SettingTab[];
}

export interface TestActionResponseDTO {
  status: number;
  testResult: string;
  userName: string;
  variables: Variable[];
  errorMessage?: string;
  errorCode?: number;
}

export default class ActionListlService {
  static getActionList() {
    return RestService.get<GroupedActions>({
      url: RestUrls.API.ACTION,
      isAuth: true,
    });
  }

  static getActionRestrictedList() {
    return RestService.get<GroupedActions>({
      url: RestUrls.API.ACTIONS_RESTRICTED,
      isAuth: true,
    });
  }

  static search(keyword: string) {
    return RestService.get<{
      actions: ActionResponseFull[];
      prototypes: ActionPrototype[];
    }>({
      url: `${RestUrls.API.ACTION}?actionFilter=${keyword}&getFullAction=true`,
      isAuth: true,
    });
  }

  static getActionConfiguration(actionId: string) {
    return RestService.get<ActionResponseFull>({
      url: `${RestUrls.API.ACTION}/${actionId}`,
      isAuth: true,
    });
  }

  static testAction(action: TestActionPayload) {
    return RestService.post({
      url: `api/actions/test`,
      isAuth: true,
      data: action,
    });
  }

  static uploadTestActionFile(
    header: {
      testActionId: string;
      variableId: string;
      fileId: string;
      connectionId: string;
    },
    file: File
  ) {
    const formData = new FormData();
    formData.append("package", file);

    return RestService.post({
      url: "api/file/upload/testAction",
      isAuth: true,
      header: header,
      workspaced: true,
      data: formData,
    });
  }

  static async downloadTestActionFile(header: {
    uploadFilePath: string;
    variableId?: string;
  }) {
    return RestService.get<File>({
      url: "api/file/download/testAction",
      isAuth: true,
      workspaced: true,
      header: header,
    });
  }

  static getOperatorList() {
    return RestService.get<Operator[]>({
      url: `api/Actions/decisional/operators`,
      isAuth: true,
    });
  }

  static getTestResponse(testId: number) {
    return RestService.get<TestActionResponseDTO>({
      url: `api/actions/test/${testId}`,
      isAuth: true,
    });
  }

  static getActionByCategory(category: string) {
    return RestService.get<GroupedActions>({
      url: `api/actions/category/${category}?getFullAction=true`,
      isAuth: true,
    });
  }

  static getActionByGroup(groupId?: string): Promise<
    RestResponse<{
      actions: ActionResponseFull[];
      prototypes: ActionPrototype[];
    }>
  >;

  static getActionByGroup(isCustom?: boolean): Promise<
    RestResponse<{
      actions: ActionResponseFull[];
      prototypes: ActionPrototype[];
    }>
  >;

  static getActionByGroup(paramOne?: string | boolean) {
    let url = `api/actions/node?getFullAction=true`;
    let header;

    if (typeof paramOne === "boolean") {
      if (paramOne) {
        url = `${url}&isCustom=true`;
      }
    } else {
      if (paramOne) {
        header = { id: paramOne };
      }
    }

    return RestService.get<{
      actions: ActionResponseFull[];
      prototypes: ActionPrototype[];
    }>({
      url,
      isAuth: true,
      header,
    });
  }
}
