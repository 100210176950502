import { actions } from "./App.actions";
import { mutations } from "./App.mutations";
import getters from "./App.getters";

export interface AppState {
  version: number | null;
  authApiVersion: number | null;
  baseApiVersion: number | null;
}

const getInitialState = () => {
  return {
    version: null,
    authApiVersion: null,
    baseApiVersion: null,
  };
};

export const AppStore = {
  state: getInitialState(),
  actions,
  mutations,
  getters,
};
