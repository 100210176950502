<template>
  <pds-select
    class="pup-c-comparison-operator-select"
    :value="value"
    @update-input="updateInput"
    :options="options"
    @blur="$emit('blur')"
    :status="status"
    :showValidationMessage="false"
    :disabled="disabled"
  >
    <template v-slot:option="{ row }">
      <div class="pup-c-comparison-operator-select--option">
        <pds-icon class="pds-u-m--r--4" :icon="row.option.icon" />
        <label> {{ row.option.name }} </label>
      </div>
    </template>

    <template v-slot:singleLabel="{ selectedOption }">
      <div class="pup-c-comparison-operator-select--selected-option">
        <pds-icon
          class="pds-u-m--r--4"
          :icon="selectedOption.icon"
          v-tooltip="{
            content: getTooltip(selectedOption),
          }"
        />
        <!-- <label> {{ selectedOption.name }} </label> -->
      </div>
    </template>
  </pds-select>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model, Emit, Watch } from "vue-property-decorator";

import {
  SelectComponent,
  IconComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import {
  Operator,
  OperatorType,
} from "@/services/actionlist/ActionList.service";
import { relationalOperators } from "@/services/condition/Condition.model";

@Component({
  components: {
    "pds-select": SelectComponent,
    "pds-icon": IconComponent,
  },
})
export default class ComparisonOperatorComponent extends Vue {
  @Model("update-input") value!: string;

  @Prop() selectedDataType!: string;

  @Prop({ default: false }) isList!: boolean;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Watch("selectedDataType")
  watchForDataChange() {
    this.loadOperators();
  }

  @Watch("value", { immediate: true })
  updateOldOperatorValues(value: any) {
    const backWardCompatValue = relationalOperators.find(
      (operator) => operator.value === value
    );

    if (backWardCompatValue) {
      this.$emit("update-input", backWardCompatValue.name);
    }
  }

  allOperators: Operator[] = [];

  options: Array<{
    value: string;
    name: string;
    icon: string;
    type: OperatorType;
    operandsAsListOptional: boolean;
    rightOperandAsListRequired: boolean;
  }> = [];

  mounted() {
    this.loadOperators();
  }

  async loadOperators() {
    if (this.allOperators.length < 1) {
      this.allOperators = this.$store.state.processes.operands;
    }

    let optionsToSend = this.allOperators
      .map((operator) => ({
        ...operator,
        dataTypes: operator.dataTypes.map((dataType) => dataType.id),
      }))
      .filter((operator) => operator.dataTypes.includes(this.selectedDataType));

    if (this.isList) {
      // const excludeIfList = ["CONTAINS", "DOES_NOT_CONTAIN"];
      optionsToSend = optionsToSend.filter(
        (option) => option.operandsAsListOptional
      );
    }

    this.options = optionsToSend.map((operator) => ({
      value: operator.operatorName,
      name: operator.operatorName.toLowerCase().replaceAll("_", " "),
      icon: operator.operatorIcon,
      type: operator.operatorType,
      operandsAsListOptional: operator.operandsAsListOptional,
      rightOperandAsListRequired: operator.rightOperandAsListRequired,
    }));
  }

  @Emit("update-input")
  updateInput(updatedValue: string) {
    const option = this.options.find((value) => value.value === updatedValue);

    this.$emit("update-conditions", {
      updatedValue: option?.type,
      operandsAsListOptional: option?.operandsAsListOptional,
      rightOperandAsListRequired: option?.rightOperandAsListRequired,
    });
    return updatedValue;
  }

  getTooltip(option: {
    value: string;
    name: string;
    icon: string;
    type: OperatorType;
    operandsAsListOptional: boolean;
    rightOperandAsListRequired: boolean;
  }) {
    return option.name[0].toUpperCase() + option.name.slice(1);
  }
}
</script>

<style lang="scss">
@import "./ComparisonOperator.component.scss";
</style>
