<template>
  <div
    :class="[
      'pds-u-m--t--16 pup-c-card-button',
      selected && 'pup-c-card-button--selected',
    ]"
    :style="{
      minHeight: isDefault && '56px',
    }"
    :tabindex="isObjectValue ? 0 : null"
    @click="openCondition"
  >
    <div
      :class="[
        'pup-c-card-button--eclipse',
        selected && 'pup-c-card-button--eclipse--selected',
        'pup-c-card-button--drag-handle', // used in draggable parent
        'pds-u-m--l--16',
      ]"
      @mouseenter="isDragHandleHovered = true"
      @mouseleave="isDragHandleHovered = false"
      @mouseup="isDragHandleHovered = false"
    >
      <pds-icon v-if="!isObjectValue" icon="repeat" size="tiny" />
      <template v-else>
        <pds-icon
          v-if="isDragHandleHovered && !disabled"
          icon="drag_indicator"
          :isOutlined="true"
          size="tiny"
        />
        <template v-else> {{ index + 1 }}</template>
      </template>
    </div>

    <!-- THIS IS THE MANAGER -->
    <div class="pds-u-m--l--8 pup-c-card-button--title">
      <p
        class="pds-u-caption pup-c-card-button--text"
        v-tooltip="{
          content: getStatus(true),
        }"
      >
        {{ getStatus(false) }}
      </p>
      <div class="pup-c-card-button--order pds-u-m--t--4">
        <p class="pds-u-caption">{{ translation.warn_target }}</p>
        <div
          v-if="getIcon() && getName()"
          class="pup-c-card-button--badge pds-u-m--l--8"
        >
          <pds-icon v-if="getIcon()" :icon="getIcon()" size="tiny" />
          <p class="pds-u-caption pds-u-m--l--8">
            {{
              getName().substring(0, 18) + (getName().length > 18 ? "..." : "")
            }}
          </p>
        </div>
        <span class="pup-c-card-button--order--no-target" v-else>
          {{ isDefault ? "no default target set" : "not set" }}
        </span>
      </div>
    </div>
    <div class="pup-c-card-button--action">
      <pds-icon
        v-if="!disabled || (disabled && isDefault)"
        :icon="isDefault ? 'info' : 'delete'"
        size="tiny"
        :isOutlined="!isDefault"
        :class="[
          'pup-c-card-button--action--icon--' + (isDefault ? 'info' : 'delete'),
          selected !== undefined && 'pds-u-m--r--8',
        ]"
        v-tooltip="{
          content: isDefault
            ? !targetNode
              ? 'Stop process with errors if none of the conditions will be fulfilled.<br>In a Foreach it will go to the next iteration.'
              : 'Go to target action if none of the conditions will be fulfilled.'
            : null,
        }"
        @click.stop="isDefault ? () => {} : deleteCase()"
      />
      <pds-icon
        v-if="(disabled || selected !== undefined) && !isDefault"
        icon="chevron_right"
        size="small"
        :isOutlined="!isDefault"
        :class="[
          'material-icons--grey',
          selected && 'pup-c-card-button--action--icon--selected',
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IconComponent, PdsTypes } from "@procesio/procesio-design-system";
import language from "@/utils/locale/en.json";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { FormBuilder, Validators } from "@/utils/ReactiveForm";
import { DecisionalCardValue } from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/ConditionBuilder.model";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import {
  Operator,
  OperatorType,
} from "@/services/actionlist/ActionList.service";
import { getTargetLine } from "@/services/condition/Condition.model";
import { Condition, getTargetNode } from "@/services/condition/Condition.model";

@Component({
  components: {
    "pds-icon": IconComponent,
  },
  name: "pup-card-button",
})
export default class DecisionalCardButton extends mixins(Variable) {
  @Prop() isDefault!: boolean;

  @Prop() index!: number;

  @Prop() parent!: Node;

  @Prop() value!: DecisionalCardValue | string;

  @Prop() settings!: Setting;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: undefined }) selected?: boolean | undefined;

  translation: Record<string, string> = language;

  isDragHandleHovered = false;

  get isObjectValue() {
    return !!this.value && typeof this.value === "object";
  }

  get targetNode() {
    if (!this.value) {
      return null;
    }

    return getTargetNode(
      this.parent,
      typeof this.value === "object" ? this.value.target : this.value
    );
  }

  created() {
    // reset target value if it is invalid
    if (
      (this.isDefault && this.value !== (this.targetNode?.id || null)) ||
      (!this.isDefault &&
        this.isObjectValue &&
        !this.isDefault &&
        ((this.value as DecisionalCardValue)?.target || null) !==
          (this.targetNode?.id || null))
    ) {
      if (this.isDefault) {
        this.settings.value = this.targetNode?.id || null;
      } else {
        const caseItem = this.settings.value.find(
          (item: DecisionalCardValue) =>
            item.internalId === (this.value as DecisionalCardValue).internalId
        );
        if (caseItem) {
          caseItem.target = this.targetNode?.id || null;
        }
      }
    }
  }

  getStatus(full: boolean) {
    if (this.isObjectValue) {
      this.value?.condition.forEach((_condition) => {
        if (_condition.leftOperator && _condition.rightOperator) {
          if (_condition.leftOperator.value === " ") {
            _condition.leftOperator.value = "";
          }
          if (_condition.rightOperator.value === " ") {
            _condition.rightOperator.value = "";
          }
        }
      });

      if (!this.isConfigured) {
        return full
          ? this.value.name
          : this.value.name.length > 36
          ? this.value.name.substring(0, 36) + "..."
          : this.value.name;
      }
    }

    if (!this.isObjectValue) {
      return language.warn_title;
    }
    return language.warn_not_configured;
  }

  get form() {
    if (!this.isObjectValue) {
      return null;
    }

    const form = new FormBuilder();

    form.control("name", this.value.name, [
      Validators.required,
      Validators.minLength(1),
    ]);
    form.control("target", this.value.target, [Validators.required]);

    form.control("conditions", this.value.condition, [Validators.required]);

    const addConditionToForm = (conditions: Condition[], level: number) => {
      for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i];

        if (!condition.value) {
          form.control(
            "left_operator_" + level + "_" + condition.id,
            condition.leftOperator?.value,
            [Validators.required]
          );

          // if (!condition.operandsAsListOptional) {
          //   form.control(
          //     "left_operator_" + level + "_" + i + "_operandsAsListOptional",
          //     this.isVariableList(condition.leftOperator!.value),
          //     [Validators.required, Validators.notchecked]
          //   );
          // }

          const operator: Operator | null = this.$store.getters.getConditionOperandByName(
            condition.operator
          );
          if (operator?.operatorType !== OperatorType.UNARY) {
            form.control(
              "right_operator_" + level + "_" + condition.id,
              condition.rightOperator?.value,
              [Validators.required]
            );
          }

          if (condition.rightOperandAsListRequired) {
            form.control(
              "right_operator_" +
                level +
                "_" +
                i +
                "_rightOperandAsListRequired",
              this.isVariableList(condition.rightOperator?.value as string),
              [Validators.required, Validators.checked]
            );
          }

          form.control(
            "operator_" + level + "_" + condition.id,
            condition.operator,
            [Validators.required]
          );
        } else if (Array.isArray(condition.value)) {
          form.control(
            "condition_group" + level + "_" + condition.id,
            condition.value,
            [Validators.required]
          );
        }

        if (condition.auxOperator) {
          form.control(
            "aux_operator_" + level + "_" + condition.id,
            condition.auxOperator.value,
            []
          );
        }

        if (condition.value) {
          condition.value && addConditionToForm(condition.value, level + 1);
        }
      }
    };

    addConditionToForm(this.value.condition, 0);

    return form;
  }

  get isConfigured() {
    this.form.validate();

    const status: {
      [key: string]: PdsTypes.InputStatus;
    } = typeof this.value.status === "object" ? this.value.status : {};

    const controlKeys = Object.keys(this.form.controls);
    Object.keys(status)
      .filter((key) => !controlKeys.includes(key))
      .forEach((key) => {
        delete status[key];
      });

    controlKeys.forEach((key) => {
      if (status[key]) {
        status[key].message = this.form.controls[key].errors || [];
      } else {
        status[key] = {
          type: PdsTypes.StatusType.ERROR,
          message: this.form.controls[key].errors || [],
        };
      }
    });

    if (!this.value.status) {
      this.value.status = status;
    }

    return this.form.hasErrors;
  }

  @Watch("value")
  onValueUpdate() {
    setTimeout(() => {
      // reset flag on drag end
      this.isDragHandleHovered = false;
    }, 0);
  }

  getName() {
    return this.targetNode?.name;
  }

  getIcon() {
    return this.targetNode?.icon;
  }

  openCondition() {
    if (this.isObjectValue) {
      this.$emit("click");
    }
  }

  deleteCase() {
    if (this.isDefault || this.disabled) {
      return;
    }

    // remove case and its connector (if defined)
    const line = getTargetLine(this.parent, this.targetNode?.id);
    if (line) {
      this.parent.lineArray = this.parent.deleteLine(line);
    }

    if (Array.isArray(this.settings.value)) {
      const caseIndex = this.settings.value.findIndex(
        (item: DecisionalCardValue) =>
          item.internalId === (this.value as DecisionalCardValue).internalId
      );
      if (caseIndex !== -1) {
        this.settings.value.splice(caseIndex, 1);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./Button.component.scss";
</style>
