import { Variable } from "@/services/crud/Orchestration.service";
import {
  DataModel,
  DataModelAttribute,
} from "@/services/datamodel/DataModel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { store } from "@/store";
import { isGuid } from "@/utils/type/guid";

const state: any = store.state;

export interface ValueDataTypes {
  scalar: DataModel[];
  list: DataModel[];
}

export const getVariableList = (
  extraVariables: Variable[] = [],
  scopes: string[] = []
) => {
  const variables: Variable[] = [
    ...state.processes.instanceVariables,
    ...state.processes.variables,
    ...store.getters.extraVariablesFlatList,
    ...state.document.variables,
    ...extraVariables,
  ];

  if (!scopes || (Array.isArray(scopes) && !scopes.length)) {
    return variables;
  }

  // filter variables if scropes are provided
  return variables.filter((variable) => {
    let isInScopes = true;
    scopes.forEach((scopeId) => {
      if (
        isInScopes &&
        !(
          Array.isArray(variable.scopesId) &&
          !!variable.scopesId.length &&
          variable.scopesId.includes(scopeId)
        )
      ) {
        isInScopes = false;
      }
    });

    return isInScopes;
  });
};

export const getValueDataTypes = (
  value: string | null | undefined,
  extraVariables: ProcessVariable[] | Variable[] = [],
  scopes: string[] = []
): ValueDataTypes => {
  if (!value) {
    return {
      scalar: [],
      list: [],
    };
  }

  const scalarDataTypes: Set<DataModel> = new Set();
  const listDataTypes: Set<DataModel> = new Set();
  const variablesStr = value
    .split(" ")
    .filter((valueStr: string) => isGuid(valueStr));

  variablesStr.forEach((varStr: string) => {
    const ids = varStr.split(".");

    const variable = getVariableList(extraVariables as Variable[], scopes).find(
      (val: Variable) => val && val.id === ids[0]
    );

    const variableType: DataModel = store.getters.dataTypes.find(
      (dataType: DataModel) => variable?.dataType === dataType.id
    );

    const targetAttributeId = ids[ids.length - 1];
    if (variable && variable.id === targetAttributeId) {
      (variable.isList ? listDataTypes : scalarDataTypes).add(variableType);
    }

    let attributeType: DataModel;
    ids.slice(1).forEach((attributeId, index) => {
      const type = index === 0 ? variableType : attributeType;
      const attribute = type?.attributes?.find(
        (attr: DataModelAttribute) => attr.id === attributeId
      );

      if (attribute && attributeId === targetAttributeId) {
        const type = store.getters.dataTypes.find(
          (dataType: DataModel) => dataType.id === attribute.dataTypeId
        );
        if (type) {
          (attribute.isList ? listDataTypes : scalarDataTypes).add(type);
        }
      }

      attributeType = store.getters.dataTypes.find(
        (dataType: DataModel) => attribute?.dataTypeId === dataType.id
      );
    });
  });

  return {
    scalar: [...scalarDataTypes],
    list: [...listDataTypes],
  };
};
