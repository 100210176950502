export const SQL_RESERVED_WORDS = [
  "ABS",
  "ABSOLUTE",
  "ACCESS",
  "ACOS",
  "ACTION",
  "ADD",
  "ADD_MONTHS",
  "ADDDATE",
  "ADMIN",
  "AFTER",
  "AGGREGATE",
  "ALL",
  "ALLOCATE",
  "ALTER",
  "AND",
  "ANY",
  "APP_NAME",
  "APPLOCK_MODE",
  "APPLOCK_TEST",
  "ARE",
  "ARRAY",
  "AS",
  "ASC",
  "ASCII",
  "ASIN",
  "ASSEMBLYPROPERTY",
  "ASSERTION",
  "ASYMKEY_ID",
  "AT",
  "ATAN",
  "ATN2",
  "AUDIT",
  "AUTHID",
  "AUTHORIZATION",
  "AUTONOMOUS_TRANSACTION",
  "AVG",
  "BACKUP",
  "BEFORE",
  "BEGIN",
  "BENCHMARK",
  "BETWEEN",
  "BFILENAME",
  "BIN",
  "BINARY_CHECKSUM",
  "BIT_AND",
  "BIT_COUNT",
  "BIT_OR",
  "BODY",
  "BOTH",
  "BREADTH",
  "BREAK",
  "BROWSE",
  "BULK",
  "BY",
  "CALL",
  "CASCADE",
  "CASCADED",
  "CASE",
  "CAST",
  "CATALOG",
  "CATCH",
  "CEIL",
  "CEILING",
  "CERT_ID",
  "CERTPROPERTY",
  "CHARACTER",
  "CHARINDEX",
  "CHARTOROWID",
  "CHECK",
  "CHECKPOINT",
  "CHECKSUM",
  "CHECKSUM_AGG",
  "CHR",
  "CLASS",
  "CLAUSE",
  "CLOSE",
  "CLUSTER",
  "CLUSTERED",
  "COALESCE",
  "COL_LENGTH",
  "COL_NAME",
  "COLLATE",
  "COLLATION",
  "COLLECT",
  "COLUMN",
  "COLUMNPROPERTY",
  "COLUMNS_UPDATED",
  "COMMENT",
  "COMMIT",
  "COMPLETION",
  "COMPRESS",
  "COMPUTE",
  "CONCAT",
  "CONCAT_WS",
  "CONNECT",
  "CONNECTION",
  "CONNECTIONPROPERTY",
  "CONSTANT",
  "CONSTRAINT",
  "CONSTRAINTS",
  "CONSTRUCTORCREATE",
  "CONTAINS",
  "CONTAINSABLE",
  "CONTAINSTABLE",
  "CONTEXT_INFO",
  "CONTINUE",
  "CONV",
  "CONVERT",
  "CORR",
  "CORRESPONDING",
  "COS",
  "COT",
  "COUNT",
  "COUNT_BIG",
  "COVAR_POP",
  "COVAR_SAMP",
  "CREATE",
  "CROSS",
  "CUBE",
  "CUME_DIST",
  "CURRENT",
  "CURRENT_DATE",
  "CURRENT_PATH",
  "CURRENT_REQUEST_ID",
  "CURRENT_ROLE",
  "CURRENT_TIME",
  "CURRENT_TIMESTAMP",
  "CURRENT_USER",
  "CURRVAL",
  "CURSOR_STATUS",
  "CYCLE",
  "DATA",
  "DATABASE",
  "DATABASE_PRINCIPAL_ID",
  "DATABASEPROPERTYEX",
  "DATALENGTH",
  "DATE_ADD",
  "DATE_FORMAT",
  "DATE_SUB",
  "DATEADD",
  "DATEDIFF",
  "DATEFIRST",
  "DATEFORMAT",
  "DATENAME",
  "DATEPART",
  "DB_ID",
  "DB_NAME",
  "DBCC",
  "DEALLOCATE",
  "DECLARE",
  "DECODE",
  "DECRYPTBYASMKEY",
  "DECRYPTBYCERT",
  "DECRYPTBYKEY",
  "DECRYPTBYKEYAUTOCERT",
  "DECRYPTBYPASSPHRASE",
  "DEFAULT",
  "DEFERRABLE",
  "DEFERRED",
  "DEGREES",
  "DELETE",
  "DENSE_RANK",
  "DENY",
  "DEPTH",
  "DEREF",
  "DESC",
  "DESCRIBE",
  "DESCRIPTOR",
  "DESTROY",
  "DESTRUCTOR",
  "DETERMINISTIC",
  "DIAGNOSTICS",
  "DICTIONARY",
  "DIFFERENCE",
  "DISCONNECT",
  "DISK",
  "DISTINCT",
  "DISTRIBUTED",
  "DO",
  "DOMAIN",
  "DROP",
  "DUMP",
  "DYNAMIC",
  "EACH",
  "ELSE",
  "ELSIF",
  "EMPTH",
  "ENCODE",
  "ENCRYPT",
  "ENCRYPTBYASMKEY",
  "ENCRYPTBYCERT",
  "ENCRYPTBYKEY",
  "ENCRYPTBYPASSPHRASE",
  "END",
  "END-EXEC",
  "EQUALS",
  "ERRLVL",
  "ERROR_LINE",
  "ERROR_MESSAGE",
  "ERROR_NUMBER",
  "ERROR_PROCEDURE",
  "ERROR_SEVERITY",
  "ERROR_STATE",
  "ESCAPE",
  "EVENTDATA",
  "EVERY",
  "EXCEPT",
  "EXCEPTION",
  "EXCLUSIVE",
  "EXEC",
  "EXECUTE",
  "EXISTS",
  "EXIT",
  "EXP",
  "EXPORT_SET",
  "EXTENDS",
  "EXTERNAL",
  "EXTRACT",
  "FALSE",
  "FETCH",
  "FILE",
  "FILE_ID",
  "FILE_IDEX",
  "FILE_NAME",
  "FILEGROUP_ID",
  "FILEGROUP_NAME",
  "FILEGROUPPROPERTY",
  "FILEPROPERTY",
  "FILLFACTOR",
  "FIRST",
  "FIRST_VALUE",
  "FLOOR",
  "FN_VIRTUALFILESTATS",
  "FOR",
  "FORALL",
  "FOREIGN",
  "FORMAT",
  "FORMATMESSAGE",
  "FOUND",
  "FREETEXT",
  "FREETEXTTABLE",
  "FROM",
  "FROM_DAYS",
  "FULL",
  "FULLTEXTCATALOG",
  "FULLTEXTCATALOGPROPERTY",
  "FULLTEXTSERVICE",
  "FULLTEXTSERVICEPROPERTY",
  "FUNCTION",
  "GENERAL",
  "GET",
  "GET_FILESTREAM_TRANSACTION_CONTEXT",
  "GET_LOCK",
  "GETANSINULL",
  "GETDATE",
  "GETUTCDATE",
  "GLOBAL",
  "GO",
  "GOTO",
  "GRANT",
  "GREATEST",
  "GROUP",
  "GROUPING",
  "GROUPING_ID",
  "HAS_PERMS_BY_NAME",
  "HASHBYTES",
  "HAVING",
  "HEAP",
  "HEX",
  "HEXTORAW",
  "HOLDLOCK",
  "HOST",
  "HOST_ID",
  "HOST_NAME",
  "HOUR",
  "IDENT_CURRENT",
  "IDENT_INCR",
  "IDENT_SEED",
  "IDENTIFIED",
  "IDENTITY",
  "IDENTITY_INSERT",
  "IDENTITYCOL",
  "IF",
  "IFNULL",
  "IGNORE",
  "IMMEDIATE",
  "IN",
  "INCLUDE",
  "INCREMENT",
  "INDEX",
  "INDEX_COL",
  "INDEXKEY_PROPERTY",
  "INDEXPROPERTY",
  "INDICATOR",
  "INITCAP",
  "INITIAL",
  "INITIALIZE",
  "INITIALLY",
  "INNER",
  "INOUT",
  "INPUT",
  "INSERT",
  "INSTR",
  "INSTRB",
  "INTERFACE",
  "INTERSECT",
  "INTO",
  "IS",
  "IS_MEMBER",
  "IS_NULL",
  "IS_NUMERIC",
  "IS_SRVROLEMEMBER",
  "ISDATE",
  "ISNULL",
  "ISNUMERIC",
  "ISOLATION",
  "ITERATE",
  "JAVA",
  "JOIN",
  "KEY",
  "KEY_GUID",
  "KEY_ID",
  "KILL",
  "LAG",
  "LANGUAGE",
  "LARGE",
  "LAST",
  "LAST_DAY",
  "LAST_VALUE",
  "LATERAL",
  "LCASE",
  "LEAD",
  "LEADING",
  "LEAST",
  "LEFT",
  "LEN",
  "LENGTH",
  "LENGTHB",
  "LESS",
  "LEVEL",
  "LIKE",
  "LIMIT",
  "LIMITED",
  "LINENO",
  "LN",
  "LOAD",
  "LOCAL",
  "LOCALTIME",
  "LOCALTIMESTAMP",
  "LOCATOR",
  "LOCK",
  "LOG",
  "LOG10",
  "LOOP",
  "LOWER",
  "LPAD",
  "LTRIM",
  "MAKE_REF",
  "MAP",
  "MATCH",
  "MAX",
  "MAXEXTENTS",
  "MERGE",
  "MID",
  "MIN",
  "MIN_ACTIVE_ROWVERSION",
  "MINUS",
  "MINUTE",
  "MLSLABEL",
  "MOD",
  "MODE",
  "MODIFIES",
  "MODIFY",
  "MODULE",
  "MONTH",
  "MONTHS_BETWEEN",
  "NAMES",
  "NATIONAL",
  "NATURAL",
  "NATURALN",
  "NEW",
  "NEW_TIME",
  "NEWID",
  "NEWSEQUENTIALID",
  "NEXT",
  "NEXT_DAY",
  "NEXTVAL",
  "NO",
  "NOAUDIT",
  "NOCHECK",
  "NOCOMPRESS",
  "NOCOPY",
  "NONCLUSTERED",
  "NONE",
  "NOT",
  "NOWAIT",
  "NTILE",
  "NULL",
  "NULLIF",
  "NUMBER_BASE",
  "NVL",
  "NVL2",
  "OBJECT",
  "OBJECT_DEFINITION",
  "OBJECT_ID",
  "OBJECT_NAME",
  "OBJECT_PROPERTY",
  "OBJECT_SCHEMA_NAME",
  "OBJECTPROPERTY",
  "OBJECTPROPERTYEX",
  "OCIROWID",
  "OCT",
  "OF",
  "OFF",
  "OFFLINE",
  "OFFSETS",
  "OLD",
  "ON",
  "ONLINE",
  "ONLY",
  "OPAQUE",
  "OPEN",
  "OPENDATASOURCE",
  "OPENQUERY",
  "OPENROWSET",
  "OPENXML",
  "OPERATION",
  "OPERATOR",
  "OPTION",
  "OR",
  "ORD",
  "ORDER",
  "ORDINALITY",
  "ORGANIZATION",
  "ORIGINAL_DB_NAME",
  "ORIGINAL_LOGIN",
  "OTHERS",
  "OUT",
  "OUTER",
  "OUTPUT",
  "OVER",
  "PACKAGE",
  "PAD",
  "PARAMETER",
  "PARAMETERS",
  "PARSENAME",
  "PARTIAL",
  "PARTITION",
  "PATH",
  "PATHNAME",
  "PATINDEX",
  "PCTFREE",
  "PERCENT",
  "PERCENT_RANK",
  "PERMISSIONS",
  "PI",
  "PIVOT",
  "PLAN",
  "PLS_INTEGER",
  "POSITIVE",
  "POSITIVEN",
  "POSTFIX",
  "POW",
  "POWER",
  "PRAGMA",
  "PREFIX",
  "PREORDER",
  "PREPARE",
  "PRESERVE",
  "PRIMARY",
  "PRINT",
  "PRIOR",
  "PRIVATE",
  "PRIVILEGES",
  "PROC",
  "PROCEDURE",
  "PUBLIC",
  "PUBLISHINGSERVERNAME",
  "PWDCOMPARE",
  "PWDENCRYPT",
  "QUOTENAME",
  "RADIANS",
  "RAISE",
  "RAISEERROR",
  "RAND",
  "RANGE",
  "RANK",
  "RATIO_TO_EXPORT",
  "RAWTOHEX",
  "READ",
  "READS",
  "READTEXT",
  "RECONFIGURE",
  "RECORD",
  "RECURSIVE",
  "REF",
  "REFERENCES",
  "REFERENCING",
  "REFTOHEX",
  "RELATIVE",
  "RELEASE",
  "RELEASE_LOCK",
  "RENAME",
  "REPEAT",
  "REPLACE",
  "REPLICATE",
  "REPLICATION",
  "RESOURCE",
  "RESTORE",
  "RESTRICT",
  "RESULT",
  "RETURN",
  "RETURNS",
  "REVERSE",
  "REVERT",
  "REVOKE",
  "RIGHT",
  "ROLLBACK",
  "ROLLUP",
  "ROUND",
  "ROUTINE",
  "ROW",
  "ROW_NUMBER",
  "ROWCOUNT",
  "ROWCOUNT_BIG",
  "ROWGUIDCOL",
  "ROWIDTOCHAR",
  "ROWLABEL",
  "ROWNUM",
  "ROWS",
  "ROWTYPE",
  "RPAD",
  "RTRIM",
  "RULE",
  "SAVE",
  "SAVEPOINT",
  "SCHEMA",
  "SCHEMA_ID",
  "SCHEMA_NAME",
  "SCOPE",
  "SCOPE_IDENTITY",
  "SCROLL",
  "SEARCH",
  "SECOND",
  "SECTION",
  "SECURITYAUDIT",
  "SEDDEV_SAMP",
  "SELECT",
  "SEPARATE",
  "SEQUENCE",
  "SERVERPROPERTY",
  "SESSION",
  "SESSION_USER",
  "SET",
  "SETS",
  "SETUSER",
  "SHARE",
  "SHUTDOWN",
  "SIGN",
  "SIGNBYASYMKEY",
  "SIGNBYCERT",
  "SIN",
  "SINH",
  "SIZE",
  "SOME",
  "SOUNDEX",
  "SP_HELPLANGUAGE",
  "SPACE",
  "SPECIFIC",
  "SPECIFICTYPE",
  "SQL",
  "SQL_VARIANT_PROPERTY",
  "SQLCODE",
  "SQLEXCEPTION",
  "SQLSTATE",
  "SQLWARNING",
  "SQRT",
  "SQUARE",
  "START",
  "STATE",
  "STATEMENT",
  "STATIC",
  "STATISTICS",
  "STATS_DATE",
  "STD",
  "STDDEV",
  "STDEVP",
  "STR",
  "STRCMP",
  "STRUCTURE",
  "STUFF",
  "SUBDATE",
  "SUBSTR",
  "SUBSTRB",
  "SUBSTRING",
  "SUBSTRING_INDEX",
  "SUBTYPE",
  "SUCCESSFUL",
  "SUM",
  "SUSER_ID",
  "SUSER_NAME",
  "SUSER_SID",
  "SUSER_SNAME",
  "SWITCHOFFSET",
  "SYNONYM",
  "SYS_CONTEXT",
  "SYS_GUID",
  "SYSDATE",
  "SYSDATETIME",
  "SYSDATETIMEOFFSET",
  "SYSTEM_USER",
  "SYSUTCDATETIME",
  "TABLE",
  "TABLESAMPLE",
  "TAN",
  "TANH",
  "TEMPORARY",
  "TERMINATE",
  "TEXTPTR",
  "TEXTSIZE",
  "TEXTVALID",
  "THAN",
  "THEN",
  "TIMEZONE_ABBR",
  "TIMEZONE_HOUR",
  "TIMEZONE_MINUTE",
  "TIMEZONE_REGION",
  "TO",
  "TO_CHAR",
  "TO_DATE",
  "TO_DAYS",
  "TO_NUMBER",
  "TO_SINGLE_BYTE",
  "TODATETIMEOFFSET",
  "TOP",
  "TRAILING",
  "TRAN",
  "TRANSACTION",
  "TRANSLATE",
  "TRANSLATION",
  "TREAT",
  "TRIGGER",
  "TRIGGER_NESTLEVEL",
  "TRIM",
  "TRUE",
  "TRUNC",
  "TRUNCATE",
  "TRY",
  "TSEQUAL",
  "TYPE",
  "TYPE_ID",
  "TYPE_NAME",
  "TYPEPROPERTY",
  "UCASE",
  "UID",
  "UNDER",
  "UNICODE",
  "UNION",
  "UNIQUE",
  "UNKNOWN",
  "UNNEST",
  "UNPIVOT",
  "UPDATE",
  "UPDATETEXT",
  "UPPER",
  "USAGE",
  "USE",
  "USER",
  "USER_ID",
  "USER_NAME",
  "USERENV",
  "USING",
  "VALIDATE",
  "VALUE",
  "VALUES",
  "VAR",
  "VAR_POP",
  "VAR_SAMP",
  "VARIABLE",
  "VARIANCE",
  "VARP",
  "VARYING",
  "VERIFYSIGNEDBYASMKEY",
  "VERIFYSIGNEDBYCERT",
  "VIEW",
  "VSIZE",
  "WAITFOR",
  "WHEN",
  "WHENEVER",
  "WHERE",
  "WHILE",
  "WITH",
  "WITHOUT",
  "WORK",
  "WRITE",
  "WRITETEXT",
  "XACT_STATE",
  "ZONE",
  "BFILE",
  "BIGINT",
  "BINARY",
  "BINARY_INTEGER",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "CHAR_BASE",
  "CLOB",
  "CURSOR",
  "DATE",
  "DATETIME",
  "DATETIME2",
  "DATETIMEOFFSET",
  "DAY",
  "DEC",
  "DECIMAL",
  "DOUBLE",
  "ENUM",
  "FLOAT",
  "HIERARCHYID",
  "IMAGE",
  "INT",
  "INTEGER",
  "INTERVAL",
  "LONG",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MONEY",
  "NCHAR",
  "NCLOB",
  "NTEXT",
  "NUMBER",
  "NUMERIC",
  "NVARCHAR",
  "PRECISION",
  "RAW",
  "REAL",
  "ROWID",
  "SMALLDATETIME",
  "SMALLINT",
  "SMALLMONEY",
  "SQL_VARIANT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "UNIQUEIDENTIFIER",
  "UROWID",
  "VARBINARY",
  "VARCHAR",
  "VARCHAR2",
  "XML",
  "YEAR",
].sort((wordA, wordB) => wordA.localeCompare(wordB));
