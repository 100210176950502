var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'pup-c-data-model-selector--elements',
    _vm.colored && _vm.disabled ? 'pup-c-acordeon-element-gray' : null,
  ],attrs:{"tabindex":"0"},on:{"click":_vm.pickElement,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.pickElement.apply(null, arguments)}}},[_c('div',{class:['pup-c-data-model-selector--elements--order'],style:({ 'padding-left': 16 * _vm.level + 'px' })},[_c('IconComponent',{staticClass:"pup-c-data-model-selector--elements--icon",attrs:{"size":"tiny","icon":"fiber_manual_record"}}),_vm._v(" "),_c('div',{staticClass:"pup-c-data-model-selector--elements--order--wrapper"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.truncateName(_vm.name).length === _vm.name.length ? null : _vm.name,
          boundariesElement: 'window',
          placement: 'bottom',
        }),expression:"{\n          content: truncateName(name).length === name.length ? null : name,\n          boundariesElement: 'window',\n          placement: 'bottom',\n        }"}],staticClass:"pup-u-body--1 pup-c-data-model-selector--elements--text"},[_vm._v("\n        "+_vm._s(_vm.truncateName(_vm.name))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"pup-c-data-model-selector--elements--badge-wrapper"},[_c('pup-data-type-badge',{staticClass:"pup-c-data-model-selector--elements--badge",attrs:{"dataType":_vm.dataType}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }