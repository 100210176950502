<template>
  <div
    class="pup-c-collapsable"
    :class="{ 'pup-c-collapsable--collapsed': collapsed }"
  >
    <!-- HEADER -->
    <div class="pup-c-collapsable--header">
      <p :class="['pds-u-subtitle--2', 'pup-c-collapsable--header--text']">
        {{ label }}
      </p>
      <pds-icon
        :icon="collapsed ? 'expand_more' : 'expand_less'"
        class="pup-c-collapsable--header--point"
        @click="changeCollapse"
      />
    </div>

    <!-- CONTENT -->
    <div class="pup-c-collapsable--content" v-if="!collapsed">
      <div
        v-if="
          values.length === 0 ||
          (values.length === 1 &&
            values[0] &&
            values[0].type === 'table' &&
            Array.isArray(values[0].value) &&
            values[0].value.length === 0)
        "
        class="pup-c-collapsable--empty"
      >
        You did not use any variable in your action configuration
      </div>
      <div v-else>
        <pup-table-input
          v-if="tableValue"
          :config="{
            columns: tableValue.columns,
            items: tableValue.value,
          }"
          class="pup-c-collapsable--table"
        >
          <template v-slot:key="{ row }">
            <span>{{ row.key }}</span>
          </template>
          <template v-slot:value="{ row }">
            <pup-upload-control
              v-if="
                row.typeRef &&
                row.typeRef.id === '10c6ac59-3929-49e6-99dc-121212121219'
              "
              :key="row.id"
              :isMultiple="row.isList"
              v-model="row.value"
            />
            <template v-else>
              <pup-fullscreen-input
                v-model="row.value"
                :isTextOnBlur="true"
                :fullScreenTitle="row.key"
                placeholder="Insert value"
              />
            </template>
          </template>
        </pup-table-input>
        <template v-else>
          <div v-for="value in values" :key="value.id">
            <pds-upload
              v-if="value.dataTypeId === nonPrimitives.FILE"
              :label="value.label"
              :files="getFilesValue(value)"
              :isMultiple="value.isList"
              downloadable
              disabled
              ignoreLabelFormatting
              @download="downloadFile(value, $event)"
            />
            <pup-fullscreen-input
              v-else
              class="pds-u-m--b--16"
              ignoreLabelFormatting
              :label="value.label"
              :fullScreenTitle="value.label"
              :value="value.value"
              :type="
                [settingTypes.TEXT_AREA, settingTypes.TEXT_AREA_BASIC].includes(
                  value.type
                )
                  ? 'textarea'
                  : 'text'
              "
              readonly
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Model, Prop } from "vue-property-decorator";
import {
  ButtonComponent,
  IconComponent,
  InputComponent,
  UploadComponent as PdsUploadComponent,
} from "@procesio/procesio-design-system";
import UploadComponent from "@/modules/ProcessDesigner/components/Controls/Upload/Upload.component.vue";
import {
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { NonPrimitives } from "@/utils/dataTypeMapper";
import { downloadBlob } from "@/utils/value/file";
import FullscreenInputComponent from "@/components/Controls/FullscreenInput/FullscreenInput.component.vue";
import ActionListlService from "@/services/actionlist/ActionList.service";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
    "pds-upload": PdsUploadComponent,
    "pds-input": InputComponent,
    "pup-upload-control": UploadComponent,
    "pup-fullscreen-input": FullscreenInputComponent,
  },
  name: "pup-collapsable-values",
})
export default class CollapsableValues extends Vue {
  @Prop() label!: string;

  @Prop() processVariable!: ProcessVariable[];

  @Model() values!: Partial<Setting>[];

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  collapsed = false;

  nonPrimitives = NonPrimitives;

  settingTypes = SettingType;

  get tableValue() {
    if (this.values.length === 1 && this.values[0].type === SettingType.TABLE) {
      return this.values[0];
    }

    return null;
  }

  changeCollapse() {
    this.collapsed = !this.collapsed;
    this.emitCollapse(this.collapsed);
  }

  @Emit("update-collapse")
  emitCollapse(isCollapsed: boolean) {
    return isCollapsed;
  }

  async downloadFile(output: any, index: number) {
    const files = Array.isArray(output.value) ? output.value : [output.value];
    const file = files[index];

    if (!file) {
      return;
    }

    const errorMessage = "There was an error. Please, try again later.";

    if (!file.path) {
      this.$toast.error(errorMessage);
      return;
    }

    try {
      const response = await ActionListlService.downloadTestActionFile({
        uploadFilePath: file.path,
      });

      if (response && response.content && !response.is_error) {
        downloadBlob(response.content, file.name);
      } else {
        this.$toast.error(errorMessage);
      }
    } catch (error) {
      this.$toast.error(errorMessage);
    }
  }

  getFilesValue(value: any) {
    const files = value.value || [];

    return (Array.isArray(files) ? files : [files]).map(
      (file: any) => file.name
    );
  }
}
</script>

<style scoped lang="scss">
@import "./Collapsable.component.scss";
</style>
