import { MutationTree } from "vuex";
import { UserWorkspace } from "@/services/user/workspace/UserWorkspace.service";
import { UserPermissionsState } from "./UserPermissions.store";
import { Event } from "@/services/user/event/Event.model";
import {
  ProcesioEntity,
  Role,
  UserPermissions,
  UserType,
} from "@/services/user/permissions/UserPermissions.model";

export enum MutationTypes {
  SET_PROCESIO_ENTITIES = "SET_PROCESIO_ENTITIES",
  SET_ROLES = "SET_ROLES",
  SET_USER_TYPES = "SET_USER_TYPES",
  SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS",
}

export type Mutations<S = UserPermissionsState> = {
  [MutationTypes.SET_PROCESIO_ENTITIES](
    state: S,
    payload: ProcesioEntity[]
  ): void;
  [MutationTypes.SET_ROLES](state: S, payload: Role[]): void;
  [MutationTypes.SET_USER_TYPES](state: S, payload: UserType[]): void;
  [MutationTypes.SET_USER_PERMISSIONS](
    state: S,
    payload: { permissions: UserPermissions; workspaceId: string | null }
  ): void;
};

export const mutations: MutationTree<UserPermissionsState> & Mutations = {
  [MutationTypes.SET_PROCESIO_ENTITIES](state, payload) {
    state.procesioEntities = payload;
  },
  [MutationTypes.SET_ROLES](state, payload) {
    state.roles = payload;
  },
  [MutationTypes.SET_USER_TYPES](state, payload) {
    state.userTypes = payload;
  },
  [MutationTypes.SET_USER_PERMISSIONS](
    state,
    {
      permissions,
      workspaceId,
    }: { permissions: UserPermissions; workspaceId: string | null }
  ) {
    state.userPermissionsPerWorkspace[workspaceId || ""] = permissions;
  },
};
