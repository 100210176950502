<template>
  <div>
    <div class="pds-u-subtitle--2">{{ label }}</div>
    <pup-variable-mapper-group
      class="pds-u-m--t--16"
      :settings="settings"
      :value="variableMapperValue"
      :direction="direction"
      :leftInputPlaceholder="
        settings.type === settingType.PROCESS_INPUT
          ? 'Select subprocess input'
          : 'Select subprocess output'
      "
      :rightInputPlaceholder="'Insert variable or value'"
      :leftColumnVariables="filteredSubProcessVariables"
      :leftColumnVariableScopes="
        processSelectSettingValue ? [processSelectSettingValue] : []
      "
      :rightColumnVariables="processVariables"
      :isLeftInputOneVariableOnly="settings.type === settingType.PROCESS_INPUT"
      :isRightInputOneVariableOnly="
        settings.type === settingType.PROCESS_OUTPUT
      "
      :hideCreateLeftInput="true"
      :infoText="infoText"
      :leftColumnLabel="
        settings.type === settingType.PROCESS_INPUT
          ? 'Subprocess Input'
          : 'Subprocess Output'
      "
      rightColumnLabel="Process Variable"
      :addButtonText="
        '+ Add ' +
          (settings.type === settingType.PROCESS_INPUT
            ? 'subprocess input'
            : 'process output')
      "
      :parent="parent"
      :disabled="disabled"
      @update-input="onMapperGroupValueUpdate"
      @remove-row="onRowRemove"
    />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

import {
  Setting,
  SettingType,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import language from "@/utils/locale/en.json";
import { Variable, VariableType } from "@/services/crud/Orchestration.service";
import VariableMapperGroup, {
  VariableMapperValue,
} from "@/modules/ProcessDesigner/components/Controls/VariableMapper/Group.component.vue";
import { VariableMapperDirection } from "../VariableMapper/Row.component.vue";
import { store } from "@/store";
import { DataModelScope } from "@/services/datamodel/DataModel.model";
import { PdsTypes } from "@procesio/procesio-design-system";

@Component({
  components: {
    "pup-variable-mapper-group": VariableMapperGroup,
  },
})
export default class SubprocessBuilderComponent extends Vue {
  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() label!: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() tooltip?: string;

  @Prop() value!: Array<{
    id: number;
    subprocess: string;
    process: string;
  }>;

  @Prop() required!: boolean;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  get variableMapperValue() {
    if (!this.value) {
      return this.value;
    }

    // map value to VariableMapperValue
    return this.value.map((val) => ({
      id: val.id,
      left: val.subprocess,
      right: val.process,
    })) as VariableMapperValue[];
  }

  translation: Record<string, string> = language;

  settingType = SettingType;

  get subprocessVariables() {
    return store.getters.getExtraVariablesByNodeId(this.parent.id);
  }

  get filteredSubProcessVariables() {
    return this.subprocessVariables.filter((variable: Variable) => {
      const variableDataType = this.$store.getters.getDataTypeById(
        variable.dataType
      );
      if (
        variableDataType &&
        variableDataType.scopes?.includes(DataModelScope.WEBHOOK)
      ) {
        return false;
      }

      if (
        this.settings.type === SettingType.PROCESS_INPUT &&
        variable.type === VariableType.INPUT
      ) {
        return true;
      }

      if (
        this.settings.type === SettingType.PROCESS_OUTPUT &&
        variable.type === VariableType.OUTPUT
      ) {
        return true;
      }
    });
  }

  get direction() {
    return this.settings.type === SettingType.PROCESS_INPUT
      ? VariableMapperDirection.LEFT
      : VariableMapperDirection.RIGHT;
  }

  get infoText() {
    if (this.tooltip) {
      return this.tooltip;
    }

    return this.settings.type === SettingType.PROCESS_INPUT
      ? this.translation.warn_input
      : this.translation.warn_output;
  }

  get processSelectSettingValue() {
    const nodeConfig = this.parent.configuration;

    const setting = nodeConfig[0].settings.find(
      (s) => s.type === SettingType.FLOW_LIST
    );

    return setting && setting.value ? setting.value : null;
  }

  onMapperGroupValueUpdate(value: VariableMapperValue[] | null) {
    // map VariableMapperValue to value
    let subprocessBuilderValue = [{ id: 0, subprocess: "", process: "" }];

    if (value && Array.isArray(value)) {
      subprocessBuilderValue = value.map((val) => ({
        id: val.id,
        subprocess: val.left,
        process: val.right,
      }));
    }

    const settingValues = this.settings.value;
    if (!settingValues) {
      this.settings.value = subprocessBuilderValue;
      return;
    }

    subprocessBuilderValue.forEach((val) => {
      const settingValue = settingValues.find(
        (value: { id: number; subprocess: string; process: string }) =>
          value.id === val.id
      );

      if (settingValue) {
        settingValue.subprocess = val.subprocess;
        settingValue.process = val.process;
      } else {
        settingValues.push(val);
      }
    });
  }

  onRowRemove(index: number) {
    if (this.settings.value) {
      this.settings.value.splice(index, 1);
    }
  }
}
</script>
