function validate(binding: any) {
  if (typeof binding.value !== "function") {
    console.warn(
      "[Vue-click-outside:] provided expression",
      binding.expression,
      "is not a function."
    );
    return false;
  }

  return true;
}

function isPopup(popupItem: any, elements: any) {
  if (!popupItem || !elements) return false;

  for (let i = 0, len = elements.length; i < len; i++) {
    try {
      if (popupItem.contains(elements[i])) {
        return true;
      }
      if (elements[i].contains(popupItem)) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return false;
}

const isServer = (vNode: any) =>
  (
    typeof vNode.componentInstance !== "undefined" &&
    vNode.componentInstance.$isServer
  );

const getHandlerFunctionFromBinding = (binding: any) => 
  typeof binding.arg !== "undefined" ? binding.arg : "click";

const bind = (el: any, binding: any, vNode: any) => {
  if (!validate(binding)) return;

  // Define Handler and cache it on the element
  function handler(e: any) {
    if (!vNode.context) return;

    // some components may have related popup item, on which we shall prevent the click outside event handler.
    const elements = e.path || (e.composedPath && e.composedPath());
    elements && elements.length > 0 && elements.unshift(e.target);

    if (el.contains(e.target) || 
      isPopup(vNode.context.popupItem, elements) || 
      typeof el.__vueClickOutside__?.callback !== "function") {
      return;
    }

    el.__vueClickOutside__.callback(e);
  }

  // add Event Listeners
  el.__vueClickOutside__ = {
    handler: handler,
    callback: binding.value,
  };

  setTimeout(() => {
    const clickHandler =
      "ontouchstart" in document.documentElement ? "touchstart" : getHandlerFunctionFromBinding(binding);
    !isServer(vNode) && document.addEventListener(clickHandler, handler);
  }, 0)
}

const update = (el: any, binding: any, vNode: any) => {
  if (!el.__vueClickOutside__) {
    bind(el, binding, vNode);
    return;
  } 
  
  el.__vueClickOutside__.callback = validate(binding) ? binding.value : null;
}

export default {
  inserted: bind,
  update,
  unbind: function(el: any, binding: any, vNode: any) {
    // Remove Event Listeners
    const clickHandler =
      "ontouchstart" in document.documentElement ? "touchstart" : getHandlerFunctionFromBinding(binding);
    !isServer(vNode) &&
      el.__vueClickOutside__ &&
      document.removeEventListener(
        clickHandler,
        el.__vueClickOutside__.handler
      );
    delete el.__vueClickOutside__;
  },
};
