import { BannerType, BannerUnit } from "@/components/Banner/banner.model";
import { CampaignData } from "@/services/campaign";
import { AnnouncementData } from "@/services/announcements";
import Vue from "vue";
import { MutationTree } from "vuex";
import { Loader, UIState } from "./UI.store";
import { UserPreferences } from "@/services/application/userPreferences/UserPreferences.model";

export enum MutationTypes {
  SET_LOADING_STATE = "SET_LOADING_STATE",
  SET_LOADER = "SET_LOADER",
  CLEAR_LOADER = "CLEAR_LOADER",
  SET_DISABLED_STATE = "SET_DISABLED_STATE",
  SET_BANNER_TYPE = "SET_BANNER_TYPE",
  SET_ANNOUNCEMENTS_DATA = "SET_ANNOUNCEMENTS_DATA",
  SET_CAMPAIGN_DATA = "SET_CAMPAIGN_DATA",
  SET_PRESSED_KEYBOARD_CODES = "SET_PRESSED_KEYBOARD_CODES",
  SET_USER_PREFERENCES = "SET_USER_PREFERENCES",
}

export type Mutations<S = UIState> = {
  [MutationTypes.SET_LOADING_STATE](state: S, payload: boolean): void;
  [MutationTypes.SET_LOADER](state: S, payload: Loader): void;
  [MutationTypes.CLEAR_LOADER](state: S, payload: Loader): void;
  [MutationTypes.SET_DISABLED_STATE](state: S, payload: boolean): void;
  [MutationTypes.SET_BANNER_TYPE](
    state: S,
    payload: {
      key: BannerUnit;
      value: BannerType | null;
    }
  ): void;
  [MutationTypes.SET_ANNOUNCEMENTS_DATA](
    state: S,
    payload: AnnouncementData[]
  ): void;
  [MutationTypes.SET_CAMPAIGN_DATA](
    state: S,
    payload: CampaignData | null
  ): void;
  [MutationTypes.SET_PRESSED_KEYBOARD_CODES](state: S, payload: string[]): void;
  [MutationTypes.SET_USER_PREFERENCES](
    state: S,
    payload: UserPreferences
  ): void;
};

export const mutations: MutationTree<UIState> & Mutations = {
  [MutationTypes.SET_LOADING_STATE](state, payload) {
    state.isLoading = payload;
  },
  [MutationTypes.SET_LOADER](state, payload) {
    state.loaders.push(payload);
  },
  [MutationTypes.CLEAR_LOADER](state, payload) {
    const index = state.loaders.findIndex((item) => item.id === payload.id);

    if (index !== -1) {
      state.loaders.splice(index, 1);
    }
  },
  [MutationTypes.SET_DISABLED_STATE](state, payload) {
    state.isDisabled = payload;
  },
  [MutationTypes.SET_BANNER_TYPE](
    state,
    payload: {
      key: BannerUnit;
      value: BannerType | null;
    }
  ) {
    Vue.set(state.bannerTypes, payload.key, payload.value);
  },
  [MutationTypes.SET_ANNOUNCEMENTS_DATA](state, data) {
    state.announcements = data;
  },
  [MutationTypes.SET_CAMPAIGN_DATA](state, data) {
    state.campaign = data;
  },
  [MutationTypes.SET_PRESSED_KEYBOARD_CODES](state, codes) {
    state.keyboardPressedCodes = codes;
  },
  [MutationTypes.SET_USER_PREFERENCES](state, preferences) {
    state.userPreferences = preferences;
  },
};
