import { isObject, forIn, omit, pick } from "lodash";

export function deepCopy(src: any) {
  const target: any = Array.isArray(src) ? [] : {};
  for (const key in src) {
    const v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v);
      } else {
        target[key] = v;
      }
    } else {
      target[key] = v;
    }
  }

  return target;
}

export const omitDeep = (obj: any, ...paths: any) => {
  obj = omit(obj, ...paths);

  forIn(obj, (value, key) => {
    if (isObject(value)) {
      obj[key] = omitDeep(value, ...paths);
    }
  });

  return obj;
};

export const pickDeep = (obj: any, ...paths: any) => {
  let resultObj: any = {};

  resultObj = pick(obj, ...paths);

  if (!Object.keys(resultObj).length) {
    forIn(obj, (value, key) => {
      if (isObject(value)) {
        resultObj[key] = pickDeep(value, ...paths);
      }
    });
  }

  return resultObj;
};
