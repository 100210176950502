<template>
  <div>
    <div class="pup-c-delay-definition--wait-until">
      <pup-date-time-picker
        @update-input="onInputUpdate"
        :value="value && value.value"
        :minDateTime="minDateTime"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import { Component, Emit, Prop } from "vue-property-decorator";
import DateTimePicker from "@/components/Controls/DateTime/DateTimePicker.component.vue";
import { Definition } from "../../Delay.model";
import { PdsTypes } from "@procesio/procesio-design-system";

@Component({
  components: {
    "pup-date-time-picker": DateTimePicker,
  },
})
export default class WaitUntil extends Vue {
  @Prop({
    default: () => ({
      value: new Date(),
      interval: null,
    }),
  })
  value!: Definition;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  get minDateTime() {
    return new Date();
  }

  @Emit("update-input")
  onInputUpdate(value: any) {
    const internalValue = { ...this.value } as any;

    internalValue.value = value;

    return internalValue;
  }
}
</script>

<style lang="scss">
@import "./WaitUntil.component.scss";
</style>
