import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";
import { UserWorkspaceType } from "@/services/user/workspace/UserWorkspace.service";
import { Route, RouteConfig } from "vue-router";
import { RouteName as MainRouteName } from "@/router";

const Layout = () =>
  import("@/modules/ResourceMap/presentation/ResourceMap.layout.vue");

const ResourceMap = () =>
  import(
    "@/modules/ResourceMap/presentation/pages/View/ResourceMap.component.vue"
  );

const ResourceMapTopbar = () =>
  import(
    "@/modules/ResourceMap/presentation/components/Topbar/ResourceMapTopbar.component.vue"
  );

export enum RouteName {
  RESOURCE_MAP = "Resource Map",
}

export const RoutePath = "resource-map";

export const isResourceMapRoutePermitted = () =>
  [
    ProcesioEntityType.ProcessDesigner,
    ProcesioEntityType.Schedule,
    ProcesioEntityType.Webhook,
  ].every((entityType) =>
    UserPermissionsGuard.isPermitted(entityType, RoleType.Read)
  );

export const routes: RouteConfig = {
  path: RoutePath,
  component: Layout,
  name: RouteName.RESOURCE_MAP,
  meta: {
    title: RouteName.RESOURCE_MAP,
    workspaceTypes: [
      UserWorkspaceType.PERSONAL,
      UserWorkspaceType.SUBWORKSPACE,
      UserWorkspaceType.ORGANIZATION,
    ],
  },
  children: [
    {
      path: "/",
      component: ResourceMap,
      name: RouteName.RESOURCE_MAP,
      meta: {
        topbar: ResourceMapTopbar,
      },
      beforeEnter: (to: Route, from: Route, next) => {
        isResourceMapRoutePermitted()
          ? next()
          : next({ name: MainRouteName.DASHBOARD });
      },
    },
  ],
};
