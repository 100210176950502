import { actions } from "./Document.actions";
import { mutations } from "./Document.mutations";
import getters from "./Document.getters";
import { Variable } from "@/services/crud/Orchestration.service";

export interface DocumentState {
  title: string | null;
  variables: Variable[];
}

const getInitialState = () => {
  return {
    title: "",
    variables: []
  };
}

export const DocumentStore = {
  state: getInitialState(),
  actions,
  mutations,
  getters,
};
