import { TableKey } from "@/services/application/table/Table.model";
import { getPlatform } from "@/utils/platform";
import { UIState } from "./UI.store";

export default {
  isLoading: (state: UIState) => state.isLoading || !!state.loaders.length,
  isDisabled: (state: UIState) => state.isDisabled,
  bannerTypes: (state: UIState) => state.bannerTypes,
  announcements: (state: UIState) => state.announcements,
  campaign: (state: UIState) => state.campaign,
  campaignHash: (state: UIState, getters: any) => {
    if (!getters.isCampaignRunning) {
      return null;
    }

    return (
      (+(state.campaign?.startDate || "")).toString(36) +
      (+(state.campaign?.endDate || "")).toString(36) +
      (state.campaign?.discountPercent || 0)
    );
  },
  isCampaignRunning: (state: UIState) => {
    return (
      state.campaign?.startDate &&
      state.campaign?.endDate &&
      state.campaign?.startDate <= new Date() &&
      state.campaign?.endDate > new Date()
    );
  },
  keyboardPressedCodes: (state: UIState) => state.keyboardPressedCodes,
  userPreferences: (state: UIState) => state.userPreferences,
  hotKeys: (state: UIState) => state.userPreferences?.hotKeys?.[getPlatform()],
  smallScreenDisclaimerSeen: (state: UIState) =>
    !!state.userPreferences?.smallScreenDisclaimerSeen,
  tableConfig: (state: UIState) => state.userPreferences?.tableConfig,
  getTableConfigByKey: (state: UIState) => (key: TableKey) =>
    !!state.userPreferences?.tableConfig &&
    !!state.userPreferences.tableConfig[key]
      ? state.userPreferences.tableConfig[key]
      : null,
};
