<template>
  <div class="pup-c-input-data-model-selector" @keydown="hideList">
    <!-- THIS IS THE INPUT HOLDER -->
    <div class="pup-c-input-data-model-selector--input-holder">
      <pds-input
        class="pds-u-m--b--24"
        style="width: 100%"
        :label="showLabel ? label : 'DEFAULT'"
        :placeholder="isChip(value) === '' ? placeholder : ''"
        :value="isChip(value) ? '' : value"
        :required="required"
        @update-input="handleUpdateInput"
        :isTextOnBlur="isBadgeVisible"
        @focus="toggleFocus"
        :disabled="disabled"
      />
      <pds-badge
        v-if="isChip(value)"
        :type="status"
        :label="getChip(value)"
        class="pup-c-chip"
        @removeClick="removeStatus(keyProp)"
      ></pds-badge>
      <div
        v-if="!isBadgeVisible && !disabled"
        :class="[
          status.type === `danger`
            ? `pup-c-input-data-model-selector--danger-chip`
            : `pup-c-input-data-model-selector--chip`,
        ]"
        @click="changeDataModelSelector"
        v-tooltip="{
          content: getContent(),
        }"
      >
        Add Variable
      </div>
    </div>

    <!-- THIS IS THE SELCTOR -->
    <data-moodel-selector
      v-if="isDataModelSelectorVisible"
      :dataModelList="constSimpleDataModel"
      :class="[
        'pup-c-input-data-model-selector--acordeon',
        dataModelPosition === 'right'
          ? 'pup-c-input-data-model-selector--acordeon--right'
          : 'pup-c-input-data-model-selector--acordeon--left',
      ]"
      @pickElement="selectVariable"
      @closed="closeDataModelSelector"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Model, Emit } from "vue-property-decorator";
import {
  InputComponent,
  BadgeStatusComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";
import DataModelSelector from "@/modules/ProcessDesigner/components/DataModelSelector/DataModelSelector.component.vue";
import ClosableDirective from "@/directives/Closable.directive";
import { temporaryDataModel } from "@/services/datamodel/Mockups.service";
import { Variable } from "@/services/crud/Orchestration.service";
import {
  DataModel,
  DataModelAttribute,
} from "@/services/datamodel/DataModel.model";

/** What variable type can we use on this input? */
enum VariableType {
  string,
  integer,
  float,
  date,
}

enum ModelPosition {
  LEFT = "left",
  RIGHT = "right",
}

@Component({
  components: {
    "pds-input": InputComponent,
    "data-moodel-selector": DataModelSelector,
    "pds-badge": BadgeStatusComponent,
  },
})
export default class InputDataModelSelector extends Vue {
  @Prop({ default: "DEFAULT" }) label!: string;

  @Prop() placeholder!: string;

  @Prop({ default: false }) isTextOnBlur!: boolean;

  @Prop({ default: true }) showLabel!: boolean;

  @Prop({
    default: () => {
      return {
        type: "",
        message: "",
      };
    },
  })
  status!: PdsTypes.InputStatus;

  @Prop() required!: Boolean;

  @Prop() keyProp!: string;

  @Model("update-input") value!: string;

  @Prop({ default: "string" }) type!: VariableType;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  constSimpleDataModel = temporaryDataModel;

  isDataModelSelectorVisible = false;

  dataModelPosition = ModelPosition.LEFT;

  isBadgeVisible = false;

  dataModelId = "";

  created() {
    this.isBadgeVisible = this.isTextOnBlur;
  }

  mounted() {
    this.isChip(this.value);
  }

  @Emit("update-input")
  handleUpdateInput(event: string) {
    if (event.charAt(event.length - 1) === "#") {
      this.changeDataModelSelector();
      this.dataModelPosition = ModelPosition.LEFT;
    }

    return event;
  }

  hideList(e: KeyboardEvent) {
    if (e.key === "Backspace") {
      if (this.value.charAt(this.value.length - 1) === "#") {
        this.closeDataModelSelector();
      }
    }

    if (e.key === "Delete") {
      if (this.value.charAt(0) === "#") {
        this.closeDataModelSelector();
      }
    }
  }

  get processVariables() {
    return this.$store.state.processes.variables;
  }

  getContent() {
    const type = "string";

    return "Expected input data type is " + type;
  }

  changeDataModelSelector() {
    this.dataModelPosition = ModelPosition.RIGHT;

    this.isDataModelSelectorVisible = true;
  }

  toggleFocus() {
    if (this.isTextOnBlur && this.isBadgeVisible) {
      this.isBadgeVisible = !this.isBadgeVisible;
    }
  }

  closeDataModelSelector() {
    this.isDataModelSelectorVisible = false;

    if (this.isTextOnBlur) {
      this.isBadgeVisible = true;
    }
  }

  selectVariable(
    variables: Array<{
      attribute: DataModelAttribute;
      id: string;
    }>
  ) {
    const value = variables
      .reduceRight((acc, variable) => {
        return `${variable.id}.${acc}`;
      }, "")
      // reduce function adds an unncesary dot which we have to remove
      // regex removes the last char of a string
      .replace(/.$/, "");

    this.$emit("update-input", value);

    this.closeDataModelSelector();
  }

  @Emit("update-input")
  removeStatus() {
    return "";
  }

  isChip(value: string): boolean {
    const guidPattern =
      /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)\b(?!\.)/g;
    const isValueGuid = guidPattern.test(value);

    return isValueGuid;
  }

  getChip(value: string) {
    if (this.isChip(value)) {
      const ids = value.split(".");

      const variable: Variable = this.$store.state.processes.variables.find(
        (val: Variable) => val.id === ids[0]
      );

      const variableType: DataModel = this.$store.getters.dataTypes.find(
        (dataType: DataModel) => variable.dataType === dataType.id
      );

      let attributeType: DataModelAttribute;
      let names = "";

      ids.slice(1).forEach((attributeId, key) => {
        let attribute: DataModelAttribute | undefined;

        if (key === 0) {
          attribute = variableType.attributes?.find(
            (attr) => attr.id === attributeId
          );
        } else {
          attribute = attributeType.attributes?.find(
            (attr) => attr.id === attributeId
          );
        }

        if (attribute) {
          names = `${names}.${attribute.name}`;
        }

        const nextAttributeType = (attribute?.attributes || []).find(
          (dataType: DataModelAttribute) =>
            attribute?.dataTypeId === dataType.dataTypeId
        );
        if (nextAttributeType) {
          attributeType = nextAttributeType;
        }
      });

      return `${variable.name}${names}`;
    }

    return value;
  }
}

Vue.directive("closable", ClosableDirective);
</script>

<style scoped lang="scss">
@import "./InputDataModelSelector.component.scss";
</style>
