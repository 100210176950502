import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { AbstractControlParameterGetter } from "./AbstractControlParameterGetter";

export class SimpleValueControlParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: Setting): Parameter | Parameter[] {
    return {
      tabPropertyId: setting.id,
      value: setting.value,
      variable: [],
    };
  }

  getVariableParameter(): VariableParameter[] {
    return [];
  }
}
