<template>
  <div class="pup-c-acordeon">
    <div
      :class="[
        'pup-c-acordeon--header',
        !colored && 'pup-c-acordeon--white',
        colored && disabled ? 'pup-c-acordeon--gray' : null,
      ]"
      :style="{ 'padding-left': 16 * level + 'px' }"
    >
      <div class="pup-c-acordeon--icon-container">
        <IconComponent
          size="small"
          :isOutlined="true"
          :icon="isCollapsed ? 'icon-arrow-drop-down' : 'icon-arrow-right'"
          v-bind:style="{ color }"
          @click="toggleCollapsed"
        />
      </div>
      <div class="pup-c-acordeon--icon-and-text">
        <div
          v-tooltip="{
            content: truncateName(name).length === name.length ? null : name,
          }"
          class="pup-c-acordeon--text-container"
          @click="pickElement"
          @keypress.enter="pickElement"
          @keydown.left="isCollapsed ? toggleCollapsed() : null"
          @keydown.right="!isCollapsed ? toggleCollapsed() : null"
          tabindex="0"
          @keydown.down="handleMoveFocus($event.target, 'next')"
          @keydown.up="handleMoveFocus($event.target, 'previous')"
        >
          {{ truncateName(name) }}
        </div>
        <div class="pup-c-acordeon--icon-container" v-if="size === 'big'">
          <IconComponent
            size="small"
            :isOutlined="isOutline"
            :icon="isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            @click="toggleCollapsed"
          />
        </div>
        <div class="pup-c-acordeon--icon-container" v-if="dataType">
          <pup-data-type-badge
            class="pup-c-acordeon--badge"
            :dataType="dataType"
          />
        </div>
      </div>
    </div>

    <slot v-if="isCollapsed"> </slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Emit, Watch } from "vue-property-decorator";

import { IconComponent } from "@procesio/procesio-design-system";
import DataTypeBadgeComponent from "@/modules/ProcessDesigner/components/Badge/DataTypeBadge/DataTypeBadge.component.vue";
import { Direction, moveFocus } from "@/utils/moveFocus";
import { truncateMiddle } from "@/utils/type/string";

@Component({
  components: { IconComponent, "pup-data-type-badge": DataTypeBadgeComponent },
})
export default class AcordeonComponent extends Vue {
  @Prop({ default: "white" }) color!: string;

  @Prop({ default: false }) isOutline!: boolean;

  @Prop() name!: string;

  @Prop({ default: "big" }) size!: string;

  @Prop({ default: null }) dataType!: {
    id: string;
    dataType: string | undefined;
    dataTypeId: string | undefined;
    isList: boolean;
  };

  @Prop({ default: 0 }) level!: number;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: true, type: Boolean }) colored!: boolean;

  @Prop({ default: true, type: Boolean }) expanded!: boolean;

  isCollapsed = true;

  @Emit("click")
  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    return this.isCollapsed;
  }

  created() {
    this.isCollapsed = this.expanded;
  }

  @Watch("expanded", { immediate: true })
  onExpandedUpdate(expanded: boolean) {
    this.$nextTick(() => {
      this.isCollapsed = expanded;
    });
  }

  pickElement() {
    this.$emit("pickElement", []);
  }

  handleMoveFocus(target: HTMLElement, direction: Direction) {
    moveFocus(target, direction);
  }

  truncateName(str: string) {
    const toLength = 24;
    return truncateMiddle(str, toLength);
  }
}
</script>

<style scoped lang="scss">
@import "./Acordeon.component.scss";
</style>
