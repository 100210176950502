var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-case-selector"},[(_vm.isDesktop)?_c('div',{staticClass:"pup-c-case-selector--border"},[_c('div',{class:[
        'pup-c-case-selector--btn-collapse',
        'pup-c-case-selector--text',
        !_vm.isCollapsed && 'pup-c-case-selector--text--collapsed',
      ],on:{"click":_vm.toggle}},[_vm._v("\n      View Cases\n      "),_c('pds-icon',{staticClass:"pup-c-case-selector--icon",attrs:{"icon":_vm.isCollapsed ? 'chevron_left' : 'chevron_right'}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pup-c-case-selector--content",class:{
      'pup-c-case-selector--content--collapsed': _vm.isCollapsed,
    }},[_c('draggable',{attrs:{"list":_vm.cases,"handle":".pup-c-card-button--drag-handle","options":{ disabled: _vm.disabled }}},_vm._l((_vm.cases),function(cas,index){return _c('pup-card-button',{key:index,class:{
          'pds-u-m--b--16': index === _vm.cases.length - 1,
        },attrs:{"index":index,"parent":_vm.node,"value":cas,"settings":_vm.settings,"selected":cas.internalId === _vm.value.internalId,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('selected', cas)}}})}),1),_vm._v(" "),(_vm.defaultCaseSetting)?_c('pup-decisional-default',{attrs:{"value":_vm.defaultCaseSetting.value,"parent":_vm.node,"settings":_vm.defaultCaseSetting}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }