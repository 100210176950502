import { ActionTree, ActionContext } from "vuex";

import DataModelService from "@/services/datamodel/DataModel.service";
import { DataModelState } from "./DataModel.store";
import { Mutations, MutationTypes } from "./DataModel.mutations";
import {
  DataModel,
  getUnknownDataModel,
} from "@/services/datamodel/DataModel.model";
import { UserWorkspaceType } from "@/services/user/workspace/UserWorkspace.service";
import { store } from "..";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";

export enum ActionTypes {
  LOAD_DATA_TYPES = "LOAD_DATA_TYPES",
  LOAD_CUSTOM_DATA = "LOAD_CUSTOM_DATA",
  LOAD_INTERNAL_DATA_MODELS = "LOAD_INTERNAL_DATA_MODELS",
  ADD_DATA_TYPE = "ADD_DATA_TYPE",
  DELETE_DATA_TYPE = "DELETE_DATA_TYPE",
  RESET_CUSTOM_DATA = "RESET_CUSTOM_DATA",
  RESET_DATA = "RESET_DATA",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<DataModelState, DataModelState>, "commit">;

export interface Actions {
  [ActionTypes.LOAD_DATA_TYPES]({
    commit,
  }: AugmentedActionContext): Promise<DataModel[]>;
  [ActionTypes.LOAD_CUSTOM_DATA](
    { commit }: AugmentedActionContext,
    { pageNumber, pageItemCount }: { pageNumber: number; pageItemCount: number }
  ): Promise<DataModel[]>;
  [ActionTypes.LOAD_INTERNAL_DATA_MODELS](
    { commit }: AugmentedActionContext,
    { pageNumber, pageItemCount }: { pageNumber: number; pageItemCount: number }
  ): Promise<DataModel[]>;
  [ActionTypes.ADD_DATA_TYPE](
    { commit }: AugmentedActionContext,
    payload: DataModel | DataModel[]
  ): void;
  [ActionTypes.DELETE_DATA_TYPE](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.RESET_CUSTOM_DATA]({
    commit,
    dispatch,
    getters,
  }: AugmentedActionContext): void;
  [ActionTypes.RESET_DATA]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<DataModelState, DataModelState> & Actions = {
  // this action is used to fetch full data models list for dropdowns
  async [ActionTypes.LOAD_DATA_TYPES]({ dispatch }) {
    let response = null;
    if (
      store.getters.activeWorkspace.type !== UserWorkspaceType.MASTER &&
      UserPermissionsGuard.isPermitted(
        ProcesioEntityType.DataModels,
        RoleType.Read
      )
    ) {
      response = await DataModelService.loadDataModelList();
    }
    if (response?.content && response.content?.pageItems) {
      const payload = response.content.pageItems
        .sort((typeA, typeB) => +typeB.isProcesio - +typeA.isProcesio)
        .sort((typeA, typeB) => +typeA.isDataModel - +typeB.isDataModel);

      payload.push(getUnknownDataModel());

      dispatch(ActionTypes.ADD_DATA_TYPE, payload);

      return payload;
    }

    return [];
  },
  // this action is used to fetch custom data models and its attributes
  async [ActionTypes.LOAD_CUSTOM_DATA](
    { dispatch },
    { pageNumber, pageItemCount } = { pageNumber: 0, pageItemCount: 0 }
  ) {
    let response = null;
    const hasDMPErmissions = UserPermissionsGuard.isPermitted(
      ProcesioEntityType.DataModels,
      RoleType.Read
    );
    if (store.getters.activeWorkspace.type !== UserWorkspaceType.MASTER) {
      response = await DataModelService[
        hasDMPErmissions
          ? "loadCustomDataModelList"
          : "loadCustomRestrictedDataModelList"
      ](pageNumber, pageItemCount);
    }

    if (response?.content) {
      const payload = response.content?.pageItems.map((item) => ({
        ...item,
        notPermitted: !hasDMPErmissions,
      }));

      dispatch(ActionTypes.ADD_DATA_TYPE, payload);

      return payload;
    }

    return [];
  },
  [ActionTypes.LOAD_INTERNAL_DATA_MODELS](
    { commit },
    { pageNumber, pageItemCount } = { pageNumber: 0, pageItemCount: 0 }
  ) {
    return DataModelService.loadInternalDataModelList(
      pageNumber,
      pageItemCount
    ).then((response) => {
      if (response.content) {
        const payload = response.content.pageItems;

        payload.push(getUnknownDataModel());

        commit(MutationTypes.ADD_DATA_TYPE, {
          data: payload,
        });

        return payload;
      }

      return [];
    });
  },
  [ActionTypes.ADD_DATA_TYPE](
    { commit, getters },
    payload: DataModel | DataModel[]
  ) {
    const workspaceId = getters.activeWorkspaceId;
    commit(MutationTypes.ADD_DATA_TYPE, {
      data: payload,
      context: {
        workspaceId,
      },
    });
  },
  [ActionTypes.DELETE_DATA_TYPE]({ commit }, payload: string) {
    commit(MutationTypes.DELETE_DATA_TYPE, payload);
  },

  [ActionTypes.RESET_CUSTOM_DATA]({ commit, dispatch, getters }) {
    // remove all custom data from store
    commit(MutationTypes.RESET_CUSTOM_DATA);

    if (store.getters.activeWorkspace.type !== UserWorkspaceType.MASTER) {
      // and reload it again
      dispatch(
        !!getters.dataTypes.length
          ? ActionTypes.LOAD_CUSTOM_DATA
          : ActionTypes.LOAD_DATA_TYPES
      );
    }
  },
  [ActionTypes.RESET_DATA]({ commit }) {
    commit(MutationTypes.RESET_DATA);
  },
};
