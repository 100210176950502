<template>
  <div>
    <div v-if="!isFullScreen" class="pup-c-input-group" ref="genericText">
      <pds-control-label
        :label="label"
        :tooltip="tooltip"
        :required="required"
        :ignoreFormatting="ignoreLabelFormatting"
      />
      <div
        class="pup-c-input-group--container"
        ref="textHolder"
        :class="[
          statusClass ? `pup-c-input-group--container--${statusClass}` : '',
          disabled && 'pup-c-input-group--container--disabled',
        ]"
      >
        <slot name="prepend" />
        <div
          v-if="!readonly && !disabled"
          :class="[
            `pup-c-input-data-model-selector--add pds-u-input-label`,
            !isEditable ? 'pup-c-input-data-model-selector--disabled' : '',
          ]"
          v-tooltip="{
            content: addVariableTooltip,
            boundariesElement: 'window',
          }"
          @mousedown="onAddVariableToggle"
          @keydown.enter="
            (isPlaceholderThere ? replaceVariable : handleAddVariable)()
          "
          @click="(isPlaceholderThere ? replaceVariable : handleAddVariable)()"
          tabindex="0"
        >
          <pds-icon
            class="pup-c-input-data-model-selector--add--icon"
            icon="add"
          />
        </div>

        <div
          :class="[
            'pup-c-input-group--wrapper',
            disabled
              ? 'pup-c-input-group--wrapper--disabled'
              : renderButton
              ? 'pup-c-input-group--wrapper--mini'
              : 'pup-c-input-group--wrapper--max',
            ,
            isPlaceholderVisible ? 'pup-c-input-group--placeholder' : null,
          ]"
          :placeholder="placeholder"
        >
          <div class="pup-c-input-group--tooltip-boundary" />

          <span
            v-for="valWord in internalValue"
            :key="valWord.id"
            :ref="`item-${valWord.id}`"
            :class="[
              'pup-c-item-wrapper',
              'pds-u-m--r--4',
              isItemEmpty(valWord.id) && emptyItemClass,
            ]"
          >
            <span
              v-if="valWord.type === 'placeholder'"
              @click="() => replaceVariable(valWord.id)"
              style="cursor: pointer"
            >
              <pds-badge label="..." color="invalid"></pds-badge>
            </span>

            <pds-badge
              v-tooltip="{
                content:
                  getTooltipDataType(valWord.value) +
                  getTooltipNameIfTooLong(valWord.value),
                placement: 'top',
                container: 'body',
                boundariesElement: 'window',
                autoHide: false,
              }"
              v-else-if="valWord.type === 'variable'"
              :label="
                parseVariableIdToName(
                  valWord.value,
                  false,
                  processVariables,
                  variableScopes
                )
              "
              @labelClick="openVariable(valWord.value)"
              @removeClick="removeVariable(valWord.id)"
              :closable="!(readonly || disabled)"
              :color="getColor(valWord.value)"
              ignoreDefaultCases
            >
            </pds-badge>
            <!-- prettier-ignore -->
            <div
            :contenteditable="!isVariableOnly && !readonly && !disabled"
            v-else
            type="text"
            @input="keyboardInputHandler($event, valWord.id)"
            @keydown="keyDownHandler($event, valWord.id)"
            @keyup="keyUpHandler($event, valWord.id)"
            @focus="focusChangeHandler($event, valWord.id)"
            @blur="blurChangeHandler"
            @keydown.enter.prevent
            @copy="copyHandler"
            class="pup-c-input-group--field pup-c-input-group--div-editable"
            :ref="`input-${valWord.id}`"
            :id="'div-' + valWord.id"
          ><template v-once>{{ typeof valWord.value === 'string' ? valWord.value.replaceAll(lineBreakRegex, "") : valWord.value }}</template></div>
            <!-- do not break lines because it will break left-right keyboard arrow navigation!-->
          </span>
        </div>

        <div
          class="pup-c-input-group--multiline"
          v-if="hasMultiLineContent"
          v-tooltip="{
            content: multilineContentTooltip,
            boundariesElement: 'window',
          }"
        >
          <pds-icon icon="sort" size="tiny" />
        </div>
      </div>
      <pds-validation-message
        :status="status"
        @statusClassChanged="(e) => (statusClass = e)"
        :showMessage="showValidationMessage"
      />
      <pup-data-model-selector
        v-if="isDataModelSelectorVisible"
        :dataModelList="dataModels"
        :parentId="parent && parent.parentId"
        :processVariables="processVariables"
        :parentDataModelId="parentDataModelId"
        :parentVariableId="parentVariableId"
        :class="[
          'pup-c-input-data-model-selector--acordeon',
          'pup-c-input-data-model-selector--acordeon--right',
          label && 'pup-c-input-data-model-selector--acordeon--with-label',
          label &&
            tooltip &&
            'pup-c-input-data-model-selector--acordeon--with-label--with-tooltip',
        ]"
        :disabled="!isEditable"
        :hideCreate="hideCreate"
        :direction="settings ? settings.direction : null"
        :expectedDataModelId="settings ? settings.dataTypeId : null"
        :isListExpected="settings ? settings.isList : false"
        @pickElement="selectVariable"
        @closed="closeDataModelSelector"
      />
    </div>
    <pup-editor
      v-else
      :parent="parent"
      :settings="settings"
      :label="label"
      :value="value"
      :processVariables="processVariables"
      fullScreen
      :fullScreenTitle="fullScreenTitle"
      :disabled="disabled"
      @update-input="emitInputUpdate"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Model, Prop, Watch } from "vue-property-decorator";

import {
  BadgeStatusComponent,
  ButtonComponent,
  IconComponent,
  ValidationMessage,
  ControlLabelComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import DataModelSelector from "@/modules/ProcessDesigner/components/DataModelSelector/DataModelSelector.component.vue";
import { createGuid, guidRegex } from "@/utils/type/guid";
import ClosableDirective from "@/directives/Closable.directive";
import { VariableParser } from "../../../Variables/Utils/VariableParser";
import { Variable } from "../../../Variables/Utils/Variable";
import {
  Setting,
  Node,
  Direction,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import language from "@/utils/locale/en.json";
import { SettingValidation } from "@/modules/ProcessDesigner/Validation/SettingValidation";
import { getValueDataTypes } from "../../../Values/ValueDataTypesHelper";
import {
  CALL_API_OUTPUT_SETTINGS,
  FOREACH_LIST_SETTING,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Settings";
import { NonPrimitives, Primitives } from "@/utils/dataTypeMapper";
import { Debounce } from "vue-debounce-decorator";
import {
  DataModel,
  UNKNOWN_DATA_MODEL_ID,
  UNKNOWN_DATA_MODEL_TOOLTIP,
} from "@/services/datamodel/DataModel.model";
import { mapGetters } from "vuex";
import {
  isHotKeyMatched,
  HotKeys,
  hotKeyToString,
} from "@/utils/keyboard/hotkeys";
import Editor from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.component.vue";
import { EventBus, Events } from "@/utils/eventBus";
import { KeyboardCode } from "@/utils/keyboard";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";
import { getVariable } from "@/modules/ProcessDesigner/Variables/Utils";

Vue.directive("closable", ClosableDirective);

interface InternalValue {
  value: string;
  id: string;
  type?: InternalValueType;
}

enum InternalValueType {
  IGNORE = "ignore",
  VARIABLE = "variable",
  PLACEHOLDER = "placeholder",
}

@Component({
  components: {
    "pup-data-model-selector": DataModelSelector,
    "pds-badge": BadgeStatusComponent,
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
    "pds-validation-message": ValidationMessage,
    "pds-control-label": ControlLabelComponent,
    "pup-editor": Editor,
  },
  computed: {
    ...mapGetters({
      keyboardPressedCodes: "keyboardPressedCodes",
    }),
  },
})
export default class GenericTextComponent extends mixins(
  VariableParser,
  Variable
) {
  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false }) isProperty!: boolean;

  @Prop() placeholder!: string;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  @Prop({ default: () => [] }) processVariables!: ProcessVariable[];

  @Prop({ default: () => [] }) variableScopes!: string[] | null;

  @Prop({ default: null }) parentDataModelId!: string | null;

  @Prop({ default: null }) parentVariableId!: string | null;

  @Prop() dataModels!: DataModel[];

  @Prop({ default: true }) showValidationMessage!: boolean;

  @Prop({ default: "" }) label!: string;

  @Prop() tooltip?: string;

  @Prop({ default: false, type: Boolean }) hideCreate!: boolean;

  @Prop({ default: false, type: Boolean }) oneVariableOnly!: boolean;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: true, type: Boolean }) hasInternalTypeValidation!: boolean;

  @Prop({ default: true, type: Boolean }) autofocusable!: boolean;

  // if false, ignore variables guids and show value as it is
  @Prop({ default: true, type: Boolean }) parseVariables!: boolean;

  @Prop({ default: true, type: Boolean }) hasFullScreenMode!: boolean;

  // value =
  // ".2d7d0370-b2e0-40eb-acba-d75526823944 sau 94cc1fd3-be4c-4231-a1c6-39ea28ff554a.fe9fef55-2f38-42f2-8a0c-7b4e334e6622";
  @Model("update-input") value!: string;

  @Prop({ default: false, type: Boolean }) ignoreLabelFormatting!: boolean;

  @Prop({ default: "" }) fullScreenTitle!: string;

  isPlaceholderVisible = false;

  badgeColor = "";

  showConsoleLogs = false;

  filledItemsSet = new Set();

  emptyItemClass = "pup-c-input-group--item--empty";

  isFullScreen = false;

  lineBreakRegex = /(\r\n|\n|\r)/gm;
  lineBreakSymbol = "↵";

  @Watch("value", { immediate: true, deep: true })
  onValueChange(value: string) {
    if (value && this.parseVariables) {
      if (this.showConsoleLogs) {
        console.log(value);
      }

      // add temporary space before each variable start to avoid variables concatenation
      (this.processVariables || []).forEach((variable) => {
        value = value.replaceAll(variable.id, " " + variable.id);
      });

      const splitedValue = value
        .split(new RegExp("\\s" + this.variableGuidRegexRaw, "gm"))
        .filter((val) => typeof val !== "undefined")
        .reduce((acc: string[], val) => {
          if (this.hasPlaceholder(val)) {
            acc.push(...val.split(this.placeholderRegex));
          } else {
            const lastValueIndex = acc.length - 1;
            const lastValue =
              lastValueIndex === -1 ? null : acc[lastValueIndex];
            // extract value to separate div if guid is related to variables
            // or there are not previous value
            // or previous value guid is related to variable
            if (
              this.isValueGuid(val) ||
              lastValue === null ||
              this.isValueGuid(lastValue)
            ) {
              let isReplaced = false;
              // for some reason this.guidRegex.test returns false on guids that start
              // with a dot .2d7d0370-b2e0-40eb-acba-d75526823944
              // if we find values of type .guid we should check if they exist
              // in the previous entry of matches so that if it matches we don't return it again
              // Ex: .2d7d0370-b2e0-40eb-acba-d75526823944 in 47fe5980-b480-48af-8d4c-cdebbf1c2303.2d7d0370-b2e0-40eb-acba-d75526823944
              if (
                guidRegex.test(val) &&
                !!lastValue &&
                this.isValueGuid(lastValue) &&
                !this.isValueGuid(val) &&
                lastValue.includes(val)
              ) {
                if (val[0] === ".") {
                  val = val.substring(1);
                }
                val = val.replace(guidRegex, "");
                isReplaced = true;
              }

              if (!isReplaced || (isReplaced && val.trim().length > 0)) {
                acc.push(
                  val.replaceAll(this.lineBreakRegex, this.lineBreakSymbol)
                );
              }
            }
            // concat values if they both are just strings
            else if (typeof acc[lastValueIndex] === "string") {
              acc[lastValueIndex] = acc[lastValueIndex] + val;
            }
          }
          return acc;
        }, []);

      this.internalValue = splitedValue
        .filter((val) => typeof val !== "undefined")
        .map((char, index) => {
          // reuse existing id to avoid extra rerenders
          const id =
            this.internalValue[index] && this.internalValue[index].id
              ? this.internalValue[index].id
              : createGuid();

          const type = this.isValueGuid(char)
            ? InternalValueType.VARIABLE
            : this.hasPlaceholder(char)
            ? InternalValueType.PLACEHOLDER
            : undefined;

          return { value: char, id, type };
        });

      this.internalValue = this.internalValue.reduce(
        (acc: InternalValue[], crt, index) => {
          if (this.showConsoleLogs) {
            console.log(crt);
          }
          acc.push(crt);
          // add a space between 2 pills
          if (
            (crt.type === InternalValueType.VARIABLE ||
              crt.type === InternalValueType.PLACEHOLDER) &&
            this.internalValue[index + 1] &&
            (this.internalValue[index + 1].type ===
              InternalValueType.VARIABLE ||
              this.internalValue[index + 1].type ===
                InternalValueType.PLACEHOLDER)
          ) {
            acc.push({ id: createGuid(), value: "" });
          }

          return acc;
        },
        []
      );

      this.isPlaceholderVisible = value.length === 0;
    } else {
      this.internalValue = [{ id: createGuid(), value: value || "" }];

      this.isPlaceholderVisible = true;
    }

    // if the last element is of type guid add an empty input after so it can be deleted
    if (
      this.isValueGuid(this.internalValue[this.internalValue.length - 1].value)
    ) {
      this.internalValue.push({
        id: createGuid(),
        value: "",
        type: InternalValueType.IGNORE,
      });
    }

    // if the first element is of type guid add an empty input before

    if (this.isValueGuid(this.internalValue[0].value)) {
      this.internalValue.splice(0, 0, {
        id: createGuid(),
        value: "",
        type: InternalValueType.IGNORE,
      });
    }
    if (this.showConsoleLogs) {
      console.log(this.internalValue);
    }
  }

  internalValue: InternalValue[] = [];

  @Watch("internalValue", { immediate: true, deep: true })
  onInterChange(value: Array<InternalValue>) {
    if (this.timeoutBlurReference) {
      clearTimeout(this.timeoutBlurReference);
    }

    if (Array.isArray(value)) {
      value.forEach((val) => {
        !val.type && val.value
          ? this.filledItemsSet.add(val.id)
          : this.filledItemsSet.delete(val.id);

        if (this.$refs.genericText) {
          // set div's content programatically if value was changed not-enter way (e.g. pasted)
          const valueElement = this.$refs.genericText.querySelector(
            "#div-" + val.id
          );
          if (
            valueElement &&
            typeof valueElement.innerHTML === "string" &&
            typeof val.value === "string" &&
            valueElement.innerHTML
              .replace(/&nbsp;/gi, "")
              .replace(/&lt;/gi, "<")
              .replace(/&gt;/gi, ">")
              .trim() !== val.value.trim()
          ) {
            valueElement.textContent = val.value;

            if (this.autofocusable) {
              // keep caret position
              const range = document.createRange();
              range.selectNodeContents(valueElement);
              range.collapse(false);
              const selection = window.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(range);
            }
          }
        }
      });
    }
  }

  @Watch("status", { immediate: true })
  onStatusUpdate() {
    this.validateInternalValue();
  }

  @Watch("processVariables", { deep: true })
  onProcessVariablesUpdate() {
    this.validateInternalValue();
  }

  @Watch("keyboardPressedCodes")
  onKeyboardPressedCodes(codes: string[], oldCodes: string[]) {
    if (codes.length < oldCodes.length) {
      return;
    }

    if (
      !this.$refs.textHolder ||
      !(this.$refs.textHolder as HTMLElement).contains(document.activeElement)
    ) {
      return;
    }

    if (isHotKeyMatched(HotKeys.TOGGLE_DATA_MODEL_SELECTOR, codes)) {
      this.onAddVariableToggle().then(() => this.handleAddVariable());
    }
  }

  @Watch("isFullScreen")
  onFullScreenStateUpdate(isFullScreen: boolean) {
    EventBus.$emit(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      isFullScreen,
      this.settings?.id || null
    );

    this.$nextTick(() => {
      if (!isFullScreen) {
        const lastInputValue =
          this.internalValue[this.internalValue.length - 1];
        if (lastInputValue && this.$refs.genericText) {
          const valueElement = this.$refs.genericText.querySelector(
            "#div-" + lastInputValue.id
          );
          if (valueElement) {
            const range = document.createRange();
            range.selectNodeContents(valueElement);
            range.collapse(false);
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
          }
        }
      }
    });
  }

  get controlType() {
    if (!this.settings) {
      return "";
    }

    const dataType: DataModel = this.$store.getters.dataTypes.find(
      (el: DataModel) => el.id === this.settings.dataTypeId
    );

    if (this.settings.isList && dataType) {
      return "list &lt;" + dataType.displayName + "&gt;";
    }

    return dataType?.displayName;
  }

  get isVariableOnly() {
    const variableOnlyTypes = ["file", "datatype"];
    const variableOnlySettingId = [
      ...CALL_API_OUTPUT_SETTINGS,
      FOREACH_LIST_SETTING,
    ];
    return (
      this.oneVariableOnly ||
      variableOnlyTypes.includes(this.settings?.type) ||
      variableOnlySettingId.includes(this.settings?.id)
    );
  }

  get isEditable() {
    if (
      this.isVariableOnly &&
      this.value &&
      !this.hasPlaceholder(this.value) &&
      this.value.trim().length > 0
    ) {
      return false;
    }

    return true;
  }

  get closesPlaceholderPosition() {
    if (!Array.isArray(this.internalValue)) {
      return null;
    }

    const index = this.internalValue.findIndex(
      (v) => v.type === InternalValueType.PLACEHOLDER
    );
    return index === -1 ? null : index;
  }

  get isPlaceholderThere() {
    return this.closesPlaceholderPosition !== null;
  }

  get hasMultiLineContent() {
    if (this.disabled || this.readonly || !this.isEditable) {
      return false;
    }

    return (
      typeof this.value === "string" && this.lineBreakRegex.test(this.value)
    );
  }

  get multilineContentTooltip() {
    return `The editor contains multi-line content. Press <b>${hotKeyToString(
      HotKeys.ENLARGE_INPUT
    )}</b> to open it.<br>
    NOTE: Removing the ${
      this.lineBreakSymbol
    } will remove the line breaks from blocks of text.`;
  }

  get addVariableTooltip() {
    if (this.isEditable) {
      return this.controlType
        ? "Add Variable <br /> type=" + this.controlType
        : "Add Variable";
    }

    if (this.settings) {
      if (this.settings.dataTypeId === NonPrimitives.FILE) {
        return "File Input field, only accepts one variable";
      } else if (this.settings.direction === Direction.Output) {
        return "Output field, only accepts one variable";
      }
    }

    return "This field accepts one variable";
  }

  isDataModelSelectorVisible = false;

  focusedElementIndex: number | null = null;

  previousCaretPosition = 0;

  variableGuidRegexRaw =
    "([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)";
  variableGuidRegex = new RegExp(this.variableGuidRegexRaw, "gm");

  renderButton = false;

  statusClass = "";

  isReplace = false;

  mounted() {
    if (this.$refs.textHolder) {
      const holderWidth = (this.$refs.textHolder as HTMLElement).clientWidth;

      this.renderButton = holderWidth > 250;

      (this.$refs.textHolder as HTMLElement).addEventListener(
        "paste",
        function (e: any) {
          e.preventDefault();

          let text = "";

          if (e.clipboardData || e.originalEvent.clipboardData) {
            text = (e.originalEvent || e).clipboardData.getData("text/plain");
          } else if ((window as any).clipboardData) {
            text = (window as any).clipboardData.getData("Text");
          }
          if (document.queryCommandSupported("insertText")) {
            document.execCommand("insertText", false, text);
          } else {
            document.execCommand("paste", false, text);
          }
        }
      );
    }

    EventBus.$on(Events["INPUT:FULLSCREEN_TOGGLE"], this.onFullScreenToggle);
  }

  onFullScreenToggle(isFullScreen: boolean, settingId: string | null) {
    if (
      this.isFullScreen &&
      !isFullScreen &&
      this.settings &&
      (this.settings.id || null) === (settingId || null)
    ) {
      this.toggleFullScreen(false);
    }
  }

  beforeDestoy() {
    this.updateInput();
    EventBus.$off(Events["INPUT:FULLSCREEN_TOGGLE"], this.onFullScreenToggle);
  }

  updateInput() {
    const value = this.internalValue
      .filter((val) => val.type !== InternalValueType.IGNORE)
      .map((val) => {
        if (this.variableGuidRegex.test(val.value)) {
          return `${val.value}`;
        } else {
          return val.value;
        }
      })
      .join("");
    // .replace(/  +/g, " ");

    if (value !== this.value) {
      this.emitInputUpdate(value);
    }

    return value;
  }

  emitInputUpdate(value: string | null) {
    value = (!value || value.trim().length) === 0 ? null : value;
    this.$emit("update-input", value);
    if (this.showConsoleLogs) {
      console.log("emiited", value);
    }
    if (this.isVariableOnly || !value) {
      this.$emit("blur");
    }
  }

  isValueGuid(value: string) {
    return (
      new RegExp(this.variableGuidRegex).test(value) &&
      !!this.getVariableDataType(
        value,
        this.processVariables,
        this.variableScopes || []
      )
    );
  }

  openVariable(id: string) {
    if (this.readonly || this.disabled) {
      return;
    }

    const variable = getVariable(id, this.processVariables);

    const dataType: DataModel = variable
      ? this.getVariableDataType(
          variable.id,
          this.processVariables,
          this.variableScopes || []
        )
      : null;

    if (
      variable &&
      !variable.isReadonly &&
      (!variable.scopesId || !variable.scopesId.length) &&
      dataType &&
      !dataType.notPermitted &&
      dataType.id !== UNKNOWN_DATA_MODEL_ID
    ) {
      EventBus.$emit(Events["VARIABLE:OPEN_FORM"], variable);
    }
  }

  removeVariable(id: string) {
    if (this.readonly || this.disabled) {
      return;
    }

    const index = this.internalValue.findIndex((val) => val.id === id);
    if (index === -1) {
      return;
    }

    setTimeout(() => {
      // take previous input because inputs between a variable will be merged
      const prevInput = this.internalValue[index - 1];
      const prevInputs = this.$refs[
        `input-${prevInput ? prevInput.id : null}`
      ] as Vue[];
      if (prevInputs) {
        const prevInputElement = prevInputs[
          prevInputs.length - 1
        ] as unknown as HTMLDivElement;
        if (!prevInputElement) {
          return;
        }

        const nextInput = this.internalValue[index + 1];
        const nextInputs = this.$refs[
          `input-${nextInput ? nextInput.id : null}`
        ] as Vue[];
        if (nextInputs) {
          const nextInput = nextInputs[
            nextInputs.length - 1
          ] as unknown as HTMLDivElement;
          if (nextInput) {
            // the value should be set programatically
            prevInputElement.textContent =
              (prevInputElement.textContent || "") + nextInput.textContent;
          }
        }

        prevInputElement.focus();
        const range = document.createRange();
        range.selectNodeContents(prevInputElement);
        range.collapse(false);

        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);

        this.validateInternalValue(this.updateInput());
      }
    }, 50);

    this.internalValue.splice(index, 1);
    this.isPlaceholderVisible = !this.internalValue.filter((item) => item.value)
      .length;
  }

  selectVariable(variable: string) {
    if (!this.isEditable) {
      return;
    }

    const lastInputId = createGuid();

    if (this.focusedElementIndex === null) {
      this.focusedElementIndex = this.internalValue.length + 1;
    }

    if (this.isReplace) {
      this.internalValue.splice(this.focusedElementIndex, 1, {
        id: createGuid(),
        value: variable,
      });
    } else {
      const editedItem = this.internalValue[this.focusedElementIndex];
      const isVariableInseredAfter =
        !editedItem || editedItem.value.length === this.previousCaretPosition;

      if (isVariableInseredAfter || !editedItem) {
        this.internalValue.splice(this.focusedElementIndex + 1, 0, {
          id: createGuid(),
          value: variable,
        });

        this.internalValue.splice(this.focusedElementIndex + 2, 0, {
          id: lastInputId,
          value: "",
          type: InternalValueType.IGNORE,
        });
      } else {
        const editedItemValue = editedItem.value;
        const leftPart = editedItemValue.substr(0, this.previousCaretPosition);
        const rightPart = editedItemValue.substr(this.previousCaretPosition);

        this.internalValue.splice(this.focusedElementIndex, 1, {
          ...editedItem,
          value: leftPart,
        });

        this.internalValue.splice(this.focusedElementIndex + 1, 0, {
          id: createGuid(),
          value: variable,
        });

        this.internalValue.splice(this.focusedElementIndex + 2, 0, {
          id: lastInputId,
          value: rightPart,
        });
      }
    }

    this.isDataModelSelectorVisible = false;

    this.isReplace = false;

    // add operation into que because some internals of vue are somewhat async
    setTimeout(() => {
      const nextInputs = this.$refs[`input-${lastInputId}`] as Vue[];
      if (nextInputs && nextInputs.length && this.isEditable) {
        const element = nextInputs[
          nextInputs.length - 1
        ] as unknown as HTMLDivElement;
        element && element.focus();
      } else {
        this.validateInternalValue();
      }
    }, 150);

    const doesVariableAlreadyExist =
      this.processVariables.findIndex(
        (item) => variable && item.id === variable.split(".")[0]
      ) !== -1;

    if (doesVariableAlreadyExist) {
      this.updateInput();
    } else {
      // add timeout to wait for variable list to be updated with new variable
      setTimeout(() => this.updateInput(), 0);
    }

    this.focusedElementIndex = null;
  }

  @Debounce(300)
  updateInputHandler(event: InputEvent, id: string) {
    if (this.showConsoleLogs) {
      console.log("dace");
    }
    const value = (event.target as HTMLDivElement).innerText;

    const index = this.internalValue.findIndex((val) => val.id === id);
    if (index !== -1) {
      Vue.set(this.internalValue, index, {
        ...this.internalValue[index],
        value: value.replaceAll(this.lineBreakSymbol, "\r\n"),
        type: undefined,
      });
    }

    this.updateInput();
  }

  // called before blur
  // if parent is draggable, it can work wrong. check solution in VariableMapperGroupComponent (vue-draggable options)
  async onAddVariableToggle() {
    if (!this.isEditable) {
      return;
    }

    if (!this.isDataModelSelectorVisible && this.focusedElementIndex !== null) {
      const focusedElementIndex =
        this.focusedElementIndex === null
          ? this.internalValue.length - 1
          : this.focusedElementIndex;
      const inputData = this.internalValue[focusedElementIndex];
      const inputs = this.$refs[`input-${inputData?.id}`] as HTMLElement[];
      if (!inputs || !inputs.length) {
        return;
      }

      if (inputs[0]) {
        this.previousCaretPosition = await this.getCaretPosition(inputs[0]);
      }
    }
  }

  handleAddVariable() {
    if (!this.isEditable) {
      return;
    }

    this.isDataModelSelectorVisible = !this.isDataModelSelectorVisible;
  }

  isItemEmpty(id: string) {
    const item = this.internalValue.find((item) => item.id === id);
    if (!item || (item && item.type)) {
      return false;
    }

    return !this.filledItemsSet.has(id);
  }

  keyboardInputHandler(event: InputEvent, id: string) {
    const isEmpty = !(event.target as HTMLDivElement)?.innerText;

    if (
      !this.internalValue.filter((item) => item.id !== id && item.value).length
    ) {
      this.isPlaceholderVisible = isEmpty;
    }

    const items = this.$refs[`item-${id}`] as HTMLElement[];
    if (items && items.length) {
      const item = items[0];
      if (isEmpty) {
        item.classList.add(this.emptyItemClass);
        this.filledItemsSet.delete(id);
      } else {
        item.classList.remove(this.emptyItemClass);
        this.filledItemsSet.add(id);
      }
    }

    this.updateInputHandler(event, id);
  }

  async keyDownHandler(event: KeyboardEvent, id: string) {
    const element = event.target as HTMLDivElement;
    this.previousCaretPosition = await this.getCaretPosition(element);
    const index = this.internalValue.findIndex((val) => val.id === id);
    if (index === -1) {
      return;
    }

    if (event.key.toLowerCase() === KeyboardCode.ESC) {
      this.$store.dispatch(
        UIActionTypes.ADD_PRESSED_KEYBOARD_CODE,
        KeyboardCode.ESC
      );
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      event.key === "Backspace" &&
      index > 0 &&
      this.previousCaretPosition === 0
    ) {
      const variableItem = this.internalValue[index - 1];
      if (variableItem && variableItem.type === InternalValueType.VARIABLE) {
        this.removeVariable(variableItem.id);
      }
    }

    if (
      !this.isFullScreen &&
      this.hasFullScreenMode &&
      isHotKeyMatched(HotKeys.ENLARGE_INPUT, this.keyboardPressedCodes)
    ) {
      this.toggleFullScreen(true);
    } else if (
      this.isFullScreen &&
      this.hasFullScreenMode &&
      isHotKeyMatched(HotKeys.COLLAPSE_INPUT, this.keyboardPressedCodes)
    ) {
      this.toggleFullScreen(false);
    }
  }

  toggleFullScreen(isFullScreen: boolean) {
    this.isFullScreen = isFullScreen;
  }

  async keyUpHandler(event: KeyboardEvent, id: string) {
    const index = this.internalValue.findIndex((val) => val.id === id);
    if (index === -1) {
      return;
    }

    const value = (event.target as HTMLDivElement).innerText;

    if (event.key === "ArrowRight") {
      const carret = await this.getCaretPosition(event.target as HTMLElement);
      if (
        (value.length === carret && this.previousCaretPosition === carret) ||
        carret === this.previousCaretPosition
      ) {
        const nextInput = this.internalValue[index + 2];
        const inputs = this.$refs[
          `input-${nextInput ? nextInput.id : null}`
        ] as Vue[];
        if (inputs && inputs.length) {
          const nextInputElement = inputs[
            inputs.length - 1
          ] as unknown as HTMLDivElement;
          nextInputElement && nextInputElement.focus();
        }
      }
    } else if (event.key === "ArrowLeft") {
      const carret = await this.getCaretPosition(event.target as HTMLElement);
      if (carret === this.previousCaretPosition) {
        const previousInput = this.internalValue[index - 2];
        const inputs = this.$refs[
          `input-${previousInput ? previousInput.id : null}`
        ] as Vue[];

        if (inputs && inputs.length) {
          const prevInputElement = inputs[
            inputs.length - 1
          ] as unknown as HTMLDivElement;
          prevInputElement && prevInputElement.focus();
          const range = document.createRange();
          range.selectNodeContents(prevInputElement);
          range.collapse(false);

          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      } else if (event.key.toLowerCase() === KeyboardCode.ESC) {
        this.$store.dispatch(
          UIActionTypes.DELETE_PRESSED_KEYBOARD_CODE,
          KeyboardCode.ESC
        );
      }
    }
  }

  getCaretPosition(editableDiv: HTMLElement): Promise<number> {
    return new Promise((resolve) => {
      let caretPos = 0,
        sel,
        range;

      editableDiv.focus();

      if (window.getSelection) {
        sel = window.getSelection();

        if (sel?.rangeCount) {
          range = sel.getRangeAt(0);

          if (range.commonAncestorContainer.parentNode?.contains(editableDiv)) {
            caretPos = range.endOffset;
          }
        }
      }

      resolve(caretPos);
    });
  }

  async focusChangeHandler(event: FocusEvent, id: string) {
    const index = this.internalValue.findIndex((val) => val.id === id);
    if (index === -1) {
      return;
    }
    this.focusedElementIndex = index;
    this.previousCaretPosition = await this.getCaretPosition(
      event.target as HTMLElement
    );
  }

  timeoutBlurReference: number | undefined;

  blurChangeHandler(event: FocusEvent) {
    if (this.timeoutBlurReference) {
      clearTimeout(this.timeoutBlurReference);
    }

    this.timeoutBlurReference = setTimeout(() => {
      if (
        !event.relatedTarget ||
        !this.$el.contains(event.relatedTarget as HTMLElement)
      ) {
        this.focusedElementIndex = null;
      }
      // this.validateInternalValue(this.value);
      this.$emit("blur", event);
    }, 500) as unknown as number;
  }

  copyHandler(event: ClipboardEvent) {
    const content = window.getSelection()?.getRangeAt(0)?.cloneContents();

    if (!content) {
      return;
    }

    const contentElement = document.createElement("div");
    contentElement.appendChild(content);

    const contentText = (contentElement.innerText || "").replaceAll(
      this.lineBreakSymbol,
      "\r\n"
    );

    event.clipboardData?.setData("text/plain", contentText);
    event.clipboardData?.setData("text/html", contentText);
    event.preventDefault();
  }

  closeDataModelSelector() {
    this.isDataModelSelectorVisible = false;
  }

  getText() {
    return !this.isProperty
      ? language.generic_variable
      : language.generic_property;
  }

  validateInternalValue(value: string | null = null) {
    if (!(this.settings && this.hasInternalTypeValidation)) {
      return;
    }

    if (value === null) {
      value = this.value;
    }

    // validate data type
    const errorMessage = "Error: data type mismatch";
    let messages = [...(this.settings.status?.message || [])];
    const messageIndex = Array.isArray(messages)
      ? messages.indexOf(errorMessage)
      : -1;

    if (
      !SettingValidation.validateDataType(
        getValueDataTypes(value),
        this.settings
      )
    ) {
      messageIndex === -1 && (messages = [errorMessage]);
    } else {
      messageIndex !== -1 && messages.splice(messageIndex, 1);
    }

    if (this.settings.status) {
      this.settings.status.message = messages;
    } else {
      this.settings.status = {
        type: PdsTypes.StatusType.ERROR,
        message: messages,
      };
    }
  }

  getColor(guid: string) {
    const dataType = this.getVariableDataType(
      guid,
      this.processVariables,
      this.variableScopes || []
    );

    // todo
    // if (dataType.isEventRelated) {
    //   return "special color for this variable"
    // }

    if (dataType)
      switch (dataType.id) {
        case Primitives.BOOLEAN:
          return "accent3";
        case Primitives.STRING:
          return "primary";
        case Primitives.INTEGER:
          return "accent1";
        case Primitives.FLOAT:
        case Primitives.DOUBLE:
          return "float";
        case Primitives.TIME:
        case Primitives.DATE:
        case Primitives.DATETIME:
          return "warning";
        case NonPrimitives.FILE:
          return "info";
        case Primitives.GUID:
          return "warning2";
        case UNKNOWN_DATA_MODEL_ID:
          return "danger";
        default:
          return "accent2";
      }
  }

  getTooltipDataType(guid: string) {
    const dataType = this.getVariableDataType(
      guid,
      this.processVariables,
      this.variableScopes || []
    );

    if (dataType?.id === UNKNOWN_DATA_MODEL_ID) {
      return UNKNOWN_DATA_MODEL_TOOLTIP;
    }

    const isList = this.isVariableList(
      guid,
      this.processVariables,
      this.variableScopes || []
    );

    if (!dataType) {
      return "";
    }

    return `${dataType.isProcesio ? "" : "Data model:"} ${
      isList ? "list &lt;" : ""
    }${dataType?.displayName}${isList ? "&gt;" : ""}`;
  }

  getTooltipNameIfTooLong(val: string) {
    const name = this.parseVariableIdToName(
      val,
      false,
      this.processVariables,
      this.variableScopes
    );
    return name.length > 61 ? ` ${name}` : "";
  }

  replaceVariable(id: string | null = null) {
    this.isReplace = true;
    const index = this.internalValue.findIndex((val) => val.id === id);

    this.focusedElementIndex =
      index !== -1 ? index : this.closesPlaceholderPosition;
    this.handleAddVariable();
  }
}
</script>

<style lang="scss">
@import "./GenericText.component.scss";
</style>
