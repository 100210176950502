import { store } from "@/store";
import UserPermissionsGuard from "./UserPermissionsGuard";

export enum RoleType {
  Admin = "Admin",
  Write = "Write",
  Update = "Update",
  Read = "Read",
  None = "None",
  "Author-Admin" = "Author-Admin",
  "Author-Write" = "Author-Write",
  "Author-Read" = "Author-Read",
  "Author-None" = "Author-None",
}

export interface Role {
  roleId: string;
  name: string;
  type: RoleType;
  order: number;
}

export enum ProcesioEntityType {
  MasterWorkspace = "MasterWorkspace",
  Workspace = "Workspace",
  ProcessDesigner = "ProcessDesigner",
  DocumentDesigner = "DocumentDesigner",
  ProcessInstance = "ProcessInstance",
  CustomActions = "CustomActions",
  Schedule = "Schedule",
  Webhook = "Webhook",
  FormTemplate = "FormTemplate",
  FormInstance = "FormInstance",
  DataModels = "DataModels",
  Credentials = "Credentials",
  ApiKey = "ApiKey",
}

export interface ProcesioEntity {
  entityId: string;
  name: string;
  type: ProcesioEntityType;
  order: number;
  isShown: boolean; // boolean: available to edit in table or not
}

export enum UserTypeName {
  Owner = "Owner",
  CoOwner = "Co-Owner",
  Admin = "Admin",
  Member = "Member",
  Basic = "Basic",
}

export interface UserType {
  userTypeId: string;
  name: UserTypeName;
  roles: {
    [key in string]: string[];
  };
  order: number;
}

export interface UserPermissions {
  userTypeId: string;
  roles: {
    [key in string]: string[]; // Role[]; // "EntityId":[{ "RoleId }]
  };
}

export const getDefaultUserPermissions = (): UserPermissions | null => {
  const defaultUserType: UserType = store.getters.userTypes.find(
    (userType: UserType) => userType.name === UserTypeName.Member
  );

  return defaultUserType
    ? {
        userTypeId: defaultUserType.userTypeId,
        roles: defaultUserType.roles,
      }
    : null;
};

export const getSelectableUserTypes = () => {
  const userPermissions: UserPermissions = store.getters.userPermissions;
  const currentUserType: UserType = store.getters.getUserTypeById(
    userPermissions.userTypeId
  );
  const ownerUserType: UserType = store.getters.getUserTypeByName(
    UserTypeName.Owner
  );

  return [...store.getters.userTypes]
    .sort((a: UserType, b: UserType) => b.order - a.order)
    .filter((userType: UserType) => {
      if (userType.name === UserTypeName.Owner) {
        return false;
      }

      if (
        userType.name === UserTypeName.CoOwner &&
        userPermissions.userTypeId !== ownerUserType.userTypeId
      ) {
        return false;
      }

      return currentUserType.order >= userType.order;
    });
};
