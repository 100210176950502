import { Variable } from "@/services/crud/Orchestration.service";
import { Commit } from "vuex";
import { MutationTypes } from "./Document.mutations";

export enum ActionTypes {
  SET_TITLE = "SET_TITLE",
  SET_VARIABLES = "SET_VARIABLES",
  RESET_DOCUMENT_DATA = "RESET_DOCUMENT_DATA",
}

export interface Actions {
  [ActionTypes.SET_TITLE](
    { commit }: { commit: Commit },
    payload: string | null
  ): void;

  [ActionTypes.SET_VARIABLES](
    { commit }: { commit: Commit },
    payload: Variable[]
  ): void;

  [ActionTypes.RESET_DOCUMENT_DATA]({ commit }: { commit: Commit }): void;
}

export const actions: any = {
  [ActionTypes.SET_TITLE](
    { commit }: { commit: Commit },
    payload: string | null
  ) {
    commit(MutationTypes.SET_TITLE, payload);
  },

  [ActionTypes.SET_VARIABLES](
    { commit }: { commit: Commit },
    payload: Variable[]
  ) {
    commit(MutationTypes.SET_VARIABLES, payload);
  },

  [ActionTypes.RESET_DOCUMENT_DATA]({ commit }: { commit: Commit }) {
    commit(MutationTypes.RESET_DOCUMENT_DATA);
  },
};
