export type Direction = "next" | "previous"

export const moveFocus = (target: HTMLElement, direction: Direction) => {
  const els = Array.from(document.querySelectorAll("[tabindex='0']"));
  let index = els.indexOf(target)
  direction === 'next' ? index ++ : index --;
  if (!!els[index]) {
    (els[index] as HTMLElement).focus();
  }
};
