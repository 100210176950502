<template>
  <div
    @click="focusSinput"
    :class="['pds-c-api', isFullScreen && 'pds-c-api--fullscreen']"
  >
    <pds-input
      disabled
      :required="$attrs.required || false"
      :status="status"
      label="Endpoint"
    >
      <slot name="prepend" slot="prepend" />
    </pds-input>

    <div :class="['pds-c-api--content']">
      <p
        v-if="input.length < 32 || isEndpointExpanded"
        :class="[
          'pds-c-api--link',
          'pds-u-body--2',
          isEndpointExpanded && 'pds-c-api--link--expandable',
          isEndpointExpanded && 'pds-c-api--link--expandable--expanded',
        ]"
        v-html="input.trim()"
        @mousedown="isEndpointExpanded && onEndpointMouseDown($event)"
        @dblclick="onExpandedEndpointDoubleClick"
        @click="onExpandedEndpointClick"
      />
      <p
        v-else
        :class="[
          'pds-c-api--ellipsis-holder',
          'pds-u-body--2',
          hasPrependSlot ? 'pds-u-m--l--32' : 'pds-u-m--l--8',
          'pds-c-api--ellipsis-holder--expandable',
        ]"
        v-tooltip="{
          content: `${input}<br><br> <i>Click to expand</i>`,
        }"
        @click="onTruncatedEndpointClick"
      >
        <span :class="['pds-c-api--ellipsis', 'pds-u-body--2']">
          {{ input.substring(0, 18) + "..." }}</span
        >
        {{ input.substring(input.length - 10) }}
      </p>
      <!-- <p :class="['pds-u-body--2', 'pds-c-api--slash']">/</p> -->
      <pup-generic-text
        :class="[
          'pds-u-body--2',
          'pds-c-api--second',
          disabled && 'pds-c-api--second--disabled',
          !input && hasPrependSlot && 'pds-c-api--second--empty',
        ]"
        :value="value"
        @update-input="emitModel"
        placeholder="{methodeName}"
        :settings="settings"
        :processVariables="processVariables"
        @blur="$emit('blur', $event)"
        :status="status"
        :showValidationMessage="false"
        :parent="parent"
        :disabled="disabled"
        :fullScreenTitle="parent.name + ' | Endpoint'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Model, Emit, Prop } from "vue-property-decorator";
import { InputComponent, PdsTypes } from "@procesio/procesio-design-system";
import ApiCallService from "@/services/apiCall/ApiCall.service";
import SecondInput from "./SecondInput.component.vue";
import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import {
  Setting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { EventBus, Events } from "@/utils/eventBus";

@Component({
  components: {
    "pds-input": InputComponent,
    "pup-sinput": SecondInput,
    "pup-generic-text": GenericText,
  },
})
export default class ApiCallInput extends Vue {
  input = "";

  @Prop({ required: true }) settings!: Setting;

  @Prop() parent!: Node;

  @Model("update-input") value: unknown | string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: "" }) label!: string;

  internalErrorMessages: string[] = [];

  hasPrependSlot = false;

  isEndpointExpanded = false;

  endpointClickPosition: { x: number; y: number } | null = null;

  endpointClickTimeout: ReturnType<typeof setTimeout> | null = null;

  isFullScreen = false;

  created() {
    this.checkForSlots();
  }

  mounted() {
    this.getEndpoint();

    EventBus.$on(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (this.settings.id === settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  beforeDestoy() {
    EventBus.$off(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (this.settings.id === settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  beforeUpdate() {
    this.checkForSlots();
  }

  async getEndpoint() {
    const result = await ApiCallService.getUrl(this.parent.id);

    if (result.payload.url) {
      this.input = result.payload.url;
    }
  }

  focusSinput() {
    const sinput = this.$refs.sinput as any;

    if (sinput) {
      sinput.focused();
    }
  }

  checkForSlots() {
    this.$nextTick(() => {
      this.hasPrependSlot =
        !!this.$scopedSlots.prepend || !!this.$slots.prepend;
    });
  }

  onEndpointMouseDown(event: MouseEvent) {
    // single click
    if (event.detail === 1) {
      this.endpointClickPosition = {
        x: event.clientX,
        y: event.clientY,
      };
    }
  }

  onTruncatedEndpointClick(event: MouseEvent) {
    // single click
    if (event.detail === 1) {
      this.isEndpointExpanded = true;
    }
  }

  onExpandedEndpointDoubleClick() {
    if (this.endpointClickTimeout) {
      clearTimeout(this.endpointClickTimeout);
      this.endpointClickTimeout = null;
    }
  }

  onExpandedEndpointClick(event: MouseEvent) {
    // single click
    if (event.detail === 1) {
      this.endpointClickTimeout = setTimeout(() => {
        if (
          this.endpointClickPosition &&
          this.endpointClickPosition.x === event.clientX &&
          this.endpointClickPosition.y === event.clientY
        ) {
          this.isEndpointExpanded = !this.isEndpointExpanded;
        }

        this.endpointClickPosition = null;
      }, 200);
    }
  }

  @Emit("update-input")
  emitModel(value: string) {
    return value;
  }
}
</script>

<style lang="scss">
@import "./ApiCall.component.scss";
</style>
