import { store } from "@/store";
import Vue from "vue";
import VueRouter, {
  NavigationGuardNext,
  Route,
  RouteConfig,
  RouteRecord,
} from "vue-router";
import {
  RouteName as ActionDesignerRouteName,
  routes as actionDesignerRoutes,
} from "@/modules/CustomActionDesigner/application/router";
import {
  RouteName as ResourceMapRouteName,
  routes as resourceMapRoutes,
} from "@/modules/ResourceMap/application/router";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";

/** AUTH */
const LogIn = () => import("@/modules/Auth/Login/LogIn.component.vue");
const ChangePass = () =>
  import("@/modules/Auth/Login/ChangePass.component.vue");
const ResetPass = () => import("@/modules/Auth/Login/ResetPass.component.vue");
const Activation = () =>
  import("@/modules/Auth/Activation/Activation.component.vue");
const CreateAccount = () =>
  import("@/modules/Auth/CreateAccount/CreateAccount.component.vue");

/** APP */
const Admin = () => import("@/modules/Admin/Admin.component.vue");
const Processes = () =>
  import("@/modules/ProcessDesigner/components/Processes.component.vue");
const Dashboard = () => import("@/modules/Dashboard/Dashboard.component.vue");

/** MODULES */
/** DESIGNER */
const Designer = () =>
  import(
    "@/modules/ProcessDesigner/components/Designer/Designer.component.vue"
  );
const ProcessTopbar = () =>
  import(
    "@/modules/ProcessDesigner/components/Topbar/ProcessTopbar/ProcessTopbar.component.vue"
  );

const ProcessList = () =>
  import(
    "@/modules/ProcessDesigner/components/ProcessList/ProcessList.component.vue"
  );
const InstancesList = () =>
  import("@/modules/ProcessDesigner/instances/InstancesList.component.vue");
const InstanceDetails = () =>
  import(
    "@/modules/ProcessDesigner/instances/instanceDetails/InstanceDetails.component.vue"
  );
const InstanceTopbar = () =>
  import(
    "@/modules/ProcessDesigner/components/Topbar/InstanceTopbar/InstanceTopbar.component.vue"
  );

/** DATA MODEL */
const DataModelCreate = () =>
  import(
    "@/modules/DataModelDesigner/DataModuleCreate/DataModelCreate.component.vue"
  );
const DataModelDesigner = () =>
  import("@/modules/DataModelDesigner/DataModelDesigner.vue");
const DataModelList = () =>
  import(
    "@/modules/DataModelDesigner/DataModelList/DataModelList.component.vue"
  );

/** CREDENTIAL MANAGER */
const CredentialModule = () =>
  import(
    "@/modules/CredentialManager/components/CredentialManager.component.vue"
  );
const CredentialList = () =>
  import(
    "@/modules/CredentialManager/components/CredentialList/CredentialList.component.vue"
  );

/** TEMPLATES DESIGNER */
const TemplateDesigner = () =>
  import("@/modules/TemplateDesigner/TemplateDesigner.vue");
const TemplateDesignerList = () =>
  import(
    "@/modules/TemplateDesigner/TemplatesList/TemplatesList.component.vue"
  );
const TemplateDesignerAction = () =>
  import(
    "@/modules/TemplateDesigner/TemplateDesignerAction/TemplateAction.component.vue"
  );
const TemplateDesignerTopbar = () =>
  import("@/modules/TemplateDesigner/components/TemplateTopbar.component.vue");

/** AUTOMATION */
const Automation = () =>
  import("@/modules/Automation/Automation.component.vue");
const Webhook = () =>
  import("@/modules/Automation/Webhooks/List/List.component.vue");
const Schedule = () =>
  import("@/modules/Automation/Schedules/List/ScheduleList.component.vue");

/** SETTINGS */
const Api = () => import("@/modules/Settings/APIKey/APIKeyTable.component.vue");
const Users = () =>
  import(
    "@/modules/Administration/UserManagement/UserManagementTable.component.vue"
  );

/** ANALYTICS */
const Analytics = () =>
  import("@/modules/Resources/Analytics/Analytics.component.vue");
const ProcessAnalytics = () =>
  import(
    "@/modules/Resources/Analytics/ProcessAnalytics/ProcessAnalytics.component.vue"
  );
const ProcessInstanceAnalytics = () =>
  import(
    "@/modules/Resources/Analytics/ProcessInstanceAnalytics/ProcessInstanceAnalytics.component.vue"
  );

/** ADMINISTRATION */
const Administration = () =>
  import("@/modules/Administration/Administration.component.vue");

/** WORKSPACES */
const WorkspaceManagement = () =>
  import(
    "@/modules/Administration/WorkspaceManagement/Table/WorkspaceManagement.component.vue"
  );

/** SUBSCRIPTION */
const Subscription = () =>
  import("@/modules/Resources/Subscriptions/Subscriptions.component.vue");

/** CUSTOM ACTION DESIGNER */

// const WorkspaceUsers = () =>
//   import("@/modules/Workspaces/UserManagement/UserManagementTable.component.vue");

/** FORM */
const Form = () => import("@/modules/Form/components/Form.component.vue");
const FormList = () =>
  import("@/modules/Form/components/FormList/FormList.component.vue");
const FormTopbar = () =>
  import("@/modules/Form/components/Topbar/Topbar.component.vue");
const FormBuilder = () =>
  import("@/modules/Form/components/Builder/Builder.component.vue");
const FormFill = () =>
  import("@/modules/Form/components/Fill/Fill.component.vue");

const FormInstanceList = () =>
  import(
    "@/modules/Form/components/FormInstanceList/FormInstanceList.component.vue"
  );
const FormInstanceTopbar = () =>
  import(
    "@/modules/Form/components/FormInstanceList/Topbar/Topbar.component.vue"
  );
const FormAssignmentList = () =>
  import(
    "@/modules/Form/components/AssignmentList/AssignmentList.component.vue"
  );

import { DefaultTitleSuffix, getTitleByRoute, setTitle } from "./titleHelper";
import ApplicationService from "@/services/application";
import { UserWorkspaceType } from "@/services/user/workspace/UserWorkspace.service";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";
import { routeMiddlewate } from "./middleware";

Vue.use(VueRouter);

/**
 * Name is used to be shown on the top bar.
 *
 * One more feature is routing by name,
 *  ideally, name should be unique, that is why in some names spaces are added
 */
export enum RouteName {
  LOGIN = "Login",
  ADMIN = "Admin",
  DASHBOARD = "Dashboard",
  CREDENTIAL_MANAGER = "Credential Manager",
  DATA_MODEL_DESIGNER = "Data Model Designer",
  DATA_MODEL_DESIGNER_CREATE = "Data Model Designer ",
  DATA_MODEL_DESIGNER_EDIT = "Data Model Designer  ",
  PROCESS_LIST = "Process Designer",
  PROCESS_DESIGNER = "Designer",
  PROCESS_INSTANCES = "Instances",
  PROCESS_LEGACY_INSTANCES = "Legacy Instances",
  PROCESS_DETAILS = "Details",
  SETTINGS = "Settings",
  API = "Api Key",
  USERS = "Users",
  AUTOMATION = "Automation",
  SCHEDULES = "Schedule ",
  WEBHOOKS = "Webhook  ",
  PROCESS_ANALYTICS = "Analytics",
  PROCESS_INSTANCE_ANALYTICS = "Analytics ",
  PROCESS_LEGACY_INSTANCE_ANALYTICS = "Analytics Legacy ",
  DOCUMENT_DESIGNER = "Document Designer",
  DOCUMENT_DESIGNER_ACTION = "Document Designer ",
  DOCUMENT_DESIGNER_ACTION_EDIT = "Document Designer  ",
  WORKSPACES = "Workspaces",
  WORKSPACES_MANAGEMENT = "Workspaces ",
  SUBSCRIPTIONS = "Subscription and billing",
  FORM_LIST = "Form Designer",
  FORM_BUILDER = "Form Designer ",
  FORM_PUBLIC = "Form (public)",
  FORM_PRIVATE = "Form (private)",
  FORM_INSTANCES = "Form Instances",
  FORM_INSTANCE_EDIT = "Form Instance edit",
  FORM_INSTANCE_DETAILS = "Form Instance Details",
  FORM_ASSIGNMENTS = "Forms ",
  FORM_ASSIGNMENT_REVIEW = "Form Assignment Review",
  // ADMINISTRATION_WORKSPACE_USERS = "Administration  ",
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteName.LOGIN,
    component: LogIn,
    meta: { title: "Integrate, Automate, Orchestrate " + DefaultTitleSuffix },
    beforeEnter: async (_, __, next) => {
      if (store.getters.token) {
        next({ name: RouteName.DASHBOARD });
      } else {
        await ApplicationService.init();
        next();
      }
    },
  },
  {
    path: "/create-account/:token?",
    component: CreateAccount,
  },
  {
    path: "/set-password/success",
    component: ChangePass,
    props: { mode: "SUCCESS" },
  },
  {
    path: "/set-password/:userId?",
    component: ChangePass,
  },
  {
    path: "/reset-password/:userId?",
    component: ChangePass,
    props: { state: "CHANGE" },
  },
  {
    path: "/reset-pass",
    component: ResetPass,
  },
  {
    path: "/activation",
    component: Activation,
  },
  {
    path: "/forms",
    component: Form,
    children: [
      {
        path: ":id/:workspaceId?",
        component: FormFill,
        name: RouteName.FORM_PUBLIC,
        beforeEnter: async (to: Route, from: Route, next) => {
          const refreshTokenExpiresIn = store.getters.refreshTokenExpiresIn;
          const timestamp = new Date().getTime();
          if (store.getters.token && timestamp < refreshTokenExpiresIn) {
            next({
              name: RouteName.FORM_PRIVATE,
              params: to.params,
              query: to.query,
              hash: to.hash,
            });
            return;
          }

          const workspaceId = to.params.workspaceId;
          if (!workspaceId) {
            return next();
          }

          const params = { ...to.params };
          delete params.workspaceId;

          router.replace({
            name: to.name as string,
            params,
            query: to.query,
            hash: "#" + workspaceId,
          });
        },
      },
    ],
  },
  {
    path: "/admin",
    name: RouteName.ADMIN,
    component: Admin,
    beforeEnter: async (to: Route, from: Route, next) => {
      const refreshTokenExpiresIn = store.getters.refreshTokenExpiresIn;
      const timestamp = new Date().getTime();
      if (
        !store.getters.token ||
        (refreshTokenExpiresIn && timestamp > refreshTokenExpiresIn)
      ) {
        next(
          to.name === RouteName.FORM_PRIVATE
            ? {
                name: RouteName.FORM_PUBLIC,
                params: to.params,
                query: to.query,
                hash: to.hash,
              }
            : { name: RouteName.LOGIN }
        );
        return;
      }

      store.dispatch(UIActionTypes.SET_LOADING_STATE, true);
      await ApplicationService.init();
      store.dispatch(UIActionTypes.SET_LOADING_STATE, false);
      routeMiddlewate(to, from, next);
    },
    children: [
      {
        path: "dashboard",
        component: Dashboard,
        name: RouteName.DASHBOARD,
        meta: { title: "Dashboard " + DefaultTitleSuffix },
      },
      {
        path: "credentials-manager",
        component: CredentialModule,
        meta: {
          title: "Credential Manager " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.Credentials,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: ":credentialId?",
            component: CredentialList,
            name: RouteName.CREDENTIAL_MANAGER,
          },
        ],
      },
      {
        path: "data-model",
        component: DataModelDesigner,
        meta: {
          title: "Data Model Designer " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.DataModels,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "/",
            component: DataModelList,
            name: RouteName.DATA_MODEL_DESIGNER,
          },
          {
            path: ":modelId?",
            component: DataModelCreate,
            name: RouteName.DATA_MODEL_DESIGNER_EDIT,
            props: true,
          },
        ],
      },
      {
        path: "process",
        component: Processes,
        meta: {
          title: "Process Designer " + DefaultTitleSuffix,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "/",
            component: ProcessList,
            name: RouteName.PROCESS_LIST,
            meta: {
              entityType: ProcesioEntityType.ProcessDesigner,
              roleType: RoleType.Read,
            },
          },
          {
            name: RouteName.PROCESS_DESIGNER,
            path: "designer/:flowId?",
            component: Designer,
            meta: {
              topbar: ProcessTopbar,
              entityType: ProcesioEntityType.ProcessDesigner,
              roleType: (to: Route) =>
                to.params.flowId ? RoleType.Read : RoleType.Write,
            },
          },
          {
            name: RouteName.PROCESS_INSTANCES,
            path: "instances/:flowId?",
            component: InstancesList,
            meta: {
              topbar: InstanceTopbar,
              entityType: ProcesioEntityType.ProcessInstance,
              roleType: RoleType.Read,
            },
          },
          {
            name: RouteName.PROCESS_LEGACY_INSTANCES,
            path: "instances/:flowId?/legacy",
            component: InstancesList,
            meta: {
              topbar: InstanceTopbar,
              entityType: ProcesioEntityType.ProcessInstance,
              roleType: RoleType.Read,
            },
            props: { legacy: true },
          },
          {
            path: ":flowId/details/:instanceId?",
            component: InstanceDetails,
            name: RouteName.PROCESS_DETAILS,
            meta: {
              topbar: InstanceTopbar,
              entityType: ProcesioEntityType.ProcessInstance,
              roleType: RoleType.Read,
            },
          },
        ],
      },
      {
        path: "document-designer",
        component: TemplateDesigner,
        meta: {
          title: RouteName.DOCUMENT_DESIGNER,
          entityType: ProcesioEntityType.DocumentDesigner,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "/",
            name: RouteName.DOCUMENT_DESIGNER,
            component: TemplateDesignerList,
          },
          {
            path: "add",
            name: RouteName.DOCUMENT_DESIGNER_ACTION,
            component: TemplateDesignerAction,
            meta: {
              topbar: TemplateDesignerTopbar,
              entityType: ProcesioEntityType.DocumentDesigner,
              roleType: RoleType.Write,
            },
          },
          {
            path: "edit/:id",
            name: RouteName.DOCUMENT_DESIGNER_ACTION_EDIT,
            component: TemplateDesignerAction,
            meta: {
              topbar: TemplateDesignerTopbar,
            },
          },
        ],
      },
      {
        path: "api",
        component: Api,
        meta: {
          title: "Api " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.ApiKey,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        name: RouteName.API,
      },
      {
        path: "users",
        component: Users,
        meta: {
          title: "Users " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.Workspace,
          roleType: RoleType.Write,
          workspaceTypes: [
            UserWorkspaceType.MASTER,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        name: RouteName.USERS,
      },
      {
        path: "webhooks",
        component: Webhook,
        meta: {
          title: "Webhook " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.Webhook,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        name: RouteName.WEBHOOKS,
      },
      {
        path: "schedules",
        component: Schedule,
        meta: {
          title: "Schedule " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.Schedule,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        name: RouteName.SCHEDULES,
      },
      {
        path: "automation",
        component: Automation,
        meta: {
          title: "Automation " + DefaultTitleSuffix,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        name: RouteName.AUTOMATION,
      },
      {
        path: "analytics",
        component: Analytics,
        meta: {
          title: "Analytics " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.ProcessDesigner,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "",
            component: ProcessAnalytics,
            name: RouteName.PROCESS_ANALYTICS,
            // meta: {
            //   entityType: ProcesioEntityType.ProcessDesigner,
            //   roleType: RoleType.Read,
            // },
          },
          {
            path: "instances/:flowId?",
            component: ProcessInstanceAnalytics,
            name: RouteName.PROCESS_INSTANCE_ANALYTICS,
            meta: {
              entityType: ProcesioEntityType.ProcessInstance,
              roleType: RoleType.Read,
            },
          },
          {
            path: "instances/:flowId?/legacy",
            component: ProcessInstanceAnalytics,
            name: RouteName.PROCESS_LEGACY_INSTANCE_ANALYTICS,
            props: { legacy: true },
            meta: {
              entityType: ProcesioEntityType.ProcessInstance,
              roleType: RoleType.Read,
            },
          },
        ],
      },
      actionDesignerRoutes,
      resourceMapRoutes,
      {
        path: "/",
        component: Administration,
        children: [
          {
            path: "workspaces",
            name: RouteName.WORKSPACES_MANAGEMENT,
            component: WorkspaceManagement,
            meta: {
              entityType: ProcesioEntityType.MasterWorkspace,
              roleType: RoleType.Read,
              workspaceTypes: [UserWorkspaceType.MASTER],
            },
          },
        ],
      },
      {
        path: "subscriptions",
        component: Subscription,
        meta: {
          title: "Subscription and billing " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.MasterWorkspace,
          roleType: RoleType.Write,
          workspaceTypes: [UserWorkspaceType.MASTER],
        },
        name: RouteName.SUBSCRIPTIONS,
      },
      {
        path: "forms",
        component: Form,
        meta: {
          title: "Form Builder " + DefaultTitleSuffix,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "/",
            component: FormList,
            name: RouteName.FORM_LIST,
            meta: {
              entityType: ProcesioEntityType.FormTemplate,
              roleType: RoleType.Read,
            },
          },
          {
            name: RouteName.FORM_BUILDER,
            path: "designer/:id?",
            component: FormBuilder,
            meta: {
              topbar: FormTopbar,
              entityType: ProcesioEntityType.FormTemplate,
              roleType: (to: Route) =>
                to.params.id ? RoleType.Read : RoleType.Write,
            },
          },
          {
            name: RouteName.FORM_INSTANCES,
            path: "submissions/:id",
            component: FormInstanceList,
            meta: {
              topbar: FormInstanceTopbar,
              entityType: ProcesioEntityType.FormInstance,
              roleType: RoleType.Read,
            },
          },
          {
            name: RouteName.FORM_INSTANCE_EDIT,
            path: "submissions/:pid/:id/edit",
            component: FormFill,
            meta: {
              topbar: FormTopbar,
              entityType: ProcesioEntityType.FormInstance,
              roleType: RoleType.Update,
            },
          },
          {
            name: RouteName.FORM_INSTANCE_DETAILS,
            path: "submissions/:id/:instanceId",
            component: FormInstanceList,
            meta: {
              topbar: FormInstanceTopbar,
              entityType: ProcesioEntityType.FormInstance,
              roleType: RoleType.Read,
            },
          },
          {
            path: ":id/:workspaceId?",
            component: FormFill,
            name: RouteName.FORM_PRIVATE,
            meta: {
              topbar: FormTopbar,
            },
            beforeEnter: async (to: Route, from: Route, next) => {
              const workspaceId = to.params.workspaceId;
              if (!workspaceId) {
                return next();
              }

              const params = { ...to.params };
              delete params.workspaceId;

              router.replace({
                name: to.name as string,
                params,
                query: to.query,
                hash: "#" + workspaceId,
              });
            },
          },
        ],
      },
      {
        path: "form-assignments",
        component: Form,
        meta: {
          title: "Form Assignments " + DefaultTitleSuffix,
          entityType: ProcesioEntityType.FormInstance,
          roleType: RoleType.Read,
          workspaceTypes: [
            UserWorkspaceType.PERSONAL,
            UserWorkspaceType.SUBWORKSPACE,
            UserWorkspaceType.ORGANIZATION,
          ],
        },
        children: [
          {
            path: "/",
            component: FormAssignmentList,
            name: RouteName.FORM_ASSIGNMENTS,
          },
          {
            path: ":id",
            component: FormFill,
            name: RouteName.FORM_ASSIGNMENT_REVIEW,
            meta: {
              topbar: FormTopbar,
            },
          },
        ],
      },
    ],
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // routes backward compatibility
  const hashTagPart = "/#/";
  if (to.fullPath.includes(hashTagPart)) {
    next(to.fullPath.replace(hashTagPart, "/"));
    return;
  }

  setTitle(getTitleByRoute(to));
  if (!!from.name) {
    return routeMiddlewate(to, from, next);
  }

  return next();
});

export default router;
