export enum BannerUnit {
  "RESOURCE" = "RESOURCE",
  "SUBSCRIPTION" = "SUBSCRIPTION",
  "PROCESIO_ANNOUNCEMENT" = "PROCESIO_ANNOUNCEMENT",
  "CAMPAIGN" = "CAMPAIGN",
}

export enum BannerType {
  "RESOURCE:SOFT_LIMIT_END_SOON" = "RESOURCE:SOFT_LIMIT_END_SOON", // not applicable if there is not hard limit
  "RESOURCE:SOFT_LIMIT_REACHED" = "RESOURCE:SOFT_LIMIT_REACHED", // not applicable if there is not hard limit
  "RESOURCE:LIMIT_REACHED" = "RESOURCE:LIMIT_REACHED",
  "SUBSCRIPTION:EXPIRED" = "SUBSCRIPTION:EXPIRED",
  "SUBSCRIPTION:EXPIRE_SOON" = "SUBSCRIPTION:EXPIRE_SOON",
  "PROCESIO_ANNOUNCEMENT" = "PROCESIO_ANNOUNCEMENT",
  "CAMPAIGN" = "CAMPAIGN",
}
