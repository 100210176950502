import { actions } from "./UserPermissions.actions";
import { mutations } from "./UserPermissions.mutations";
import getters from "./UserPermissions.getters";
import {
  ProcesioEntity,
  Role,
  UserPermissions,
  UserType,
} from "@/services/user/permissions/UserPermissions.model";

export interface UserPermissionsState {
  procesioEntities: ProcesioEntity[];
  roles: Role[];
  userTypes: UserType[];
  userPermissionsPerWorkspace: { [key in string]: UserPermissions };
}

const getInitialState = (): UserPermissionsState => {
  return {
    procesioEntities: [],
    roles: [],
    userTypes: [],
    userPermissionsPerWorkspace: {},
  };
};

export const UserPermissionsStore = {
  state: getInitialState,
  actions,
  mutations,
  getters,
};
