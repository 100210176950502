<template>
  <div class="pup-c-case-selector">
    <div v-if="isDesktop" class="pup-c-case-selector--border">
      <div
        :class="[
          'pup-c-case-selector--btn-collapse',
          'pup-c-case-selector--text',
          !isCollapsed && 'pup-c-case-selector--text--collapsed',
        ]"
        @click="toggle"
      >
        View Cases
        <pds-icon
          :icon="isCollapsed ? 'chevron_left' : 'chevron_right'"
          class="pup-c-case-selector--icon"
        />
      </div>
    </div>
    <div
      class="pup-c-case-selector--content"
      :class="{
        'pup-c-case-selector--content--collapsed': isCollapsed,
      }"
    >
      <draggable
        :list="cases"
        handle=".pup-c-card-button--drag-handle"
        :options="{ disabled: disabled }"
      >
        <pup-card-button
          v-for="(cas, index) in cases"
          :key="index"
          :index="index"
          :parent="node"
          :value="cas"
          :settings="settings"
          :selected="cas.internalId === value.internalId"
          :disabled="disabled"
          @click="$emit('selected', cas)"
          :class="{
            'pds-u-m--b--16': index === cases.length - 1,
          }"
        />
      </draggable>

      <pup-decisional-default
        v-if="defaultCaseSetting"
        :value="defaultCaseSetting.value"
        :parent="node"
        :settings="defaultCaseSetting"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import {
  SidePanelComponent,
  IconComponent,
  ResponsiveMixin,
} from "@procesio/procesio-design-system";
import {
  Node,
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { DecisionalCardValue } from "../ConditionBuilder.model";
import ConditionCardButton from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/card/button/Button.component.vue";
import DecisionalDefault from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/DecisionalDefault.component.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    "pds-sidepanel": SidePanelComponent,
    "pds-icon": IconComponent,
    "pup-card-button": ConditionCardButton,
    "pup-decisional-default": DecisionalDefault,
    draggable,
  },
})
export default class CaseSelector extends mixins(ResponsiveMixin) {
  @Prop() value!: DecisionalCardValue;

  @Prop() node!: Node;

  @Prop({ default: () => [] }) cases!: DecisionalCardValue[];

  @Prop() settings!: Setting;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  isCollapsed = false;

  get defaultCaseSetting() {
    let defaultCaseSetting: Setting | null = null;

    this.node.configuration.forEach((settingTab) => {
      settingTab.settings.forEach((setting) => {
        if (
          !defaultCaseSetting &&
          setting.type === SettingType.DECISIONAL_DEFAULT
        ) {
          defaultCaseSetting = setting;
        }
      });
    });

    return defaultCaseSetting;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
</script>

<style lang="scss">
@import "./CaseSelector.component.scss";
</style>
