import { HubConnection } from "@microsoft/signalr";
import { MutationTree } from "vuex";
import { SignalRState } from "./signalR.store";

export enum MutationTypes {
  SET_CONNECTION = "SET_CONNECTION",
  SET_CONNECTION_ID = "SET_CONNECTION_ID",
}

export type Mutations<S = SignalRState> = {
  [MutationTypes.SET_CONNECTION](state: S, payload: HubConnection | null): void;
  [MutationTypes.SET_CONNECTION_ID](state: S, payload: string | null): void;
};

export const mutations: MutationTree<SignalRState> & Mutations = {
  [MutationTypes.SET_CONNECTION](state, payload) {
    state.connection = payload;
  },
  [MutationTypes.SET_CONNECTION_ID](state, payload) {
    state.connectionId = payload;
    if (state.connection) {
      state.connection.connectionId = payload;
    }
  }
}