import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  AttributeParameter,
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { hasVariable } from "@/modules/ProcessDesigner/Variables/Utils";
import { ParameterVariableCounter } from "../ParameterVariableCounter";

export abstract class AbstractControlParameterGetter {
  variableCounter: ParameterVariableCounter;

  constructor(variableCounter: ParameterVariableCounter | null = null) {
    if (!variableCounter) {
      variableCounter = new ParameterVariableCounter();
    }
    this.variableCounter = variableCounter;
  }

  abstract getParameter(setting: Setting): Parameter | Parameter[];

  abstract getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[];

  /**
   * @description
   * Transcludes the value of a control which contains variable GUIDSs into
   * an object with references to those variables.
   *
   * @param value - Control value to be transcluded. Ex: 'Dear 941167ab-618b-4e72-8b66-ba69c206922a'
   * @returns
   * An object with the keys:
   * - `value`: the transcluded value of a control. Ex: 'Dear <%0%>'
   * - `variable`: An array of references of those transclusions. Ex [{id: 0, variableId: "941167ab-618b-4e72-8b66-ba69c206922a", attribute: null}]
   */
  parseControlValueToParameter(
    value: string
  ): {
    value: string;
    variable: VariableParameter[];
  } {
    if (typeof value !== "string") {
      return {
        value,
        variable: [],
      };
    }

    const usedVariables: VariableParameter[] = [];

    const guidRegexGlobal = new RegExp(
      /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/,
      "g"
    );

    const transcludedValue = value.replaceAll(
      guidRegexGlobal,
      (match: string) => {
        if (!hasVariable(match)) {
          return match;
        }

        const varTreeAsArray = match.split(".");

        const currentVariable: VariableParameter = {
          id: this.variableCounter.getValue(),
          variableId: varTreeAsArray[0],
          attribute: varTreeAsArray
            .slice(1)
            .reduceRight(function(
              nextAttribute: AttributeParameter | null,
              attributeId
            ) {
              return {
                attributeId,
                nextAttribute,
              };
            },
            null),
        };

        usedVariables.push(currentVariable);

        return "<%" + (this.variableCounter.increment() - 1) + "%>";
      }
    );

    return {
      value: transcludedValue,
      variable: usedVariables,
    };
  }
}
