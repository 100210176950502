import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { AbstractControlParameterGetter } from "./AbstractControlParameterGetter";

export class DefaultControlParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: Setting): Parameter | Parameter[] {
    const values = Array.isArray(setting.value)
      ? setting.value
      : [setting.value];

    // TODO: find a better solution for this any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return values.reduce((params, settingValue: any) => {
      let value = settingValue;

      if (
        settingValue &&
        typeof settingValue === "object" &&
        typeof settingValue.type !== "undefined"
      ) {
        value = settingValue.value;
      }

      const replacedValue = this.parseControlValueToParameter(value);

      params.push({
        tabPropertyId: setting.id,
        value: replacedValue.value,
        variable: replacedValue.variable,
      });

      return params;
    }, []);
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (!parameter.variable) {
        return;
      }

      if (Array.isArray(parameter.variable)) {
        variables.push(...parameter.variable);
      } else {
        variables.push(parameter.variable);
      }
    });

    return variables;
  }
}
