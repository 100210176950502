import ls from "@/services/localStorage";
import UserLocalStorage from "@/services/localStorage/user";
import { Event } from "@/services/user/event/Event.model";
import EventService from "@/services/user/event/Event.service";
import UserService from "@/services/user/User.service";
import { Commit, Dispatch } from "vuex";
import UserWorkspaceService, {
  UserWorkspace,
} from "../../services/user/workspace/UserWorkspace.service";
import { MutationTypes } from "./UserPermissions.mutations";
import { ActionTypes as ResourceActionTypes } from "@/store/resource/Resource.actions";
import { ProcesioEntity } from "@/services/user/permissions/UserPermissions.model";
import UserPermissionsService from "@/services/user/permissions/UserPermissions.service";
import { store } from "..";

export enum ActionTypes {
  LOAD_PERMISSIONS_DATA = "LOAD_PERMISSIONS_DATA",
  LOAD_USER_PERMISSIONS = "LOAD_USER_PERMISSIONS",
}

export interface Actions {
  [ActionTypes.LOAD_PERMISSIONS_DATA]({
    commit,
  }: {
    commit: Commit;
    dispatch: Dispatch;
  }): void;
  [ActionTypes.LOAD_USER_PERMISSIONS](
    {
      commit,
    }: {
      commit: Commit;
    },
    workspaceId?: string
  ): void;
}

export const actions: any = {
  async [ActionTypes.LOAD_PERMISSIONS_DATA]({
    commit,
    dispatch,
  }: {
    commit: Commit;
    dispatch: Dispatch;
  }) {
    commit(
      MutationTypes.SET_PROCESIO_ENTITIES,
      (await UserPermissionsService.getProcesioEntityList()).content || []
    );
    commit(
      MutationTypes.SET_ROLES,
      (await UserPermissionsService.getRoleList()).content || []
    );
    commit(
      MutationTypes.SET_USER_TYPES,
      (await UserPermissionsService.getUserTypeList()).content || []
    );
  },

  async [ActionTypes.LOAD_USER_PERMISSIONS](
    {
      commit,
    }: {
      commit: Commit;
    },
    workspaceId?: string
  ) {
    if (workspaceId === undefined) {
      workspaceId = store.getters.activeWorkspaceId;
    }

    commit(MutationTypes.SET_USER_PERMISSIONS, {
      permissions:
        (
          await UserPermissionsService.getActiveUserPermissions(
            workspaceId as string
          )
        ).content || null,
      workspaceId,
    });
  },
};
