var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"pds-u-subtitle--2"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('pup-variable-mapper-group',{staticClass:"pds-u-m--t--16",attrs:{"settings":_vm.settings,"value":_vm.variableMapperValue,"direction":_vm.direction,"leftInputPlaceholder":_vm.settings.type === _vm.settingType.PROCESS_INPUT
        ? 'Select subprocess input'
        : 'Select subprocess output',"rightInputPlaceholder":'Insert variable or value',"leftColumnVariables":_vm.filteredSubProcessVariables,"leftColumnVariableScopes":_vm.processSelectSettingValue ? [_vm.processSelectSettingValue] : [],"rightColumnVariables":_vm.processVariables,"isLeftInputOneVariableOnly":_vm.settings.type === _vm.settingType.PROCESS_INPUT,"isRightInputOneVariableOnly":_vm.settings.type === _vm.settingType.PROCESS_OUTPUT,"hideCreateLeftInput":true,"infoText":_vm.infoText,"leftColumnLabel":_vm.settings.type === _vm.settingType.PROCESS_INPUT
        ? 'Subprocess Input'
        : 'Subprocess Output',"rightColumnLabel":"Process Variable","addButtonText":'+ Add ' +
        (_vm.settings.type === _vm.settingType.PROCESS_INPUT
          ? 'subprocess input'
          : 'process output'),"parent":_vm.parent,"disabled":_vm.disabled},on:{"update-input":_vm.onMapperGroupValueUpdate,"remove-row":_vm.onRowRemove}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }