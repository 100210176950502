import { EditorLanguage } from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.model";

export enum TabsPayloadKeys {
  QUERY_PARAMS = "queryParams",
  HEADERS = "headers",
  BODY = "body",
}

export enum TabsPayloadLabels {
  QUERY_PARAMS = "Query Params",
  HEADERS = "Headers",
  BODY = "Body",
}

export enum PayloadBodyTypes {
  BINARY = "BINARY",
  FORM_DATA = "FORM_DATA",
  RAW = "RAW",
  X_WWW_FORM_URLENCODED = "X_WWW_FORM_URLENCODED",
}

export enum PayloadBodyTypeLabels {
  BINARY = "binary",
  FORM_DATA = "form-data",
  RAW = "raw",
  X_WWW_FORM_URLENCODED = "x-www-form-urlencoded",
}

export enum TabsPayloadItemType {
  TEXT = "TEXT",
  FILE = "FILE",
}

export interface TabsPayloadOldValue {
  queryParams: TabsPayloadItem[];
  headers: TabsPayloadItem[];
  body: string;
}

export interface TabsPayloadValue {
  queryParams: TabsPayloadItem[];
  headers: TabsPayloadItem[];
  body: PayloadBody;
}

export interface TabsPayloadItem {
  key: string;
  value: string;
  type?: TabsPayloadItemType | null;
  // description?: string;
}

export interface RawBodyPayloadValue {
  format: EditorLanguage | null;
  value: string;
}

export interface PayloadBody {
  type: PayloadBodyTypes;
  value: {
    [key in PayloadBodyTypes]: TabsPayloadItem[] | RawBodyPayloadValue | string;
  };
}

export const getInitialPayloadBody = (): PayloadBody => ({
  type: PayloadBodyTypes.RAW,
  value: {
    [PayloadBodyTypes.BINARY]: "",
    [PayloadBodyTypes.FORM_DATA]: [],
    [PayloadBodyTypes.RAW]: {
      format: EditorLanguage.JSON,
      value: "",
    },
    [PayloadBodyTypes.X_WWW_FORM_URLENCODED]: [],
  },
});

export const getInitialTabsPayload = (): TabsPayloadValue => ({
  [TabsPayloadKeys.QUERY_PARAMS]: [],
  [TabsPayloadKeys.HEADERS]: [],
  [TabsPayloadKeys.BODY]: getInitialPayloadBody(),
});
