import { MutationTree } from "vuex";
import { ResourceState } from "./Resource.store";
import { ResourceTime } from "@/services/resources/Resource.model";

export enum MutationTypes {
  SET_TIME = "SET_TIME",
  SET_EXPIRATION_DATE = "SET_EXPIRATION_DATE",
  SET_AUTO_RENEWABLE = "SET_AUTO_RENEWABLE"
}

export type Mutations<S = ResourceState> = {
  [MutationTypes.SET_TIME](state: S, payload: ResourceTime): void;
  [MutationTypes.SET_EXPIRATION_DATE](state: S, payload: Date): void;
};

export const mutations: MutationTree<ResourceState> & Mutations = {
  [MutationTypes.SET_TIME](state, payload) {
    state.time = payload;
  },
  [MutationTypes.SET_EXPIRATION_DATE](state, payload) {
    state.expirationDate = payload;
  },
  [MutationTypes.SET_AUTO_RENEWABLE](state, payload) {
    state.autoRenewable = payload;
  },
}