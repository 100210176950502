import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";
import {
  DecisionalCardValue,
  Condition,
  RowCondition,
} from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/Decisional.model";

export class DecisionalCaseParameterGetter extends AbstractControlParameterGetter {
  prepareConditions(condition: Condition, index: number): any {
    const isOfTypeGroup = "value" in condition && condition.value;
    if (isOfTypeGroup) {
      return {
        id: index,
        leftOperator: null,
        rightOperator: null,
        auxOperator: null,
        logicOperator: condition.logicOperator,
        groupName: condition.name,
        value: condition.value.map((condition) => {
          return this.prepareConditions(condition, index);
        }),
      };
    } else {
      let leftOperator;
      if ("leftOperator" in condition) {
        leftOperator = this.parseControlValueToParameter(
          condition.leftOperator.value
        );
        if (leftOperator.value === null) {
          leftOperator = null;
        }
      }

      let rightOperator;
      if ("rightOperator" in condition && condition.rightOperator) {
        rightOperator = this.parseControlValueToParameter(
          condition.rightOperator.value
        );
        if (rightOperator.value === null) {
          rightOperator = null;
        }
      }

      let auxOperator;
      if ("auxOperator" in condition && condition.auxOperator) {
        auxOperator = this.parseControlValueToParameter(
          condition.auxOperator.value
        );
        if (!auxOperator.value) {
          auxOperator = null;
        }
      }

      return {
        id: index,
        operator: (condition as RowCondition).operator || 0,
        logicOperator: condition.logicOperator,
        leftOperator,
        rightOperator,
        auxOperator,
      };
    }
  }

  getParameter(setting: Setting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      value: setting.value?.map((decValue: DecisionalCardValue) => {
        return {
          id: decValue.id,
          actionid: decValue.target,
          condition: decValue.condition.map(this.prepareConditions.bind(this)),
        };
      }),
      variable: null,
    };

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    const getVariableFromCondition = (condition: any) => {
      if (
        condition.leftOperator &&
        Array.isArray(condition.leftOperator.variable)
      ) {
        variables.push(...condition.leftOperator.variable);
      }

      if (
        condition.rightOperator &&
        Array.isArray(condition.rightOperator.variable)
      ) {
        variables.push(...condition.rightOperator.variable);
      }

      if (
        condition.auxOperator &&
        Array.isArray(condition.auxOperator.variable)
      ) {
        variables.push(...condition.auxOperator.variable);
      }

      if (Array.isArray(condition.value)) {
        condition.value.forEach((condition: any) => {
          getVariableFromCondition(condition);
        });
      }
    };

    parameters.forEach((parameter) => {
      if (Array.isArray(parameter.value)) {
        parameter.value.forEach((value) => {
          if (Array.isArray(value.condition)) {
            // condition returned from prepareConditions() method above
            value.condition.forEach((condition: any) => {
              getVariableFromCondition(condition);
            });
          }
        });
      }
    });

    return variables;
  }
}
