import RestService, { PaginatedResponse, RestResponse } from "../Rest.service";
import { TemplateDesignerItem, Document } from "./TemplateDesigner.model";
import RestUrls from "@/services/Rest.paths";

export default class TemplateDesignerService {
  static getList(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<TemplateDesignerItem>>({
      url: RestUrls.API.DOCUMENT_DESIGNER.LIST_PAGINATED(
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static getRestrictedList() {
    return RestService.get<PaginatedResponse<TemplateDesignerItem>>({
      url: RestUrls.API.DOCUMENT_DESIGNER.LIST_RESTRICTED,
      isAuth: true,
    });
  }

  static getDocumentTemplate(id: string) {
    return RestService.get<Document>({
      url: RestUrls.API.DOCUMENT_DESIGNER.GET_BY_ID(id),
      isAuth: true,
    });
  }

  static getRestrictedDocumentTemplate(id: string) {
    return RestService.get<Document>({
      url: RestUrls.API.DOCUMENT_DESIGNER.GET_RESTRICTED_BY_ID(id),
      isAuth: true,
    });
  }

  static insertData(data: any) {
    return RestService.post({
      url: RestUrls.API.DOCUMENT_DESIGNER.SAVE,
      data: data,
      isAuth: true,
    });
  }

  static updateData(data: any) {
    return RestService.put({
      url: RestUrls.API.DOCUMENT_DESIGNER.SAVE,
      data: data,
      isAuth: true,
    });
  }

  static deleteDocumentTemplate(id: string) {
    return RestService.delete({
      url: RestUrls.API.DOCUMENT_DESIGNER.DELETE(id),
      isAuth: true,
    });
  }

  static async getCount() {
    const response = await this.getList();
    if (response.content) {
      response.content = response.content.totalItemCount;
    }

    return response as Promise<RestResponse<number>>;
  }
}
