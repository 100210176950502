<template>
  <div class="pup-c-expandable-input">
    <component
      class="pup-c-expandable-input--component"
      :is="component"
      :value="value"
      :placeholder="placeholder"
      :processVariables="processVariables"
      :settings="settings"
      :autoOpen="true"
      :parent="parent"
      :oneVariableOnly="oneVariableOnly"
      :disabled="disabled"
      :fullScreenTitle="fullScreenTitle"
      ref="input"
      @update-input="$emit('update-input', $event)"
    />
    <pds-icon
      v-if="!oneVariableOnly"
      icon="icon-arrow-expand"
      @click="expand"
      :class="[
        'pds-u-m--l--4 pds-u-m--r--12',
        'pup-c-expandable-input--toggle',
      ]"
      size="tiny"
      v-tooltip="{
        content: !disabled ? 'Activate for multiline editing' : '',
      }"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";
import {
  InputComponent,
  IconComponent,
} from "@procesio/procesio-design-system";
import GenericTextComponent from "../GenericText/GenericText.component.vue";
import { InlineInputTypes } from "./ExpandableInput.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

@Component({
  components: {
    "pup-generic-text": GenericTextComponent,
    "pds-input": InputComponent,
    "pds-icon": IconComponent,
  },
})
export default class ExpandableInputComponent extends Vue {
  @Model("update-input") value!: string;

  @Prop() settings!: Setting;

  @Prop({ default: InlineInputTypes.INPUT }) inlineInputType!: InlineInputTypes;

  @Prop({ default: () => [] }) processVariables!: ProcessVariable[];

  @Prop({ default: "Insert value" }) placeholder!: string;

  @Prop() parent!: Node;

  @Prop({ default: false, type: Boolean }) oneVariableOnly!: boolean;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: "" }) fullScreenTitle!: string;

  get component() {
    if (this.oneVariableOnly) {
      return "pup-generic-text";
    }

    switch (this.inlineInputType) {
      case InlineInputTypes.GENERIC_TEXT:
        return "pup-generic-text";
      case InlineInputTypes.INPUT:
      default:
        return "pds-input";
    }
  }

  expand() {
    const input = this.$refs.input;
    if (input && this.component === "pup-generic-text") {
      (input as GenericTextComponent).toggleFullScreen(true);
    }
  }
}
</script>

<style lang="scss">
@import "./ExpandableInput.component.scss";
</style>
