<template>
  <div>
    <!-- COLUMNS -->
    <div v-if="value">
      <pup-column
        v-for="(column, index) in value.rows"
        :key="column.id"
        :index="index"
        @remove-item="removeColumn"
        :settings="{
          ...(settings || {}),
          dataTypeId: '',
        }"
        :value="column"
        :parent="parent"
        @column-value="(_newValue) => updateColumn(_newValue, index)"
        :models="models"
        :storeDataAs="storeDataAs"
        :disabled="disabled"
      />
    </div>

    <!-- ADD BUTTON -->
    <pds-button
      type="link"
      color="ordinary"
      size="small"
      class="pds-u-m--t--8"
      @click="addColumn"
      ignoreFormatting
      v-if="!disabled"
    >
      + Add column
    </pds-button>
  </div>
</template>
<script lang="ts">
import { Prop, Component, Emit, Watch, Model } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import {
  IconComponent,
  ButtonComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";

import ColumnComponent from "./Column.component.vue";

import {
  Setting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { createGuid } from "@/utils/type/guid";
import { DataModel } from "@/services/datamodel/DataModel.model";

@Component({
  components: {
    "pds-icon": IconComponent,
    "generic-text": GenericText,
    "pds-button": ButtonComponent,
    "pup-column": ColumnComponent,
  },
})
export default class ColumnDefinition extends mixins(Variable) {
  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() label!: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() tooltip?: string;

  @Prop() storeDataAs!: Setting;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  /** TODO: create interfaces */
  /** TODO ADD {variable, row} */
  @Model("update-value")
  value!: {
    variable: string;
    rows: Array<{
      columnName: string;
      attribute: null | string;
      id: string;
    }> | null;
  };

  @Prop() required!: boolean;

  storeDataType: DataModel | undefined | null = null;

  @Watch("storeDataAs", { immediate: true, deep: true })
  onStoreDataChange(storeDataAs: Setting) {
    if (storeDataAs && storeDataAs.value) {
      const cleanVariable = storeDataAs.value.match(
        new RegExp(this.variableRegex)
      );

      if (storeDataAs.value && cleanVariable) {
        this.storeDataType = this.getVariableDataType(cleanVariable[0]);
        if (this.storeDataType) {
          this.updateVariable(cleanVariable[0]);
        }
      } else {
        this.updateVariable(null);
      }
    }
  }

  updateVariable(value: string | null) {
    if (this.value?.variable !== value) {
      this.$emit("update-value", { ...this.value, variable: value });
    }
  }

  @Emit("update-value")
  addColumn() {
    if (this.value) {
      let value = this.value.rows;

      if (!value) {
        value = [];
      }
      value.push({
        id: createGuid(),
        columnName: "",
        attribute: "",
      });

      return { ...this.value, rows: value };
    } else {
      const value = [];
      value.push({
        id: createGuid(),
        columnName: "",
        attribute: "",
      });
      return { rows: value };
    }
  }

  @Emit("update-value")
  removeColumn(index: number) {
    let value = this.value.rows;

    if (!value) {
      value = [];
    }
    value.splice(index, 1);

    return { ...this.value, rows: value };
  }

  updateColumn(value: any, index: number) {
    const currentColumns = this.value.rows;

    if (currentColumns) {
      currentColumns.splice(index, 1, value);
    }

    return { ...this.value, rows: value };
  }

  get models() {
    if (!this.storeDataType) {
      return [];
    }
    return this.storeDataType?.attributes;
  }
}
</script>
<style scoped lang="scss">
@import "./ColumnDefinition.component.scss";
</style>
