export function camelize(str: string, allUpper = false) {
  if (!str) {
    return null;
  }

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0
        ? allUpper
          ? word.toUpperCase()
          : word.toLowerCase()
        : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function insertAtPosition(
  str: string,
  insertion: string,
  position: number
) {
  if (typeof str !== "string") {
    str = "";
  }

  return (
    str.substring(0, position) + insertion + str.substring(position, str.length)
  );
}

export function deleteAtPosition(str: string, position: number) {
  if (typeof str !== "string") {
    str = "";
  }
  console.log(str.length);
  console.log(str.slice(0, position));
  console.log(str.slice(position + 1));

  return str.slice(0, position) + str.slice(position + 1);
}

export function truncateMiddle(str: string, toLength: number) {
  // if odd, make it even
  return str.length > toLength
    ? `${str.substring(0, toLength / 2)}...${str.substr(
        str.length - toLength / 2
      )}`
    : str;
}

export const escapeString = (str: string): string => {
  if (typeof str !== "string") {
    return "";
  }

  return (
    str
      .replace(/&nbsp;/gi, "")
      .replace(/&amp;amp;/gi, "&")
      .replace(/&amp;/gi, "&")
      .replace(/&lt;/gi, "<")
      .replace(/&gt;/gi, ">")
      .replace(/&quot;/gi, '"')
      .replace(/&#39;/gi, "'")
      .replace(/&#039;/gi, "'")
  );
};
