import RestService from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import { Event } from "./Event.model";
import { config } from "@/config";

export default class NotificationService {
  static baseUrl = config.api;

  static getEventList() {
    return RestService.get<Event[]>({
      url: RestUrls.API.USER.EVENTS.LIST,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }

  static updateEvent(id: string | null = null) {
    return RestService.patch<Event>({
      url: RestUrls.API.USER.EVENTS.UPDATE_EVENT,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
      data: {
        id: id || null,
        markAllUnread: !id
      }
    });
  }

  static setConnection(connectionId: string) {
    return RestService.post<Event>({
      url: RestUrls.API.USER.EVENTS.SET_CONNECTION,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
      data: `"${connectionId}"`
    });
  }
}
