<template>
  <div>
    <div class="pup-c-d-manager--line"></div>
    <div class="pds-u-m--t--16">Cases:</div>
    <pds-message
      v-if="(value && !value.length) || !value"
      :text="translation.warn_decisional"
      class="pds-u-m--t--16"
    />

    <div v-if="value && value.length > 0">
      <draggable
        :list="value"
        handle=".pup-c-card-button--drag-handle"
        :options="{ disabled: disabled }"
      >
        <pup-card-button
          v-for="(cas, index) in value"
          :key="index"
          :index="index"
          :parent="parent"
          :value="cas"
          :settings="settings"
          :disabled="disabled"
          @click="setOpenedCard(cas)"
        />
      </draggable>
      <pup-condition-builder
        v-if="openedCard"
        :value="openedCard"
        :conditions="openedCard.condition"
        :settings="settings"
        :parent="parent"
        :processVariables="processVariables"
        :form="form"
        :cases="value"
        :disabled="disabled"
        @caseSelected="setOpenedCard($event)"
        @close="setOpenedCard(null)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  IconComponent,
  MessageComponent,
} from "@procesio/procesio-design-system";
import {
  Node,
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import language from "@/utils/locale/en.json";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { Condition } from "@/services/condition/Condition.model";
import ConditionCardButton from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/card/button/Button.component.vue";
import ConditionBuilder from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/ConditionBuilder.component.vue";
import { DecisionalCardValue } from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/Decisional.model";
import { FormBuilder, Validators } from "@/utils/ReactiveForm";
import {
  Operator,
  OperatorType,
} from "@/services/actionlist/ActionList.service";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { EventBus, Events } from "@/utils/eventBus";
import draggable from "vuedraggable";

@Component({
  components: {
    "pds-icon": IconComponent,
    "pds-message": MessageComponent,
    "pup-card-button": ConditionCardButton,
    "pup-condition-builder": ConditionBuilder,
    draggable,
  },
})
export default class DecisionalManager extends mixins(Variable) {
  @Prop() parent!: Node;

  @Prop() settings!: Setting;

  @Prop() value!: DecisionalCardValue[];

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  translation: Record<string, string> = language;

  openedCard: DecisionalCardValue | null = null;

  created() {
    EventBus.$on(Events["PROCESS:DECISIONAL_CASE_SELECT"], this.setOpenedCard);
  }

  destroyed() {
    EventBus.$off(Events["PROCESS:DECISIONAL_CASE_SELECT"], this.setOpenedCard);
  }

  get form() {
    if (!this.openedCard) {
      return null;
    }

    const form = new FormBuilder();

    form.control("name", this.openedCard.name, [
      Validators.required,
      Validators.minLength(1),
    ]);
    form.control("target", this.openedCard.target, [Validators.required]);

    form.control("conditions", this.openedCard.condition, [
      Validators.required,
    ]);

    const addConditionToForm = (conditions: Condition[], level: number) => {
      for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i];

        if (!condition.value) {
          form.control(
            "left_operator_" + level + "_" + condition.id,
            condition.leftOperator?.value,
            [Validators.required]
          );

          // if (!condition.operandsAsListOptional) {
          //   form.control(
          //     "left_operator_" + level + "_" + i + "_operandsAsListOptional",
          //     this.isVariableList(condition.leftOperator!.value),
          //     [Validators.required, Validators.notchecked]
          //   );
          // }

          const operator: Operator | null = this.$store.getters.getConditionOperandByName(
            condition.operator
          );
          if (operator?.operatorType !== OperatorType.UNARY) {
            form.control(
              "right_operator_" + level + "_" + condition.id,
              condition.rightOperator?.value,
              [Validators.required]
            );
          }

          if (condition.rightOperandAsListRequired) {
            form.control(
              "right_operator_" +
                level +
                "_" +
                i +
                "_rightOperandAsListRequired",
              this.isVariableList(condition.rightOperator?.value as string),
              [Validators.required, Validators.checked]
            );
          }

          form.control(
            "operator_" + level + "_" + condition.id,
            condition.operator,
            [Validators.required]
          );
        } else if (Array.isArray(condition.value)) {
          form.control(
            "condition_group" + level + "_" + condition.id,
            condition.value,
            [Validators.required]
          );
        }

        if (condition.auxOperator) {
          form.control(
            "aux_operator_" + level + "_" + condition.id,
            condition.auxOperator.value,
            []
          );
        }

        if (condition.value) {
          condition.value && addConditionToForm(condition.value, level + 1);
        }
      }
    };

    addConditionToForm(this.openedCard.condition, 0);

    return form;
  }

  @Watch("value", { deep: true })
  onValueUpdate(value: DecisionalCardValue[]) {
    if (!this.parent || !Array.isArray(value) || this.disabled) {
      return;
    }

    const casesSetting = this.parent.configuration[0].settings.find(
      (setting) => setting.type === SettingType.DECISIONAL_CASES
    );
    const defaultSetting = this.parent.configuration[0].settings.find(
      (setting) => setting.type === SettingType.DECISIONAL_DEFAULT
    );

    if (!(casesSetting && Array.isArray(casesSetting.value))) {
      return;
    }

    this.parent.lineArray
      .filter((line) => line.nodeStart.id === this.parent.id)
      .forEach((line) => {
        const caseItem = value.find((item) => line.nodeEnd.id === item.target);

        let name: string | null = "";
        if (caseItem) {
          name = caseItem.name;
        } else if (defaultSetting && defaultSetting.value === line.nodeEnd.id) {
          name = "Default";
        }

        // TODO: import Line from canvas
        (line as any).updateLine({
          ...line,
          portData: {
            ...(line as any).portData,
            name,
          },
        });
      });

    if (
      this.openedCard &&
      value.findIndex((item) => item.id === this.openedCard?.id) === -1 &&
      value.length
    ) {
      this.setOpenedCard(value[0]);
    }
  }

  setOpenedCard(value: DecisionalCardValue | null = null) {
    this.openedCard = value;
  }
}
</script>

<style scoped lang="scss">
@import "./DecisionalManager.component.scss";
</style>
