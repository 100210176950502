<template>
  <pds-tabs
    class="pup-c-tabs-payload"
    :selectedTabIndex="selectedTabIndex"
    @tab-selected="selectedTabIndex = $event"
  >
    <pds-tab v-for="tab in tabs" :key="tab.key" :title="tab.title">
      <component
        v-if="tabValue"
        :is="tab.component"
        :label="tab.title"
        :config="tableConfig"
        :processVariables="processVariables"
        :parent="parent"
        :settings="settings ? { ...settings, dataTypeId: '' } : null"
        v-model="tabValue"
        newRowAutopopulate
        :disabled="disabled"
        class="pup-c-tabs-payload--tab"
      >
        <template v-slot:actions-per-row="props" v-if="!disabled">
          <div class="pup-c-tabs-payload--tab--table--actions">
            <pds-icon
              v-if="props.index < tableConfig.items.length - 1 && !disabled"
              icon="clear"
              @click="deleteRow(props.index)"
              class="pup-c-tabs-payload--tab--table--actions--delete"
            />
          </div>
        </template>
      </component>
    </pds-tab>
  </pds-tabs>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model } from "vue-property-decorator";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import {
  TabsComponent,
  TabComponent,
  IconComponent,
} from "@procesio/procesio-design-system";
import {
  getInitialTabsPayload,
  TabsPayloadItem,
  TabsPayloadKeys,
  TabsPayloadLabels,
  TabsPayloadValue,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";
import TInput from "../TableInput/TInput.component.vue";
import PayloadBody from "@/modules/ProcessDesigner/components/Controls/TabsPayload/PayloadBody/PayloadBody.component.vue";
import ExpandableInputComponent from "../ExpandableInput/ExpandableInput.component.vue";

@Component({
  components: {
    "pds-tabs": TabsComponent,
    "pds-tab": TabComponent,
    "pds-icon": IconComponent,
    "pup-table-input": TInput,
    "pup-payload-body": PayloadBody,
    "pup-expanded-input": ExpandableInputComponent,
  },
})
export default class TabsPayloadComponent extends Vue {
  @Model("update-input") value!: TabsPayloadValue;

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  selectedTabIndex = 0;

  tabs = [
    {
      title: TabsPayloadLabels.QUERY_PARAMS,
      component: TInput,
      key: TabsPayloadKeys.QUERY_PARAMS,
    },
    {
      title: TabsPayloadLabels.HEADERS,
      component: TInput,
      key: TabsPayloadKeys.HEADERS,
    },
    {
      title: TabsPayloadLabels.BODY,
      component: PayloadBody,
      key: TabsPayloadKeys.BODY,
    },
  ];

  get tabValue() {
    if (!this.value) {
      return null;
    }
    return this.value[this.tabs[this.selectedTabIndex].key];
  }

  set tabValue(value) {
    if (!this.value) {
      return;
    }

    this.value[this.tabs[this.selectedTabIndex].key] = value as
      | TabsPayloadItem[]
      | PayloadBody;
  }

  get tableConfig() {
    if (
      [TabsPayloadKeys.QUERY_PARAMS, TabsPayloadKeys.HEADERS].includes(
        this.tabs[this.selectedTabIndex].key
      )
    ) {
      return {
        columns: [
          {
            key: "key",
            label: "Key",
            placeholder: "Insert key",
            component: "pds-input",
          },
          {
            key: "value",
            label: "Value",
            placeholder: "Insert value",
            component: "pup-expandable-input",
          },
        ],
        items: this.tabValue,
      };
    }

    // return null;
    return {
      columns: [],
      items: [],
    };
  }

  created() {
    if (!this.value || typeof this.value === "string") {
      this.$emit("update-input", getInitialTabsPayload());
    }
  }

  deleteRow(index: number) {
    if (Array.isArray(this.tabValue)) {
      (this.tabValue as TabsPayloadItem[]).splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
@import "./TabsPayload.component.scss";
</style>
