<template>
  <div
    tabindex="0"
    :class="[
      'pup-c-data-model-selector--elements',
      colored && disabled ? 'pup-c-acordeon-element-gray' : null,
    ]"
    @click="pickElement"
    @keypress.enter="pickElement"
  >
    <div
      :class="['pup-c-data-model-selector--elements--order']"
      :style="{ 'padding-left': 16 * level + 'px' }"
    >
      <IconComponent
        size="tiny"
        icon="fiber_manual_record"
        class="pup-c-data-model-selector--elements--icon"
      />
      <div class="pup-c-data-model-selector--elements--order--wrapper">
        <p
          v-tooltip="{
            content: truncateName(name).length === name.length ? null : name,
            boundariesElement: 'window',
            placement: 'bottom',
          }"
          class="
          pup-u-body--1
          pup-c-data-model-selector--elements--text"
        >
          {{ truncateName(name) }}
        </p>
        <div class="pup-c-data-model-selector--elements--badge-wrapper">
          <pup-data-type-badge
            class="pup-c-data-model-selector--elements--badge"
            :dataType="dataType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

import { IconComponent } from "@procesio/procesio-design-system";
import DataTypeBadgeComponent from "@/modules/ProcessDesigner/components/Badge/DataTypeBadge/DataTypeBadge.component.vue";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { truncateMiddle } from "@/utils/type/string";

@Component({
  components: {
    IconComponent,
    "pup-data-type-badge": DataTypeBadgeComponent,
  },
})
export default class DataModelElementComponent extends Vue {
  @Prop() name!: string;

  @Prop({ default: null }) dataType!: {
    id: string;
    dataType: string | undefined;
    dataTypeId: string | undefined;
    isList: boolean;
  };

  @Prop({ default: null }) parentVariable!: ProcessVariable;

  @Prop({ default: false }) padding!: boolean;

  @Prop() level!: number;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: true, type: Boolean }) colored!: boolean;

  pickElement() {
    this.$emit("pickElement", []);
  }

  truncateName(str: string) {
    const toLength = 24;
    return truncateMiddle(str, toLength);
  }
}
</script>

<style scoped lang="scss">
@import "./DataModelElement.component.scss";
</style>
