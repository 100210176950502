var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-modal-editor"},[_c('div',{staticClass:"pup-c-modal-editor--toggle",on:{"click":_vm.toggleModal}},[(_vm.showPreview)?_c('pup-generic-text',{attrs:{"placeholder":_vm.placeholder,"value":_vm.trim ? _vm.getString() : _vm.value,"autofocusable":false,"parseVariables":_vm.parseVariables,"disabled":_vm.disabled,"readonly":_vm.readonly}}):_vm._e()],1),_vm._v(" "),(_vm.isModal)?_c('pds-modal-component',{attrs:{"isFixedHeight":true,"headline":_vm.disabled ? 'Read' : _vm.title,"isFooter":_vm.isFooter,"disabled":_vm.disabled,"saveCta":"Copy to clipboard","cancelCta":"Download"},on:{"close":_vm.toggleModal,"download":_vm.download,"copy":_vm.copy}},[_c('div',{class:['pup-c-modal-editor--container']},[_c('div',{staticClass:"pup-c-input-group--holder pds-u-m--b--8",class:[
          _vm.statusClass ? `pup-c-input-group--holder--${_vm.statusClass}` : '',
        ]},[_c('label',{staticClass:"pup-c-input-group--label pds-u-subtitle--2"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.tooltip)?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.tooltip,
          }),expression:"{\n            content: tooltip,\n          }"}],staticClass:"material-icons--grey pds-u-m--l--8",attrs:{"icon":"info","isOutlined":true}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"test",staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"contentToCopy"}}),_vm._v(" "),_c('pds-validation-message',{attrs:{"status":_vm.status},on:{"statusClassChanged":(e) => (_vm.statusClass = e)}}),_vm._v(" "),(_vm.isDataModelSelectorVisible && !_vm.disabled)?_c('pup-data-model-selector',{class:[
          'pup-c-input-data-model-selector--acordeon',
          'pup-c-input-data-model-selector--acordeon--right',
        ],staticStyle:{"top":"0"},attrs:{"parentId":_vm.parent.parentId,"dataModelList":[],"processVariables":_vm.processVariables},on:{"pickElement":_vm.selectVariable,"closed":_vm.closeDataModelSelector}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }