export interface CampaignData {
  startDate: Date | null;
  endDate: Date | null;
  discountPercent: number;
  availableHours: number;
}

const url =
  "https://procesio-asset-hosting.fra1.digitaloceanspaces.com/configuration/Campaign.json";

export const CAMPAIGN_HOURS_QUERY_PARAM_KEY = "hours";

export const getCampaignData = async () => {
  let data: CampaignData | null = null;

  try {
    const response = await fetch(url);
    const json = await response.json();

    data = {
      startDate: json.startDate ? new Date(json.startDate) : null,
      endDate: json.endDate ? new Date(json.endDate) : null,
      discountPercent: json.discountPercent || 0,
      availableHours: json.availableHours || 0,
    };
  } catch (err) {
    console.log("Campaign data load failed: " + err);
  }

  return data;
};
