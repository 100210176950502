var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.setting.type !== 'ignore')?_c(_vm.getSettingComponent(_vm.setting),{key:_vm.setting.id,tag:"component",staticClass:"pds-u-m--b--16",attrs:{"color":"primary","keyProp":_vm.setting.id,"label":_vm.setting.label,"email":_vm.setting.value,"subject":_vm.setting.subject,"options":_vm.setting.options,"credentialType":_vm.setting.credentialsTemplateId,"isDisabled":_vm.setting.disabled || _vm.disabled,"disabled":_vm.setting.disabled || _vm.disabled,"settings":_vm.setting,"placeholder":_vm.setting.placeholder,"tooltip":_vm.setting.tooltip,"config":{
    columns: _vm.setting.columns,
    items: _vm.setting.value,
  },"processVariables":_vm.processVariables,"parent":_vm.parent,"required":_vm.setting.isRequired,"id":_vm.setting.id,"isFullWidth":true,"tabs":_vm.setting.value,"type":_vm.isTextArea(_vm.setting.type),"status":_vm.status,"parentFlow":_vm.flow,"storeDataAs":_vm.storeDataAs,"readonly":_vm.readonly,"ignoreLabelFormatting":_vm.ignoreLabelFormatting,"shouldPrevent":_vm.shouldPrevent,"draggable":_vm.draggable,"lang":_vm.setting.language},on:{"click":function($event){return _vm.$emit('ui', _vm.setting)},"update-input":function($event){return _vm.$emit('update-input', $event)},"blur":function($event){return _vm.$emit('blur', $event)}},nativeOn:{"click":function($event){return _vm.clickHandler($event, _vm.setting)}},scopedSlots:_vm._u([_vm._l((['prepend', 'singleLabel', 'placeholder']),function(slotName){return {key:slotName,fn:function({ selectedOption, keyName, placeholder }){return [(_vm.getControlIcon(_vm.setting))?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.getControlIconTooltip(_vm.setting),
        boundariesElement: 'window',
      }),expression:"{\n        content: getControlIconTooltip(setting),\n        boundariesElement: 'window',\n      }"}],key:slotName,class:[
        'material-icons--grey',
        'pup-control-manager--direction-icon',
        _vm.getControlIconClass(_vm.setting),
      ],attrs:{"icon":_vm.getControlIcon(_vm.setting),"isOutlined":false,"size":"tiny"}}):_vm._e(),_vm._v(" "),(selectedOption)?[_vm._v(" "+_vm._s(selectedOption[keyName]))]:_vm._e(),_vm._v(" "),(placeholder)?[_vm._v(" "+_vm._s(placeholder))]:_vm._e()]}}})],null,true),model:{value:(_vm.setting.value),callback:function ($$v) {_vm.$set(_vm.setting, "value", $$v)},expression:"setting.value"}},[_vm._v("\n  "+_vm._s(_vm.setting.label)+"\n  ")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }