<template>
  <div>
    <div class="pup-c-date-time-picker">
      <pds-date-picker
        :value="value"
        @update-input="$emit('update-input', $event)"
        class="pup-c-date-time-picker--date pds-u-m--r--8"
        :minDate="minDateTime"
        :readonly="readonly"
        :disabled="disabled"
        placeholder="Select date"
      />
      <label class="pds-u-input-label pds-u-m--l--4 pds-u-m--r--4">at</label>
      <pds-time-picker
        :value="value"
        class="pup-c-date-time-picker--time pds-u-m--l--8"
        @update-input="$emit('update-input', $event)"
        :minDateTime="minDateTime"
        :readonly="readonly"
        :disabled="disabled"
        placeholder="Select time"
      />
    </div>
    <pds-validation-message :status="status" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import {
  DatePicker,
  TimePicker,
  ValidationMessage,
  PdsTypes,
} from "@procesio/procesio-design-system";

import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    "pds-date-picker": DatePicker,
    "pds-time-picker": TimePicker,
    "pds-validation-message": ValidationMessage,
  },
  model: {
    event: "update-input",
  },
})
export default class DateTimePicker extends Vue {
  @Prop() value: Date | undefined | null;

  @Prop({ default: null }) minDateTime!: Date;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;
}
</script>

<style scoped lang="scss">
@import "./DateTimePicker.component.scss";
</style>
