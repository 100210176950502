var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-input-group--holder"},[(_vm.tooltip)?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.tooltip,
    }),expression:"{\n      content: tooltip,\n    }"}],staticClass:"material-icons--grey pds-u-m--r--8",attrs:{"icon":"info","isOutlined":true}}):_vm._e(),_vm._v(" "),_c('pds-table',{class:[
      'pup-c-tinput',
      _vm.disabled && 'pup-c-tinput--disabled',
      _vm.isFullScreen && 'pup-c-tinput--fullscreen',
    ],attrs:{"config":{ ..._vm.config, items: _vm.items },"isConfigurable":false},scopedSlots:_vm._u([_vm._l((_vm.config.columns),function(column){return {key:column.key,fn:function({ row }){return [_vm._t(column.key,function(){return [_c(column.component,{key:row.id,tag:"component",class:[
            'pup-c-tinput--element',
            column.component === 'pds-input' &&
              'pup-c-tinput--element--input',
          ],attrs:{"type":_vm.getComponentType(row),"keyProp":row.id,"placeholder":column.placeholder,"isTextOnBlur":true,"parent":_vm.parent,"settings":_vm.settings,"dataType":column.component === 'pup-data-type-badge' ? row : '',"showLabel":false,"processVariables":_vm.processVariables,"inlineInputType":_vm.inlineInputTypes.GENERIC_TEXT,"disabled":_vm.disabled,"fullScreenTitle":_vm.parent && _vm.label && _vm.parent.name + ' | ' + _vm.label},on:{"blur":_vm.handleBlurDuplicates},model:{value:(row[column.key]),callback:function ($$v) {_vm.$set(row, column.key, $$v)},expression:"row[column.key]"}})]},{"row":row})]}}}),{key:"actions-per-row",fn:function(props){return [_vm._t("actions-per-row",null,null,props)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }