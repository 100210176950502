import { escapeString } from "@/utils/type/string";
import { ProcessState } from "./Process.store";

export default {
  "process/id": (state: ProcessState) => state.id,
  "process/title": (state: ProcessState) =>
    state.title ? escapeString(state.title) : "",
  "process/description": (state: ProcessState) => state.description,
  variables: (state: ProcessState) => state.variables,
  "instance/id": (state: ProcessState) => state.instanceId,
  "instance/variables": (state: ProcessState) => state.instanceVariables,
  "instance/updateDate": (state: ProcessState) => state.instanceUpdateDate,
  isActive: (state: ProcessState) => state.isActive,
  isLoaded: (state: ProcessState) => state.isLoaded,
  isValidated: (state: ProcessState) => state.isValidated,
  isValidating: (state: ProcessState) => state.isValidating,
  isSaved: (state: ProcessState) => state.isSaved,
  isSaving: (state: ProcessState) => state.isSaving,
  isReadyToRun: (state: ProcessState) => state.isValidated && state.isSaved,
  lastRunProcessId: (state: ProcessState) => state.lastRunProcessId,
  lastRunInstanceId: (state: ProcessState) => state.lastRunInstanceId,
  validationErrors: (state: ProcessState) => state.validationErrors,

  extraVariablesGrouped: (state: ProcessState) =>
    state.extraVariablesGroupedByNodeId,

  extraVariablesFlatList: (state: ProcessState) =>
    [...Object.values(state.extraVariablesGroupedByNodeId)].reduce(
      (variables, group) => {
        variables.push(...group);
        return variables;
      },
      []
    ),

  // method-style access
  getExtraVariablesByNodeId: (state: ProcessState) => (nodeId: string) => {
    if (typeof state.extraVariablesGroupedByNodeId[nodeId] === "undefined") {
      return [];
    }

    return state.extraVariablesGroupedByNodeId[nodeId];
  },
  isExtraVariablesByNodeIdDefined: (state: ProcessState) => (nodeId: string) =>
    !!state.extraVariablesGroupedByNodeId[nodeId],
  getVariableById: (state: ProcessState) => (id: string) =>
    state.variables.find((v) => v.id === id) || null,

  conditionOperands: (state: ProcessState) => state.operands,
  getConditionOperandByName: (state: ProcessState) => (name: string) =>
    state.operands.find((o) => o.operatorName === name) || null,

  actionList: (state: ProcessState) => state.actionList,
  getActionById: (state: ProcessState) => (id: string) =>
    state.actionList.find((action) => action.actionId === id) || null,

  getActionNameById: (_: ProcessState, getters: any) => (id: string) =>
    getters.getActionById(id)?.name || null,

  openedSidePanelId: (state: ProcessState) => state.sidepanel,

  testActionModel: (state: ProcessState) => state.testActionModel,
};
