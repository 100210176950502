import { store } from "@/store";
import { cloneDeep } from "lodash";
import { Column } from "@/services/application/table";

export enum DataModelType {
  INTERNAL = 1, // usual data models created in designer
  EXTERNAL = 2, // data models which belong to extrenal sources such as webhooks, ...
}

export enum DataModelFormType {
  DEFAULT = "DEFAULT", // default
  AUTO = "AUTO", // generated from json (file, url, definition)
}

export enum DataModelAccessType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum DataModelScope {
  WEBHOOK = "WEBHOOK",
  FORM = "FORM",
}

export interface DataModelBase {
  id?: string;
  name: string;
  displayName: string;
}
export interface GenerateDataModelDTO extends DataModelBase {
  content?: string | null;
}

export interface GenerateFileDataModelDTO extends DataModelBase {
  file?: File | null;
}

export interface CreateDataModelDTO extends DataModelBase {
  isPublic: boolean;
  content?: DataModel | null; // data model may be created from contend (e.g. generated DM case)
}

export interface CreatePrivateDataModelDTO extends DataModelBase {
  rootDataTypeId: string | null;
  attribute: DataModelAttribute;
}

export interface DataModel extends DataModelBase {
  id: string;
  attributes: DataModelAttribute[];
  isDataModel: boolean;
  isPublic: boolean;
  isPrimaryType?: boolean;
  isProcesio: boolean;
  firstName?: string | null;
  lastName?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
  type?: number;
  csharpCorrespondent?: number;
  workspaceName?: string | null;
  parentIds?: string[]; // list of root data model ids
  closestParentId?: string | null;
  scopes?: DataModelScope[];
  notPermitted?: boolean;
}

export interface DataModelAttributeBase extends DataModelBase {
  attributeId?: string;
  jsonProperty: string | null;
  dataTypeId: string;
  isList: boolean;
}

export interface DataModelAttribute extends DataModelAttributeBase, DataModel {
  id: string;
  parentDataTypeId: string;
  dataTypeName?: string | null;
}

export interface DataTypeToChangeDTO {
  rootDataTypeId: string; // root (master) dataType id
  dataTypeId: string; //  attribute dataType id
}

// todo export types from pds
export interface BaseItem<T> {
  attributes?: Array<T>;
}
export interface Pagination {
  total: number;
  pageTotal: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
}
export interface Config<T extends BaseItem<T>> {
  items: Array<T>;
  columns: Column[];
  pagination?: Pagination;
}

export const convertAttributeToDataModel = (
  attribute: DataModelAttribute
): DataModel | null => {
  if (!attribute.isDataModel) {
    return null;
  }

  const parentDataModel: DataModel | undefined = store.getters.dataTypes.find(
    (item: DataModel) => item.id === attribute.parentDataTypeId
  );

  return {
    id: attribute.dataTypeId,
    name: attribute.name,
    displayName: attribute.dataTypeName || attribute.displayName,
    attributes: attribute.attributes,
    isDataModel: attribute.isDataModel,
    isPrimaryType: attribute.isPrimaryType,
    isProcesio: attribute.isProcesio,
    isPublic: attribute.isPublic,
    updatedOn: attribute.updatedOn,
    type: attribute.type,
    csharpCorrespondent: attribute.csharpCorrespondent,
    firstName: parentDataModel
      ? parentDataModel.firstName
      : attribute.firstName,
    lastName: parentDataModel ? parentDataModel.lastName : attribute.lastName,
    workspaceName: parentDataModel
      ? parentDataModel.workspaceName
      : attribute.workspaceName,
    closestParentId: attribute.parentDataTypeId,
    scopes: parentDataModel?.scopes,
  };
};

export const isCustomDataModel = (item: DataModel | DataModelAttribute) =>
  item.isDataModel && !item.isProcesio && !item.isPrimaryType;

export const findAttributeById = (
  id: string,
  attributes: DataModelAttribute[] = store.getters.dataTypes
): DataModelAttribute | null => {
  let attribute: DataModelAttribute | null = null;

  const find = (
    id: string,
    attributes: DataModelAttribute[]
  ): DataModelAttribute | null => {
    attributes.forEach((item) => {
      if (attribute) {
        return;
      }

      if (item.id === id) {
        attribute = item;
        return;
      }

      if (!item.attributes || !item.attributes.length) {
        attribute = null;
        return;
      }

      attribute = find(id, item.attributes || []);
    });

    return attribute;
  };

  return find(id, attributes);
};

export const searchAttributes = (
  search: string,
  attributes: DataModelAttribute[] = store.getters.dataTypes
): DataModelAttribute[] => {
  const find = (attribute: DataModelAttribute) => {
    if (attribute.name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    if (attribute.attributes) {
      const foundAttributes: DataModelAttribute[] = (
        attribute.attributes || []
      ).filter(find);
      attribute.attributes = foundAttributes;
      return !!foundAttributes.length;
    }
  };

  return cloneDeep(attributes).filter(find);
};

export const getDataModelParents = (id: string): DataModel[] => {
  const dataModel: DataModel | null = store.getters.getDataTypeById(id);
  if (!dataModel || !dataModel.closestParentId) {
    return [];
  }

  const parents: DataModel[] = [];

  const parentDataModel: DataModel | null = store.getters.getDataTypeById(
    dataModel.closestParentId
  );
  if (parentDataModel) {
    parents.push(...getDataModelParents(parentDataModel.id), parentDataModel);
  }

  return parents;
};

export const getDataModelChildren = (id: string): DataModel[] => {
  const dataModel: DataModel | null = store.getters.getDataTypeById(id);
  if (!dataModel || !dataModel.attributes || !dataModel.attributes.length) {
    return [];
  }

  const children: DataModel[] = [];

  dataModel.attributes.forEach((attr) => {
    const childDataModel: DataModel | null = store.getters.getDataTypeById(
      attr.dataTypeId
    );
    if (childDataModel && isCustomDataModel(childDataModel)) {
      children.push(childDataModel, ...getDataModelChildren(childDataModel.id));
    }
  });

  return children;
};

export const UNKNOWN_DATA_MODEL_ID = "499e75d4-e011-43aa-9c7c-dc2772565da2";
export const UNKNOWN_DATA_MODEL_NAME = "Unknown";
export const UNKNOWN_DATA_MODEL_TOOLTIP =
  "Variable's data model is not defined.";
export const getUnknownDataModel = () => ({
  id: UNKNOWN_DATA_MODEL_ID,
  name: UNKNOWN_DATA_MODEL_NAME,
  displayName: UNKNOWN_DATA_MODEL_NAME,
  isDataModel: true,
  isPublic: false,
  isProcesio: true,
  scopes: [],
  attributes: [],
});
