<template>
  <div
    class="pup-c-collapsable"
    :class="{ 'pup-c-collapsable--collapsed': collapsed }"
  >
    <!-- HEADER -->
    <div class="pup-c-collapsable--header" @click="changeCollapse">
      <slot name="title">
        <p :class="['pds-u-subtitle--2', 'pup-c-collapsable--header--text']">
          {{ label }}
        </p>
      </slot>
      <pds-icon
        :icon="collapsed ? 'expand_more' : 'expand_less'"
        class="pup-c-collapsable--header--point"
        @click="changeCollapse"
      />
    </div>

    <!-- CONTENT -->
    <div class="pup-c-collapsable--content" v-if="!collapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  ButtonComponent,
  IconComponent,
} from "@procesio/procesio-design-system";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
  },
  name: "pup-collapsable",
})
export default class Collapsable extends Vue {
  @Prop() label!: string;

  @Prop({ default: false, type: Boolean }) collapsedByDefault!: boolean;

  collapsed = false;

  created() {
    this.collapsed = this.collapsedByDefault;
  }

  changeCollapse() {
    this.collapsed = !this.collapsed;
  }
}
</script>

<style lang="scss">
@import "./Collapsable.component.scss";
</style>
