import RestService from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import { config } from "@/config";
import { UserPreferences } from "./UserPreferences.model";

export default class UserPreferencesService {
  static baseUrl = config.api;

  static getUserPreferences() {
    return RestService.get<UserPreferences>({
      url: RestUrls.API.USER.PREFERENCES,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }

  static saveUserPreferences(data: UserPreferences) {
    return RestService.post({
      url: RestUrls.API.USER.PREFERENCES,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
      data,
    });
  }
}
