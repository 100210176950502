<template>
  <div>
    <pup-variable-mapper-group
      :settings="settings"
      :value="variableMapperValue"
      leftInputPlaceholder="Insert value"
      rightInputPlaceholder="Insert value"
      :leftColumnVariables="leftColumnVariables"
      :rightColumnVariables="processVariables"
      :isLeftInputOneVariableOnly="true"
      :infoText="tooltip"
      addButtonText="+ Add variable"
      :parent="parent"
      :disabled="disabled"
      @update-input="onMapperGroupValueUpdate"
      @sorted="onMapperGroupValueUpdate($event, true)"
      @remove-row="onRowRemove"
      draggable
    />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

import {
  Node,
  MapProcessDataSetting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import VariableMapperGroup, {
  VariableMapperValue,
} from "@/modules/ProcessDesigner/components/Controls/VariableMapper/Group.component.vue";
import { MapProcessDataValue } from "@/modules/ProcessDesigner/components/Controls/MapProcessData/MapProcessData.model";
import { PdsTypes } from "@procesio/procesio-design-system";

@Component({
  components: {
    "pup-variable-mapper-group": VariableMapperGroup,
  },
})
export default class MapProcessDataComponent extends Vue {
  @Prop() settings!: MapProcessDataSetting;

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() tooltip?: string;

  @Prop() value!: Array<MapProcessDataValue>;

  @Prop({ default: () => [] }) processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  get variableMapperValue() {
    if (!this.value) {
      return this.value;
    }

    // map value to VariableMapperValue
    return this.value.map((val) => ({
      id: val.id,
      left: val.destination,
      right: val.source,
    })) as VariableMapperValue[];
  }

  get leftColumnVariables() {
    return this.processVariables.filter((variable) => !variable.isConst);
  }

  onMapperGroupValueUpdate(
    value: VariableMapperValue[] | null,
    setForce = false
  ) {
    // map VariableMapperValue to value
    let dataMapperValue = [{ id: 0, destination: "", source: "" }];

    if (value && Array.isArray(value)) {
      dataMapperValue = value.map((val) => ({
        id: val.id,
        destination: val.left,
        source: val.right,
      }));
    }

    const settingValues = this.settings.value;
    if (!settingValues || setForce) {
      this.settings.value = dataMapperValue;
      return;
    }

    dataMapperValue.forEach((val) => {
      const settingValue = settingValues.find(
        (value: MapProcessDataValue) => value.id === val.id
      );

      if (settingValue) {
        settingValue.destination = val.destination;
        settingValue.source = val.source;
      } else {
        settingValues.push(val);
      }
    });
  }

  onRowRemove(index: number) {
    if (this.settings.value) {
      this.settings.value.splice(index, 1);
    }
  }
}
</script>
