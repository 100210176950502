import { ResourceState } from "./Resource.store";

export default {
  isRunLimitExceeded: (state: ResourceState) =>
    (state.time.masterConsumed >= state.time.limit.soft &&
      !state.time.canExceedPaidTime) ||
    state.time.masterConsumed >=
      state.time.limit.soft + (state.time.limit.hard || 0),

  expirationDate: (state: ResourceState) => state.expirationDate,
  autoRenewable: (state: ResourceState) => state.autoRenewable,
};
