<template>
  <div>
    <div :class="['pup-c-data-model-selector--big-acordeon']">
      <div v-for="attribute in orderedNonPrimitives" :key="attribute.name">
        <AcordeonComponent
          color="black"
          size="small"
          :name="attribute.name"
          :dataType="attribute"
          :level="level"
          :disabled="disabled"
          @pickElement="pickElement(attribute, $event, disabled)"
          :colored="colored"
          :expanded="attribute.attributes.length < 10"
        >
          <recursive-acordeon
            :attributes="attribute.attributes"
            :level="level + 1"
            :parentDataTypesId="
              new Set(parentDataTypesId).add(attribute.dataTypeId)
            "
            :disabled="attribute.isList || disabled"
            :recursiveFilter="recursiveFilter"
            :processVariables="processVariables"
            @pickElement="
              pickElement(attribute, $event, attribute.isList || disabled)
            "
            :colored="colored"
          />
        </AcordeonComponent>
      </div>
      <div v-for="primitive in orderedPrimitives" :key="primitive.name">
        <DataModelElementComponent
          @keydown.down.native="handleMoveFocus($event.target, 'next')"
          @keydown.up.native="handleMoveFocus($event.target, 'previous')"
          @pickElement="pickElement(primitive, $event, disabled)"
          :disabled="recursiveFilter ? recursiveFilter(primitive) : disabled"
          :name="primitive.name"
          :dataType="primitive"
          :padding="nonPrimitives.length === 0"
          :level="level"
          :colored="colored"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { Direction, moveFocus } from "@/utils/moveFocus";

import "@/services/datamodel/DataModel.model";
import "@/services/datamodel/DataModel.service";

import {
  IconComponent,
  InputComponent,
  BadgeStatusComponent,
  ButtonComponent,
} from "@procesio/procesio-design-system";
import DataModelElementComponent from "./DataModelElement.component.vue";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import AcordeonComponent from "@/modules/ProcessDesigner/components/DataModelSelector/components/Acordeon/Acordeon.component.vue";
import { DataModelAttribute } from "@/services/datamodel/DataModel.model";

@Component({
  components: {
    IconComponent,
    AcordeonComponent,
    InputComponent,
    BadgeStatusComponent,
    DataModelElementComponent,
    ButtonComponent,
  },
  name: "recursive-acordeon",
})
export default class RecursiveAcordeonComponent extends Vue {
  // @Prop() variables!: string[];
  @Prop({ default: () => [] }) attributes!: DataModelAttribute[];

  @Prop({ default: 1 }) level!: number;

  @Prop({ default: () => new Set() }) parentDataTypesId!: Set<string>;

  @Prop() varId?: string;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop() processVariables!: ProcessVariable[];

  @Prop()
  recursiveFilter?: (attr: DataModelAttribute) => boolean;

  @Prop({ default: true, type: Boolean }) colored!: boolean;

  nonPrimitives: DataModelAttribute[] = [];

  primitives: DataModelAttribute[] = [];

  @Watch("parentDataTypesId")
  onParentDataTypesChange() {
    this.loadModel();
  }

  // attribute list ordered alphabetically
  get orderedNonPrimitives() {
    return this.nonPrimitives.sort((a, b) => a.name.localeCompare(b.name));
  }

  // primitive list ordered alphabetically
  get orderedPrimitives() {
    return this.primitives.sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }

  @Watch("attributes", { immediate: true })
  onAttributesUpdate() {
    this.loadModel();
  }

  async loadModel() {
    const attributes = this.attributes;

    this.primitives = [];
    this.nonPrimitives = [];

    if (attributes) {
      attributes.forEach((attribute: DataModelAttribute) => {
        const hasChildren =
          Array.isArray(attribute.attributes) && attribute.attributes.length;

        if (!hasChildren) {
          this.primitives.push(attribute);
        } else {
          if (!this.parentDataTypesId.has(attribute.dataTypeId)) {
            this.nonPrimitives.push(attribute);
          } else {
            this.primitives.push(attribute);
          }
        }
      });
    }
  }

  pickElement(
    attribute: DataModelAttribute,
    previousAttributes: string[] = [],
    isDisabled = false
  ) {
    if (isDisabled) {
      return;
    }

    previousAttributes.unshift(attribute.id);
    this.$emit("pickElement", previousAttributes || []);
  }

  handleMoveFocus(target: HTMLElement, direction: Direction) {
    moveFocus(target, direction);
  }
}
</script>

<style scoped lang="scss">
@import "./DataModelElement.component.scss";
</style>
