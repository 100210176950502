var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-input-data-model-selector",on:{"keydown":_vm.hideList}},[_c('div',{staticClass:"pup-c-input-data-model-selector--input-holder"},[_c('pds-input',{staticClass:"pds-u-m--b--24",staticStyle:{"width":"100%"},attrs:{"label":_vm.showLabel ? _vm.label : 'DEFAULT',"placeholder":_vm.isChip(_vm.value) === '' ? _vm.placeholder : '',"value":_vm.isChip(_vm.value) ? '' : _vm.value,"required":_vm.required,"isTextOnBlur":_vm.isBadgeVisible,"disabled":_vm.disabled},on:{"update-input":_vm.handleUpdateInput,"focus":_vm.toggleFocus}}),_vm._v(" "),(_vm.isChip(_vm.value))?_c('pds-badge',{staticClass:"pup-c-chip",attrs:{"type":_vm.status,"label":_vm.getChip(_vm.value)},on:{"removeClick":function($event){return _vm.removeStatus(_vm.keyProp)}}}):_vm._e(),_vm._v(" "),(!_vm.isBadgeVisible && !_vm.disabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.getContent(),
      }),expression:"{\n        content: getContent(),\n      }"}],class:[
        _vm.status.type === `danger`
          ? `pup-c-input-data-model-selector--danger-chip`
          : `pup-c-input-data-model-selector--chip`,
      ],on:{"click":_vm.changeDataModelSelector}},[_vm._v("\n      Add Variable\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.isDataModelSelectorVisible)?_c('data-moodel-selector',{class:[
      'pup-c-input-data-model-selector--acordeon',
      _vm.dataModelPosition === 'right'
        ? 'pup-c-input-data-model-selector--acordeon--right'
        : 'pup-c-input-data-model-selector--acordeon--left',
    ],attrs:{"dataModelList":_vm.constSimpleDataModel},on:{"pickElement":_vm.selectVariable,"closed":_vm.closeDataModelSelector}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }