<template>
  <pds-sidepanel
    title="Case Configuration"
    @close="closeSidePanel"
    :class="[
      'pup-c-case-configuration',
      isFullScreen && 'pup-c-case-configuration--fullscreen',
    ]"
    :isFooter="false"
  >
    <div class="pup-c-case-configuration--wrapper">
      <pup-case-selector
        v-if="Array.isArray(cases) && cases.length"
        :value="value"
        :node="parent"
        :cases="cases"
        :settings="settings"
        :disabled="disabled"
        @selected="$emit('caseSelected', $event)"
      />
      <div class="pup-c-case-configuration--wrapper--config">
        <pds-collapsable title="Details" class="pds-u-m--b--16">
          <pds-input
            label="Name"
            v-model="value.name"
            :required="true"
            @focus.native.capture="fieldFocusHandler('name')"
            @blur="fieldBlurHandler('name')"
            :maxlength="28"
            :status="value.status ? value.status.name : null"
            :disabled="disabled"
          />
        </pds-collapsable>

        <condition-group
          :settings="settings"
          :parent="parent"
          :processVariables="processVariables"
          :form="form"
          :value="value"
          :condition="{
            value: conditions,
            name: 'Advanced Filtering Builder',
          }"
          :initialStatus="initialStatus"
          :disabled="disabled"
        />

        <pds-collapsable title="Target" class="pds-u-m--b--16">
          <div class="pup-c-case-configuration--targets">
            <pds-select
              label="Go To"
              placeholder="Select target"
              class="full-wdith"
              @focus.native.capture="fieldFocusHandler('target')"
              @blur="fieldBlurHandler('target')"
              :status="value.status ? value.status.target : null"
              :options="targetOptions"
              :showValidationMessage="false"
              v-model="value.target"
              :disabled="disabled"
              :clearable="!!value.target"
            />
          </div>
        </pds-collapsable>
      </div>
    </div>
  </pds-sidepanel>
</template>

<script lang="ts">
import { Prop, Component, Emit, Watch } from "vue-property-decorator";

import {
  SidePanelComponent,
  InputComponent,
  SelectComponent,
  ButtonComponent,
  PdsTypes,
  CollapsableComponent,
} from "@procesio/procesio-design-system";

import Language from "@/utils/locale/en.json";

import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { DecisionalCardValue } from "./ConditionBuilder.model";

import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

import ConditionGroup from "./ConditionGroup/ConditionGroup.component.vue";

import { FormBuilder } from "@/utils/ReactiveForm";
import {
  RelationalOperators,
  Condition,
} from "@/services/condition/Condition.model";

import { mixins } from "vue-class-component";

import { Controls } from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Controls";
import CaseSelector from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/CaseSelector/CaseSelector.component.vue";
import { EventBus, Events } from "@/utils/eventBus";

@Component({
  components: {
    "pds-sidepanel": SidePanelComponent,
    "pds-input": InputComponent,
    "pds-select": SelectComponent,
    "pds-button": ButtonComponent,
    "pds-collapsable": CollapsableComponent,
    "condition-group": ConditionGroup,
    "pup-case-selector": CaseSelector,
  },
})
export default class ConditionBuilderComponent extends mixins(Controls) {
  @Prop() value!: DecisionalCardValue;

  @Prop() conditions!: Condition[];

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop() form!: FormBuilder;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: () => [] }) cases!: DecisionalCardValue[];

  relationalOperators = RelationalOperators;

  blurredFields: Set<string> = new Set();

  language = Language;

  initialStatus: { [key: string]: PdsTypes.InputStatus } | null = null;

  isFullScreen = false;

  @Watch("value.status", { immediate: true, deep: true })
  onStatusUpdate(status: { [key: string]: PdsTypes.InputStatus }) {
    if (!status) {
      return;
    }

    if (!this.initialStatus) {
      this.initialStatus = { ...status };
    } else {
      Object.keys(this.initialStatus)
        .filter((key) => !Object.keys(status).includes(key))
        .forEach((key) => {
          this.initialStatus && delete this.initialStatus[key];
        });
    }
  }

  get allTargets() {
    return this.parent.lineArray
      .filter((line) => !(line as any).portData?.isDefault)
      .map((line) => line.nodeEnd)
      .filter((target) => target.id !== this.parent.id);
  }

  get targetOptions() {
    let targets: string[] = [];

    this.traverseControls(this.parent.configuration, (control) => {
      if (control.value && Array.isArray(control.value)) {
        console.log(control);
        targets = (control.value as DecisionalCardValue[])
          ?.map((val) => val.target)
          .filter((target) => target !== this.value.target);
      }
    });

    return this.allTargets
      .filter((target) => !targets.includes(target.id))
      .map((target) => ({
        name: target.name,
        value: target.id,
      }));
  }

  mounted() {
    EventBus.$on(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (this.settings.id === settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );

    EventBus.$emit(Events["PROCESS:TOGGLE_SIDE_TABS_INDEX"], -1);
  }

  beforeDestoy() {
    EventBus.$off(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (this.settings.id === settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  @Emit("close")
  closeSidePanel(e: MouseEvent) {
    return e;
  }

  // reason: validation on blur is needed
  fieldFocusHandler(field: string) {
    const blurredFields = new Set(this.blurredFields);

    blurredFields.delete(field);

    this.blurredFields = blurredFields;
  }

  fieldBlurHandler(field: string) {
    const blurredFields = new Set(this.blurredFields);

    blurredFields.add(field);

    this.blurredFields = blurredFields;
  }
}
</script>

<style lang="scss">
@import "./ConditionBuilder.component.scss";
</style>
