<template>
  <div>
    <pds-select
      :value="value"
      @update-input="updateInput"
      @blur="$emit('blur')"
      :options="options"
      searchable
      :label="label"
      :required="required"
      :tooltip="tooltip"
      :status="status"
      :ignoreLabelFormatting="true"
      :disabled="disabled"
      :clearable="!required"
      placeholder="Select form template"
      searchPlaceholder="Search by name"
    >
    </pds-select>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model } from "vue-property-decorator";
import { SelectComponent, PdsTypes } from "@procesio/procesio-design-system";
import FormTemplateService from "@/services/form/FormTemplate.service";

@Component({
  components: {
    "pds-select": SelectComponent,
  },
})
export default class CredentialSelectComponent extends Vue {
  @Model("update-input") value: unknown;

  @Prop() label: string | undefined;

  @Prop() tooltip?: string;

  @Prop({ required: true }) credentialType!: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  options: Array<{ value: string; name: string }> = [];

  mounted() {
    FormTemplateService.getFormTemplateBasicList().then((res) => {
      if (!res.is_error && res.content) {
        this.options = res.content.map((form) => {
          return {
            value: form.id,
            name: form.name,
          };
        });
      }
    });
  }

  updateInput(updatedValue: unknown) {
    this.$emit("update-input", updatedValue);
  }
}
</script>

<style lang="scss">
@import "./FormSelect.component.scss";
</style>
