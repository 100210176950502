import {
  ProcesioEntity,
  ProcesioEntityType,
  Role,
  RoleType,
} from "./UserPermissions.model";
import { store } from "@/store";

export default class UserPermissionsGuard {
  static isPermitted(
    entityType: ProcesioEntityType,
    reqiuredRoleType: RoleType
  ) {
    const entity: ProcesioEntity | undefined =
      store.getters.getProcesEntityByType(entityType);
    const requiredRole: Role | undefined =
      store.getters.getRoleByType(reqiuredRoleType);
    const userPermissions = store.getters.userPermissions;

    if (!entity || !requiredRole || !userPermissions) {
      return false;
    }

    const rolesPerEntity: string[] =
      userPermissions.roles[entity.entityId] || [];
    if (!rolesPerEntity.length) {
      return false;
    }

    const userRole: Role | undefined = store.getters.getRoleById(
      rolesPerEntity[0]
    );

    return (userRole?.order || 0) >= requiredRole.order;
  }
}
