var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-test-action"},[(_vm.isDesktop)?_c('div',{class:['pup-c-test-action--values--border']},[_c('div',{class:[
        'pup-c-test-action--btn-collapse',
        'pup-c-test-action--values--text',
        !_vm.isTestCollapsed && 'pup-c-test-action--values--text--collapsed',
      ],on:{"click":_vm.toggleIsTestCollapsed}},[_vm._v("\n      Test Action\n      "),(!_vm.submited)?_c('pds-icon',{class:[
          'pup-c-test-action--values--icon',
          _vm.isFullScreen && 'pup-c-test-action--values--icon--fullscreen',
        ],attrs:{"icon":_vm.isTestCollapsed ? 'chevron_left' : 'chevron_right'}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pup-c-test-action--values",class:{
      'pup-c-test-action--values--collapsed': _vm.isTestCollapsed,
    }},[_c('pup-collapsable-values',{staticClass:"pds-u-m--b--16 pup-c-test-action--test-values",attrs:{"label":"Test values","values":_vm.testValuesModel},on:{"update-collapse":_vm.checkCollapsed}}),_vm._v(" "),_c('pds-message',{staticClass:"pds-u-m--b--16 pds-u-m--l--4",attrs:{"text":_vm.enlargeInputText,"size":"small","variation":"ghost"}}),_vm._v(" "),_c('pds-button',{class:[
        'pds-u-m--b--16',
        'pup-c-test-action--test-button',
        _vm.isCollapsed && 'pup-c-test-action--test-button--isCollapsed',
      ],attrs:{"type":"ghost","color":"primary"},on:{"click":_vm.testActionTimedOut}},[_vm._v("\n      Test action\n    ")]),_vm._v(" "),(_vm.testOutputModel.length > 0)?_c('pup-collapsable-values',{class:[
        'pup-c-test-action--test-output',
        _vm.isCollapsed && 'pup-c-test-action--test-output--isCollapsed',
      ],attrs:{"values":_vm.testOutputModel,"label":"Test output","readonly":""}}):_vm._e()],1),_vm._v(" "),(!_vm.isDesktop)?_c('div',{staticClass:"pup-c-test-action--responsive-test"},[_c('pds-button',{staticClass:"pup-c-test-action--responsive-test--button",attrs:{"color":"subtle","type":"outlined"},on:{"click":_vm.closeTestAction}},[_vm._v("\n      Close Test Action\n    ")])],1):_vm._e(),_vm._v(" "),(_vm.submited)?_c('div',{staticClass:"pup-c-test-action--animation"},[_c('lottie-animation',{attrs:{"path":"./static/lottie/17146-loading.json","loop":true,"autoPlay":true,"width":300,"height":300}}),_vm._v(" "),_c('p',{staticClass:"pds-u-subtitle--1"},[_vm._v("Please, hold on! 😊")]),_vm._v(" "),_c('p',{staticClass:"pds-u-subtitle--1 pup-c-call-api--animation--description"},[_vm._v("\n      We are sending the request.\n    ")]),_vm._v(" "),_c('pds-button',{staticClass:"pup-c-call-api--animation--button pds-u-m--t--24",on:{"click":_vm.cancelRequest}},[_vm._v("\n      Cancel\n    ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }