import {
  DataModel,
  DataModelAttribute,
  isCustomDataModel,
} from "@/services/datamodel/DataModel.model";
import { DataModelState } from "./DataModel.store";

export default {
  dataTypes: (state: DataModelState) => state.dataTypes || [],
  publicDataTypes: (state: DataModelState) =>
    (state.dataTypes || []).filter(
      (item) => item.isPublic && !(item.scopes || []).length
    ),
  dataTypesWithChildren: (_: DataModelState, getters: any) => (id: string) => {
    let initialDataTypes = [];

    if (id) {
      const dataType = getters.getDataTypeById(id);
      if (dataType) {
        initialDataTypes = [dataType];
      }
    } else {
      initialDataTypes = getters.publicDataTypes;
    }

    if (!initialDataTypes.length) {
      return [];
    }

    const dataTypes = [...initialDataTypes];

    const flatAttributes = (attributes: DataModelAttribute[]) => {
      attributes.forEach((attribute: DataModelAttribute) => {
        const dataType: DataModel | null = getters.getDataTypeById(
          attribute.dataTypeId
        );
        if (
          dataType &&
          dataTypes.findIndex((dt) => dt.id === dataType.id) === -1
        ) {
          dataTypes.push(dataType);
          if (dataType.attributes && !!(dataType.attributes || []).length) {
            flatAttributes(dataType.attributes);
          }
        }
      });
    };

    initialDataTypes.forEach((publicDataType: DataModel) => {
      publicDataType.attributes && flatAttributes(publicDataType.attributes);
    });

    return dataTypes;
  },
  dataTypesWithDataModelChildren: (_: DataModelState, getters: any) => (
    id: string
  ) =>
    getters
      .dataTypesWithChildren(id)
      .filter((dataType: DataModel) => isCustomDataModel(dataType)),
  getDataTypeById: (state: DataModelState) => (id: string) =>
    state.dataTypes.find((dataType) => dataType.id === id) || null,
};
