import { DataModelType } from "./datamodel/DataModel.model";
import { OAuth2IdentityProvider } from "./auth/OAuth2/OAuth2.model";

export default {
  API: {
    ACTION: "api/actions",
    ACTIONS_RESTRICTED: "api/actions/restricted",
    ACTIONS: {
      FOLDERS: {
        RENAME: "api/Actions/folders/rename",
        ADD: "api/Actions/folders",
        DELETE: (nodeId: string) => `api/Actions/folders/${nodeId}`,
        SAVE: "api/Actions/templates",
      },
      TEMPLATE: {
        DELETE: (nodeId: string) => `api/Actions/templates/${nodeId}`,
      },
    },
    PROJECTS: {
      FLOW: (flowId: string) => {
        return `api/projects/${flowId}`;
      },
      FLOW_RESTRICTED: (flowId: string) => `api/projects/${flowId}/restricted`,
      DUPPLICATE: (flowId: string) => {
        return `api/projects/${flowId}/duplicate`;
      },
      INSTANCES: (flowId: string, pageNumber = 0, pageItemCount = 1000) => {
        return `api/projects/${flowId}/instances?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`;
      },
      INSTANCESHISTORY: (
        flowId: string,
        pageNumber = 0,
        pageItemCount = 1000
      ) => {
        return `api/projects/${flowId}/history?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`;
      },
      LEGACY_INSTANCE: (
        flowId: string,
        pageNumber = 0,
        pageItemCount = 1000
      ) => {
        return `api/projects/${flowId}/archive?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`;
      },
      STATUS: (instanceId: string) => {
        return `api/projects/instances/${instanceId}/status`;
      },
      STOPINSTANCE: (instanceId: string) => {
        return `api/projects/instances/${instanceId}/stop`;
      },
      DEFAULT: "api/projects",
      RUN: (flowId: string) => {
        return `api/projects/${flowId}/run`;
      },
      DELETE: (id: string) => {
        return `api/project/${id}/delete`;
      },
      STEP: "api/process/step",
      ALL: "api/process/all",
      TABLE: "api/project/table",
      PUBLISH: (id: number) => {
        return `api/project/${id}/publish`;
      },
      PAYLOAD: (id: string) => {
        return `api/Projects/${id}/payload`;
      },
      LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
        `api/projects?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      LIST_RESTRICTED: "api/projects/restricted",
      NOTIFICATIONS: (id = "") => `api/projects/notifications${id && "/" + id}`,
      COUNT: "api/projects/count",
      UPLOAD_FILE: "api/file/upload/flow",
      DOWNLOAD_FILE: "api/file/download",
    },
    DATATYPE: {
      DEFAULT: "api/dataTypes",
      PRIVATE: "api/dataTypes/private",
      GENERATE: "api/dataTypes/generate",
      GENERATE_FROM_FILE: "api/dataTypes/generate/file",
      UPDATE: "api/dataTypes",
      UPDATE_NAME: "api/dataTypes?updateName=true",
      CLONE_PUBLIC: "api/dataTypes/clone",
      CHANGE_TO_PUBLIC: "api/dataTypes/changeToPublic",
      ATTRIBUTE: (dataModelId: string, attributeId = "") =>
        `api/dataTypes/attribute/${dataModelId}${
          attributeId && "/" + attributeId
        }`,
      MODELID: (
        modelId: string,
        type: DataModelType = DataModelType.INTERNAL
      ) => `api/dataTypes/${modelId}?type=${type}`,
      LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
        `api/dataTypes?addProperties=true&pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      LIST_CUSTOM_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
        `api/dataTypes?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}&includeProcesioEntries=false&addProperties=true`,
      LIST_CUSTOM_RESTRICTED: (pageNumber = 0, pageItemCount = 1000) =>
        `api/dataTypes/restricted?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      COUNT: "api/dataTypes/count",
      LIST_INTERNAL_PAGINATED: (pageNumber = 0, pageItemCount = 0) =>
        `api/dataTypes/procesio?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
    },
    CREDENTIALMANAGER: {
      TYPES: "api/credentials/types",
      ALL_CREDENTIALS: (pageNumber = 0, pageItemCount = 1000) =>
        `api/credentials?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      ADD: "api/credentials",
      TEST: "api/credentials/test",
      COUNT: "api/credentials/count",
    },
    AUTOMATION: {
      SCHEDULES: {
        LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
          `api/schedules?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
        SAVE: () => `api/schedules`,
        DUPLICATE_SCHEDULE: (scheduleId: string) =>
          `api/schedules/${scheduleId}/duplicate`,
        TOGGLE_SCHEDULE_STATUS: (scheduleId: string, status: boolean) =>
          `api/schedules/${scheduleId}/status?enable=${
            status ? "true" : "false"
          }`,
        DELETE_SCHEDULE: (scheduleId: string) => `api/schedules/${scheduleId}`,
        GET_BY_ID: (scheduleId: string) => `api/schedules/${scheduleId}`,
        NOTIFICATIONS: (id = "") =>
          `api/schedules/notifications${id && "/" + id}`,
        COUNT: "api/schedules/count",
        UPLOAD_FILE: "api/file/upload/schedule",
        DOWNLOAD_FILE: "api/file/download/schedule",
      },
      WEBHOOK: {
        LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
          `api/webhooks?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
        GET_BY_ID: (id: string) => `api/webhooks/${id}`,
        GET_USAGE_STATUS_BY_ID: (id: string) => `api/webhooks/${id}/used`,
        SAVE: "api/webhooks",
        UNDO: (id: string) => `api/webhooks/${id}/undo`,
        DELETE: (id: string) => `api/webhooks/${id}`,
        LISTEN: "api/webhooks/listen",
        GENERATE_DATA_MODEL: "api/webhooks/generate-data",
        COUNT: "api/webhooks/count",
      },
    },
    ADMINISTRATION: {
      WORKSPACE: {
        LIST: "api/workspaces",
        SUB_LIST_PAGINATED: (
          parentId: string | null | undefined = null,
          pageNumber = 0,
          pageItemCount = 1000
        ) => {
          let url = "api/Workspace";
          if (parentId) {
            url += `/${parentId}`;
          }
          url += `/subworkspaces?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`;
          return url;
        },
        GET_BY_ID: (id: string) => `api/Workspace/${id}`,
        SAVE: () => `api/Workspace`,
        DELETE: (id: string) => `api/Workspace/${id}`,
      },
      USER_MANAGEMENT: {
        LIST_PAGINATED: (
          workspace: string | null = null,
          pageNumber = 0,
          pageItemCount = 1000
        ) =>
          `api/Workspace/users?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}` +
          (workspace ? `&targetWorkspace=${workspace}` : ""),
        INVITE_USER: (workspace: string | null = null) =>
          "api/Workspace/invite" +
          (workspace ? `?targetWorkspace=${workspace}` : ""),
        TRANSFER_OWNERSHIP: (userId: string) =>
          `api/Workspace/transfer-ownership/${userId}`,
        DELETE_USER: (userId: string, workspace: string | null = null) =>
          `api/Workspace/invite/${userId}` +
          (workspace ? `?targetWorkspace=${workspace}` : ""),
      },
      SUBSCRIPTIONS: {
        LIST: "api/subscriptions",
        REFUND: (id: string) => `api/subscriptions/refund/${id}`,
        RENEW: (id: string, isAutoRenew: boolean) =>
          `api/subscriptions/renew/${id}/${isAutoRenew ? "true" : "false"}`,
      },
    },
    USER: {
      EVENTS: {
        LIST: "api/notifications",
        UPDATE_EVENT: "api/notifications",
        SET_CONNECTION: "api/notifications/connection",
      },
      PREFERENCES: "api/userPreferences",
      PERMISSIONS: {
        PROCESIO_ENTITY_LIST: "api/userPermissions/entities",
        ROLE_LIST: "api/userPermissions/roles",
        USER_TYPE_LIST: "api/userPermissions/userTypes",
        USER_PERMISSIONS: (
          userId: string | null = null,
          workspace: string | null = null
        ) =>
          `api/userPermissions${userId ? "/" + userId : ""}${
            workspace ? `?targetWorkspace=${workspace}` : ""
          }`,
        DEFAULT_USER_PERMISSIONS_PER_WORKSPACE: (workspaceId: string) =>
          `api/userPermissions/workspace/${workspaceId}/default`,
      },
    },
    API_KEY: {
      LIST: "api/apiKey",
      CREATE_KEY: "api/apiKey",
      DELETE_KEY: (id: string) => `api/apiKey/${id}`,
      REVOKE_ALL_KEYS: "api/apiKey",
    },
    RESOURCES: {
      USED: (intervalQueryParam: string) =>
        `api/resources/used?${intervalQueryParam}`,
      SUB_WS_USED: (intervalQueryParam: string) =>
        `api/resources/used/subworkspaces?${intervalQueryParam}`,
      ANALYTICS: {
        PROCESSES: {
          PAGINATED_LIST: (
            intervalQueryParam: string,
            pageNumber = 0,
            pageItemCount = 1000,
            masterWorkspaceId: string | null = null
          ) =>
            `api/resources/analytics/processes${
              masterWorkspaceId ? "/" + masterWorkspaceId : ""
            }?${intervalQueryParam}&pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
          DETAILS: (processId: string, intervalQueryParam: string) =>
            `api/resources/analytics/processes/${processId}/details?${intervalQueryParam}`,
        },
        INSTANCES: {
          DETAILS: (instanceId: string) =>
            `api/resources/analytics/instances/${instanceId}/details`,
        },
        USERS: (
          intervalQueryParam: string,
          pageNumber = 0,
          pageItemCount = 1000,
          masterWorkspaceId: string | null = null
        ) =>
          `api/resources/analytics/users${
            masterWorkspaceId ? "/" + masterWorkspaceId : ""
          }?${intervalQueryParam}&pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
        WORKSPACES: (
          intervalQueryParam: string,
          pageNumber = 0,
          pageItemCount = 1000,
          masterWorkspaceId: string | null = null
        ) =>
          `api/resources/analytics/subworkspaces${
            masterWorkspaceId ? "/" + masterWorkspaceId : ""
          }?${intervalQueryParam}&pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      },
    },
    FORM: {
      TEMPLATE: {
        LIST_BASIC: "api/FormTemplate/all/basic",
        LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
          `api/FormTemplate?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
        GET_BY_ID: (id: string, workspace: string | null = null) =>
          `api/FormTemplate/${(workspace ? workspace + "/" : "") + id}`,
        SAVE: "api/FormTemplate",
        PATCH_UPDATE: (id: string) => `api/FormTemplate/${id}`,
        DELETE: (id: string) => `api/FormTemplate/${id}`,
        TOGGLE_STATE: (id: string) => `api/FormTemplate/${id}/state`,
        DUPLICATE: (id: string) => `api/FormTemplate/${id}/duplicate`,
      },
      INSTANCE: {
        LIST_PAGINATED: (id: string, pageNumber = 0, pageItemCount = 1000) =>
          `api/form/${id}/all?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
        COUNT: (id: string) => `api/form/${id}/count`,
        GET_BY_ID: (id: string) => `api/form/${id}`,
        DELETE: "api/form",
      },
      SUBMIT: "api/form",
      ASSIGNMENT_LIST_PAGINATED: (
        opened = true,
        pageNumber = 0,
        pageItemCount = 1000
      ) =>
        `api/form/assigned?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}&awaitingAction=${
          opened ? "true" : "false"
        }`,
      UPLOAD_FILE: "api/form/upload",
      DOWNLOAD_FILE: "api/form/download",
      PROCESS: {
        GET: (processId: string) =>
          `api/formTemplate/processTemplate/${processId}`,
        GET_ALL: "api/formTemplate/processTemplate/list",
        PUBLISH: (id: string, processId: string) =>
          `api/formProcess/${id}/${processId}/publish`,
        LAUNCH: (
          id: string,
          processId: string,
          runSynchronous = false,
          secondsTimeOut = 60
        ) =>
          `api/formProcess/${id}/${processId}/launch?runSynchronous=${runSynchronous}&secondsTimeOut=${secondsTimeOut}`,
        UPLOAD_FILE: (id: string, processId: string) =>
          `api/formProcess/${id}/${processId}/upload`,
        DOWNLOAD_FILE: "api/formProcess/download",
      },
    },
    DOCUMENT_DESIGNER: {
      LIST_PAGINATED: (pageNumber = 0, pageItemCount = 1000) =>
        `api/DocumentTemplate?pageNumber=${pageNumber}&pageItemCount=${pageItemCount}`,
      LIST_RESTRICTED: "api/DocumentTemplate/restricted",
      GET_BY_ID: (id: string) => `api/DocumentTemplate/${id}`,
      GET_RESTRICTED_BY_ID: (id: string) =>
        `api/DocumentTemplate/${id}/restricted`,
      SAVE: "api/DocumentTemplate",
      DELETE: (id: string) => `api/DocumentTemplate/${id}`,
    },
    TRANSPORT: {
      IMPORT: "api/transport/import",
      EXPORT: "api/transport/export",
    },
  },
  AUTH: {
    AUTHENTIFICATE: "api/authentication",
    REFRESHTOKEN: "api/authentication/refreshToken",
    LOGOUT: "api/authentication/logOut",
    PASSWORD: {
      CREATE: "api/Users",
      UPDATE: "api/users/password/update",
      FORGOT: "api/users/password/forgot",
      CHANGE: "api/users/password/change",
      RESEND_TOKEN: "api/Users/resendToken",
    },
    REFER: "api/users/refer-friend",
    OAUTH2_AUTHORIZATION: (provider: OAuth2IdentityProvider) =>
      `api/authentication/authorize/${provider}?redirect_uri=${window.location.origin}`,
    OAUTH2_CALLBACK: (provider: OAuth2IdentityProvider) =>
      `api/authentication/oauth2/callback/${provider}`,
  },
  GUI: {
    VERSION: "gui/Version",
  },
};
