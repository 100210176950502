import { AbstractControlParameterGetter } from "./AbstractControlParameterGetter";
import { SettingType } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ParameterVariableCounter } from "../ParameterVariableCounter";
import { TabsPayloadParameterGetter } from "./CallApi/TabsPayloadParameterGetter";
import { DefaultControlParameterGetter } from "./DefaultControlParameterGetter";
import { DecisionalCaseParameterGetter } from "./Decisional/DecisionalCaseParameterGetter";
import { ColumnDefinitionParameterGetter } from "./GetFileData/ColumnDefinitionParameterGetter";
import { SimpleValueControlParameterGetter } from "./SimpleValueControlParameterGetter";
import { ProcessInputOutputParameterGetter } from "./CallSubprocess/ProcessInputOutputParameterGetter";
import { MapProcessDataParameterGetter } from "./MapProcessData/MapProcessDataParameterGetter";
import { DocumentDesignerMapperDataParameterGetter } from "./DocumentDesignerMapper/DocumentDesignerMapperDataParameterGetter";
import { TabsPayloadOldParameterGetter } from "./CallApi/TabsPayloadOldParameterGetter";

export class ControlParameterGetterFactory {
  static getParameterGetter(
    settingType: SettingType,
    variableCounter: ParameterVariableCounter | null = null
  ): AbstractControlParameterGetter {
    switch (settingType) {
      case SettingType.TABS_PAYLOAD_OLD: 
        return new TabsPayloadOldParameterGetter(variableCounter);
      case SettingType.TABS_PAYLOAD:
        return new TabsPayloadParameterGetter(variableCounter);
      case SettingType.DECISIONAL_CASES:
        return new DecisionalCaseParameterGetter(variableCounter);
      case SettingType.COLUMN_DEFINITION:
        return new ColumnDefinitionParameterGetter(variableCounter);
      case SettingType.PROCESS_INPUT:
      case SettingType.PROCESS_OUTPUT:
        return new ProcessInputOutputParameterGetter(variableCounter);
      case SettingType.MAP_PROCESS_DATA:
        return new MapProcessDataParameterGetter(variableCounter);
      case SettingType.IGNORE:
      case SettingType.CREDENTIAL:
      case SettingType.VERB_SELECT:
      case SettingType.SELECT:
      case SettingType.RADIO:
      case SettingType.FLOW_LIST:
        return new SimpleValueControlParameterGetter(variableCounter);
      case SettingType.DOCUMENT_MAPPER_BUILDER:
        return new DocumentDesignerMapperDataParameterGetter(variableCounter);
      default:
        return new DefaultControlParameterGetter(variableCounter);
    }
  }
}
