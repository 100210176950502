export enum Primitives {
  BOOLEAN = "0317bfee-b2f5-4bde-bfe8-121212121210",
  INTEGER = "0317bfee-b2f5-4bde-bfe8-121212121211",
  FLOAT = "0317bfee-b2f5-4bde-bfe8-121212121212",
  DOUBLE = "0317bfee-b2f5-4bde-bfe8-121212121213",
  STRING = "0317bfee-b2f5-4bde-bfe8-121212121214",
  DATE = "0317bfee-b2f5-4bde-bfe8-121212121215",
  RELATIONSHIP = "0317bfee-b2f5-4bde-bfe8-121212121216",
  TIME = "0317bfee-b2f5-4bde-bfe8-121212121217",
  DATETIME = "0317bfee-b2f5-4bde-bfe8-121212121218",
  NUMBER = "NUMBER",
  GUID = "0317bfee-b2f5-4bde-bfe8-121212121222",
}

export const LazyPrimities = {
  ...Primitives,
  boolean: "0317bfee-b2f5-4bde-bfe8-121212121210",
  integer: "0317bfee-b2f5-4bde-bfe8-121212121211",
  float: "0317bfee-b2f5-4bde-bfe8-121212121212",
  double: "0317bfee-b2f5-4bde-bfe8-121212121213",
  string: "0317bfee-b2f5-4bde-bfe8-121212121214",
  date: "0317bfee-b2f5-4bde-bfe8-121212121215",
  relationship: "0317bfee-b2f5-4bde-bfe8-121212121216",
  time: "0317bfee-b2f5-4bde-bfe8-121212121217",
  datetime: "0317bfee-b2f5-4bde-bfe8-121212121218",
  number: "number",
  file: "10c6ac59-3929-49e6-99dc-121212121219",
  undefined: "undefined",
  object: "object",
  function: "function",
  symbol: "symbol",
  bigint: "bigint",
};

export enum NonPrimitives {
  FILE = "10c6ac59-3929-49e6-99dc-121212121219",
  JSON = "0317bfee-b2f5-4bde-bfe8-121212121220",
  OBJECT = "0317bfee-b2f5-4bde-bfe8-121212121221",
  SYSTEM_VARIABLE = "10c6ac59-3929-49e6-99dc-121212121221",
  ERROR = "10c6ac59-3929-49e6-99dc-121212121220",
}

export const isPrimitive = (dataTypeId: string) => {
  return (Object.values(Primitives) as string[]).includes(dataTypeId);
};

export const isCustomTypeAllowed = (dataTypeId: string) => {
  return ([NonPrimitives.JSON, NonPrimitives.OBJECT] as string[]).includes(
    dataTypeId
  );
};
