<template>
  <div
    :class="[
      'pup-c-data-model-selector',
      inlineMode && 'pup-c-data-model-selector--inline',
    ]"
    @keydown.esc="onEscPress"
    tabindex="0"
    ref="selectorContainer"
    v-closable="closableCallback"
  >
    <template v-if="isHeightSet">
      <div class="pup-c-data-model-selector--header" ref="header">
        <pds-input
          icon="search"
          placeholder="Search by name"
          :isSearch="true"
          v-model="filters.search"
          variation="small"
          class="pup-c-data-model-selector--header--search"
          ref="selectorSearch"
          @keydown.enter.native="onSearchEnterPress"
        />
        <pds-dropdown v-if="filterable" :outlined="true">
          <template v-slot:button-content>
            <div
              class="pup-c-data-model-selector--header--filter pds-u-m--l--4"
              v-tooltip="{
                content: 'Filter variables',
                boundariesElement: 'window',
              }"
            >
              <pds-icon icon="filter_list" size="tiny" />
            </div>
          </template>
          <div class="pup-c-data-model-selector--header--filter--dropdown">
            <pds-checkbox
              v-model="filters.showPrimitive"
              class="pup-c-data-model-selector--header--filter--dropdown--item"
              variation="tiny"
            >
              Primitive data types
            </pds-checkbox>
            <pds-checkbox
              v-model="filters.showCustom"
              class="pup-c-data-model-selector--header--filter--dropdown--item"
              variation="tiny"
            >
              Custom data types
            </pds-checkbox>
          </div>
        </pds-dropdown>

        <div
          v-if="!(hideCreate || isDataModelMode)"
          class="pup-c-data-model-selector--header--add-button pds-u-m--l--4"
          @click="onCreateVariableClick"
          v-tooltip="{
            content: 'Create variable',
            boundariesElement: 'window',
          }"
        >
          <pds-icon icon="add" size="tiny" />
        </div>
      </div>

      <div
        v-if="emptyResultText"
        v-html="emptyResultText"
        class="pup-c-data-model-selector--empty"
      />

      <div
        class="pup-c-data-model-selector--big-acordeon"
        ref="variablesAccordeon"
        v-else
      >
        <template>
          <div
            v-for="(item, index) in itemsByPage"
            :key="item.id"
            :class="'pup-c-data-model-selector--' + index"
          >
            <pup-accordeon
              icon="highlight_off"
              :name="item.name"
              color="black"
              size="small"
              :dataType="item"
              v-if="item.attributes && item.attributes.length !== 0"
              :colored="colored"
              :ref="'accordeon-' + item.id"
              :expanded="isDataModelSmall(item)"
              @pickElement="pickElement(item)"
              @click="setExpandedState(item, $event)"
            >
              <pds-r-acordeon
                :attributes="item.attributes"
                @pickElement="pickElement(item, $event)"
                :filter="
                  item.name.toUpperCase().includes(filters.search.toUpperCase())
                "
                :disabled="item.isList"
                :colored="colored"
                :parentDataTypesId="new Set([item.dataTypeId])"
              />
            </pup-accordeon>

            <pup-data-model-element
              v-else
              @pickElement="pickElement(item)"
              @keydown.down.native="handleMoveFocus($event.target, 'next')"
              @keydown.up.native="handleMoveFocus($event.target, 'previous')"
              :parentDataTypesId="new Set().add(item.id)"
              :name="item.name"
              :dataType="item"
              :colored="colored"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Variable as VariableMixin } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { Prop, Component, Watch } from "vue-property-decorator";
import "./DataModelSelector.component.scss";
import "@/services/datamodel/DataModel.model";
import "@/services/datamodel/DataModel.service";
import {
  IconComponent,
  InputComponent,
  BadgeStatusComponent,
  ButtonComponent,
  DropdownComponent,
  CheckboxComponent,
} from "@procesio/procesio-design-system";
import DataModelElementComponent from "./components/DataModelElement.component.vue";
import RecursiveAcordeon from "./components/RecursiveAcordeon.component.vue";
import AcordeonComponent from "@/modules/ProcessDesigner/components/DataModelSelector/components/Acordeon/Acordeon.component.vue";
import {
  DataModel,
  findAttributeById,
  isCustomDataModel,
  Pagination,
  searchAttributes,
} from "@/services/datamodel/DataModel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { EventBus, Events } from "@/utils/eventBus";
import { Variable } from "@/services/crud/Orchestration.service";
import { Direction } from "../PropertiesPanel/PropertiesPanel.model";
import { mapGetters } from "vuex";
import { moveFocus, Direction as FocusDirection } from "@/utils/moveFocus";
import { cloneDeep } from "lodash";

@Component({
  components: {
    "pds-icon": IconComponent,
    "pds-input": InputComponent,
    "pds-badge-status": BadgeStatusComponent,
    "pds-button": ButtonComponent,
    "pds-r-acordeon": RecursiveAcordeon,
    "pds-dropdown": DropdownComponent,
    "pds-checkbox": CheckboxComponent,
    "pup-accordeon": AcordeonComponent,
    "pup-data-model-element": DataModelElementComponent,
  },
  computed: {
    ...mapGetters({
      dataTypes: "dataTypes",
    }),
  },
})
export default class DataModelSelectorComponent extends mixins(VariableMixin) {
  // For Data Model mode
  @Prop({ default: null }) parentDataModelId!: string | null;

  @Prop({ default: null }) parentVariableId!: string | null;

  // For Variable mode
  @Prop({ default: () => [] }) processVariables!: ProcessVariable[];

  @Prop() parentId!: string;

  @Prop({ default: false, type: Boolean }) hideCreate!: boolean;

  @Prop({ default: true, type: Boolean }) filterable!: boolean;

  @Prop({ default: "Process Variables" }) title!: string;

  @Prop({ default: () => false })
  isVariableDisabled?: (variable: ProcessVariable) => boolean;

  @Prop({
    default: () => false,
  })
  isAttributeDisabled?: (variable: ProcessVariable) => boolean;

  @Prop({})
  recursiveFilter?: (variable: ProcessVariable) => boolean;

  @Prop({ default: false, type: Boolean }) inlineMode!: boolean;

  @Prop({ default: true, type: Boolean }) colored!: boolean;

  @Prop({ default: Direction.NONE }) direction!: Direction;

  @Prop({ default: true, type: Boolean }) hasSearchAutoFocus!: boolean;

  @Prop({ default: null }) expectedDataModelId!: string | null;

  @Prop({ default: false, type: Boolean }) isListExpected!: boolean;

  filters = {
    search: "",
    showPrimitive: true,
    showCustom: true,
  };

  isHeightSet = false;

  isClosable = false;

  itemMinHeightPx = 40;
  // infinite scroll pagination
  pagination: Pagination = {
    perPage: 1000,
    currentPage: 1,
    total: 0,
    pageTotal: 0,
    lastPage: 0,
  };

  paginatedItems: any[] = [];
  itemsExpandedState: { [key in string]: boolean | undefined } = {};
  userItemsExpandedState: { [key in string]: boolean | undefined } = {}; // item state set by user (click)

  get items() {
    const items: any[] = [];
    if (this.isDataModelMode) {
      let parentDataModelId = this.parentDataModelId;
      const parentVariable: Variable | null =
        this.$store.getters.getVariableById(this.parentVariableId);

      if (parentVariable) {
        parentDataModelId = parentVariable.dataType;
      }

      const dataType: DataModel =
        this.$store.getters.getDataTypeById(parentDataModelId);

      if (!dataType || dataType.notPermitted) {
        return items;
      }

      (dataType.attributes || []).forEach((item) => {
        items.push({
          id: item.id,
          name: item.name,
          dataTypeId: item.dataTypeId,
          attributes: item.attributes,
          isDataModel: item.isDataModel,
          isProcesio: item.isProcesio,
          isList: item.isList,
        });
      });
    } else {
      const processVariables = this.processVariables.filter(
        (variable) =>
          (this.direction === Direction.Output ? !variable.isConst : true) &&
          (!(variable as unknown as Variable).contextId ||
            (variable as unknown as Variable).contextId === this.parentId) &&
          !!variable.name.length
      );

      processVariables.forEach((variable: ProcessVariable) => {
        const dataType: DataModel = this.$store.getters.getDataTypeById(
          variable.dataType
        );
        if (!dataType || dataType.notPermitted) {
          return;
        }

        items.push({
          id: variable.id,
          name: variable.name,
          dataTypeId: dataType.id,
          attributes: dataType.attributes,
          isDataModel: dataType.isDataModel,
          isProcesio: dataType.isProcesio,
          isList: variable.isList,
        });
      });
    }

    return items;
  }

  get searchedItems() {
    if (this.items.length === 0) {
      return [];
    }

    let items = cloneDeep(this.items).filter((item) => {
      const isCustom = isCustomDataModel(item);

      return (
        (this.filters.showPrimitive && !isCustom) ||
        (this.filters.showCustom && isCustom)
      );
    });

    const search = this.filters.search;
    if (search && search.length > 0) {
      items = items
        .filter(
          (item: any) =>
            item.name
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            !!searchAttributes(search, item.attributes || []).length
        )
        .map((item) => ({
          ...item,
          attributes: searchAttributes(search, item.attributes || []),
        }));
    }

    return items
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => {
        if ((a.attributes || []).length > (b.attributes || []).length) {
          return -1;
        } else if ((a.attributes || []).length < (b.attributes || []).length) {
          return 1;
        }
        return 0;
      });
  }

  get itemsByPage() {
    return this.paginatedItems.slice(0, this.pagination.currentPage).flat();
  }

  isDataModelSmall(dataModel: DataModel) {
    return dataModel.attributes?.length < 10;
  }

  get areFiltersApplied() {
    return !(
      this.filters.search.length === 0 &&
      this.filters.showPrimitive &&
      this.filters.showCustom
    );
  }

  get isDataModelMode() {
    return (
      (!!this.parentDataModelId || !!this.parentVariableId) &&
      !this.processVariables.filter((variable) => !!variable.name).length
    );
  }

  get emptyResultText() {
    const noVariableAndDataModelText = "No variables or data models defined.";
    const emptySearchText = `No results found. <span class="pds-u-m--t--12">Try to adjust your search or your filters to find what you are looking for.</span>`;

    if (!this.searchedItems.length) {
      return this.areFiltersApplied
        ? emptySearchText
        : noVariableAndDataModelText;
    }

    return null;
  }

  get closableCallback() {
    if (this.isClosable) {
      return () => this.close();
    }

    return () => undefined;
  }

  @Watch("isClosable")
  onClosableUpdate(isClosable: boolean) {
    isClosable ? this.eventBusOff() : this.eventBusOn();
  }

  @Watch("itemsByPage")
  onItemsByPageUpdate(items: any[]) {
    this.$nextTick(() => {
      items.forEach((item) => {
        const refs = this.$refs["accordeon-" + item.id];
        const accordeon = refs && refs[0];
        accordeon && (accordeon.isCollapsed = this.itemsExpandedState[item.id]);
      });
    });
  }

  @Watch("searchedItems", { immediate: true })
  onSearchedItemsUpdate(items: any[]) {
    const itemsExpandedState = { ...this.itemsExpandedState };

    items.forEach((item) => {
      let expanded: boolean | undefined =
        this.userItemsExpandedState[item.id] !== undefined
          ? this.userItemsExpandedState[item.id]
          : this.isDataModelSmall(item);
      if (
        this.userItemsExpandedState[item.id] === undefined &&
        !!this.filters.search
      ) {
        expanded = true;
      }

      itemsExpandedState[item.id] = expanded;
    });
    this.itemsExpandedState = itemsExpandedState;

    const getItemHeight = (item: any) => {
      let height = this.itemMinHeightPx;

      if (
        !!this.itemsExpandedState[item.id] ||
        (typeof this.itemsExpandedState[item.id] === "undefined" &&
          (this.isDataModelSmall(item) || !!this.filters.search))
      ) {
        (item.attributes || []).forEach((attr: any) => {
          height += getItemHeight(attr);
        });
      }

      return height;
    };

    const itemsMinHeight = items.reduce(
      (data: { [key in string]: number }, item) => {
        const height = getItemHeight(item);
        data[item.id] = height;
        return data;
      },
      {}
    );

    this.$nextTick(() => {
      // paginate searched items: px calculation
      let page = 1;
      let totalOnPage = 0;

      const computedStyle = window.getComputedStyle(
        this.$refs.selectorContainer as HTMLElement
      );
      const maxContainerHeightPx = parseInt(
        (computedStyle as any)["max-height"],
        10
      );

      const headerHeightPx = (this.$refs.header as HTMLElement).offsetHeight;
      const paginatedItems: any[] = [];

      // do not paginate of container max height is not set (e.g. inline mode)
      if (isNaN(+maxContainerHeightPx)) {
        paginatedItems.push(this.searchedItems);
      } else {
        this.searchedItems.forEach((item) => {
          const height = itemsMinHeight[item.id];
          // jump to next page ONLY IF current page limit is exceed (to avoid spaces)
          if (
            totalOnPage + height > this.pagination.perPage &&
            totalOnPage >= maxContainerHeightPx - headerHeightPx
          ) {
            page++;
            totalOnPage = 0;
          }

          totalOnPage += height;

          if (!Array.isArray(paginatedItems[page - 1])) {
            paginatedItems[page - 1] = [];
          }
          paginatedItems[page - 1].push(item);
        });
      }

      // this.pagination.currentPage = 1;
      this.paginatedItems = paginatedItems;
    });
  }

  eventBusOn() {
    EventBus.$on("variable-created", this.populateCreatedVariable);
    EventBus.$on(Events["VARIABLE:CLOSE_FORM"], this.setClosableState);
  }

  eventBusOff() {
    EventBus.$off("variable-created", this.populateCreatedVariable);
    EventBus.$off(Events["VARIABLE:CLOSE_FORM"], this.setClosableState);
  }

  populateCreatedVariable(variable: ProcessVariable) {
    this.pickElementProgramatically(variable);
  }

  mounted() {
    // infinite scroll handle
    this.$nextTick(() => {
      if (this.$refs.variablesAccordeon) {
        (this.$refs.variablesAccordeon as Element).addEventListener(
          "scroll",
          (event: Event) => {
            const element = event.target as Element;
            if (
              element &&
              Math.ceil(element.scrollHeight) -
                Math.ceil(element.scrollTop) -
                10 <=
                Math.ceil(element.clientHeight) &&
              this.pagination.currentPage < this.paginatedItems.length
            ) {
              this.pagination.currentPage++;
            }
          }
        );
      }
    });
    if (this.inlineMode) {
      this.isHeightSet = true;
      this.$nextTick(() => {
        const selectorSearch = this.$refs["selectorSearch"];
        if (selectorSearch && this.hasSearchAutoFocus) {
          (selectorSearch as InputComponent).focused();
        }
      });
      return;
    }

    const selectorContainer = this.$refs["selectorContainer"] as HTMLDivElement;

    if (selectorContainer) {
      selectorContainer.focus();
    }

    const topPos = selectorContainer.getBoundingClientRect().top;
    let maxHeight = document.body.clientHeight - topPos;

    const closestSidePanel = selectorContainer.closest(".pds-c-side-panel");
    if (closestSidePanel) {
      const sidePanelFooter = closestSidePanel.querySelector(
        ".pds-c-side-panel--footer"
      );
      if (sidePanelFooter) {
        maxHeight = maxHeight - sidePanelFooter.clientHeight;
      }
    }

    if (maxHeight > 300) {
      selectorContainer.style.maxHeight = `${maxHeight - 20}px`;

      this.isHeightSet = true;
    }
    // calculate max height because selector is expended up
    else {
      const searchInputHeight = 72;
      const margin8px = 8;
      const headerSidePanelHeight = 84;
      const propertiesPanelHeaderHeight = 70;
      const propertiesPanelTabHeight = 50;

      let maxHeightCalculated = topPos - searchInputHeight - margin8px;

      const isInsideSidePanel =
        !!selectorContainer.closest(".pds-c-side-panel");
      const closestPropertiesPanel =
        selectorContainer.closest(".properties-panel");
      const isInsidePropertiesPanel = !!closestPropertiesPanel;

      if (isInsideSidePanel) {
        maxHeightCalculated -= headerSidePanelHeight;
      } else if (isInsidePropertiesPanel) {
        maxHeightCalculated -=
          propertiesPanelHeaderHeight +
          (document.body.clientHeight - closestPropertiesPanel.clientHeight);

        // is inside tabs?
        if (!!selectorContainer.closest(".tabs--body")) {
          maxHeightCalculated -= propertiesPanelTabHeight;
        }
      }

      selectorContainer.style.maxHeight = `${maxHeightCalculated}px`;

      this.isHeightSet = true;

      this.$nextTick(() => {
        selectorContainer.style.top = `-${
          selectorContainer.getBoundingClientRect().height
        }px`;
      });
    }

    this.$nextTick(() => {
      const selectorSearch = this.$refs["selectorSearch"];
      if (selectorSearch && this.hasSearchAutoFocus) {
        (selectorSearch as InputComponent).focused();
      }

      this.setClosableState(true);
    });
  }

  pickElement(element: any, previousAttributes: string[] = []) {
    const attributes = [...(previousAttributes || [])];
    attributes.unshift(element.id);

    if (
      this.isDataModelMode &&
      (this.parentDataModelId || this.parentVariableId)
    ) {
      // if variables are defined, parentVariableId has more prioriry
      const id = !!this.processVariables.length
        ? this.parentVariableId || this.parentDataModelId
        : this.parentDataModelId || this.parentVariableId;
      attributes.unshift(id as string);
    }

    const attributeNames: string[] = [];

    attributes.forEach((attrId) => {
      let item = this.items.find((item) => item.id === attrId);
      if (!item) {
        item = findAttributeById(attrId, element.attributes);
      }
      if (item && (item.displayName || item.name)) {
        attributeNames.push(item.displayName || item.name);
      }
    });

    const fullId = attributes.join(".");
    const fullName = attributeNames.join(".");

    this.$emit("pickElement", fullId);
    this.$emit("pickElementFullData", {
      id: fullId,
      name: fullName,
    });
  }

  handleMoveFocus(target: HTMLElement, direction: FocusDirection) {
    moveFocus(target, direction);
  }

  onCreateVariableClick() {
    this.setClosableState(false);

    EventBus.$emit(Events["VARIABLE:OPEN_FORM"], null, {
      dataModelId: this.expectedDataModelId,
      isList: this.isListExpected,
      name: this.filters.search || "",
    });
  }

  onSearchEnterPress(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.searchedItems.length) {
      return;
    }

    this.pickElementProgramatically(this.searchedItems[0]);
  }

  pickElementProgramatically(element: any) {
    this.pickElement(element);
  }

  onEscPress(event: KeyboardEvent) {
    event.stopPropagation();
    this.close(event);
  }

  setExpandedState(item: any, isExpanded: boolean) {
    this.userItemsExpandedState[item.id] = isExpanded;

    this.onSearchedItemsUpdate(this.searchedItems);
  }

  setClosableState(isClosable = true) {
    setTimeout(() => (this.isClosable = isClosable), 0);
  }

  beforeDestroy() {
    this.eventBusOff();
  }

  close(event: Event | null = null) {
    this.$emit("closed", event);
  }
}
</script>
