<template>
  <div class="pup-toast">
    <pds-control-label
      v-if="label"
      :label="label"
      :tooltip="tooltip"
      :required="required"
      ignoreFormatting
    />
    <pds-message type="info" v-if="value">
      <span class="pup-toast--content" v-html="value"></span>
    </pds-message>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  ControlLabelComponent,
  MessageComponent,
} from "@procesio/procesio-design-system";
import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

@Component({
  components: {
    "pds-message": MessageComponent,
    "pds-control-label": ControlLabelComponent,
  },
})
export default class Toast extends Vue {
  @Prop() settings!: Setting;

  @Prop() label!: string;

  @Prop() tooltip!: string;

  @Prop({ default: false }) required!: string;

  @Prop() value!: string;
}
</script>

<style lang="scss">
.pup-toast {
  display: flex;
  flex-direction: column;


  &--content {
    > * {
      color: inherit;
    }
  }
}
</style>
