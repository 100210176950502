<template>
  <pds-select
    :value="value"
    @update-input="updateInput"
    :options="options"
    :tooltip="tooltip"
    :status="status"
    @blur="$emit('blur')"
    label="Verb"
    :disabled="disabled"
    :required="$attrs.required || false"
  >
    <template v-slot:singleLabel="{ selectedOption, keyName }">
      <slot
        name="singleLabel"
        v-bind:selectedOption="selectedOption"
        v-bind:keyName="keyName"
      />
    </template>

    <template v-slot:placeholder="{ placeholder }">
      <slot name="placeholder" v-bind:placeholder="placeholder" />
    </template>
  </pds-select>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";

import { PdsTypes, SelectComponent } from "@procesio/procesio-design-system";

import CredentialService from "@/services/credentialManager/CredentialManager.service";

@Component({
  components: {
    "pds-select": SelectComponent,
  },
})
export default class VerbSelectComponent extends Vue {
  @Model("update-input") value: unknown;

  @Prop({ required: true }) credentialType!: string;

  @Prop() tooltip?: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  options: Array<{ value: string; name: string }> = [];

  mounted() {
    this.loadVerbs();
  }

  async loadVerbs() {
    const response = await CredentialService.getVerb(this.credentialType);

    if (response.content)
      this.options = (response.content as any[]).map((option: any) => ({
        value: option.gid,
        name: option.value,
      }));
  }

  @Emit("update-input")
  updateInput(updatedValue: unknown) {
    return updatedValue;
  }
}
</script>
