import { DocumentMapperSetting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";

export class DocumentDesignerMapperDataParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: DocumentMapperSetting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      variable: [],
      value: setting.value?.map((val) => {
        const destinationData = this.parseControlValueToParameter(val.document);
        const sourceData = this.parseControlValueToParameter(val.process);

        const destination = {
          ...destinationData.variable[0],
        };

        const source = {
          value: sourceData.value,
          variable: sourceData.variable,
        };

        return {
          id: val.id,
          // only needed for debuging purpose
          ...(process.env.NODE_ENV === "development" && {
            slugId: setting.type,
          }),
          source,
          destination,
        };
      }),
    };

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (Array.isArray(parameter.value)) {
        parameter.value.forEach((value) => {
          if (value.source && Array.isArray(value.source.variable)) {
            variables.push(...value.source.variable);
          }

          if (value.destination) {
            variables.push(value.destination);
          }
        });
      }
    });

    return variables;
  }
}
