var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.isFullScreen)?_c('div',{ref:"genericText",staticClass:"pup-c-input-group"},[_c('pds-control-label',{attrs:{"label":_vm.label,"tooltip":_vm.tooltip,"required":_vm.required,"ignoreFormatting":_vm.ignoreLabelFormatting}}),_vm._v(" "),_c('div',{ref:"textHolder",staticClass:"pup-c-input-group--container",class:[
        _vm.statusClass ? `pup-c-input-group--container--${_vm.statusClass}` : '',
        _vm.disabled && 'pup-c-input-group--container--disabled',
      ]},[_vm._t("prepend"),_vm._v(" "),(!_vm.readonly && !_vm.disabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.addVariableTooltip,
          boundariesElement: 'window',
        }),expression:"{\n          content: addVariableTooltip,\n          boundariesElement: 'window',\n        }"}],class:[
          `pup-c-input-data-model-selector--add pds-u-input-label`,
          !_vm.isEditable ? 'pup-c-input-data-model-selector--disabled' : '',
        ],attrs:{"tabindex":"0"},on:{"mousedown":_vm.onAddVariableToggle,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;(_vm.isPlaceholderThere ? _vm.replaceVariable : _vm.handleAddVariable)()},"click":function($event){(_vm.isPlaceholderThere ? _vm.replaceVariable : _vm.handleAddVariable)()}}},[_c('pds-icon',{staticClass:"pup-c-input-data-model-selector--add--icon",attrs:{"icon":"add"}})],1):_vm._e(),_vm._v(" "),_c('div',{class:[
          'pup-c-input-group--wrapper',
          _vm.disabled
            ? 'pup-c-input-group--wrapper--disabled'
            : _vm.renderButton
            ? 'pup-c-input-group--wrapper--mini'
            : 'pup-c-input-group--wrapper--max',
          ,
          _vm.isPlaceholderVisible ? 'pup-c-input-group--placeholder' : null,
        ],attrs:{"placeholder":_vm.placeholder}},[_c('div',{staticClass:"pup-c-input-group--tooltip-boundary"}),_vm._v(" "),_vm._l((_vm.internalValue),function(valWord){return _c('span',{key:valWord.id,ref:`item-${valWord.id}`,refInFor:true,class:[
            'pup-c-item-wrapper',
            'pds-u-m--r--4',
            _vm.isItemEmpty(valWord.id) && _vm.emptyItemClass,
          ]},[(valWord.type === 'placeholder')?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.replaceVariable(valWord.id)}},[_c('pds-badge',{attrs:{"label":"...","color":"invalid"}})],1):(valWord.type === 'variable')?_c('pds-badge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                _vm.getTooltipDataType(valWord.value) +
                _vm.getTooltipNameIfTooLong(valWord.value),
              placement: 'top',
              container: 'body',
              boundariesElement: 'window',
              autoHide: false,
            }),expression:"{\n              content:\n                getTooltipDataType(valWord.value) +\n                getTooltipNameIfTooLong(valWord.value),\n              placement: 'top',\n              container: 'body',\n              boundariesElement: 'window',\n              autoHide: false,\n            }"}],attrs:{"label":_vm.parseVariableIdToName(
                valWord.value,
                false,
                _vm.processVariables,
                _vm.variableScopes
              ),"closable":!(_vm.readonly || _vm.disabled),"color":_vm.getColor(valWord.value),"ignoreDefaultCases":""},on:{"labelClick":function($event){return _vm.openVariable(valWord.value)},"removeClick":function($event){return _vm.removeVariable(valWord.id)}}}):_c('div',{ref:`input-${valWord.id}`,refInFor:true,staticClass:"pup-c-input-group--field pup-c-input-group--div-editable",attrs:{"contenteditable":!_vm.isVariableOnly && !_vm.readonly && !_vm.disabled,"type":"text","id":'div-' + valWord.id},on:{"input":function($event){return _vm.keyboardInputHandler($event, valWord.id)},"keydown":[function($event){return _vm.keyDownHandler($event, valWord.id)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}],"keyup":function($event){return _vm.keyUpHandler($event, valWord.id)},"focus":function($event){return _vm.focusChangeHandler($event, valWord.id)},"blur":_vm.blurChangeHandler,"copy":_vm.copyHandler}},[_vm._o([_vm._v(_vm._s(typeof valWord.value === 'string' ? valWord.value.replaceAll(_vm.lineBreakRegex, "") : valWord.value))],0,valWord.id)],2)],1)})],2),_vm._v(" "),(_vm.hasMultiLineContent)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.multilineContentTooltip,
          boundariesElement: 'window',
        }),expression:"{\n          content: multilineContentTooltip,\n          boundariesElement: 'window',\n        }"}],staticClass:"pup-c-input-group--multiline"},[_c('pds-icon',{attrs:{"icon":"sort","size":"tiny"}})],1):_vm._e()],2),_vm._v(" "),_c('pds-validation-message',{attrs:{"status":_vm.status,"showMessage":_vm.showValidationMessage},on:{"statusClassChanged":(e) => (_vm.statusClass = e)}}),_vm._v(" "),(_vm.isDataModelSelectorVisible)?_c('pup-data-model-selector',{class:[
        'pup-c-input-data-model-selector--acordeon',
        'pup-c-input-data-model-selector--acordeon--right',
        _vm.label && 'pup-c-input-data-model-selector--acordeon--with-label',
        _vm.label &&
          _vm.tooltip &&
          'pup-c-input-data-model-selector--acordeon--with-label--with-tooltip',
      ],attrs:{"dataModelList":_vm.dataModels,"parentId":_vm.parent && _vm.parent.parentId,"processVariables":_vm.processVariables,"parentDataModelId":_vm.parentDataModelId,"parentVariableId":_vm.parentVariableId,"disabled":!_vm.isEditable,"hideCreate":_vm.hideCreate,"direction":_vm.settings ? _vm.settings.direction : null,"expectedDataModelId":_vm.settings ? _vm.settings.dataTypeId : null,"isListExpected":_vm.settings ? _vm.settings.isList : false},on:{"pickElement":_vm.selectVariable,"closed":_vm.closeDataModelSelector}}):_vm._e()],1):_c('pup-editor',{attrs:{"parent":_vm.parent,"settings":_vm.settings,"label":_vm.label,"value":_vm.value,"processVariables":_vm.processVariables,"fullScreen":"","fullScreenTitle":_vm.fullScreenTitle,"disabled":_vm.disabled},on:{"update-input":_vm.emitInputUpdate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }