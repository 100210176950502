import RestService, { PaginatedResponse } from "../Rest.service";
import Urls from "../Rest.paths";
import { Control } from "@/modules/CredentialManager/components/Form/Controls.model";
import { store } from "@/store";
import { config } from "@/config";

export interface CredentialTypeDTO {
  gid?: string | null;
  pid?: string | null; // parent type id; eg Google Sheets has REST API id as a pid
  updatedOn: Date;
  name: string;
  properties: Control[];
  submittedBy: string;
  type?: CredentialType;
  icon?: string | null;
}

export interface CredentialPostDTO {
  gid: string;
  gtid: string; // type id
  gtpid: string; // parent type id
  name: string;
  status: boolean;
  properties: Array<{ id: string; value: any }>;
  description: string | null;
  isNew: boolean;
}

export interface CredentialItemDTO extends CredentialPostDTO {
  updatedOn: Date;
  submittedBy: string;
  tname: string;
  type?: CredentialType;
}

export interface CredentialTestResponse {
  statusCode: number;
  stateMessage: string | null;
  body: string | null;
  isSuccess: boolean;
}

export interface CredentialDTO {
  gid: string;
  gtid: string | null; // type id
  gtpid: string | null; // parent type id
  updatedOn?: Date;
  name: string;
  properties: Control[];
  isNew: boolean;
  status?: boolean;
  submittedBy?: string;
  description?: string | null;
  type?: CredentialType;
}

export enum CredentialType {
  REST_API = "REST_API",
  SMTP = "SMTP",
  FTP = "FTP",
  DB = "DB",
  CUSTOM = "CUSTOM",
  REST_API_OAUTH_PREDEFINED = "REST_API_OAUTH_PREDEFINED",
}

export interface VerbOptions {
  name: string;
  value: string;
}

export interface Option {
  description: string;
  gid: string;
  gtid: string;
  updatedOn: string;
  name: string;
  properties: POptions[];
  status: true;
  submittedBy: string;
  tname: string;
}

interface POptions {
  id: string;
  value: string;
}

export default class CredentialManager {
  static certificate = config.protocol;

  static baseUrl = config.api;

  static deleteCredential(id: string) {
    return RestService.delete({
      url: `api/credentials/${id}`,
      isAuth: true,
    });
  }

  static getCredentialTypes() {
    return RestService.get<CredentialTypeDTO[]>({
      url: Urls.API.CREDENTIALMANAGER.TYPES,
      isAuth: true,
    });
  }

  static getCredentials(pageNumber = 0, pageItemCount = 1000) {
    return RestService.get<PaginatedResponse<CredentialItemDTO>>({
      url: Urls.API.CREDENTIALMANAGER.ALL_CREDENTIALS(
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static saveCredential(credential: CredentialPostDTO) {
    const method = credential.isNew ? "post" : "put";
    return RestService[method]<{ id: string }>({
      url: Urls.API.CREDENTIALMANAGER.ADD,
      isAuth: true,
      data: credential,
    });
  }

  static testCredential(credential: CredentialPostDTO) {
    return RestService.post<CredentialTestResponse>({
      url: Urls.API.CREDENTIALMANAGER.TEST,
      isAuth: true,
      data: credential,
    });
  }

  static getById(credentialId: string) {
    return RestService.get<CredentialDTO>({
      url: `api/credentials/${credentialId}`,
      isAuth: true,
    });
  }

  static getVerb(credentialId: string) {
    return RestService.get({
      url: `api/credentials/verbs/${credentialId}`,
      isAuth: true,
    });
  }
  static getListByType(typeId: string) {
    return RestService.get<PaginatedResponse<Option>>({
      url: `api/Credentials/list/${typeId}?pageNumber=0&pageItemCount=1000`,
      isAuth: true,
    });
  }

  static getRestrictedById(id: string) {
    return RestService.get<CredentialDTO>({
      url: `api/Credentials/${id}/restricted`,
      isAuth: true,
    });
  }

  static getRestrictedListByType(typeId: string) {
    return RestService.get<PaginatedResponse<Option>>({
      url: `api/Credentials/list/${typeId}/restricted`,
      isAuth: true,
    });
  }

  static oAuthAuthorize(credentialId: string) {
    return RestService.get({
      url: `api/credentials/authorize/${credentialId}`,
      isAuth: true,
    });
  }

  static oAuthAccessToken(
    credentialId: string,
    data: { redirectUri: string; code: string; state: string | null }
  ) {
    return RestService.post<CredentialTestResponse>({
      url: `api/credentials/accessToken/${credentialId}`,
      data: data,
      isAuth: true,
    });
  }

  static upload(credentialId: string, file: File) {
    const headers = new Headers();

    headers.set("Accept", "application/json");

    headers.set("Authorization", `Bearer ${store.getters.token}`);

    const formData = new FormData();

    formData.append("package", file);

    headers.delete("Content-Type");
    delete (headers as any)["Content-Type"];

    headers.set("workspaceId", store.getters.activeWorkspaceIdStringified);

    return fetch(
      `${this.certificate}://${this.baseUrl}/api/credentials/upload/${credentialId}`,
      {
        method: "POST",
        headers,
        body: formData,
      }
    ).then((resp) => resp.json());
  }

  static uploadTestConnection(file: File) {
    const headers = new Headers();

    headers.set("Accept", "application/json");

    headers.set("Authorization", `Bearer ${store.getters.token}`);

    const formData = new FormData();

    formData.append("package", file);

    headers.delete("Content-Type");
    delete (headers as any)["Content-Type"];

    headers.set("workspaceId", store.getters.activeWorkspaceIdStringified);

    return fetch(
      `${this.certificate}://${this.baseUrl}/api/credentials/upload/test`,
      {
        method: "POST",
        headers,
        body: formData,
      }
    ).then(async (resp) => ({
      status: resp.status,
      content: await resp.json(),
    }));
  }

  static getCount() {
    return RestService.get<number>({
      url: Urls.API.CREDENTIALMANAGER.COUNT,
      isAuth: true,
    });
  }
}
