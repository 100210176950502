var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-tabs--tab"},[_c(_vm.getSettingComponent(_vm.tab.value[0].type),{key:_vm.tab.id,tag:"component",attrs:{"keyProp":_vm.tab.id,"config":{
      columns: _vm.tab.value[0].columns,
      items: _vm.tab.value[0].value,
    },"value":_vm.tab.value[0].value,"lang":_vm.tab.value[0].lang,"parent":_vm.parent,"processVariables":_vm.processVariables,"multilineEditingRowId":_vm.multilineEditingRowId,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"actions-per-row",fn:function(props){return [_c('div',{staticClass:"pup-c-tabs--tab--actions"},[_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.disabled
              ? ''
              : props.row.id === _vm.multilineEditingRowId
              ? 'Activate for single line editing'
              : 'Activate for multiline editing',
          }),expression:"{\n            content: disabled\n              ? ''\n              : props.row.id === multilineEditingRowId\n              ? 'Activate for single line editing'\n              : 'Activate for multiline editing',\n          }"}],class:[
            'pds-u-m--r--8 pds-u-m--l--8',
            'pup-c-tabs--tab--editor',
            props.row.id === _vm.multilineEditingRowId &&
              'pup-c-tabs--tab--editor--active',
          ],attrs:{"icon":"icon-arrow-expand"},on:{"click":function($event){return _vm.toggleEditor(props.row)}}}),_vm._v(" "),(!_vm.disabled)?_c('pds-icon',{staticClass:"pup-c-tabs--tab--delete",attrs:{"icon":"clear"},on:{"click":function($event){return _vm.deleteRow(props.index)}}}):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }