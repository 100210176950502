import { IntervalFilterValue } from "@/services/resources/Resource.model";

export const msToSec = (ms: number) => ms / 1000;

export const msToHours = (ms: number) => msToSec(ms) / 3600;

export const msToDuration = (ms: number) => {
  const milliseconds = ms % 1000;
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  const seconds = Math.floor(((ms % 360000) % 60000) / 1000);

  let duration =
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds);

  if (milliseconds > 0) {
    const formattedMs = Math.round(milliseconds / 10);
    duration += "." + (formattedMs < 10 ? "0" : "") + formattedMs;
  }

  return duration;
};

export const getIntervalFilterValue = (
  date: Date | null = null
): IntervalFilterValue => {
  if (!date) {
    date = new Date();
  }
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  end.setHours(23, 59, 59); // end of the last day

  return {
    startDate: start,
    endDate: end,
  };
};
