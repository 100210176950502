<template>
  <div class="pup-c-column-d--column pds-u-m--t--16 pds-u-m--r--16">
    <div class="pup-c-column-d--item">
      <pds-input
        placeholder="Column name"
        :value="value.columnName"
        @update-input="updateColumnName"
        :disabled="disabled"
      />
    </div>
    <div class="pup-c-column-d--item pds-u-m--l--8">
      <generic-text
        :settings="settings"
        :parent="parent"
        :isProperty="true"
        placeholder="Map data"
        :value="value.attribute"
        @update-input="updateAttribute"
        :parentVariableId="storeDataAs.value"
        :disabled="disabled"
        oneVariableOnly
        :fullScreenTitle="parent.name + ' | Map data'"
      />
    </div>
    <div
      class="pup-c-column-d--remove pds-u-m--l--16"
      @click="removeItem"
      v-if="!disabled"
    >
      <pds-icon icon="delete" :isOutlined="true" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Component, Emit, Model } from "vue-property-decorator";

import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import {
  IconComponent,
  ButtonComponent,
  InputComponent,
} from "@procesio/procesio-design-system";

import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

@Component({
  components: {
    "pds-icon": IconComponent,
    "generic-text": GenericText,
    "pds-button": ButtonComponent,
    "pds-input": InputComponent,
  },
})
export default class Column extends Vue {
  @Prop() index!: number;

  @Prop() settings!: Setting;

  @Prop() storeDataAs!: ProcessVariable;

  @Prop() parent!: Node;

  @Model("column-value") value!: {
    columnName: string;
    attribute: null | string;
    id: string;
  };

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Emit("remove-item")
  removeItem() {
    return this.index;
  }

  @Emit("column-value")
  updateColumnName(value: string) {
    const currentValue = { ...this.value, columnName: value };

    return currentValue;
  }

  @Emit("column-value")
  updateAttribute(value: string) {
    const currentValue = { ...this.value, attribute: value };

    return currentValue;
  }
}
</script>
<style scoped lang="scss">
@import "./ColumnDefinition.component.scss";
</style>
