export enum Platform {
  UNKNOWN = "UNKNOWN",
  WINDOWS = "WINDOWS",
  MAC = "MAC",
}

export const getPlatform = () => {
  if (navigator.platform) {
    if (navigator.platform.startsWith("Mac")) {
      return Platform.MAC;
    } else if (navigator.platform === "Win32") {
      return Platform.WINDOWS;
    }
  }
  return Platform.UNKNOWN;
};
