export const isValidURL = (urlString: string) => {
  if (typeof urlString !== "string" || urlString.length === 0) {
    return false;
  }

  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const isUrlAvailable = async (urlString: string) => {
  return new Promise((resolve) => {
    if (!isValidURL(urlString)) {
      resolve(false);
      return;
    }

    const xhr = new XMLHttpRequest();

    xhr.open("GET", urlString);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.onload = () => resolve(xhr.status === 200);
    // 0 to catch CORS error
    xhr.onerror = () => resolve([0, 200].includes(xhr.status));

    try {
      xhr.send();
    } 
    // catch CORS error
    catch {
      resolve(true);
    }
  });
}