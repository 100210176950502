<template>
  <div id="app" @dragover.prevent @drop.prevent>
    <ProgressBarComponent class="app--progress-bar" />
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import "@procesio/procesio-design-system/dist/procesioui.css";
import "@procesio/procesio-ui-builder/dist/uibuilder.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "@/components/Tooltip/Tooltip.component.scss";
import ProgressBarComponent from "@/components/ProgressBar/ProgressBar.component.vue";
import AuthService from "./services/auth/Auth.service";

Vue.use(VueToast, {
  position: "top-right",
  duration: 5000,
});

import VTooltip from "v-tooltip";
import VueRouter from "vue-router";
Vue.use(VTooltip, {
  defaultTrigger: "hover focus click",
});

import { Userpilot } from "userpilot";
import { mapGetters } from "vuex";
import { RouteName } from "./router";

interface PrivateRouter extends VueRouter {
  history: {
    pending?: {
      fullPath: string;
    };
  };
}

@Component({
  components: {
    ProgressBarComponent,
  },
  computed: {
    ...mapGetters({
      token: "token",
      tokenExpiresIn: "tokenExpiresIn",
    }),
  },
})
export default class App extends Vue {
  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    Userpilot.reload();
    console.log("User Pilot Reloaded");
  }

  mounted() {
    let currentPath = this.$route.fullPath;

    if (currentPath.includes("/#/")) {
      currentPath = currentPath.replace("/#/", "/");
      this.$router.push(currentPath);
    }

    //history is private api, so it doesn't appear on VueRouter
    const path =
      (this.$router as PrivateRouter).history.pending?.fullPath ||
      this.$route.fullPath;

    if (path === "/" && !!this.token) {
      AuthService.refreshToken().then((res) => {
        if (res.content.access_token) {
          this.$router.push({ name: RouteName.DASHBOARD });
        }
      });
    }
  }
}

declare module "vue/types/vue" {
  // 3. Declare augmentation for Vue
  interface Vue {
    $toast: Record<string, any>;
  }
}
</script>

<style lang="scss">
.v-notices {
  padding-top: 104px;
  padding-right: 24px;
  z-index: 10000001;
}

html,
body {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100%;

  #app {
    overflow: auto;
    height: 100vh;
  }

  .sandbox {
    position: fixed;
  }

  .app {
    &--progress-bar {
      z-index: 200;
      position: fixed;
      width: 100%;
    }
  }
}

* {
  // Chrome
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-clip: padding-box;
    border-radius: 100px;
    background-color: #c1c8d6;
  }

  ::-webkit-scrollbar-track {
    background-color: #e3e8f3;
    background-clip: padding-box;
    border: 1px solid transparent;
    background-clip: padding-box;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c1c8d6;
  }

  // Other browsers except Chrome
  @supports #{"\not selector(*::-webkit-scrollbar)"} {
    scrollbar-color: #c1c8d6 #e3e8f3;
    scrollbar-width: thin;
  }

  .pup {
    &-c {
      &-breadcrumbs {
        display: flex;
        font-family: Lato;
        font-style: normal;
        font-size: 20px;
        line-height: 32px;
        color: var(--c-neutral--500);

        .pds-c-link {
          &,
          &:hover {
            color: var(--c-neutral--500);
          }
        }

        &--active {
          color: #141a32;
        }

        @media only screen and (max-width: 768px) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
