var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-input-group--holder"},[(_vm.tooltip)?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.tooltip,
    }),expression:"{\n      content: tooltip,\n    }"}],staticClass:"material-icons--grey pds-u-m--r--8",attrs:{"icon":"info","isOutlined":true}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pup-c-tinput-old--container"},[_c('table',{staticClass:"pup-c-tinput-old"},[_c('thead',{staticClass:"pup-c-tinput-old--header"},[_c('tr',[_vm._l((_vm.config.columns),function(columnHead){return _c('th',{key:columnHead.key,staticClass:"pup-c-tinput-old--head pds-u-overline"},[_vm._v("\n            "+_vm._s(columnHead.label)+"\n          ")])}),_vm._v(" "),_c('th',{staticClass:"pup-c-tinput-old--head pds-u-overline"})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(row,index){return _c('tr',{key:index,class:[
            'pup-c-tinput-old--row',
            _vm.disabled && 'pup-c-tinput-old--row--disabled',
          ]},[_vm._l((_vm.config.columns),function(columnHead,key){return _c('td',{key:key,staticClass:"pup-c-tinput-old--cell pds-u-body--2"},[(columnHead.component)?[(
                  row.typeRef &&
                    row.typeRef.id ===
                      '10c6ac59-3929-49e6-99dc-121212121219' &&
                    key === 1
                )?_c('pup-upload-control',{key:row.id,staticClass:"pup-c-tinput-old--element",attrs:{"disabled":_vm.disabled},model:{value:(row[columnHead.key]),callback:function ($$v) {_vm.$set(row, columnHead.key, $$v)},expression:"row[columnHead.key]"}}):_c(_vm.getComponent(columnHead, row),{key:row.id,tag:"component",staticClass:"pup-c-tinput-old--element",attrs:{"type":_vm.getComponentType(row),"keyProp":row.id,"placeholder":columnHead.placeholder,"isTextOnBlur":true,"parent":_vm.parent,"dataType":columnHead.component === 'pup-data-type-badge' ? row : '',"showLabel":false,"processVariables":_vm.processVariables,"autoOpen":true,"disabled":_vm.disabled},on:{"blur":_vm.handleBlurDuplicates},model:{value:(row[columnHead.key]),callback:function ($$v) {_vm.$set(row, columnHead.key, $$v)},expression:"row[columnHead.key]"}})]:[_c('span',{attrs:{"title":row[columnHead.key]}},[_vm._v(_vm._s(row[columnHead.key]))])]],2)}),_vm._v(" "),_c('td',{staticClass:"pup-c-tinput-old--cell"},[_vm._t("actions-per-row",null,{"row":row,"index":index})],2)],2)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }