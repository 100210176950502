import {
  ColumnDefinitionSetting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  AttributeParameter,
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";

export class ColumnDefinitionParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: ColumnDefinitionSetting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      value: [] as {
        columnName: string;
        attribute: AttributeParameter | null;
      }[],
      variable: this.parseControlValueToParameter(
        setting ? (setting as ColumnDefinitionSetting).value?.variable : ""
      ).variable,
    };

    if (!(setting.value && setting.value.rows)) {
      return parameter;
    }

    parameter.value = setting.value.rows.reduce(
      (
        rows: {
          columnName: string;
          attribute: AttributeParameter | null;
        }[],
        row: { id: string; columnName: string; attribute: string | null }
      ) => {
        const rowData = this.parseControlValueToParameter(
          row.attribute as string
        );

        rows.push({
          columnName: row.columnName,
          attribute:
            rowData.variable && rowData.variable.length
              ? rowData.variable[0].attribute
              : null,
        });
        return rows;
      },
      []
    );

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (!parameter.variable) {
        return;
      }

      if (Array.isArray(parameter.variable)) {
        variables.push(...parameter.variable);
      } else {
        variables.push(parameter.variable);
      }
    });

    return variables;
  }
}
