import { UserRole } from "@/services/administration/userManagement/UserManagement.model";
import RestService from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import { store } from "@/store";
import { config } from "@/config";
import { guidRegexRaw } from "@/utils/type/guid";

export const DefaultWorkspaceKey = "Procesio01";

export interface UserWorkspace {
  id: string | null;
  parentId: string | null;
  workspace: string; // name
  type: UserWorkspaceType;
  hasAccess: boolean;
  // roles: UserRole[];
}

export enum UserWorkspaceType {
  PERSONAL = "Personal",
  MASTER = "Master",
  SUBWORKSPACE = "SubWorkspace",
  ORGANIZATION = "Organizational", // backend said this type is not used on prod
}

export const PROCESIO_WORKSPACE_ID = "e806c3b3-e631-4072-a54d-f8a76117fb43";

export default class UserWorkspaceService {
  static baseUrl = config.api;

  static async getWorkspaceData() {
    const workspaces = await this.getUserWorkspaces();

    const activeWorkspaceId = store.getters.activeWorkspaceId;
    let activeWorkspace: UserWorkspace | undefined | null = workspaces.find(
      (workspace: UserWorkspace) => workspace.id === activeWorkspaceId
    );

    if (!activeWorkspace) {
      const personalWorkspace = workspaces.find(
        (workspace: UserWorkspace) => !workspace.id
      );
      if (personalWorkspace) {
        activeWorkspace = personalWorkspace;
      } else {
        activeWorkspace = null;
      }
    }

    return {
      workspaces,
      activeWorkspace,
    };
  }

  static async getUserWorkspaces() {
    const response = await this.loadWorkspaceList();

    if (!response.content || response.is_error) {
      return [];
    }

    const data = [...response.content];

    data.forEach((workspace: UserWorkspace) => {
      // AppSumo support; see PRC-3288
      workspace.workspace = workspace.workspace?.replace(
        new RegExp("AppSumo\\s" + guidRegexRaw),
        "AppSumo Subscription"
      );

      // New master workspace create support; see PRC-3501
      workspace.workspace = workspace.workspace?.replace(
        new RegExp(guidRegexRaw),
        ""
      );
    });

    return data;
  }

  static loadWorkspaceList() {
    return RestService.get<UserWorkspace[]>({
      url: RestUrls.API.ADMINISTRATION.WORKSPACE.LIST,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }
}
