import {
  ProcesioEntityType,
  RoleType,
  UserTypeName,
} from "@/services/user/permissions/UserPermissions.model";
import { UserPermissionsState } from "./UserPermissions.store";

export default {
  procesioEntities: (state: UserPermissionsState) => state.procesioEntities,
  roles: (state: UserPermissionsState) => state.roles,
  userTypes: (state: UserPermissionsState) => state.userTypes,
  userPermissions: (state: UserPermissionsState, getters: any) =>
    state.userPermissionsPerWorkspace[getters.activeWorkspaceIdStringified],
  userTypeId: (state: UserPermissionsState, getters: any) =>
    getters.userPermissions?.userTypeId || null,
  userRolesPerEntity: (state: UserPermissionsState, getters: any) =>
    getters.userPermissions?.roles || null,
  getProcesEntityById: (state: UserPermissionsState) => (id: string) =>
    state.procesioEntities.find((entity) => entity.entityId === id),
  getProcesEntityByType:
    (state: UserPermissionsState) => (type: ProcesioEntityType) =>
      state.procesioEntities.find((entity) => entity.type === type),
  getRoleById: (state: UserPermissionsState) => (id: string) =>
    state.roles.find((role) => role.roleId === id),
  getRoleByType: (state: UserPermissionsState) => (type: RoleType) =>
    state.roles.find((role) => role.type === type),
  getUserTypeById: (state: UserPermissionsState) => (id: string) =>
    state.userTypes.find((type) => type.userTypeId === id),
  getUserTypeByName: (state: UserPermissionsState) => (name: UserTypeName) =>
    state.userTypes.find((type) => type.name === name),
};
