<template>
  <div class="pup-c-input-group--holder">
    <pds-icon
      v-if="tooltip"
      class="material-icons--grey pds-u-m--r--8"
      icon="info"
      :isOutlined="true"
      v-tooltip="{
        content: tooltip,
      }"
    />
    <div class="pup-c-tinput-old--container">
      <table class="pup-c-tinput-old">
        <thead class="pup-c-tinput-old--header">
          <tr>
            <th
              class="pup-c-tinput-old--head pds-u-overline"
              v-for="columnHead in config.columns"
              :key="columnHead.key"
            >
              {{ columnHead.label }}
            </th>
            <th class="pup-c-tinput-old--head pds-u-overline"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="[
              'pup-c-tinput-old--row',
              disabled && 'pup-c-tinput-old--row--disabled',
            ]"
            v-for="(row, index) in items"
            :key="index"
          >
            <td
              class="pup-c-tinput-old--cell pds-u-body--2"
              v-for="(columnHead, key) in config.columns"
              :key="key"
            >
              <template v-if="columnHead.component">
                <pup-upload-control
                  v-if="
                    row.typeRef &&
                      row.typeRef.id ===
                        '10c6ac59-3929-49e6-99dc-121212121219' &&
                      key === 1
                  "
                  class="pup-c-tinput-old--element"
                  :key="row.id"
                  :disabled="disabled"
                  v-model="row[columnHead.key]"
                ></pup-upload-control>

                <component
                  v-else
                  class="pup-c-tinput-old--element"
                  :is="getComponent(columnHead, row)"
                  :type="getComponentType(row)"
                  :key="row.id"
                  :keyProp="row.id"
                  v-model="row[columnHead.key]"
                  :placeholder="columnHead.placeholder"
                  :isTextOnBlur="true"
                  :parent="parent"
                  :dataType="
                    columnHead.component === 'pup-data-type-badge' ? row : ''
                  "
                  :showLabel="false"
                  :processVariables="processVariables"
                  :autoOpen="true"
                  :disabled="disabled"
                  @blur="handleBlurDuplicates"
                />
              </template>

              <template v-else>
                <span :title="row[columnHead.key]">{{
                  row[columnHead.key]
                }}</span>
              </template>
            </td>

            <td class="pup-c-tinput-old--cell">
              <slot
                name="actions-per-row"
                v-bind:row="row"
                :index="index"
              ></slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
/** VUE RELATED */
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

/** DESIGN SYSTEM */
import {
  ButtonComponent,
  InputComponent,
  IconComponent,
} from "@procesio/procesio-design-system";

import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import UploadComponent from "@/modules/ProcessDesigner/components/Controls/Upload/Upload.component.vue";
import { Node } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { createGuid } from "@/utils/type/guid";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import ModalEditor from "@/modules/ProcessDesigner/components/CodeEditors/ModalEditor/ModalEditor.component.vue";
import DataTypeBadgeComponent from "@/modules/ProcessDesigner/components/Badge/DataTypeBadge/DataTypeBadge.component.vue";
import { isCustomTypeAllowed } from "@/utils/dataTypeMapper";
import {
  DataModel,
  isCustomDataModel,
} from "@/services/datamodel/DataModel.model";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-input": InputComponent,
    "pds-input-select": GenericText,
    "pup-data-type-badge": DataTypeBadgeComponent,
    "pup-upload-control": UploadComponent,
    "pds-icon": IconComponent,
    "pup-modal-editor": ModalEditor,
  },
})
export default class TInputOld extends Vue {
  @Prop() config!: any;

  @Prop() tooltip?: string;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: null }) multilineEditingRowId!: string | null;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  get items() {
    const items = this.config.items;
    const columns = this.config.columns;

    if (items.length) {
      if (
        items[items.length - 1].value != "" ||
        items[items.length - 1].key != ""
      ) {
        if (columns.length <= 2) {
          items.push({
            key: "",
            value: "",
            id: createGuid(),
          });
        }
      }
    } else {
      items.push({
        key: "",
        value: "",
        id: createGuid(),
      });
    }

    return items;
  }

  getComponent(columnHead: { component: string }, row: { id: string }) {
    // if generic text
    if (columnHead.component === "pds-input-select") {
      return row.id === this.multilineEditingRowId
        ? "pup-modal-editor"
        : "pds-input-select";
    }

    return columnHead.component;
  }

  getComponentType({ typeRef }: { typeRef: DataModel }) {
    return typeRef &&
      (isCustomDataModel(typeRef) || isCustomTypeAllowed(typeRef.id))
      ? "textarea"
      : "text";
  }

  handleBlurDuplicates() {
    console.log("we are bluring");

    const items = this.config.items;

    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        const sameItem = items.find(
          (it: { key: string; value: string; id: string }) =>
            it.key === items[i].key && it.key != "" && it.id !== items[i].id
        );

        if (sameItem) {
          items.splice(i, 1);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./TInput.component.scss";
</style>
