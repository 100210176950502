import Vue from "vue";

export const EventBus = new Vue();

enum ProcessEvents {
  "PROCESS:VALIDATE" = "PROCESS:VALIDATE",
  "PROCESS:SAVE" = "PROCESS:SAVE",
  "PROCESS:RUN" = "PROCESS:RUN",
  "PROCESS:CANCEL_RUN" = "PROCESS:CANCEL_RUN",
  "PROCESS:LAUNCH" = "PROCESS:LAUNCH",
  "PROCESS:TOGGLE_ACTIVE_STATE" = "PROCESS:TOGGLE_ACTIVE_STATE",
  "PROCESS:TOGGLE_SIDE_TABS_INDEX" = "PROCESS:TOGGLE_SIDE_TABS_INDEX",
  "PROCESS:OPEN_PROPERTIES_PANEL" = "PROCESS:OPEN_PROPERTIES_PANEL",
  "PROCESS:DECISIONAL_CASE_SELECT" = "PROCESS:DECISIONAL_CASE_SELECT",
}

enum VariableEvents {
  "VARIABLE:OPEN_PANEL" = "VARIABLE:OPEN_PANEL",
  "VARIABLE:CLOSE_PANEL" = "VARIABLE:CLOSE_PANEL",
  "VARIABLE:OPEN_FORM" = "VARIABLE:OPEN_FORM",
  "VARIABLE:CLOSE_FORM" = "VARIABLE:CLOSE_FORM",
  "VARIABLE:REMOVE" = "VARIABLE:REMOVE",
}

enum DocumentEvents {
  "DOCUMENT:SAVE" = "DOCUMENT:SAVE",
}

enum TopbarEvents {
  "TOPBAR:TOGGLE_FOCUS" = "TOPBAR:TOGGLE_FOCUS",
}

enum InputEvents {
  "INPUT:FULLSCREEN_TOGGLE" = "INPUT:FULLSCREEN_TOGGLE",
}

enum TestActionEvents {
  "TEST_ACTION:OPEN_IN_SIDE_PANEL" = "TEST_ACTION:OPEN_IN_SIDE_PANEL",
}

enum TransportEvents {
  "TRANSPORT:IMPORT_COMPLETED" = "TRANSPORT:IMPORT_COMPLETED",
}

enum PreferenceEvents {
  "PREFERENCES:OPEN_HOTKEYS" = "PREFERENCES:OPEN_HOTKEYS",
}

enum BuyResourceEvents {
  "BUY_RESOURCE:OPEN_MODAL" = "BUY_RESOURCE:OPEN_MODAL",
}

enum FormBuilderEvents {
  "FORM_BUILDER:SET_MODE" = "FORM_BUILDER:SET_MODE",
  "FORM_BUILDER:SAVE" = "FORM_BUILDER:SAVE",
  "FORM_BUILDER:PUBLISH" = "FORM_BUILDER:PUBLISH",
  "FORM_BUILDER:SHARE" = "FORM_BUILDER:SHARE",
  "FORM_BUILDER:OPEN_FORM" = "FORM_BUILDER:OPEN_FORM",
  "FORM_BUILDER:FOCUS_FORM_NAME" = "FORM_BUILDER:FOCUS_FORM_NAME",
}

export const Events = {
  ...ProcessEvents,
  ...VariableEvents,
  ...DocumentEvents,
  ...TopbarEvents,
  ...InputEvents,
  ...TestActionEvents,
  ...TransportEvents,
  ...PreferenceEvents,
  ...BuyResourceEvents,
  ...FormBuilderEvents,
};
