<template>
  <div class="pup-c-variable-mapper--row pds-u-m--t--16 pds-u-m--r--8">
    <div
      :class="[
        'pup-c-variable-mapper--item pup-c-variable-mapper--item--left pds-u-m--l--8 pds-u-m--r--8',
        disabled && 'pup-c-variable-mapper--item--disabled',
      ]"
    >
      <generic-text
        :settings="settings"
        :isProperty="true"
        :placeholder="leftInputPlaceholder"
        v-model="value.left"
        :processVariables="leftColumnVariables"
        :variableScopes="leftColumnVariableScopes"
        :parent="parent"
        :hideCreate="hideCreateLeftInput"
        :oneVariableOnly="isLeftInputOneVariableOnly"
        :disabled="disabled"
        :fullScreenTitle="
          parent && settings ? parent.name + ' | ' + settings.label : ''
        "
      />
    </div>

    <pds-icon
      class="pup-c-variable-mapper--icon pds-u-m--r--8"
      :icon="direction === directions.RIGHT ? 'arrow_forward' : 'arrow_back'"
    />

    <div
      :class="[
        'pup-c-variable-mapper--item',
        disabled && 'pup-c-variable-mapper--item--disabled',
      ]"
    >
      <generic-text
        :settings="settings"
        :isProperty="true"
        :placeholder="rightInputPlaceholder"
        v-model="value.right"
        :processVariables="rightColumnVariables"
        :variableScopes="rightColumnVariableScopes"
        :parent="parent"
        :hideCreate="hideCreateRightInput"
        :oneVariableOnly="isRightInputOneVariableOnly"
        :disabled="disabled"
        :fullScreenTitle="
          parent && settings ? parent.name + ' | ' + settings.label : ''
        "
      />
    </div>
    <div
      class="pup-c-variable-mapper--remove pds-u-m--l--16"
      @click="removeItem"
      v-if="!disabled"
    >
      <pds-icon icon="delete" :isOutlined="true" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Component, Emit } from "vue-property-decorator";
import {
  Setting,
  SettingType,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  IconComponent,
  ButtonComponent,
  InputComponent,
} from "@procesio/procesio-design-system";
import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import { Variable } from "@/services/crud/Orchestration.service";
import { VariableMapperValue } from "./Group.component.vue";

export enum VariableMapperDirection {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

@Component({
  components: {
    "pds-icon": IconComponent,
    "generic-text": GenericText,
    "pds-button": ButtonComponent,
    "pds-input": InputComponent,
  },
})
export default class VariableMapperRowComponent extends Vue {
  @Prop() value!: VariableMapperValue;

  @Prop() parent!: Node;

  @Prop() index!: number;

  @Prop() settings!: Setting;

  @Prop({ default: "" }) leftInputPlaceholder!: string;

  @Prop({ default: "" }) rightInputPlaceholder!: string;

  @Prop({ default: () => [] }) leftColumnVariables!: Variable[];

  @Prop({ default: () => [] }) rightColumnVariables!: Variable[];

  @Prop({ default: () => [] }) leftColumnVariableScopes!: string[] | null;

  @Prop({ default: () => [] }) rightColumnVariableScopes!: string[] | null;

  @Prop({ default: false }) isLeftInputOneVariableOnly!: boolean;

  @Prop({ default: false }) isRightInputOneVariableOnly!: boolean;

  @Prop({ default: false }) hideCreateLeftInput!: boolean;

  @Prop({ default: false }) hideCreateRightInput!: boolean;

  @Prop({ default: VariableMapperDirection.LEFT })
  direction!: VariableMapperDirection;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  settingType = SettingType;

  directions = VariableMapperDirection;

  @Emit("remove-item")
  removeItem() {
    return this.index;
  }
}
</script>
<style scoped lang="scss">
@import "./VariableMapper.component.scss";
</style>
