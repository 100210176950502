import Vue from "vue";
import Component from "vue-class-component";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";

@Component
export class Permissionable extends Vue {
  ProcesioEntityTypes = ProcesioEntityType;

  RoleTypes = RoleType;

  $permitted(entityType: ProcesioEntityType, roleType: RoleType) {
    return UserPermissionsGuard.isPermitted(entityType, roleType);
  }
}
