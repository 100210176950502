<template>
  <div class="pup-c-adder">
    <pds-button
      class="pup-c-adder--button"
      icon="add"
      type="outlined"
      color="subtle"
      size="small"
      :isIconOutlined="false"
      @click="addCondition"
      >{{ language.condition_builder_adder_left }}</pds-button
    >
    <template v-if="level < 3">
      <p class="pds-u-body--2">or</p>
      <pds-button
        class="pup-c-adder--button"
        icon="add"
        type="outlined"
        color="subtle"
        size="small"
        :isIconOutlined="false"
        @click="addGroup"
        >{{ language.condition_builder_adder_right }}</pds-button
      >
    </template>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { ButtonComponent } from "@procesio/procesio-design-system";

import Language from "@/utils/locale/en.json";

@Component({
  components: {
    "pds-button": ButtonComponent,
  },
})
export default class AddRow extends Vue {
  @Prop() level!: number;

  language = Language;

  @Emit("add-condition")
  addCondition(e: MouseEvent) {
    return e;
  }

  @Emit("add-group")
  addGroup(e: MouseEvent) {
    return e;
  }
}
</script>

<style lang="scss">
@import "./AddRow.component.scss";
</style>
