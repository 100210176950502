import {
  Setting,
  SettingTab,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { Parameter } from "@/services/crud/Orchestration.service";
import { ControlParameterGetterFactory } from "./ControParameterGetter/ControlParameterGetterFactory";
import { ParameterVariableCounter } from "./ParameterVariableCounter";

export const getParametersFromSettingTabs = (
  settingTabs: SettingTab[]
): Parameter[] => {
  const variableCounter = new ParameterVariableCounter();

  const getSettingParameter = (setting: Setting): Parameter | Parameter[] => {
    const parameterGetter = ControlParameterGetterFactory.getParameterGetter(
      setting.type,
      variableCounter
    );
    return parameterGetter.getParameter(setting);
  };

  const parameters: Parameter[] = [];
  const pushToParameters = (param: Parameter | Parameter[]) => {
    parameters.push(...(Array.isArray(param) ? param : [param]));
  };

  settingTabs.forEach((tab) => {
    tab.settings.forEach((setting: Setting) => {
      const settingValues = Array.isArray(setting.value)
        ? setting.value
        : [setting.value];

      settingValues.forEach((value) => {
        if (value && typeof value.type !== "undefined") {
          pushToParameters(getSettingParameter(value));
        } else {
          pushToParameters(getSettingParameter(setting));
        }
      });
    });
  });

  return parameters;
};
