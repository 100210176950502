<template>
  <pds-select
    :value="value"
    @update-input="$emit('update-input', $event)"
    @blur="$emit('blur')"
    :options="options"
    :label="label"
    :required="true"
    :tooltip="tooltip"
    :status="status"
    :disabled="disabled"
    searchable
    searchPlaceholder="Search by name"
  >
    <template v-slot:singleLabel="{ selectedOption, keyName }">
      <slot
        name="singleLabel"
        v-bind:selectedOption="selectedOption"
        v-bind:keyName="keyName"
      />
    </template>

    <template v-slot:placeholder="{ placeholder }">
      <slot name="placeholder" v-bind:placeholder="placeholder" />
    </template>
  </pds-select>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model, Watch } from "vue-property-decorator";
import { PdsTypes, SelectComponent } from "@procesio/procesio-design-system";
import TemplateDesignerService from "@/services/templateDesigner/TemplateDesigner.service";
import { ActionTypes as ProcessActionTypes } from "@/store/process/Process.actions";
import {
  GenericSetting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  DOCUMENT_MAPPER_SETTING,
  DOCUMENT_MAPPER_SIDE_PANEL_SETTING,
} from "../../PropertiesPanel/Utils/Settings";
import { getEmptyRow } from "./DocumentMapper.model";
import { Variable } from "@/services/crud/Orchestration.service";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";
import { PaginatedResponse } from "@/services/Rest.service";
import { TemplateDesignerItem } from "@/services/templateDesigner/TemplateDesigner.model";
import { mixins } from "vue-class-component";
import { Permissionable } from "@/mixins/Permissionable";

@Component({
  components: {
    "pds-select": SelectComponent,
  },
})
export default class DocumentSelectComponent extends mixins(Permissionable) {
  @Model("update-input") value: unknown;

  @Prop() label: string | undefined;

  @Prop() tooltip?: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() parent!: Node;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  options: Array<{ value: string; name: string }> = [];

  @Watch("value", { immediate: true })
  async onValueUpdate(id: string | null, oldId: string | undefined) {
    if (id && this.parent && this.parent.id) {
      const response = await TemplateDesignerService[
        this.$permitted(
          this.ProcesioEntityTypes.DocumentDesigner,
          this.RoleTypes.Read
        )
          ? "getDocumentTemplate"
          : "getRestrictedDocumentTemplate"
      ](id);
      if (response.content) {
        const document = response.content;

        await this.$store.dispatch(
          ProcessActionTypes.SET_EXTRA_VARIABLES_BY_KEY,
          {
            nodeId: this.parent.id,
            variables: document.variables.map((variable) => ({
              ...variable,
              scopesId: [document.id],
            })),
          }
        );
        if (typeof oldId !== "undefined" && id !== oldId) {
          this.reinitMapping(document.variables);
        }
      }
    }
  }

  async mounted() {
    const response = await TemplateDesignerService[
      this.$permitted(
        this.ProcesioEntityTypes.DocumentDesigner,
        this.RoleTypes.Read
      )
        ? "getList"
        : "getRestrictedList"
    ]();

    if (!response.content) {
      return;
    }

    this.options = response.content.pageItems.map((option) => ({
      value: option.id,
      name: option.name,
    }));
  }

  reinitMapping(variables: Variable[]) {
    if (!this.parent) {
      return;
    }

    const nodeConfig = this.parent.configuration;

    // reset mapped document variables when document id is changed
    const setting = [
      ...nodeConfig[0].settings,
      ...(nodeConfig[0].settings.find(
        (s) => s.id === DOCUMENT_MAPPER_SIDE_PANEL_SETTING
      )?.value || []),
    ].find((s) => s.id === DOCUMENT_MAPPER_SETTING) as GenericSetting;

    if (setting) {
      setting.value = variables.map((v, index) =>
        getEmptyRow(index, v.id, v.defaultValue)
      );
    }
  }
}
</script>
