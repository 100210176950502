export class ParameterVariableCounter {
  value = 0;

  getValue() {
    return this.value;
  }

  increment() {
    return ++this.value;
  }
}
