import {
  NavigationGuardNext,
  Route,
  RouteConfig,
  RouteRecord,
} from "vue-router";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";
import router, { RouteName } from ".";
import { store } from "@/store";
import {
  UserWorkspace,
  UserWorkspaceType,
} from "@/services/user/workspace/UserWorkspace.service";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";

export const routeMiddlewate = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const workpaceCheckoutRoutes = [
    RouteName.PROCESS_DESIGNER,
    RouteName.PROCESS_DETAILS,
    RouteName.FORM_PRIVATE,
    RouteName.FORM_PUBLIC,
    RouteName.FORM_ASSIGNMENT_REVIEW,
  ];

  const doNotResetHashRoutes = [RouteName.FORM_PRIVATE, RouteName.FORM_PUBLIC];

  if (
    workpaceCheckoutRoutes.includes(to.name as RouteName) &&
    store.getters.isAuthenticated
  ) {
    const workspaceHash = (to.hash || "").split("?")[0].replace("#", "");

    // switch to workspace from hash
    if (workspaceHash !== store.getters.activeWorkspaceId) {
      const targetWorkspaceId =
        !workspaceHash && doNotResetHashRoutes.includes(to.name as RouteName)
          ? null
          : workspaceHash;

      const workspace = store.getters.workspaces.find(
        (ws: UserWorkspace) => ws.id === targetWorkspaceId
      );

      if (workspace && workspace.type !== UserWorkspaceType.MASTER) {
        await store.dispatch(
          UserActionTypes.SET_ACTIVE_WORKSPACE_PERSIST,
          workspace
        );
      }
    }

    // set hash to url
    const workspaceId = store.getters.activeWorkspaceIdStringified;
    if (
      !doNotResetHashRoutes.includes(to.name as RouteName) &&
      workspaceHash !== workspaceId
    ) {
      router.replace({
        path: to.path,
        params: to.params,
        query: to.query,
        hash: "#" + workspaceId,
      });
      return;
    }
  }

  const isRoutePermitted = routePermissionGuard(to);

  isRoutePermitted ? next() : next({ name: RouteName.DASHBOARD });
};

export const routePermissionGuard = (to: Route) => {
  let isRolePermitted = true;
  let isWorkspacePermitted = true;

  to.matched.forEach((routeRecord: RouteRecord) => {
    const meta = routeRecord.meta;
    if (meta.entityType && meta.roleType && isRolePermitted) {
      isRolePermitted = UserPermissionsGuard.isPermitted(
        meta.entityType,
        typeof meta.roleType === "function" ? meta.roleType(to) : meta.roleType
      );
    }

    if (
      isWorkspacePermitted &&
      Array.isArray(meta.workspaceTypes) &&
      !!meta.workspaceTypes.length &&
      !meta.workspaceTypes.includes(store.getters.activeWorkspace?.type)
    ) {
      isWorkspacePermitted = false;
    }
  });

  return isRolePermitted && isWorkspacePermitted;
};
