export interface DocumentMapperValue {
  id: number;
  document: string; // left
  process: string; // right
}

export const getEmptyRow = (index: number, variableId: string, value: any) => {
  let processValue = "";
  if (value) {
    processValue = value;
  }

  return {
    id: index,
    document: variableId,
    process: processValue,
  };
};
