var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['pds-c-api', _vm.isFullScreen && 'pds-c-api--fullscreen'],on:{"click":_vm.focusSinput}},[_c('pds-input',{attrs:{"disabled":"","required":_vm.$attrs.required || false,"status":_vm.status,"label":"Endpoint"}},[_vm._t("prepend",null,{"slot":"prepend"})],2),_vm._v(" "),_c('div',{class:['pds-c-api--content']},[(_vm.input.length < 32 || _vm.isEndpointExpanded)?_c('p',{class:[
        'pds-c-api--link',
        'pds-u-body--2',
        _vm.isEndpointExpanded && 'pds-c-api--link--expandable',
        _vm.isEndpointExpanded && 'pds-c-api--link--expandable--expanded',
      ],domProps:{"innerHTML":_vm._s(_vm.input.trim())},on:{"mousedown":function($event){_vm.isEndpointExpanded && _vm.onEndpointMouseDown($event)},"dblclick":_vm.onExpandedEndpointDoubleClick,"click":_vm.onExpandedEndpointClick}}):_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: `${_vm.input}<br><br> <i>Click to expand</i>`,
      }),expression:"{\n        content: `${input}<br><br> <i>Click to expand</i>`,\n      }"}],class:[
        'pds-c-api--ellipsis-holder',
        'pds-u-body--2',
        _vm.hasPrependSlot ? 'pds-u-m--l--32' : 'pds-u-m--l--8',
        'pds-c-api--ellipsis-holder--expandable',
      ],on:{"click":_vm.onTruncatedEndpointClick}},[_c('span',{class:['pds-c-api--ellipsis', 'pds-u-body--2']},[_vm._v("\n        "+_vm._s(_vm.input.substring(0, 18) + "..."))]),_vm._v("\n      "+_vm._s(_vm.input.substring(_vm.input.length - 10))+"\n    ")]),_vm._v(" "),_c('pup-generic-text',{class:[
        'pds-u-body--2',
        'pds-c-api--second',
        _vm.disabled && 'pds-c-api--second--disabled',
        !_vm.input && _vm.hasPrependSlot && 'pds-c-api--second--empty',
      ],attrs:{"value":_vm.value,"placeholder":"{methodeName}","settings":_vm.settings,"processVariables":_vm.processVariables,"status":_vm.status,"showValidationMessage":false,"parent":_vm.parent,"disabled":_vm.disabled,"fullScreenTitle":_vm.parent.name + ' | Endpoint'},on:{"update-input":_vm.emitModel,"blur":function($event){return _vm.$emit('blur', $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }