<template>
  <div class="pup-c-test-action">
    <div v-if="isDesktop" :class="['pup-c-test-action--values--border']">
      <div
        :class="[
          'pup-c-test-action--btn-collapse',
          'pup-c-test-action--values--text',
          !isTestCollapsed && 'pup-c-test-action--values--text--collapsed',
        ]"
        @click="toggleIsTestCollapsed"
      >
        Test Action
        <pds-icon
          v-if="!submited"
          :icon="isTestCollapsed ? 'chevron_left' : 'chevron_right'"
          :class="[
            'pup-c-test-action--values--icon',
            isFullScreen && 'pup-c-test-action--values--icon--fullscreen',
          ]"
        />
      </div>
    </div>

    <div
      class="pup-c-test-action--values"
      :class="{
        'pup-c-test-action--values--collapsed': isTestCollapsed,
      }"
    >
      <pup-collapsable-values
        label="Test values"
        class="pds-u-m--b--16 pup-c-test-action--test-values"
        :values="testValuesModel"
        @update-collapse="checkCollapsed"
      />

      <pds-message
        class="pds-u-m--b--16 pds-u-m--l--4"
        :text="enlargeInputText"
        size="small"
        variation="ghost"
      />

      <pds-button
        type="ghost"
        color="primary"
        @click="testActionTimedOut"
        :class="[
          'pds-u-m--b--16',
          'pup-c-test-action--test-button',
          isCollapsed && 'pup-c-test-action--test-button--isCollapsed',
        ]"
      >
        Test action
      </pds-button>

      <pup-collapsable-values
        v-if="testOutputModel.length > 0"
        :class="[
          'pup-c-test-action--test-output',
          isCollapsed && 'pup-c-test-action--test-output--isCollapsed',
        ]"
        :values="testOutputModel"
        label="Test output"
        readonly
      />
    </div>

    <div v-if="!isDesktop" class="pup-c-test-action--responsive-test">
      <pds-button
        class="pup-c-test-action--responsive-test--button"
        color="subtle"
        type="outlined"
        @click="closeTestAction"
      >
        Close Test Action
      </pds-button>
    </div>

    <div v-if="submited" class="pup-c-test-action--animation">
      <lottie-animation
        path="./static/lottie/17146-loading.json"
        :loop="true"
        :autoPlay="true"
        :width="300"
        :height="300"
      />

      <p class="pds-u-subtitle--1">Please, hold on! 😊</p>
      <p class="pds-u-subtitle--1 pup-c-call-api--animation--description">
        We are sending the request.
      </p>

      <pds-button
        @click="cancelRequest"
        class="pup-c-call-api--animation--button pds-u-m--t--24"
      >
        Cancel
      </pds-button>
    </div>
  </div>
</template>

<script lang="ts">
/** VUE RELATED */
import { mixins } from "vue-class-component";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";

/** DESIGN SYSTEM */
import {
  SidePanelComponent,
  ButtonComponent,
  IconComponent,
  ResponsiveMixin,
  MessageComponent,
} from "@procesio/procesio-design-system";

import Collapsable from "@/components/Collapsable/CollapsableValues.component.vue";
import {
  Direction,
  Node,
  Setting,
  SettingTab,
  SettingType,
  GenericSetting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { EventBus, Events } from "@/utils/eventBus";
import OrchestrationService, {
  Variable as IVariable,
} from "@/services/crud/Orchestration.service";
import ActionListlService, {
  TestActionPayload,
} from "@/services/actionlist/ActionList.service";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

// mixins
import { VariableParser } from "@/modules/ProcessDesigner/Variables/Utils/VariableParser";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { Controls } from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Controls";
import { ErrorContent, RestResponse } from "@/services/Rest.service";
import { FormBuilder, Validators } from "@/utils/ReactiveForm";
import { getParametersFromSettingTabs } from "@/modules/ProcessDesigner/ParameterGetter";
import { mapGetters } from "vuex";
import { isValidJSON } from "@/utils/type/json";
import { DataModel } from "@/services/datamodel/DataModel.model";
import { NonPrimitives } from "@/utils/dataTypeMapper";
import { createGuid } from "@/utils/type/guid";
import { TestActionModel } from "@/modules/ProcessDesigner/components/Controls/TestAction/TestAction.model";
import { ActionTypes } from "@/store/process/Process.actions";
import { HotKeys, hotKeyToString } from "@/utils/keyboard/hotkeys";

@Component({
  components: {
    "pds-sidepanel": SidePanelComponent,
    "pup-collapsable-values": Collapsable,
    "pds-button": ButtonComponent,
    "lottie-animation": LottieAnimation,
    "pds-icon": IconComponent,
    "pds-message": MessageComponent,
  },
  computed: {
    ...mapGetters({
      signalRConnectionId: "signalR/connectionId",
      testActionModel: "testActionModel",
    }),
  },
})
export default class TestAction extends mixins(
  Controls,
  VariableParser,
  Variable,
  ResponsiveMixin
) {
  @Prop() node!: Node;

  @Prop({ default: false, type: Boolean }) isFullScreen!: boolean;

  selectedNode: Node | undefined;

  isCollapsed = false;

  testValuesModel = [
    {
      id: "1b09c4b1-723f-48bb-9906-32fee1673e27",
      type: SettingType.TABLE,
      label: "",
      columns: [
        {
          key: "key",
          label: "Variable",
          placeholder: "Insert key",
        },
        {
          key: "value",
          label: "Test Value  ",
          placeholder: "Insert value",
          component: "pds-input",
        },
        {
          key: "type",
          label: "Type",
          placeholder: "",
          component: "pup-data-type-badge",
        },
      ],
      value: [],
    },
  ];

  testOutputModel: Array<Partial<Setting>> = [];

  testId: number | undefined;

  isTestCollapsed = true;

  guidPattern =
    /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/g;

  submited = false;

  get variables(): IVariable[] {
    return this.$store.state.processes.variables;
  }

  get types(): DataModel[] {
    return this.$store.getters.dataTypes;
  }

  get enlargeInputText() {
    return `To enlarge an input, click on it and press ${hotKeyToString(
      HotKeys.ENLARGE_INPUT
    )}.`;
  }

  @Watch("variables")
  onVariablesUpdate() {
    this.findVariables(this.node);
  }

  @Watch("testValuesModel", { deep: true })
  @Watch("testOutputModel", { deep: true })
  onTestModelUpdate() {
    this.$store.dispatch(ActionTypes.SET_TEST_ACTION_MODEL, {
      input: this.testValuesModel,
      output: this.testOutputModel,
    } as TestActionModel);
  }

  @Watch("isTestCollapsed", { immediate: true })
  onPanelCollapsedUpdate(
    isTestCollapsed: boolean,
    oldValue: boolean | undefined
  ) {
    if (this.testActionModel) {
      if (Array.isArray(this.testActionModel.input)) {
        this.testValuesModel = this.testActionModel.input;
      }

      if (Array.isArray(this.testActionModel.output)) {
        this.testOutputModel = [...this.testActionModel.output];
      }
    }

    if (oldValue !== undefined) {
      this.$emit(isTestCollapsed ? "close" : "open");
    }
  }

  mounted() {
    this.findVariables(this.node);

    EventBus.$on("find-variables", this.findVariables);
    EventBus.$on("completed:test-action", this.completedTestHandler);
    EventBus.$on("side-tabs-open", this.handleSideTabsOpen);

    if (window.innerWidth > 768) {
      this.isTestCollapsed = true;
    } else {
      this.isTestCollapsed = false;
    }
  }

  destroyed() {
    EventBus.$off("find-variables", this.findVariables);
    EventBus.$off("completed:test-action", this.completedTestHandler);
    EventBus.$off("side-tabs-open", this.handleSideTabsOpen);
  }

  getStatus(status: string) {
    switch (status) {
      case "1":
        return "None";

      case "5":
        return "Dispatched";

      case "10":
        return "Starting";

      case "20":
        return "Running";

      case "30":
        return "Output Done";

      case "40":
        return "Finished";

      case "90":
        return "Error";

      default:
        break;
    }
  }

  async completedTestHandler() {
    if (!this.testId) {
      this.submited = false;
      return;
    }

    const response = await ActionListlService.getTestResponse(this.testId);

    this.testOutputModel = [];

    if (response.content) {
      const testStatus = {
        id: "93a67b8a-e142-49ea-976b-f4191b895bd3",
        label: "Test status",
        type: SettingType.TEXT_BASIC,
        value: this.getStatus(String(response.content.status)),
        disabled: true,
        placeholder: "",
      };

      this.testOutputModel.push(testStatus);

      if (response.content.errorMessage) {
        const errorMessage = {
          id: createGuid(),
          label: "Test error message",
          type: SettingType.TEXT_BASIC,
          value: response.content.errorMessage,
          disabled: true,
          placeholder: "",
        };

        this.testOutputModel.push(errorMessage);
      }

      if (response.content.errorCode) {
        const errorCode = {
          id: createGuid(),
          label: "Test error code",
          type: SettingType.TEXT_BASIC,
          value: response.content.errorCode,
          disabled: true,
          placeholder: "",
        };

        this.testOutputModel.push(errorCode);
      }

      const testResult: Record<string, any> = JSON.parse(
        response.content.testResult
      );

      const testOutputs = Object.entries(testResult).reduce(
        (settings: Setting[], [variableId, value]) => {
          let setting: GenericSetting | undefined;

          const variable = this.variables.find(
            (item) => item.id === variableId
          );
          const dataModel = this.$store.getters.getDataTypeById(
            variable?.dataType
          );

          if (variable && dataModel) {
            setting = {
              id: variable.id,
              label: variable.name,
              value: value,
              type: SettingType.TEXT_BASIC,
              isList: variable.isList,
              dataTypeId: dataModel.id,
            };

            if (typeof value === "boolean") {
              setting.value = value.toString();
            }

            // format json value
            const isValueObject =
              [NonPrimitives.OBJECT, NonPrimitives.FILE].includes(
                dataModel.id
              ) ||
              Array.isArray(value) ||
              typeof value === "object";

            const isMultiline =
              dataModel.csharpCorrespondent === 120 ||
              dataModel.isDataModel ||
              (value && isValueObject);

            if (isMultiline) {
              setting.type = SettingType.TEXT_AREA_BASIC;
            }

            const isValueJson = isMultiline && isValidJSON(value);

            if (
              (isValueJson || isValueObject) &&
              dataModel.id !== NonPrimitives.FILE
            ) {
              if (isValueJson) {
                value = JSON.parse(value);
              }

              setting.value = JSON.stringify(
                value,
                undefined,
                isMultiline ? 4 : 0
              );
            }
          }

          if (setting) {
            settings.push(setting);
          }

          return settings;
        },
        []
      );

      if (testOutputs) {
        this.testOutputModel.push(...testOutputs);
      }
    }

    this.submited = false;
  }

  closeTestAction() {
    this.isTestCollapsed = true;
  }

  handleSideTabsOpen(open: boolean) {
    if (open) this.closeTestAction();
  }

  checkCollapsed(isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
  }

  findVariables(node: Node) {
    this.selectedNode = node;

    const uniqueVariables: Record<
      string,
      {
        key: string;
        value?: unknown;
        type: string;
        id: string;
        typeRef: DataModel;
        dataTypeId: string;
        isList: boolean;
      }
    > = {};

    this.traverseControls(node.configuration, (control) => {
      let value: string;

      if (
        (control.type === SettingType.TABS_PAYLOAD_OLD ||
          control.type === SettingType.TABS_PAYLOAD ||
          control.type === SettingType.PROCESS_INPUT) &&
        control.value
      ) {
        value = JSON.stringify(control.value);
      } else {
        value = control.value;
      }

      if (
        this.hasVariable(value) &&
        (control.direction === Direction.Input ||
          control.direction === Direction.InputOutput ||
          control.type === SettingType.TABS_PAYLOAD_OLD ||
          control.type === SettingType.TABS_PAYLOAD)
      ) {
        const variablesWithinValue = value.match(this.guidPattern);

        variablesWithinValue?.forEach((variableWithinValue) => {
          const controlVariableType =
            this.getVariableDataType(variableWithinValue);

          if (controlVariableType) {
            uniqueVariables[variableWithinValue] = {
              key: this.parseVariableIdToName(variableWithinValue),
              id: variableWithinValue,
              type: controlVariableType.name,
              typeRef: controlVariableType,
              dataTypeId: controlVariableType.id,
              isList: this.isVariableList(variableWithinValue) || false,
            };
          }
        });
      }
    });

    const newTestValues = Object.values(uniqueVariables).map((variable) => {
      const processVariable = this.$store.getters.getVariableById(variable.id);
      return {
        ...variable,
        value:
          (this.testValuesModel[0].value as any).find(
            (testvalues: any) => (testvalues as any).id === variable.id
          )?.value ||
          processVariable?.defaultValue ||
          "",
      };
    });

    this.testValuesModel[0].value = newTestValues as any;
  }

  toggleIsTestCollapsed() {
    this.isTestCollapsed = !this.isTestCollapsed;
    !this.isTestCollapsed &&
      EventBus.$emit(Events["PROCESS:TOGGLE_SIDE_TABS_INDEX"], -1);
  }

  isNodeValid() {
    const form = new FormBuilder();

    this.traverseControls(this.selectedNode?.configuration, (control) => {
      const validations = [];

      if (control.isRequired) {
        validations.push(Validators.required);
      }

      form.control(control.label, control.value, validations);
    });

    form.validate();

    return {
      isValid: !form.hasErrors,
      errors: form.errors,
    };
  }

  @Emit("submited")
  async testAction() {
    const errors = this.isNodeValid();

    if (!errors.isValid) {
      Object.entries(errors.errors).forEach(([key, errors]) => {
        errors.forEach((err) => this.$toast.error(`${key}: ${err}`));
      });
      return false;
    }

    const testValue =
      Array.isArray(this.testValuesModel) && this.testValuesModel.length
        ? this.testValuesModel[0]
        : null;

    if (!testValue) {
      return;
    }

    const testAction: TestActionPayload = {
      variables: this.variables.filter((variable) =>
        this.isVariableUsedInConfiguration(
          variable,
          this.selectedNode?.configuration
        )
      ),
      testValues: testValue.value.map(
        (testValue: {
          key: string;
          value: unknown;
          type: string;
          id: string;
          dataTypeId: string;
          isList: boolean;
        }) => {
          const ids = testValue.id.split(".");

          const getVal = () => {
            const value = testValue.value;
            if (testValue.dataTypeId === NonPrimitives.FILE) {
              if (!testValue.isList && Array.isArray(value) && value.length) {
                return { name: value[0].name };
              }

              return Array.isArray(value)
                ? value.map((file) => ({ name: file.name }))
                : value;
            }

            return value;
          };

          const varItem = {
            // id: createGuid(),
            variableId: ids[0],
            attribute: null,
            value: getVal(),
          };

          const variable = ids
            .slice(1)
            .reduceRight(function (o: Record<string, any> | null, s) {
              return {
                attributeId: s,
                nextAttribute: o,
              };
            }, null);

          varItem.attribute = variable as any;

          return varItem;
        }
      ),
      action: {
        gtid: this.selectedNode?.templateId as string,
        parameters: getParametersFromSettingTabs(
          this.selectedNode?.configuration as SettingTab[]
        ),
      },
      connectionId: this.signalRConnectionId,
    };

    this.submited = true;

    const response: RestResponse<any> = await ActionListlService.testAction(
      testAction
    );

    // handle errors
    if (!response.content || response.is_error) {
      const errors: string[] = [];

      if (Array.isArray(response.error_content)) {
        (response.error_content || []).forEach((error) => {
          const message = `${error.target}: ${error.value}`;
          if (!errors.includes(message)) {
            errors.push(message);
          }
        });
      } else {
        errors.push("Something went wrong. Please try again.");
      }

      if (errors.length) {
        this.$toast.error(errors.join("<br>"));
      }

      this.submited = false;

      return;
    }

    this.testId = (response.content as any).testActionInstance.gid;

    const fileInputs = testValue.value.filter(
      (tv: any) => tv.dataTypeId === NonPrimitives.FILE
    );

    const fld: Array<{
      variableName: string;
      variableId: string;
      fileId: string;
      id: string;
      value: any;
    }> = [];

    fileInputs.forEach((fileInput: any) => {
      const fileVariable = response.content?.testActionInstance.testValues.find(
        (variable: any) => fileInput.id === variable.variableId
      );

      const files = Array.isArray(fileVariable.value)
        ? fileVariable.value
        : [fileVariable.value];

      for (let index = 0; index < files.length; index++) {
        const element = fileInput.value[index];

        fld.push({
          ...fileInput,
          variableName: fileVariable?.name,
          variableId: fileVariable?.variableId,
          fileId: files[index].id,
          value: element,
        });
      }
    });

    const promises: Array<Promise<Response>> = [];

    for (let i = 0; i < fld.length; i++) {
      const promise = await ActionListlService.uploadTestActionFile(
        {
          testActionId: this.testId as unknown as string,
          variableId: fld[i].variableId,
          fileId: fld[i].fileId as string,
          connectionId: this.signalRConnectionId,
        },
        fld[i].value as File
      );

      promises.push(promise);
    }

    const uploadErrors: ErrorContent[][] = (promises as any).filter((item) =>
      Array.isArray(item)
    );

    if (uploadErrors.length) {
      this.submited = false;

      const errors: string[] = [];

      uploadErrors.forEach((errorArray: ErrorContent[]) => {
        errorArray.forEach((error: ErrorContent) => {
          const message = `${error.target}: ${error.value}`;
          if (!errors.includes(message)) {
            errors.push(message);
          }
        });
      });

      if (!errors.length) {
        errors.push(
          "Something went wrong when files being uploaded. Please try again."
        );
      }

      if (errors.length) {
        this.$toast.error(errors.join("<br>"));
      }
    }
  }

  testActionTimedOut() {
    setTimeout(this.testAction, 500);
  }

  checkVariables(arg: Setting) {
    const guidPattern =
      /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/g;

    const isGuid = guidPattern.test(arg.value);

    if (isGuid) {
      return true;
    }

    return false;
  }

  cancelRequest() {
    this.submited = false;
  }
}
</script>

<style lang="scss">
@import "./TestAction.component.scss";
</style>
