import { actions } from "./User.actions";
import { mutations } from "./User.mutations";
import getters from "./User.getters";
import { UserWorkspace } from "@/services/user/workspace/UserWorkspace.service";
import UserLocalStorage from "@/services/localStorage/user";
import UserService from "@/services/user/User.service";
import { Event } from "@/services/user/event/Event.model";

export interface UserState {
  userId: string | null;
  userEmail: string;
  firstName: string;
  lastName: string;
  createdAt: string | null;
  token: string | null;
  refreshToken: string | null;
  tokenExpiresIn: number | null;
  refreshTokenExpiresIn: number | null;
  workspaces: UserWorkspace[];
  activeWorkspace: UserWorkspace;
  events: Event[];
}

const getInitialState = () => {
  let token = UserLocalStorage.getToken();
  const refreshToken = UserLocalStorage.getRefreshToken();
  const tokenExpiresIn = UserLocalStorage.getTokenExpiresIn();
  const refreshTokenExpiresIn = UserLocalStorage.getRefreshTokenExpiresIn();
  const userData = UserService.getUserData(token);

  const timestamp = new Date().getTime();
  if (token && refreshTokenExpiresIn && timestamp > refreshTokenExpiresIn) {
    token = null;
  }

  return {
    userId: userData.id,
    userEmail: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    createdAt: userData.created,
    token,
    refreshToken,
    tokenExpiresIn,
    refreshTokenExpiresIn,
    workspaces: [],
    activeWorkspace: null,
    events: [],
  };
};

export const UserStore = {
  state: getInitialState(),
  actions,
  mutations,
  getters,
};
