<template>
  <div
    :class="['pup-c-logic-operator', hasDivider && 'pup-c-logic-operator--has-divider']"
  >
    <div
      @click="updateInput"
      class="pup-c-logic-operator--badge pds-u-caption"
      v-tooltip="{
        content: tooltip,
      }"
    >
      <span class="pup-c-logic-operator--badge--check pds-u-m--r--8"></span>
      {{ value === logicOperators.AND ? "AND" : "OR" }}
    </div>
  </div>
</template>

<script lang="ts">
import { LogicOperators } from "@/services/condition/Condition.model";
import Vue from "vue";
import { Component, Model, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class LogicOperatorComponent extends Vue {
  tooltip = "AND has priority over OR in evaluating conditions";

  @Model("update-input") value!: LogicOperators;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: true, type: Boolean }) hasDivider?: boolean;

  @Watch("value", { immediate: true, deep: true })
  updateValue(value: LogicOperators) {
    if (value === LogicOperators.NONE) {
      this.$emit("update-input", LogicOperators.AND);
    }
  }

  logicOperators = LogicOperators;

  updateInput(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (this.value === LogicOperators.AND) {
      this.$emit("update-input", LogicOperators.OR);
    } else {
      this.$emit("update-input", LogicOperators.AND);
    }

    console.log("dance");
  }
}
</script>

<style lang="scss">
@import "./LogicOperator.component.scss";
</style>
