import RestService, { PaginatedResponse } from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import {
  FormTemplate,
  FormTemplateListItem,
  FormTemplateStatus,
  FormTemplateBasic
} from "./FormTemplate.model";
import CryptoJS from "crypto-js";
import { store } from "@/store";

export default class FormTemplateService {
  static getFormTemplateBasicList() {
    return RestService.get<FormTemplateBasic[]>({
      url: RestUrls.API.FORM.TEMPLATE.LIST_BASIC,
      isAuth: true,
      workspaced: true,
    });
  }

  static getFormTemplateList(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<FormTemplateListItem>>({
      url: RestUrls.API.FORM.TEMPLATE.LIST_PAGINATED(pageNumber, pageItemCount),
      isAuth: true,
      workspaced: true,
    });
  }

  static async getFormTemplateById(id: string) {
    const response = await RestService.get<FormTemplate>({
      url: RestUrls.API.FORM.TEMPLATE.GET_BY_ID(id),
      isAuth: true,
      workspaced: true,
    });

    if (typeof response.content?.data.code === "string") {
      const decryptedCode = CryptoJS.AES.decrypt(
        response.content.data.code,
        process.env.VUE_APP_ENV_VARIABLE
      ).toString(CryptoJS.enc.Utf8);

      response.content.data.code = decryptedCode
        ? JSON.parse(decryptedCode)
        : null;
    }

    return response;
  }

  // difference from getFormTemplateById is BE authorization
  static async getFormTemplateDetailsById(id: string, workspace: string) {
    const response = await RestService.get<FormTemplate>({
      url: RestUrls.API.FORM.TEMPLATE.GET_BY_ID(id, workspace),
      isAuth: !!store.getters.token,
      workspaced: !!store.getters.token,
    });

    if (typeof response.content?.data.code === "string") {
      const decryptedCode = CryptoJS.AES.decrypt(
        response.content.data.code,
        process.env.VUE_APP_ENV_VARIABLE
      ).toString(CryptoJS.enc.Utf8);

      response.content.data.code = decryptedCode
        ? JSON.parse(decryptedCode)
        : null;
    }

    return response;
  }

  static saveFormTemplate(data: FormTemplate, isNew: boolean) {
    if (data.data.code) {
      data.data.code = CryptoJS.AES.encrypt(
        JSON.stringify(data.data.code),
        process.env.VUE_APP_ENV_VARIABLE
      ).toString();
    }

    return RestService[isNew ? "post" : "put"]({
      url: RestUrls.API.FORM.TEMPLATE.SAVE,
      isAuth: true,
      workspaced: true,
      data,
    });
  }

  static toggleState(id: string, state: boolean) {
    return RestService.patch({
      url:
        RestUrls.API.FORM.TEMPLATE.PATCH_UPDATE(id) +
        "?state=" +
        (state ? "true" : "false"),
      isAuth: true,
      workspaced: true,
      data: {},
    });
  }

  static async toggleStatus(id: string, status: FormTemplateStatus) {
    const response = await FormTemplateService.getFormTemplateById(id);
    if (response.is_error || !response.content) {
      return response;
    }

    return await FormTemplateService.saveFormTemplate(
      {
        ...response.content,
        status,
      },
      false
    );
  }

  static duplicateFormTemplate(id: string) {
    return RestService.post({
      url: RestUrls.API.FORM.TEMPLATE.DUPLICATE(id),
      isAuth: true,
      workspaced: true,
      data: {},
    });
  }

  static deleteFormTemplate(id: string) {
    return RestService.delete<any>({
      url: RestUrls.API.FORM.TEMPLATE.DELETE(id),
      isAuth: true,
      workspaced: true,
    });
  }
}
