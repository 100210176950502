import { MutationTree } from "vuex";
import { AppState } from "./App.store";

export enum MutationTypes {
  SET_VERSION = "SET_VERSION",
  SET_AUTH_API_VERSION = "SET_AUTH_API_VERSION",
  SET_BASE_API_VERSION = "SET_BASE_API_VERSION",
}

export type Mutations<S = AppState> = {
  [MutationTypes.SET_VERSION](state: S, payload: number | null): void;
  [MutationTypes.SET_AUTH_API_VERSION](state: S, payload: number | null): void;
  [MutationTypes.SET_BASE_API_VERSION](state: S, payload: number | null): void;
};

export const mutations: MutationTree<AppState> & Mutations = {
  [MutationTypes.SET_VERSION](state, payload) {
    state.version = payload;
  },
  [MutationTypes.SET_AUTH_API_VERSION](state, payload) {
    state.authApiVersion = payload;
  },
  [MutationTypes.SET_BASE_API_VERSION](state, payload) {
    state.baseApiVersion = payload;
  },
};
