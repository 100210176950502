<template>
  <div class="pup-c-payload-body--item--typed" ref="typedInput">
    <pds-input
      v-model="value.key"
      :isTextOnBlur="true"
      placeholder="Insert key"
      class="pds-u-m--r--8 pup-c-tinput--element pup-c-tinput--element--input"
      :disabled="disabled"
    />
    <pds-select
      :value="value.type"
      :options="itemTypes"
      class="pup-c-payload-body--item--typed--select"
      :disabled="disabled"
      @update-input="onItemTypeUpdate(value, $event)"
    >
      <template v-slot:option="{ row }">
        <div
          class="pup-c-payload-body--item--typed--select--option"
        >
          <pds-icon
            :icon="row.option.icon"
            class="pup-c-payload-body--item--typed--select--option--icon"
          />
          {{ row.option.name }}
        </div>
      </template>

      <template v-slot:singleLabel="{ selectedOption }">
        <pds-icon
          :icon="selectedOption.icon"
          :isOutlined="selectedOption.value === tabsPayloadItemTypes.FILE"
          v-tooltip="{
            content: getItemTypeTooltip(selectedOption.value),
            boundariesElement: 'window',
          }"
          size="tiny"
          class="material-icons--grey pup-c-payload-body--item--typed--select--label--icon"
        />
      </template>
    </pds-select>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model } from "vue-property-decorator";
import {
  SelectComponent,
  InputComponent,
  IconComponent,
} from "@procesio/procesio-design-system";
import {
  TabsPayloadItem,
  TabsPayloadItemType,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";

@Component({
  components: {
    "pds-select": SelectComponent,
    "pds-input": InputComponent,
    "pds-icon": IconComponent,
  },
})
export default class TypedInputComponent extends Vue {
  @Model("update-input") value!: TabsPayloadItem;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  tabsPayloadItemTypes = TabsPayloadItemType;

  itemTypes = [
    {
      name: "Text",
      value: TabsPayloadItemType.TEXT,
      icon: "format_color_text",
    },
    {
      name: "File",
      value: TabsPayloadItemType.FILE,
      icon: "insert_drive_file",
    },
  ];

  getItemTypeTooltip(itemType: TabsPayloadItemType) {
    if (!itemType) {
      return null;
    }

    const item = this.itemTypes.find((item) => item.value === itemType);

    return item ? item.name : null;
  }

  onItemTypeUpdate(item: TabsPayloadItem, itemType: TabsPayloadItemType) {
    item.value = "";
    item.type = itemType;
  }
}
</script>

<style lang="scss">
@import "./TypedInput.component.scss";
</style>
