export const isValidJSON = (jsonString: string) => {
  if (typeof jsonString !== "string") {
    return false;
  }

  try {
    JSON.parse(jsonString);
  } catch (_) {
    return false;
  }

  return true;
};

export const parseJSON = (jsonString: string) => {
  if (typeof jsonString !== "string") {
    return null;
  }

  let parsed = null;
  try {
    parsed = JSON.parse(jsonString);
  } catch (_) {
    return null;
  }

  return parsed;
};
