import {
  DataModel,
  DataModelAttribute,
} from "./DataModel.model";

/* eslint-disable */

export const config = {
  columns: [
    {
      key: "display_name",
      label: "Display Name",
      component: "Paragraph",
    },
    {
      key: "name",
      label: "Name",
      component: "Paragraph",
    },
  ],
  items: [
    {
      id: 1,
      user_id: 12,
      display_name: "Adress Id",
      name: "adressId",
      status: "string",
    },
    {
      id: 1,
      user_id: 12,
      display_name: "Adress Id",
      name: "adressId",
      status: "string",
    },
  ],
};

export const nestedConfigItems: DataModelAttribute[] =
  [
    {
      "id": "d98a7f75-f95d-4d0d-8fdc-f33a3ef92634",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
      "name": "level1",
      "displayName": "Level1",
      "isList": false,
      "jsonProperty": "level1",
      "typeName": "Boolean",
      "attributes": [
        {
          "id": "05d15d7a-c50b-4d31-9a58-5f6d025f9fab",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
          "name": "level2",
          "displayName": "Level 2",
          "isList": false,
          "jsonProperty": "level2",
          "typeName": "Boolean",
          "attributes": [
            {
              "id": "d98a7f75-f95d-4d0d-8fdc-f33a3ef92634",
              "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
              "name": "level3a",
              "displayName": "Level 3a",
              "isList": false,
              "jsonProperty": "level3a",
              "typeName": "Boolean",
              "attributes": [
                {
                  "id": "d98a7f75-f95d-4d0d-8fdc-f33a3ef92634",
                  "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
                  "name": "level4",
                  "displayName": "Level 4",
                  "isList": false,
                  "jsonProperty": "level4",
                  "typeName": "Boolean",
                  "attributes": [
                    {
                      "id": "d98a7f75-f95d-4d0d-8fdc-f33a3ef92634",
                      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
                      "name": "level5",
                      "displayName": "Level 5",
                      "isList": false,
                      "jsonProperty": "level5",
                      "typeName": "Boolean"
                    }
                  ]
                }
              ]
            },
            {
              "id": "d98a7f75-f95d-4d0d-8fdc-f33a3ef92634",
              "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
              "name": "level3b",
              "displayName": "Level 3b",
              "isList": false,
              "jsonProperty": "level3b",
              "typeName": "Boolean"
            }
          ]
        }
      ]
    },
    {
      "id": "05d15d7a-c50b-4d31-9a58-5f6d025f9fab",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121210",
      "name": "flat",
      "displayName": "Flat",
      "isList": false,
      "jsonProperty": "flat",
      "typeName": "Boolean"
    }
  ]

export const dataModelMock: DataModel = {
  "attributes": [
    {
      "id": "aa4a909f-2779-4deb-b05e-fc354855e3c1",
      "attributeId": "aa4a909f-2779-4deb-b05e-fc354855e3c1",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121211",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "id",
      "displayName": "id",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "id",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "513b9b4c-1e5f-45d9-8f6c-976815c77dc9",
      "attributeId": "513b9b4c-1e5f-45d9-8f6c-976815c77dc9",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "name",
      "displayName": "name",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "name",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "e976f82e-9a1d-4de1-a9b3-8b6d8898c8c0",
      "attributeId": "e976f82e-9a1d-4de1-a9b3-8b6d8898c8c0",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "status",
      "displayName": "status",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "status",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "6f514d3c-bbad-4131-9ce8-baf971a96f3e",
      "attributeId": "6f514d3c-bbad-4131-9ce8-baf971a96f3e",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "species",
      "displayName": "species",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "species",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "25df1ce5-947b-4794-9c29-b2805fd38f0f",
      "attributeId": "25df1ce5-947b-4794-9c29-b2805fd38f0f",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "type",
      "displayName": "type",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "type",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "b59703d6-5d3a-4884-aa5b-ebe04c0bbca1",
      "attributeId": "b59703d6-5d3a-4884-aa5b-ebe04c0bbca1",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "gender",
      "displayName": "gender",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "gender",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "b38d2888-6aee-48d2-954a-b02e83f37918",
      "attributeId": "b38d2888-6aee-48d2-954a-b02e83f37918",
      "dataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "origin",
      "displayName": "origin",
      "isDataModel": true,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "origin",
      "lastUpdate": "2022-03-25T14:30:55.7561438Z",
      "attributes": [
        {
          "id": "885b93a6-b509-4418-8f35-b0f3ff3c94a0",
          "attributeId": "885b93a6-b509-4418-8f35-b0f3ff3c94a0",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
          "name": "name",
          "displayName": "name",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "name",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
        {
          "id": "5529129b-608a-45c1-95b6-e35484bd94f0",
          "attributeId": "5529129b-608a-45c1-95b6-e35484bd94f0",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
          "name": "url",
          "displayName": "url",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "url",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        }
      ],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "d059b23d-0455-42ac-addc-32b3be42323d",
      "attributeId": "d059b23d-0455-42ac-addc-32b3be42323d",
      "dataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "location",
      "displayName": "location",
      "isDataModel": true,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": true,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "location",
      "lastUpdate": "2022-03-25T14:30:55.7561349Z",
      "attributes": [
        {
          "id": "a4ff3c13-2378-412f-82c8-e0e1cb77e8bd",
          "attributeId": "a4ff3c13-2378-412f-82c8-e0e1cb77e8bd",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
          "name": "name",
          "displayName": "name",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "name",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
        {
          "id": "116e02c8-744a-407c-87a6-efa22dd800e4",
          "attributeId": "116e02c8-744a-407c-87a6-efa22dd800e4",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
          "name": "url",
          "displayName": "url",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "url",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        }
      ],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "caee6971-4dc2-4801-984e-b1f08e998d31",
      "attributeId": "caee6971-4dc2-4801-984e-b1f08e998d31",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "image",
      "displayName": "image",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "image",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "053671e5-6890-4953-9c9d-fb4f8cb8b6fc",
      "attributeId": "053671e5-6890-4953-9c9d-fb4f8cb8b6fc",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "episode",
      "displayName": "episode",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": true,
      "jsonProperty": "episode",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "aec56ff9-7669-4e3b-be0b-b3753225a1d5",
      "attributeId": "aec56ff9-7669-4e3b-be0b-b3753225a1d5",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "url",
      "displayName": "url",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "url",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "59614239-9693-42c8-9cb2-ff3792c26b1d",
      "attributeId": "59614239-9693-42c8-9cb2-ff3792c26b1d",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121218",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "created",
      "displayName": "created",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "created",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    }
  ],
  "id": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
  "name": "Annie",
  "displayName": "Annie",
  "isDataModel": true,
  "isPrimaryType": false,
  "isProcesio": false,
  "isPublic": true,
  "csharpCorrespondent": 0,
  "type": 1,
  "lastUpdate": "2022-03-25T14:30:55.7560243Z",
  "firstName": null,
  "lastName": null,
  "workspaceName": null,
  parentIds: [""]
}


export const dataModel: DataModel[] = [
  {
    id: "1-1",
    name: "client",
    attributeCount: 3,
    icon: "add",
    displayName: "Client",
  },
  {
    id: "1-2",
    name: "client2",
    attributeCount: 5,
    icon: "filter_list",
    displayName: "Client2",
  },
  {
    id: "1-3",
    name: "client3",
    attributeCount: 6,
    icon: "help",
    displayName: "Client3",
  },
];

export const searchDataModelMock: DataModel[] = [
  {
    id: "1-1",
    name: "client",
    attributeCount: 3,
    icon: "close",
    displayName: "Client",
  },
  {
    id: "1-2",
    name: "client2",
    attributeCount: 5,
    icon: "exit",
    displayName: "Client2",
  },
];

export const temporaryDataModel: DataModel = {
  id: "1",
  name: "Client",
  displayName: "Client",
  attributes: [],
};

export const primitiveList = [
  {
    value: "1",
    name: "integer",
  },
  {
    value: "2",
    name: "string",
  },
  {
    value: "3",
    name: "float",
  },
  {
    value: "4",
    name: "relationship",
  },
];

export const annieDataModel = {
  "attributes": [
    {
      "id": "d059b23d-0455-42ac-addc-32b3be42000d",
      "dataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958w",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "empty",
      "displayName": "empty",
      "isDataModel": true,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "empty",
      "lastUpdate": "2022-03-25T14:30:55.7561349Z",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "aa4a909f-2779-4deb-b05e-fc354855e3c1",
      "attributeId": "aa4a909f-2779-4deb-b05e-fc354855e3c1",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121211",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "id",
      "displayName": "id",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "id",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "513b9b4c-1e5f-45d9-8f6c-976815c77dc9",
      "attributeId": "513b9b4c-1e5f-45d9-8f6c-976815c77dc9",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "name",
      "displayName": "name",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "name",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "e976f82e-9a1d-4de1-a9b3-8b6d8898c8c0",
      "attributeId": "e976f82e-9a1d-4de1-a9b3-8b6d8898c8c0",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "status",
      "displayName": "status",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "status",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "6f514d3c-bbad-4131-9ce8-baf971a96f3e",
      "attributeId": "6f514d3c-bbad-4131-9ce8-baf971a96f3e",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "species",
      "displayName": "species",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "species",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "25df1ce5-947b-4794-9c29-b2805fd38f0f",
      "attributeId": "25df1ce5-947b-4794-9c29-b2805fd38f0f",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "type",
      "displayName": "type",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "type",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "b59703d6-5d3a-4884-aa5b-ebe04c0bbca1",
      "attributeId": "b59703d6-5d3a-4884-aa5b-ebe04c0bbca1",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "gender",
      "displayName": "gender",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "gender",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "b38d2888-6aee-48d2-954a-b02e83f37918",
      "attributeId": "b38d2888-6aee-48d2-954a-b02e83f37918",
      "dataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "origin",
      "displayName": "origin",
      "isDataModel": true,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "origin",
      "lastUpdate": "2022-03-25T14:30:55.7561438Z",
      "attributes": [
        {
          "id": "885b93a6-b509-4418-8f35-b0f3ff3c94a0",
          "attributeId": "885b93a6-b509-4418-8f35-b0f3ff3c94a0",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
          "name": "originname",
          "displayName": "originname",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "originname",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
        {
          "id": "5529129b-608a-45c1-95b6-e35484bd94f0",
          "attributeId": "5529129b-608a-45c1-95b6-e35484bd94f0",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
          "name": "originurl",
          "displayName": "originurl",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "originurl",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
        {
          "id": "d059b23d-0455-42ac-addc-32b3be42323f",
          "attributeId": "d059b23d-0455-42ac-addc-32b3be42323f",
          "dataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958f",
          "parentDataTypeId": "97015922-e43f-4f1c-8042-a8c767eb18bc",
          "name": "location_origin",
          "displayName": "location_origin",
          "isDataModel": true,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "location_origin",
          "lastUpdate": "2022-03-25T14:30:55.7561349Z",
          "attributes": [
            {
              "id": "a4ff3c13-2378-412f-82c8-e0e1cb77e8ba",
              "attributeId": "a4ff3c13-2378-412f-82c8-e0e1cb77e8ba",
              "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
              "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958f",
              "name": "locationname",
              "displayName": "locationname",
              "isDataModel": false,
              "isPrimaryType": false,
              "isProcesio": false,
              "isPublic": false,
              "csharpCorrespondent": 0,
              "type": 1,
              "isList": false,
              "jsonProperty": "locationname",
              "lastUpdate": "0001-01-01T00:00:00",
              "attributes": [],
              "firstName": null,
              "lastName": null,
              "workspaceName": null
            },
            {
              "id": "116e02c8-744a-407c-87a6-efa22dd800e5",
              "attributeId": "116e02c8-744a-407c-87a6-efa22dd800e5",
              "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
              "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958f",
              "name": "locationlocationurl",
              "displayName": "locationurl",
              "isDataModel": false,
              "isPrimaryType": false,
              "isProcesio": false,
              "isPublic": false,
              "csharpCorrespondent": 0,
              "type": 1,
              "isList": false,
              "jsonProperty": "locationurl",
              "lastUpdate": "0001-01-01T00:00:00",
              "attributes": [],
              "firstName": null,
              "lastName": null,
              "workspaceName": null
            }
          ],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
      ],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "d059b23d-0455-42ac-addc-32b3be42323d",
      "attributeId": "d059b23d-0455-42ac-addc-32b3be42323d",
      "dataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "location",
      "displayName": "location",
      "isDataModel": true,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": true,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "location",
      "lastUpdate": "2022-03-25T14:30:55.7561349Z",
      "attributes": [
        {
          "id": "a4ff3c13-2378-412f-82c8-e0e1cb77e8bd",
          "attributeId": "a4ff3c13-2378-412f-82c8-e0e1cb77e8bd",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
          "name": "locationname",
          "displayName": "locationname",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "locationname",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        },
        {
          "id": "116e02c8-744a-407c-87a6-efa22dd800e4",
          "attributeId": "116e02c8-744a-407c-87a6-efa22dd800e4",
          "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
          "parentDataTypeId": "0dee17ca-206b-48cc-a873-afa2c79f958e",
          "name": "locationlocationurl",
          "displayName": "locationurl",
          "isDataModel": false,
          "isPrimaryType": false,
          "isProcesio": false,
          "isPublic": false,
          "csharpCorrespondent": 0,
          "type": 1,
          "isList": false,
          "jsonProperty": "locationurl",
          "lastUpdate": "0001-01-01T00:00:00",
          "attributes": [],
          "firstName": null,
          "lastName": null,
          "workspaceName": null
        }
      ],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "caee6971-4dc2-4801-984e-b1f08e998d31",
      "attributeId": "caee6971-4dc2-4801-984e-b1f08e998d31",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "image",
      "displayName": "image",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "image",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "053671e5-6890-4953-9c9d-fb4f8cb8b6fc",
      "attributeId": "053671e5-6890-4953-9c9d-fb4f8cb8b6fc",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "episode",
      "displayName": "episode",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": true,
      "jsonProperty": "episode",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "aec56ff9-7669-4e3b-be0b-b3753225a1d5",
      "attributeId": "aec56ff9-7669-4e3b-be0b-b3753225a1d5",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121214",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "url",
      "displayName": "url",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "url",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    },
    {
      "id": "59614239-9693-42c8-9cb2-ff3792c26b1d",
      "attributeId": "59614239-9693-42c8-9cb2-ff3792c26b1d",
      "dataTypeId": "0317bfee-b2f5-4bde-bfe8-121212121218",
      "parentDataTypeId": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
      "name": "created",
      "displayName": "created",
      "isDataModel": false,
      "isPrimaryType": false,
      "isProcesio": false,
      "isPublic": false,
      "csharpCorrespondent": 0,
      "type": 1,
      "isList": false,
      "jsonProperty": "created",
      "lastUpdate": "0001-01-01T00:00:00",
      "attributes": [],
      "firstName": null,
      "lastName": null,
      "workspaceName": null
    }
  ],
  "id": "58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d",
  "name": "Annie",
  "displayName": "Annie",
  "isDataModel": true,
  "isPrimaryType": false,
  "isProcesio": false,
  "isPublic": true,
  "csharpCorrespondent": 0,
  "type": 1,
  "lastUpdate": "2022-03-25T14:30:55.7560243Z",
  "firstName": null,
  "lastName": null,
  "workspaceName": null,
  parentIds: ["58dbb1a9-da32-4bb7-89b9-a6fd5f3e273d", "0dee17ca-206b-48cc-a873-afa2c79f958e",]
}
