import { DataModel } from "@/services/datamodel/DataModel.model";

import { actions } from "./DataModel.actions";
import { mutations } from "./DataModel.mutations";
import getters from "./DataModel.getters";

export const DataModelStore = {
  state: () => ({
    dataTypes: [],
  }),
  mutations,
  actions,
  getters,
};

export interface DataModelState {
  dataTypes: DataModel[];
}
