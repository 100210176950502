<template>
  <div
    :class="[
      'properties-panel',
      isFullScreen && 'properties-panel--fullscreen',
    ]"
    v-if="_node && _node.id"
  >
    <div class="properties-panel--header" v-if="!isFullScreen">
      <div class="properties-panel--title pds-u-subtitle--2">
        <div class="properties-panel--title--main">
          <span class="properties-panel--title--main--text">
            {{ title }}
          </span>
          <pds-icon
            v-if="!disabled"
            icon="cloud_done"
            class="properties-panel--cloud-icon"
            :isOutlined="true"
            v-tooltip="{
              content: `Changes locally saved <br>
                    (Save the process before exiting <br>
                    to prevent losing them)`,
              trigger: 'hover',
              boundariesElement: 'window',
            }"
          />
        </div>
        <div class="properties-panel--title--action-type" v-if="actionType">
          Action type: {{ actionType }}
        </div>
      </div>
      <div class="properties-panel--actions">
        <span @click="close" v-if="closable"
          ><pds-icon id="processDesignerPropPanelClose" icon="close"
        /></span>
      </div>
    </div>
    <div class="properties-panel--body">
      <pup-test-action
        v-if="
          (isTestable && this.isDesktop) || (isTestActionVisible && !isDesktop)
        "
        :node="_node"
        :isFullScreen="isFullScreen"
        @open="isTestActionOpened = true"
        @close="closeTestAction"
      />
      <div
        :class="[
          'properties-panel--body--content',
          isTestable && 'properties-panel--body--content--testable',
        ]"
        v-if="isDesktop || (!isDesktop && !isTestActionVisible)"
      >
        <div class="tabs">
          <div class="tabs--header">
            <div
              v-for="(tab, index) in _node.configuration"
              :key="tab.id"
              class="tab"
              :class="[activeTab.id === tab.id && `tab--active`]"
              @click="() => setActiveTab(tab, index)"
            >
              {{ tab.label }}
            </div>
          </div>
          <div class="tabs--body">
            <template
              v-if="
                hasConfiguration && activeTab.id === _node.configuration[0].id
              "
            >
              <pds-input
                label="Action name"
                v-model="node.name"
                :maxlength="32"
                class="pds-u-m--b--8"
                :required="true"
                :status="{
                  type: 'danger',
                  message:
                    (controlsForm.controls.name &&
                      controlsForm.controls.name.errors) ||
                    [],
                }"
                :disabled="disabled"
              />
              <pds-message
                v-if="actionDocumentation && actionDocumentation.url"
                class="pds-u-m--b--8 pds-u-m--l--4"
                size="small"
                variation="ghost"
                type="help"
              >
                <span
                  @click="openDocumentationUrl"
                  class="properties-panel--guide-link"
                  v-tooltip="{
                    content: actionDocumentation.image
                      ? `<img src='${actionDocumentation.image}'/>`
                      : null,
                    boundariesElement: 'window',
                    classes: 'properties-panel--guide-link--tooltip',
                  }"
                >
                  Learn more</span
                >
                about this action.
              </pds-message>

              <pds-input
                type="textarea"
                label="Description"
                v-model="node.description"
                class="pds-u-m--t--8 pds-u-m--b--16"
                :disabled="disabled"
              />
            </template>

            <pds-message v-if="!hasConfiguration" type="error">
              Unable to find current action configuration. Please check
              <b>Toolbar > Custom Actions</b> tab.
              <br />
              <br />
              <b>Note:</b> if the current process was imported from another
              workspace, custom actions must be reuploaded and configured
              accordingly.
            </pds-message>

            <pup-control-global
              v-for="setting in activeTab.settings"
              :parent="_node"
              :key="setting.id"
              :keyProp="setting.id"
              :setting="setting"
              @ui="$emit('ui', { ...setting, parent: _node })"
              class="pds-u-m--b--16"
              type="ghost"
              color="primary"
              :isFullWidth="true"
              :storeDataAs="storeDataAs"
              :processVariables="processVariables"
              :flow="flow"
              :status="setting.status"
              :disabled="disabled"
              @update-input="onInputUpdate($event, setting.id)"
              @blur="blurHandler(setting.id)"
            >
              {{ setting.label }}
            </pup-control-global>
          </div>
        </div>
        <div
          class="properties-panel--body--content--testable--responsive"
          v-if="isTestable && !isDesktop"
        >
          <pds-button
            class="
              properties-panel--body--content--testable--responsive--button
            "
            color="subtle"
            type="outlined"
            @click="goToTestAction"
          >
            Test Action
          </pds-button>
        </div>
      </div>
    </div>
    <!-- <div class="properties-panel--footer">
      <pds-button
        class="pds-u-m--r--24"
        @click="cancel"
        type="link"
        color="neutral"
        size="small"
        >Cancel</pds-button
      >
      <pds-button @click="save" type="solid" color="primary" size="small"
        >Save changes</pds-button
      >
    </div> -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

/** APP RELATED */
import { EventBus, Events } from "@/utils/eventBus";

import {
  IconComponent,
  InputComponent,
  SelectComponent,
  RadioboxComponent,
  ButtonComponent,
  CheckboxComponent,
  ResponsiveMixin,
  PdsTypes,
  MessageComponent,
} from "@procesio/procesio-design-system";

import UploadSettingComponent from "@/modules/ProcessDesigner/components/Controls/UploadSetting/UploadSetting.component.vue";

import {
  GenericSetting,
  isNodeTestable,
  Node,
  Setting,
  SettingTab,
  SettingType,
} from "./PropertiesPanel.model";

import InputDataModelSelector from "@/modules/ProcessDesigner/components/Controls/InputDataModelSelector/InputDataModelSelector.component.vue";

import ApiCallComponent from "@/services/apiCall/ApiCall.service";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { Flow } from "@/services/crud/Orchestration.service";

export const ControlsBus = new Vue();

import { Controls } from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Controls";
import { FormBuilder, Validators } from "@/utils/ReactiveForm";
import TestAction from "../Controls/TestAction/TestAction.component.vue";
import { cloneDeep } from "lodash";
import { omitDeep, pickDeep } from "@/utils/type/object";
import { mapGetters } from "vuex";

@Component({
  components: {
    "pds-icon": IconComponent,
    "pds-input": InputComponent,
    "input-selector": InputDataModelSelector,
    "pds-checkbox": CheckboxComponent,
    "pds-select": SelectComponent,
    "pds-radio": RadioboxComponent,
    "pds-button": ButtonComponent,
    "pds-message": MessageComponent,
    "upload-setting": UploadSettingComponent,
    "pup-test-action": TestAction,
  },
  computed: {
    ...mapGetters({
      openedSidePanelId: "openedSidePanelId",
    }),
  },
})
export default class PropertiesPanel extends mixins(Controls, ResponsiveMixin) {
  //================================================================================
  // Properties
  //================================================================================
  @Prop({ default: () => ({}) }) node!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop() flow!: Flow | null;

  @Prop({ default: true, type: Boolean }) closable!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  controlsForm = new FormBuilder();

  isTestActionVisible = false;

  isTestActionOpened = false;

  isFullScreen = false;

  // property to store node confgiration value
  // because it is not possible to get previous object value in its watcher
  // DOCUMENTATION SAYS: Note: when mutating (rather than replacing) an Object or an Array, the old value will be the same as new value because they reference the same Object/Array. Vue doesn’t keep a copy of the pre-mutate value.
  savedNodeConfiguration: SettingTab[] | null = null;

  @Watch("activeTab", { immediate: true })
  onActiveTabChange() {
    const fields = this.activeTab?.settings;
    if (!fields) return;

    fields.forEach((field: GenericSetting) => {
      const rules = [];
      field.isRequired && rules.push(Validators.required);
      this.controlsForm.control(field.id, field.value, rules);
    });
  }
  //================================================================================
  // Watchers
  //================================================================================
  // @Watch("activeTab.settings", { deep: true })
  // onActiveTabSettingChange() {
  //   this.checkNotTrackedFieldsUpdate();
  // }

  @Watch("node", { immediate: true })
  onNodeChanged(node: Node) {
    if (!node?.id) {
      return;
    }

    this._node = {
      ...node,
      name: node.name,
      description: node.description,
      configuration: node.configuration
        .sort((x, y) => x.orderId - y.orderId)
        .map((settingTab) => ({
          id: settingTab.id,
          label: settingTab.label,
          orderId: settingTab.orderId,
          settings: settingTab.settings
            .sort((x, y) => x.rowId - y.rowId)
            // TODO: rewrite it as a recursive function
            .map((setting) => {
              if (Array.isArray(setting.value)) {
                return {
                  ...setting,
                  value: setting.value.sort((x, y) => x.rowId - y.rowId),
                };
              } else {
                return setting;
              }
            }),
        })),
    };

    // console.log("NODE CHANGED");
    this.$forceUpdate();

    this._node.configuration.forEach((config) => {
      this.traverseSettings(config.settings);
    });

    if (this.disabled) {
      this.activeIndex = 0;
      this.activeTab = this.hasConfiguration
        ? this._node.configuration[this.activeIndex]
        : this.defaultTab;
    }
  }

  @Watch("node.name")
  @Watch("node.description")
  onNodeParamsChange() {
    this.controlsForm = new FormBuilder();
    this.controlsForm.control("name", this.node.name, [Validators.required]);
    this.controlsForm.validate();

    this.save();
  }

  storeDataAs: Setting | null = null;

  @Watch("_node.configuration", { immediate: true, deep: true })
  async onConfigChanged(val: SettingTab[]) {
    EventBus.$emit("find-variables", this._node);

    this.activeTab =
      Array.isArray(val) && !!val.length
        ? val[this.activeIndex]
        : this.defaultTab;

    this.$forceUpdate();

    if (val) {
      this.traverseControls(val, async (setting) => {
        if (setting.type === SettingType.DATA_TYPE) {
          this.storeDataAs = setting;
        }
      });

      const clearedValue = this.omitNotTrackedFieldsFromConfiguration(val);
      const clearedSavedValue = this.savedNodeConfiguration
        ? this.omitNotTrackedFieldsFromConfiguration(
            this.savedNodeConfiguration
          )
        : null;

      if (
        !!clearedSavedValue &&
        JSON.stringify(clearedValue) !== JSON.stringify(clearedSavedValue)
      ) {
        this.$emit("configChanged");
      }

      this.savedNodeConfiguration = cloneDeep(val);
    }

    // console.log("_NODE CONFIG CHANGED");
  }

  @Watch("openedSidePanelId")
  onSidePanelUpdate() {
    if (this.openedSidePanelId && this.isTestActionOpened) {
      EventBus.$emit("side-tabs-open", true);
      EventBus.$emit(
        Events["TEST_ACTION:OPEN_IN_SIDE_PANEL"],
        this.openedSidePanelId
      );
    }
  }

  checkNotTrackedFieldsUpdate() {
    if (!this._node?.configuration) {
      return;
    }

    const pickedValue = this.pickNotTrackedFieldsFromConfiguration(
      this._node.configuration
    );
    const pickedSavedValue = this.savedNodeConfiguration
      ? this.pickNotTrackedFieldsFromConfiguration(this.savedNodeConfiguration)
      : null;

    if (
      !!pickedSavedValue &&
      JSON.stringify(pickedValue) !== JSON.stringify(pickedSavedValue)
    ) {
      this.$emit("notTrackedConfigChanged");
    }
  }

  omitNotTrackedFieldsFromConfiguration(value: SettingTab[]) {
    if (!value) {
      return value;
    }

    return cloneDeep(value).map((tab) => {
      tab.settings = tab.settings.map((setting) => omitDeep(setting, "status"));
      return tab;
    });
  }

  pickNotTrackedFieldsFromConfiguration(value: SettingTab[]) {
    if (!value) {
      return value;
    }

    return cloneDeep(value).map((tab) => {
      tab.settings = tab.settings.map((setting) => pickDeep(setting, "status"));
      return tab;
    });
  }

  get isTestable() {
    if (!this._node || this.disabled) {
      return false;
    }

    return isNodeTestable(this._node);
  }

  get title() {
    if (!this.node) {
      return null;
    }

    return this.node.name;
  }

  get action() {
    if (!this._node || !this.node) {
      return null;
    }

    return this.$store.getters.getActionById(this._node.templateId);
  }

  get actionType() {
    return this.action ? this.action.name : null;
  }

  get actionDocumentation() {
    return this.action ? this.action.documentation : null;
  }

  get hasConfiguration() {
    return (
      this._node &&
      Array.isArray(this._node.configuration) &&
      !!this._node.configuration.length
    );
  }

  //================================================================================
  // Class variables
  //================================================================================
  _node: Node | null | undefined = null;

  activeIndex = 0;

  activeTab: SettingTab = { id: "", label: "", settings: [], orderId: 0 };
  defaultTab: SettingTab = { id: "", label: "", settings: [], orderId: 0 };

  //================================================================================
  // Lifecycle hooks
  //================================================================================
  mounted() {
    ControlsBus.$on("set:base-url", this.setBaseUrl);
    EventBus.$on(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  destroyed() {
    ControlsBus.$off("set:base-url", this.setBaseUrl);
    EventBus.$off(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      (isFullScreen: boolean, settingId: string | null) => {
        if (settingId) {
          this.isFullScreen = isFullScreen;
        }
      }
    );
  }

  //================================================================================
  // Emit functions
  //================================================================================
  @Emit("close")
  close() {
    this.controlsForm.reset();
    return false;
  }

  save() {
    if (this._node) {
      this._node.name = this.node.name;
      this._node.description = this.node.description;
      this.$emit("save", this._node);
    }
  }

  traverseSettings(settings: Setting[]) {
    settings.forEach((setting) => {
      if (Array.isArray(setting.value)) {
        this.traverseSettings(setting.value);
      } else {
        if (setting.type === SettingType.TABS_PAYLOAD_OLD) {
          if (!setting.value) {
            setting.value = {
              queryParams: [],
              headers: [],
              body: "",
            };
          }
        }
      }
    });
  }

  cancel() {
    this.close();
  }

  setActiveTab(tab: SettingTab, index: number) {
    this.activeTab = tab;

    this.activeIndex = index;
  }

  setBaseUrl(val: string) {
    ApiCallComponent.setBase(this.node.id, val);
  }

  onInputUpdate(payload: any, id: string) {
    const control = this.controlsForm.controls[id];
    if (control) {
      control.value = payload;
      this.doFieldValidation(id);
    }
  }

  doFieldValidation(id: string) {
    const control = this.controlsForm.controls[id];
    if (!control) {
      return;
    }

    control.validate();

    const setting = this.activeTab.settings.find(
      (setting) => setting.id === id
    );
    if (setting) {
      Vue.set(setting, "status", {
        type: PdsTypes.StatusType.ERROR,
        message: control.errors || [],
      });
    }
  }

  blurHandler(id: string) {
    this.doFieldValidation(id);
  }

  goToTestAction() {
    this.isTestActionVisible = true;
  }

  closeTestAction() {
    this.isTestActionVisible = false;
    this.isTestActionOpened = false;
  }

  openDocumentationUrl() {
    let url = this.actionDocumentation?.url;

    if (url) {
      if (!url.match(/^https?:\/\//i)) {
        url = "https://" + url;
      }

      window.open(url, "_blank");
    }
  }
}
</script>
<style lang="scss">
@import "./PropertiesPanel.component.scss";
</style>
