import { ValueDataTypes } from "@/modules/ProcessDesigner/Values/ValueDataTypesHelper";
import {
  isCustomTypeAllowed,
  isPrimitive,
  NonPrimitives,
  Primitives,
} from "@/utils/dataTypeMapper";
import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { DataModel } from "@/services/datamodel/DataModel.model";

export class SettingValidation {
  static validateDataType(
    { scalar: scalarDataTypes, list: listDataTypes }: ValueDataTypes,
    setting: Setting,
    excludedDataTypesId: string[] = []
  ): boolean {
    if (scalarDataTypes.length === 0 && listDataTypes.length === 0) {
      return true;
    }

    // check for list type
    if (
      (setting.isList &&
        (scalarDataTypes.length !== 0 || listDataTypes.length !== 1)) ||
      (!setting.isList &&
        (scalarDataTypes.length === 0 || listDataTypes.length !== 0))
    ) {
      return false;
    }

    const settingDataTypeId = setting.dataTypeId;

    const validTypeFlags = [...scalarDataTypes, ...listDataTypes]
      .filter((dataType) => !!dataType)
      .reduce((flags: boolean[], dataType: DataModel) => {
        let flag = false;

        if (excludedDataTypesId.includes(dataType.id)) {
          flag = false;
        }
        // Object data type means any type is allowed
        else if (settingDataTypeId === NonPrimitives.OBJECT) {
          flag = true;
        } else if (isPrimitive(dataType.id)) {
          flag =
            settingDataTypeId === Primitives.STRING
              ? true
              : dataType.id === settingDataTypeId;
        } else {
          flag =
            isCustomTypeAllowed(settingDataTypeId) ||
            dataType.id === settingDataTypeId;
        }

        flags.push(flag);
        return flags;
      }, []);

    return validTypeFlags.every((flag: boolean) => flag);
  }
}
