var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-payload-body"},[_c('pds-radio',{staticClass:"pup-c-payload-body--radios pds-u-m--b--16",attrs:{"options":_vm.bodyTypes,"variation":"tiny","direction":"horizontal","disabled":_vm.disabled},model:{value:(_vm.value.type),callback:function ($$v) {_vm.$set(_vm.value, "type", $$v)},expression:"value.type"}}),_vm._v(" "),(_vm.value.type === _vm.payloadBodyTypes.RAW)?_c('pds-select',{staticClass:"pup-c-payload-body--raw--select pds-u-m--t--8",attrs:{"options":_vm.typeFormats,"disabled":_vm.disabled},model:{value:(_vm.typeFormat),callback:function ($$v) {_vm.typeFormat=$$v},expression:"typeFormat"}}):_vm._e(),_vm._v(" "),_c(_vm.component,{tag:"component",class:[
      'pup-c-payload-body--tab--table',
      _vm.value.type && 'pup-c-payload-body--tab--' + _vm.value.type.toLowerCase(),
    ],attrs:{"label":_vm.componentLabel,"processVariables":_vm.processVariables,"settings":_vm.genericTextSetting,"status":_vm.genericTextStatus,"config":_vm.tableConfig,"parent":_vm.parent,"lang":_vm.typeFormat,"autoformat":true,"newRowAutopopulate":"","disabled":_vm.disabled,"fullScreenTitle":_vm.fullScreenTitle},scopedSlots:_vm._u([(_vm.value.type === _vm.payloadBodyTypes.FORM_DATA)?{key:"key",fn:function({ row }){return [_c('pup-typed-input',{attrs:{"value":row,"disabled":_vm.disabled}})]}}:null,{key:"value",fn:function({ row }){return [_c('pup-expandable-input',{attrs:{"inlineInputType":_vm.inlineInputTypes.GENERIC_TEXT,"processVariables":_vm.processVariables,"placeholder":row.type === _vm.tabsPayloadItemTypes.FILE
            ? 'Select file variable'
            : 'Insert value',"parent":_vm.parent,"disabled":_vm.disabled,"settings":_vm.settings && row.type === _vm.tabsPayloadItemTypes.FILE
            ? {
                ..._vm.settings,
                dataTypeId: _vm.nonPrimitives.FILE,
              }
            : _vm.settings,"fullScreenTitle":_vm.fullScreenTitle,"oneVariableOnly":row.type === _vm.tabsPayloadItemTypes.FILE},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})]}},(!_vm.disabled)?{key:"actions-per-row",fn:function(props){return [_c('div',{staticClass:"pup-c-tabs-payload--tab--table--actions"},[(props.index < _vm.tableConfig.items.length - 1 && !_vm.disabled)?_c('pds-icon',{staticClass:"pup-c-tabs-payload--tab--table--actions--delete",attrs:{"icon":"clear"},on:{"click":function($event){return _vm.deleteRow(props.index)}}}):_vm._e()],1)]}}:null,{key:"fullScreenEditorPrepend",fn:function(){return [(_vm.value.type === _vm.payloadBodyTypes.RAW)?_c('pds-select',{staticClass:"pup-c-payload-body--raw--select pds-u-m--t--16 pds-u-m--l--16",attrs:{"options":_vm.typeFormats,"disabled":_vm.disabled},model:{value:(_vm.typeFormat),callback:function ($$v) {_vm.typeFormat=$$v},expression:"typeFormat"}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.typeValue),callback:function ($$v) {_vm.typeValue=$$v},expression:"typeValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }