var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.infoText)?_c('pds-message',{attrs:{"text":_vm.infoText}}):_vm._e(),_vm._v(" "),(_vm.leftColumnLabel || _vm.rightColumnLabel)?_c('div',{class:[
      _vm.infoText ? 'pds-u-m--t--16' : 'pds-u-m--t--8',
      'pds-u-input-label',
      'pup-c-variable-mapper--subtitle',
    ]},[_c('p',{class:['pds-u-m--l--8']},[_vm._v("\n      "+_vm._s(_vm.leftColumnLabel)+"\n    ")]),_vm._v(" "),_c('p',{class:['pup-c-variable-mapper--subtitle--right']},[_vm._v("\n      "+_vm._s(_vm.rightColumnLabel)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('vue-draggable',{attrs:{"list":_vm.internalValue,"options":{
      disabled: !_vm.draggable,
      filter: '.pup-c-input-group',
      preventOnFilter: false,
    }}},_vm._l((_vm.internalValue),function(column,index){return _c('pup-variable-mapper-row',{key:column.id,attrs:{"index":index,"value":column,"settings":_vm.settings,"leftInputPlaceholder":_vm.leftInputPlaceholder,"rightInputPlaceholder":_vm.rightInputPlaceholder,"leftColumnVariables":_vm.leftColumnVariables,"leftColumnVariableScopes":_vm.leftColumnVariableScopes,"rightColumnVariables":_vm.rightColumnVariables,"rightColumnVariableScopes":_vm.rightColumnVariableScopes,"isLeftInputOneVariableOnly":_vm.isLeftInputOneVariableOnly,"isRightInputOneVariableOnly":_vm.isRightInputOneVariableOnly,"parent":_vm.parent,"direction":_vm.direction,"hideCreateLeftInput":_vm.hideCreateLeftInput,"hideCreateRightInput":_vm.hideCreateRightInput,"disabled":_vm.disabled},on:{"remove-item":_vm.removeRow}})}),1),_vm._v(" "),(!_vm.disabled)?_c('pds-button',{staticClass:"pds-u-m--t--8",attrs:{"type":"link","color":"ordinary","size":"small","ignoreFormatting":""},on:{"click":_vm.addColumn}},[_vm._v("\n    "+_vm._s(_vm.addButtonText)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }