import { Userpilot } from "userpilot";
import { store } from "@/store";

enum UserPilotKeys {
  CREATED_AT = "created_at",
}

export const init = () => {
  if (store.getters.userId && store.getters.createdAt) {
    Userpilot.identify(store.getters.userId, {
      [UserPilotKeys.CREATED_AT]: store.getters.createdAt.toISOString(),
    });
  }
};
