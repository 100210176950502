import { getPlatform, Platform } from "@/utils/platform";

export const KeyboardCode = {
  BACKSPACE: "backspace",
  TAB: "tab",
  ENTER: "enter",
  SHIFT: "shift",
  CTRL: "control",
  ALT: "alt",
  PAUSE: "pause",
  CAPS_LOCK: "capslock",
  ESC: "escape",
  SPACE: "space",
  PAGE_UP: "pageup",
  PAGE_DOWN: "pagedown",
  END: "end",
  HOME: "home",
  ARROW_LEFT: "arrowleft",
  ARROW_UP: "arrowup",
  ARROW_RIGHT: "arrowright",
  ARROW_DOWN: "arrowdown",
  PRINT_SCREEN: "printscreen",
  INSERT: "insert",
  DELETE: "delete",
  0: "digit0",
  1: "digit1",
  2: "digit2",
  3: "digit3",
  4: "digit4",
  5: "digit5",
  6: "digit6",
  7: "digit7",
  8: "digit8",
  9: "digit9",
  A: "keya",
  B: "keyb",
  C: "keyc",
  D: "keyd",
  E: "keye",
  F: "keyf",
  G: "keyg",
  H: "keyh",
  I: "keyi",
  J: "keyj",
  K: "keyk",
  L: "keyl",
  M: "keym",
  N: "keyn",
  O: "keyo",
  P: "keyp",
  Q: "keyq",
  R: "keyr",
  S: "keys",
  T: "keyt",
  U: "keyu",
  V: "keyv",
  W: "keyw",
  X: "keyx",
  Y: "keyy",
  Z: "keyz",
  // META_LEFT: "metaleft",
  // META_RIGHT: "metaright",
  META: "meta",
  CONTEXT_MENU: "contextmenu",
  NUMPAD_0: "numpad0",
  NUMPAD_1: "numpad1",
  NUMPAD_2: "numpad2",
  NUMPAD_3: "numpad3",
  NUMPAD_4: "numpad4",
  NUMPAD_5: "numpad5",
  NUMPAD_6: "numpad6",
  NUMPAD_7: "numpad7",
  NUMPAD_8: "numpad8",
  NUMPAD_9: "numpad9",
  NUMBAD_MULTIPLY: "numpadmultiply",
  NUMPAD_ADD: "numpadadd",
  NUMPAD_SUBTRACT: "numpadsubtract",
  NUMPAD_DECIMAL: "numpaddecimal",
  NUMPAD_DIVIDE: "numpaddivide",
  F1: "f1",
  F2: "f2",
  F3: "f3",
  F4: "f4",
  F5: "f5",
  F6: "f6",
  F7: "f7",
  F8: "f8",
  F9: "f9",
  F10: "f10",
  F11: "f11",
  F12: "f12",
  NUM_LOCK: "numlock",
  SCROLL_LOCK: "scrolllock",
  SEMICOLON: "semicolon", // ;
  EQUAL: "equal", // =
  COMMA: "comma", // ,
  DASH: "minus", // -
  PERIOD: "period", // .
  FORWARD_SLASH: "slash", // /
  BACKQUOTE: "backquote", // `
  BRACKET_LEFT: "bracketleft", // [
  BACK_SLASH: "backslash", // \
  BRACKET_RIGHT: "bracketright", // ]
  SINGLE_QUOTE: "quote", // '
};

export const getKeyboardEventCode = (event: KeyboardEvent) => {
  if (!event.code) {
    return null;
  }

  // DOM_KEY_LOCATION_LEFT = 1; DOM_KEY_LOCATION_RIGHT = 2;
  if (![1, 2].includes(event.location)) {
    return event.code.toLowerCase();
  }

  switch (event.key.toLowerCase()) {
    case KeyboardCode.SHIFT:
      return KeyboardCode.SHIFT;
    case KeyboardCode.CTRL:
      return KeyboardCode.CTRL;
    case KeyboardCode.ALT:
      return KeyboardCode.ALT;
    case KeyboardCode.META:
      return KeyboardCode.META;
    default:
      return event.code.toLowerCase();
  }
};

export const getKeyLabel = (code: string) => {
  let labels: { [key in string]: string } = {
    [KeyboardCode.BACKSPACE]: "backspace",
    [KeyboardCode.TAB]: "tab",
    [KeyboardCode.ENTER]: "enter",
    [KeyboardCode.SHIFT]: "shift",
    [KeyboardCode.CTRL]: "ctrl",
    [KeyboardCode.ALT]: "alt",
    [KeyboardCode.PAUSE]: "pause",
    [KeyboardCode.CAPS_LOCK]: "capsLock",
    [KeyboardCode.ESC]: "esc",
    [KeyboardCode.SPACE]: "␣",
    [KeyboardCode.PAGE_UP]: "pageUp",
    [KeyboardCode.PAGE_DOWN]: "pageDown",
    [KeyboardCode.END]: "end",
    [KeyboardCode.HOME]: "home",
    [KeyboardCode.ARROW_LEFT]: "←",
    [KeyboardCode.ARROW_UP]: "↑",
    [KeyboardCode.ARROW_RIGHT]: "→",
    [KeyboardCode.ARROW_DOWN]: "↓",
    [KeyboardCode.PRINT_SCREEN]: "printScreen",
    [KeyboardCode.INSERT]: "insert",
    [KeyboardCode.DELETE]: "⌫",
    [KeyboardCode[0]]: "0",
    [KeyboardCode[1]]: "1",
    [KeyboardCode[2]]: "2",
    [KeyboardCode[3]]: "3",
    [KeyboardCode[4]]: "4",
    [KeyboardCode[5]]: "5",
    [KeyboardCode[6]]: "6",
    [KeyboardCode[7]]: "7",
    [KeyboardCode[8]]: "8",
    [KeyboardCode[9]]: "9",
    [KeyboardCode.A]: "a",
    [KeyboardCode.B]: "b",
    [KeyboardCode.C]: "c",
    [KeyboardCode.D]: "d",
    [KeyboardCode.E]: "e",
    [KeyboardCode.F]: "f",
    [KeyboardCode.G]: "g",
    [KeyboardCode.H]: "h",
    [KeyboardCode.I]: "i",
    [KeyboardCode.J]: "j",
    [KeyboardCode.K]: "k",
    [KeyboardCode.L]: "l",
    [KeyboardCode.M]: "m",
    [KeyboardCode.N]: "n",
    [KeyboardCode.O]: "o",
    [KeyboardCode.P]: "p",
    [KeyboardCode.Q]: "q",
    [KeyboardCode.R]: "r",
    [KeyboardCode.S]: "s",
    [KeyboardCode.T]: "t",
    [KeyboardCode.U]: "u",
    [KeyboardCode.V]: "v",
    [KeyboardCode.W]: "w",
    [KeyboardCode.X]: "x",
    [KeyboardCode.Y]: "y",
    [KeyboardCode.Z]: "z",
    [KeyboardCode.META]: "Win",
    [KeyboardCode.CONTEXT_MENU]: "Context Menu",
    [KeyboardCode.NUMPAD_0]: "0",
    [KeyboardCode.NUMPAD_1]: "1",
    [KeyboardCode.NUMPAD_2]: "2",
    [KeyboardCode.NUMPAD_3]: "3",
    [KeyboardCode.NUMPAD_4]: "4",
    [KeyboardCode.NUMPAD_5]: "5",
    [KeyboardCode.NUMPAD_6]: "6",
    [KeyboardCode.NUMPAD_7]: "7",
    [KeyboardCode.NUMPAD_8]: "8",
    [KeyboardCode.NUMPAD_9]: "9",
    [KeyboardCode.NUMBAD_MULTIPLY]: "*",
    [KeyboardCode.NUMPAD_ADD]: "+",
    [KeyboardCode.NUMPAD_SUBTRACT]: "-",
    [KeyboardCode.NUMPAD_DECIMAL]: ".",
    [KeyboardCode.NUMPAD_DIVIDE]: "/",
    [KeyboardCode.F1]: "f1",
    [KeyboardCode.F2]: "f2",
    [KeyboardCode.F3]: "f3",
    [KeyboardCode.F4]: "f4",
    [KeyboardCode.F5]: "f5",
    [KeyboardCode.F6]: "f6",
    [KeyboardCode.F7]: "f7",
    [KeyboardCode.F8]: "f8",
    [KeyboardCode.F9]: "f9",
    [KeyboardCode.F10]: "f10",
    [KeyboardCode.F11]: "f11",
    [KeyboardCode.F12]: "f12",
    [KeyboardCode.NUM_LOCK]: "numLock",
    [KeyboardCode.SCROLL_LOCK]: "scrollLock",
    [KeyboardCode.SEMICOLON]: ";",
    [KeyboardCode.EQUAL]: "=",
    [KeyboardCode.COMMA]: ",",
    [KeyboardCode.DASH]: "-",
    [KeyboardCode.PERIOD]: ".",
    [KeyboardCode.FORWARD_SLASH]: "/",
    [KeyboardCode.BACKQUOTE]: "`",
    [KeyboardCode.BRACKET_LEFT]: "[",
    [KeyboardCode.BACK_SLASH]: "\\",
    [KeyboardCode.BRACKET_RIGHT]: "]",
    [KeyboardCode.SINGLE_QUOTE]: "'",
  };

  if (getPlatform() === Platform.MAC) {
    labels = {
      ...labels,
      [KeyboardCode.SHIFT]: "⇧",
      [KeyboardCode.ALT]: "⌥",
      [KeyboardCode.META]: "⌘",
    };
  }

  return labels[code] || code;
};
