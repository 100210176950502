<template>
  <div>
    <pds-input
      style="width: 100%"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :required="required"
      @blur="handleBlurInput($event)"
      :tooltip="tooltip"
      :disabled="disabled || !variableType"
      :status="status"
    />
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";

import { Prop, Model, Watch, Component } from "vue-property-decorator";

import { InputComponent, PdsTypes } from "@procesio/procesio-design-system";

import { ActionTypes } from "@/store/process/Process.actions";
import { createGuid } from "@/utils/type/guid";
import { Variable, VariableType } from "@/services/crud/Orchestration.service";
import { Node } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { Variable as VariableMixin } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { FOREACH_LIST_SETTING } from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Settings";
import { Primitives } from "@/utils/dataTypeMapper";
import { EventBus, Events } from "@/utils/eventBus";

@Component({
  components: {
    "pds-input": InputComponent,
  },
})
export default class ScopedVariable extends mixins(VariableMixin) {
  @Prop() label!: string;

  @Prop() placeholder!: string;

  @Prop() tooltip?: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Model("update-input") value!: string;

  @Prop() required!: boolean;

  @Prop() parent!: Node;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  variable!: Variable | null;

  internalValue = "";

  variableType = "";

  @Watch("value", { immediate: true, deep: true })
  onValueChange(value: string) {
    this.variable =
      this.$store.state.processes.variables.find(
        (variable: Variable) => variable.id === value
      ) || null;

    if (this.variable) {
      this.internalValue = this.variable.name;
      this.variableType = this.variable.dataType;
    } else {
      this.internalValue = "";
      if (this.listVariableId) {
        this.onListVariableIdChange(this.listVariableId);
      } else {
        this.variableType = "";
      }
    }
  }

  @Watch("listVariableId", { immediate: true })
  onListVariableIdChange(listVariableId: string | null) {
    if (listVariableId) {
      const type = this.getVariableDataType(listVariableId);
      const typeId = type ? type.id : Primitives.STRING;
      this.variableType = typeId;
    } else {
      this.deleteVariable();
    }
  }

  get listVariableId() {
    let variableId = null;

    this.parent.configuration.forEach((config) => {
      config.settings.forEach((setting) => {
        if (setting.id === FOREACH_LIST_SETTING) {
          variableId =
            typeof setting.value === "string" ? setting.value.trim() : null;
        }
      });
    });

    return variableId;
  }

  handleBlurInput(blurEvent: FocusEvent) {
    if (!this.internalValue.length) {
      this.internalValue = this.variable
        ? this.variable.name
        : this.internalValue;
    } else {
      const variableWithSameName = this.$store.state.processes.variables.find(
        (variable: Variable) =>
          !variable.isDeleted &&
          !!variable.name.length &&
          this.internalValue === variable.name &&
          this.variable?.id !== variable.id
      );
      if (variableWithSameName) {
        this.internalValue =
          this.variable && variableWithSameName.isDeleted
            ? this.variable.name
            : "";
      }
    }

    if (this.variable?.name !== this.internalValue) {
      const variableId = this.setVariable(this.internalValue);
      this.$emit("update-input", variableId);
      this.variable = this.$store.getters.getVariableById(variableId);
    }

    this.$emit("blur", blurEvent);
  }

  setVariable(name: string) {
    let variableId = this.value;

    if (!variableId) {
      const variable =
        this.$store.state.processes.variables.find(
          (variable: Variable) => variable.contextId === this.parent.id
        ) || null;
      variable && (variableId = variable.id);
    }

    if (!variableId) {
      variableId = createGuid();
    }

    this.$store.dispatch(ActionTypes.SET_VARIABLE, {
      dataType: this.variableType,
      defaultValue: null,
      id: variableId,
      isInput: false,
      isList: false,
      isOutput: false,
      name: name,
      type: VariableType.UNDEFINED,
      contextId: this.parent.id,
      parentVariableId: this.listVariableId,
      isReadonly: true,
      isDeleted: false,
    });

    return variableId;
  }

  deleteVariable() {
    if (!this.value) {
      return;
    }

    this.setVariable("");
    EventBus.$emit(Events["VARIABLE:REMOVE"], this.value);
    this.internalValue = "";
    this.variableType = "";
    this.$emit("update-input", null);
  }
}
</script>
