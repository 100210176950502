<template>
  <div>
    <pds-message :text="infoText" v-if="infoText" />
    <div
      :class="[
        infoText ? 'pds-u-m--t--16' : 'pds-u-m--t--8',
        'pds-u-input-label',
        'pup-c-variable-mapper--subtitle',
      ]"
      v-if="leftColumnLabel || rightColumnLabel"
    >
      <p :class="['pds-u-m--l--8']">
        {{ leftColumnLabel }}
      </p>
      <p :class="['pup-c-variable-mapper--subtitle--right']">
        {{ rightColumnLabel }}
      </p>
    </div>

    <!-- COLUMNS -->
    <vue-draggable
      :list="internalValue"
      :options="{
        disabled: !draggable,
        filter: '.pup-c-input-group',
        preventOnFilter: false,
      }"
    >
      <pup-variable-mapper-row
        v-for="(column, index) in internalValue"
        :key="column.id"
        :index="index"
        :value="column"
        @remove-item="removeRow"
        :settings="settings"
        :leftInputPlaceholder="leftInputPlaceholder"
        :rightInputPlaceholder="rightInputPlaceholder"
        :leftColumnVariables="leftColumnVariables"
        :leftColumnVariableScopes="leftColumnVariableScopes"
        :rightColumnVariables="rightColumnVariables"
        :rightColumnVariableScopes="rightColumnVariableScopes"
        :isLeftInputOneVariableOnly="isLeftInputOneVariableOnly"
        :isRightInputOneVariableOnly="isRightInputOneVariableOnly"
        :parent="parent"
        :direction="direction"
        :hideCreateLeftInput="hideCreateLeftInput"
        :hideCreateRightInput="hideCreateRightInput"
        :disabled="disabled"
      />
    </vue-draggable>

    <!-- ADD BUTTON -->
    <pds-button
      type="link"
      color="ordinary"
      size="small"
      class="pds-u-m--t--8"
      @click="addColumn"
      ignoreFormatting
      v-if="!disabled"
    >
      {{ addButtonText }}
    </pds-button>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch, Emit } from "vue-property-decorator";

import {
  IconComponent,
  ButtonComponent,
  MessageComponent,
} from "@procesio/procesio-design-system";

import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import {
  Setting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { Variable } from "@/services/crud/Orchestration.service";
import VariableMapperRow, {
  VariableMapperDirection,
} from "@/modules/ProcessDesigner/components/Controls/VariableMapper/Row.component.vue";
import draggable from "vuedraggable";

export interface VariableMapperValue {
  id: number;
  left: any;
  right: any;
}

@Component({
  components: {
    "pds-icon": IconComponent,
    "generic-text": GenericText,
    "pds-button": ButtonComponent,
    "pup-variable-mapper-row": VariableMapperRow,
    "pds-message": MessageComponent,
    "vue-draggable": draggable,
  },
})
export default class VariableMapperGroupComponent extends Vue {
  @Prop() value!: Array<VariableMapperValue>;

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop({ default: null }) infoText!: string;

  @Prop({ default: null }) leftColumnLabel!: string;

  @Prop({ default: null }) rightColumnLabel!: string;

  @Prop({ default: "+ Add" }) addButtonText!: string;

  @Prop({ default: "" }) leftInputPlaceholder!: string;

  @Prop({ default: "" }) rightInputPlaceholder!: string;

  @Prop({ default: () => [] }) leftColumnVariables!: Variable[];

  @Prop({ default: () => [] }) rightColumnVariables!: Variable[];

  @Prop({ default: () => [] }) leftColumnVariableScopes!: string[] | null;

  @Prop({ default: () => [] }) rightColumnVariableScopes!: string[] | null;

  @Prop({ default: false, type: Boolean }) isLeftInputOneVariableOnly!: boolean;

  @Prop({ default: false, type: Boolean }) isRightInputOneVariableOnly!: boolean;

  @Prop({ default: false, type: Boolean }) hideCreateLeftInput!: boolean;

  @Prop({ default: false, type: Boolean }) hideCreateRightInput!: boolean;

  @Prop({ default: true, type: Boolean }) autoInit!: boolean;

  @Prop() required!: boolean;

  @Prop({ default: VariableMapperDirection.LEFT })
  direction!: VariableMapperDirection;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: false, type: Boolean }) draggable!: boolean;

  internalValue: Array<VariableMapperValue> = [];

  @Watch("value", { immediate: true, deep: true })
  onValueChange(value: Array<VariableMapperValue> | null) {
    const internal = [];
    if (value === null) {
      this.autoInit && internal.push({ id: 0, left: "", right: "" });
    } else {
      value.map((val) => internal.push({ ...val }));
    }

    this.internalValue = internal;
  }

  @Watch("internalValue", { immediate: true, deep: true })
  onInternalValueChange(value: Array<VariableMapperValue> | null) {
    if (this.wasValueSorted(value)) {
      this.$emit("sorted", value);
    } else {
      this.$emit("update-input", value);
    }
  }

  wasValueSorted(value: Array<VariableMapperValue> | null) {
    const originalValue = this.value;

    if (
      !this.draggable ||
      !Array.isArray(value) ||
      !Array.isArray(originalValue) ||
      value.length !== originalValue.length
    ) {
      return false;
    }

    for (let i = 0; i < value.length; i++) {
      if (value[i] && originalValue[i] && value[i].id !== originalValue[i].id) {
        return true;
      }
    }

    return false;
  }

  addColumn() {
    let id = 0;

    if (Array.isArray(this.internalValue) && this.internalValue.length > 0) {
      const lastId = Math.max(...this.internalValue.map((item) => item.id));
      id = lastId + 1;
    }
    this.internalValue.push({
      id,
      left: "",
      right: "",
    });
  }

  @Emit("remove-row")
  removeRow(index: number) {
    this.internalValue.splice(index, 1);
    return index;
  }
}
</script>

<style lang="scss">
@import "./VariableMapper.component.scss";
</style>
