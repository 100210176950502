import VersionService from "@/services/version/Version.service";
import { Commit, Dispatch } from "vuex";
import { MutationTypes } from "./App.mutations";

export enum ActionTypes {
  SET_VERSION = "SET_VERSION",
  SET_AUTH_API_VERSION = "SET_AUTH_API_VERSION",
  SET_BASE_API_VERSION = "SET_BASE_API_VERSION",
  LOAD_VERSION = "LOAD_VERSION",
}

export interface Actions {
  [ActionTypes.SET_VERSION](
    { commit }: { commit: Commit },
    payload: number | null
  ): void;

  [ActionTypes.SET_AUTH_API_VERSION](
    { commit }: { commit: Commit },
    payload: number | null
  ): void;

  [ActionTypes.SET_BASE_API_VERSION](
    { commit }: { commit: Commit },
    payload: number | null
  ): void;

  [ActionTypes.LOAD_VERSION]({ dispatch }: { dispatch: Dispatch }): void;
}

export const actions: any = {
  [ActionTypes.SET_VERSION](
    { commit }: { commit: Commit },
    version: number | null
  ) {
    commit(MutationTypes.SET_VERSION, version);
  },

  [ActionTypes.SET_AUTH_API_VERSION](
    { commit }: { commit: Commit },
    version: number | null
  ) {
    commit(MutationTypes.SET_AUTH_API_VERSION, version);
  },

  [ActionTypes.SET_BASE_API_VERSION](
    { commit }: { commit: Commit },
    version: number | null
  ) {
    commit(MutationTypes.SET_BASE_API_VERSION, version);
  },

  async [ActionTypes.LOAD_VERSION]({ dispatch }: { dispatch: Dispatch }) {
    const response = await VersionService.getCurrentVersion();
    if (!response.content || response.is_error) {
      return;
    }

    dispatch(ActionTypes.SET_VERSION, response.content.version);
    dispatch(ActionTypes.SET_AUTH_API_VERSION, response.content.authApiVersion);
    dispatch(ActionTypes.SET_BASE_API_VERSION, response.content.baseApiVersion);
  },
};
