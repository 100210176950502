import { RequestPayloadOldSetting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { TabsPayloadOldValue } from "@/services/apiCall/tabsPayload/TabsPayload.model";
import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";

export class TabsPayloadOldParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: RequestPayloadOldSetting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      value: {
        body: "",
        headers: [],
        queryParams: [],
      } as TabsPayloadOldValue,
      variable: [] as VariableParameter[],
    };

    const replacedBody = this.parseControlValueToParameter(setting.value.body);
    const unintBody = replacedBody.value || "";
    parameter.variable.push(...replacedBody.variable);

    const unintHeaders = setting.value.headers.map((header) => {
      const replacedHeader = this.parseControlValueToParameter(header.value);

      parameter.variable.push(...replacedHeader.variable);

      return {
        ...header,
        value: replacedHeader.value,
      };
    });

    const unintquery = setting.value.queryParams.map((queryParams) => {
      const replacedQuery = this.parseControlValueToParameter(
        queryParams.value
      );

      parameter.variable.push(...replacedQuery.variable);

      return {
        ...queryParams,
        value: replacedQuery.value,
      };
    });

    parameter.value.body = unintBody;
    parameter.value.headers = unintHeaders;
    parameter.value.queryParams = unintquery;

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (!parameter.variable) {
        return;
      }

      if (Array.isArray(parameter.variable)) {
        variables.push(...parameter.variable);
      } else {
        variables.push(parameter.variable);
      }
    });

    return variables;
  }
}
