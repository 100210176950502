import { Variable } from "@/services/crud/Orchestration.service";
import { store } from "@/store";
import { isGuid } from "@/utils/type/guid";
import { VariableParser } from "./VariableParser";
import {
  DataModel,
  DataModelAttribute,
} from "@/services/datamodel/DataModel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

export default {
  VariableParser,
};

const state: any = store.state;

/**
 * @param fullCheck
 *  if true, check if each part (id) of variable is valid
 *  if false, check if first part (id) of variable is valid
 */
export const hasVariable = (
  value: any,
  fullCheck = true,
  extraVariables: ProcessVariable[] | Variable[] = []
) => {
  if (!isGuid(value)) {
    return false;
  }

  const variablesStr = value
    .split(" ")
    .filter((valueStr: string) => isGuid(valueStr));

  const matches = variablesStr.map((varStr: string) => {
    const ids = varStr.split(".");
    const variable: Variable = [
      ...state.processes.instanceVariables,
      ...state.processes.variables,
      ...store.getters.extraVariablesFlatList,
      ...state.document.variables,
      ...extraVariables,
    ].find((val: Variable) => val.id === ids[0]);

    const variableType: DataModel = store.getters.dataTypes.find(
      (dataType: DataModel) => variable?.dataType === dataType.id
    );

    if (!fullCheck && variableType) {
      return true;
    }

    const targetAttributeId = ids[ids.length - 1];
    if (variable && variable.id === targetAttributeId) {
      return true;
    }

    let match = false;
    let attributeType: DataModel;
    ids.slice(1).forEach((attributeId, index) => {
      const type = index === 0 ? variableType : attributeType;
      const attribute = type?.attributes?.find(
        (attr: DataModelAttribute) => attr.id === attributeId
      );

      if (attribute && attributeId === targetAttributeId) {
        match = true;
      }

      attributeType = store.getters.dataTypes.find(
        (dataType: DataModel) => attribute?.dataTypeId === dataType.id
      );
    });

    return match;
  });

  return matches.some((match: boolean) => match);
};

export const getVariable = (
  value: any,
  extraVariables: ProcessVariable[] | Variable[] = []
) => {
  if (!isGuid(value)) {
    return null;
  }

  const ids = value.split(".");
  if (!ids.length) {
    return null;
  }

  const variable: Variable = [
    ...state.processes.instanceVariables,
    ...state.processes.variables,
    ...store.getters.extraVariablesFlatList,
    ...state.document.variables,
    ...extraVariables,
  ].find((val: Variable) => val.id === ids[0]);

  const variableType: DataModel = store.getters.dataTypes.find(
    (dataType: DataModel) => variable?.dataType === dataType.id
  );

  return variable && variableType ? variable : null;
};

// check to filter context variables (now they have type 100 - context)
export const isContextVariable = (variable: Variable) => {
  const prefix = "contextVariable";
  return (
    variable.name.includes(prefix) && isGuid(variable.name.replace(prefix, ""))
  );
};
