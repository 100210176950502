import { HubConnection } from "@microsoft/signalr";
import { Commit } from "vuex";
import { MutationTypes } from "./signalR.mutations";

export enum ActionTypes {
  SET_CONNECTION = "SET_CONNECTION",
  SET_CONNECTION_ID = "SET_CONNECTION_ID"
}

export interface Actions {
  [ActionTypes.SET_CONNECTION](
    { commit }: { commit: Commit },
    payload: boolean
  ): void;
  [ActionTypes.SET_CONNECTION_ID](
    { commit }: { commit: Commit },
    payload: string | null
  ): void;
}

export const actions: any = {
  [ActionTypes.SET_CONNECTION]({ commit }: { commit: Commit }, connection: HubConnection | null) {
    commit(MutationTypes.SET_CONNECTION, connection);
  },
  [ActionTypes.SET_CONNECTION_ID]({ commit }: { commit: Commit }, connectionId: string | null) {
    commit(MutationTypes.SET_CONNECTION_ID, connectionId);
  }
}