import ls from "../services/localStorage";
import CryptoJS from "crypto-js";

const USER_KEY = "user";
const PASSWORD_KEY = "password";
const VERSION_KEY = "version";
const VERSION_NOTIFICATION_KEY = "version_notification";

const STORAGE_KEYS = {
  USER_KEY,
  PASSWORD_KEY,
  VERSION_KEY,
  VERSION_NOTIFICATION_KEY,
};

const save = (key: string, data: string) => {
  const encrypted = CryptoJS.AES.encrypt(
    data,
    process.env.VUE_APP_ENV_VARIABLE
  ).toString();

  ls.set(key, encrypted);
};

const get = (key: string) => {
  const encrypted = ls.get(key);
  if (encrypted === null) {
    return false;
  }

  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    process.env.VUE_APP_ENV_VARIABLE
  ).toString(CryptoJS.enc.Utf8);

  return decrypted;
};

const clearAll = () => {
  Object.values(STORAGE_KEYS).forEach((key: string) => ls.remove(key));
};

const clear = (key: string) => {
  ls.remove(key);
};

const storage = {
  save,
  get,
  clearAll,
  clear,
  ...STORAGE_KEYS,
};

export default storage;
