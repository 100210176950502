<template>
  <div class="pup-c-tabs" v-if="value.length > 0">
    <div class="pup-c-input-group--holder pds-u-m--b--8">
      <!-- TABS HEADER -->
      <div :class="['pup-c-tabs--header']">
        <div
          v-for="(tab, index) in value"
          :class="[
            'pup-c-tabs--header--tab',
            selectedTab === index ? 'pup-c-tabs--header--tab--selected' : '',
          ]"
          :key="tab.id"
          @click="updateSelected(index)"
        >
          {{ tab.label }}
        </div>
      </div>

      <pds-icon
        v-if="tooltip"
        class="material-icons--grey pds-u-m--l--16"
        icon="info"
        :isOutlined="true"
        v-tooltip="{
          content: tooltip,
        }"
      />
    </div>

    <!-- TAB BODY -->
    <div>
      <pds-tab
        :processVariables="processVariables"
        :parent="parent"
        v-model="value[selectedTab]"
        :disabled="disabled"
      ></pds-tab>
    </div>
  </div>
</template>

<script lang="ts">
/** VUE RELATED */
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

/** DESIGN SYSTEM */
import {
  ButtonComponent,
  IconComponent,
} from "@procesio/procesio-design-system";
import Tab from "./Tab.component.vue";
import {
  Node,
  TabSetting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

@Component({
  components: {
    "pds-button": ButtonComponent,
    "pds-tab": Tab,
    "pds-icon": IconComponent,
  },
})
export default class Tabs extends Vue {
  // tabs: any[] = TabsService.tabs;

  @Prop() tooltip?: string;

  @Prop({ default: () => [] }) value!: TabSetting[];

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  selectedTab = 0;

  updateSelected(index: number) {
    this.selectedTab = index;
  }
}
</script>

<style scoped lang="scss">
@import "./Tabs.component.scss";
</style>
