// Call API action
export const STATUS_OUTPUT_SETTING = "45a7bbde-7e2a-4bd4-a6d3-8420f7790006";
export const BODY_OUTPUT_SETTING = "33a7c142-d895-485d-855b-38103a3dbfab";
export const FILE_OUTPUT_SETTING = "84860281-c009-4f7c-84ab-2cd9b8033d58";
export const CALL_API_OUTPUT_SETTINGS = [
  STATUS_OUTPUT_SETTING,
  BODY_OUTPUT_SETTING,
  FILE_OUTPUT_SETTING,
];

// Call Subprocess action
export const SUBPROCESS_SELECT_SETTING = "bc93d0be-98f6-42d6-b2ac-a782eadd79bf";
export const SUBPROCESS_SIDE_PANEL_SETTING =
  "5456caf0-be8e-4a04-86cb-dbae203af978";

// Trigger Subprocess action
export const TRIGGER_SUBPROCESS_SELECT_SETTING =
  "6824ee16-b6c4-a34e-bd21-f8136346af81";
export const TRIGGER_SUBPROCESS_SIDE_PANEL_SETTING =
  "8540605e-2f4d-1746-9059-8bb7b944e0d3";

// ForEach action
export const FOREACH_LIST_SETTING = "2d5f230f-a1df-4cb5-a0bf-13481fadae8b";
export const FOREACH_LIST_ITEM_SETTING = "57b5b1eb-f412-49e0-a08d-1deea055f8c7";

// Delay action
export const DELAY_SETTING = "04311b79-221e-47f8-a014-4863c6b08bac";
export const DELAY_TYPE_SETTING = "f1293589-6ede-4cc6-a04b-cc70e7084cb0";
export const DELAY_DEFINITION_SETTING = "6d159186-5f36-424c-b7cf-16f4ce495b19";

// Map Process data action
export const MAP_PROCESS_DATA_SETTING = "a5f92824-bfb7-49db-93f4-742d0ed9307d";

// Decisional
export const DECISIONAL_CASES_SETTING = "11d4044a-8586-47f6-b3ce-1cae5da40f30";

// Get file data action
export const GET_FILE_DATA_DEFINITION_SETTING =
  "a0973f35-5d52-4614-8578-0b7f27ce2cc1";
export const GET_FILE_DATA_SIDE_PANEL_SETTING =
  "a9e8877a-1d5e-44ae-baa1-9bbd6f6a1bce";

// Map document action
export const DOCUMENT_SELECT_SETTING = "c7c65d6c-f065-4619-8bde-b731cba1ffb9";
export const DOCUMENT_MAPPER_SETTING = "2283a3cf-5d3b-45d0-bd3d-e4b032d246af";
export const DOCUMENT_MAPPER_SIDE_PANEL_SETTING =
  "9a6fced4-7832-4d44-92d2-7a8d6583fea7";
