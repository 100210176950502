import { PdsTypes } from "@procesio/procesio-design-system";

export interface AnnouncementData {
  id: string;
  message: string;
  messageType?: PdsTypes.MessageType;
  startDate?: Date | null;
  endDate?: Date | null;
}

const dataUrl =
  "https://procesio-asset-hosting.fra1.digitaloceanspaces.com/configuration/Announcements.json";

export const geAnnouncementData = async () => {
  let data: AnnouncementData[] = [];

  try {
    const response = await fetch(dataUrl);
    const json = await response.json();

    data = (Array.isArray(json) ? json : []).map((item) => ({
      id: item.id,
      message: item.message,
      startDate: item.startDate ? new Date(item.startDate) : null,
      endDate: item.endDate ? new Date(item.endDate) : null,
      messageType: item.type,
    }));
  } catch (err) {
    console.log("Announcement data load failed: " + err);
  }

  return data;
};
