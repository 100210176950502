import { RequestPayloadSetting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  getInitialPayloadBody,
  PayloadBodyTypes,
  RawBodyPayloadValue,
  TabsPayloadItem,
  TabsPayloadValue,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";
import {
  Parameter,
  VariableParameter,
} from "@/services/crud/Orchestration.service";
import { AbstractControlParameterGetter } from "../AbstractControlParameterGetter";
import { cloneDeep } from "lodash";

export class TabsPayloadParameterGetter extends AbstractControlParameterGetter {
  getParameter(setting: RequestPayloadSetting): Parameter | Parameter[] {
    const parameter = {
      tabPropertyId: setting.id,
      value: {
        body: getInitialPayloadBody(),
        headers: [],
        queryParams: [],
      } as TabsPayloadValue,
      variable: [] as VariableParameter[],
    };

    const settingBodyValue = cloneDeep(setting?.value?.body?.value || {}) as {
      [key in PayloadBodyTypes]:
        | TabsPayloadItem[]
        | RawBodyPayloadValue
        | string;
    };

    Object.keys(settingBodyValue).forEach((bodyKey: string) => {
      const bodyValue = settingBodyValue[bodyKey as PayloadBodyTypes];
      if (Array.isArray(bodyValue)) {
        settingBodyValue[bodyKey as PayloadBodyTypes] = bodyValue.map(
          (item: TabsPayloadItem) => {
            const replacedHeader = this.parseControlValueToParameter(
              item.value
            );
            parameter.variable.push(...replacedHeader.variable);

            return {
              ...item,
              value: replacedHeader.value,
            };
          }
        );
      } else if (
        typeof bodyValue === "object" &&
        typeof bodyValue.format !== "undefined"
      ) {
        const replacedHeader = this.parseControlValueToParameter(
          bodyValue.value
        );
        parameter.variable.push(...replacedHeader.variable);
        settingBodyValue[bodyKey as PayloadBodyTypes] = {
          ...bodyValue,
          value: replacedHeader.value,
        };
      } else if (bodyValue) {
        const replacedHeader = this.parseControlValueToParameter(
          bodyValue as string
        );
        parameter.variable.push(...replacedHeader.variable);
        settingBodyValue[bodyKey as PayloadBodyTypes] = replacedHeader.value;
      }
    });

    const unintHeaders = (setting.value?.headers || []).map((header) => {
      const replacedHeader = this.parseControlValueToParameter(header.value);

      parameter.variable.push(...replacedHeader.variable);

      return {
        ...header,
        value: replacedHeader.value,
      };
    });

    const unintquery = (setting.value?.queryParams || []).map((queryParams) => {
      const replacedQuery = this.parseControlValueToParameter(
        queryParams.value
      );

      parameter.variable.push(...replacedQuery.variable);

      return {
        ...queryParams,
        value: replacedQuery.value,
      };
    });

    parameter.value.body.type =
      setting.value?.body?.type || PayloadBodyTypes.RAW;
    parameter.value.body.value = settingBodyValue;
    parameter.value.headers = unintHeaders;
    parameter.value.queryParams = unintquery;

    return parameter;
  }

  getVariableParameter(
    parameter: Parameter | Parameter[]
  ): VariableParameter[] {
    const parameters = Array.isArray(parameter) ? parameter : [parameter];

    const variables: VariableParameter[] = [];

    parameters.forEach((parameter) => {
      if (!parameter.variable) {
        return;
      }

      if (Array.isArray(parameter.variable)) {
        variables.push(...parameter.variable);
      } else {
        variables.push(parameter.variable);
      }
    });

    return variables;
  }
}
