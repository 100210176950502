interface ApiCallResponse {
  url: string;
}

export default class ApiCallComponent {
  static baseUrl: Record<string, string> = {};

  static setBase(id: string, val: string) {
    this.baseUrl[id] = val;
  }

  static getUrl(data: string): Promise<{ payload: ApiCallResponse }> {
    const searchDataModel = new Promise<{ payload: ApiCallResponse }>(
      (resolve) => {
        setTimeout(() => {
          resolve({
            payload: {
              url: this.baseUrl[data],
            },
          });
        }, 100);
      }
    );

    return searchDataModel;
  }
}
