import { convertDateFromUTC } from "@/utils/type/date";
import { escapeString } from "@/utils/type/string";
import { msToDuration } from "@/utils/type/time";
import Vue from "vue";

const dateFormatter = (date: Date) => {
  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear()
  );
};

const dateTimeFormatter = (date: Date, withSeconds = false) => {
  return dateFormatter(date) + " " + timeFormatter(date, withSeconds);
};

const timeFormatter = (date: Date, withSeconds = false) => {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: withSeconds ? "2-digit" : undefined,
  });
};

// the filter expects Date in UTC timezone
// Firstly it converts Date to client's timezone, then applies formatting
Vue.filter(
  "formatUTCDateTime",
  function (dateInput: Date | string | null, withSeconds = false) {
    if (!dateInput) {
      return "";
    }

    const date =
      (typeof dateInput === "string" && dateInput.indexOf("Z")) !== -1
        ? new Date(dateInput)
        : convertDateFromUTC(new Date(dateInput));

    return dateTimeFormatter(date, withSeconds);
  }
);

Vue.filter("formatDate", function (dateInput: Date | string | null) {
  if (!dateInput) {
    return "";
  }

  const date = new Date(dateInput);
  return dateFormatter(date);
});

Vue.filter("formatDateTime", function (dateInput: Date | string | null) {
  if (!dateInput) {
    return "";
  }

  const date = new Date(dateInput);
  return dateTimeFormatter(date);
});

Vue.filter("formatTime", function (dateInput: Date | string | null) {
  if (!dateInput) {
    return "";
  }

  const date = new Date(dateInput);
  return timeFormatter(date);
});

Vue.filter("msToDuration", (value: number) => {
  if (!value || isNaN(+value)) {
    value = 0;
  }

  return msToDuration(value);
});

Vue.filter("hours", (value: number) => {
  if (!value || isNaN(+value)) {
    value = 0;
  }

  const formatted = value % 1 === 0 ? value : value.toFixed(2);
  return formatted + " hour" + (formatted === 1 ? "" : "s");
});

Vue.filter("seconds", (value: number) => {
  if (!value || isNaN(+value)) {
    value = 0;
  }

  const formatted = value % 1 === 0 ? value : value.toFixed(2);
  return formatted + " second" + (formatted === 1 ? "" : "s");
});

Vue.filter("escape", (str: string) => escapeString(str));
