<template>
  <div class="pds-c-api--holder">
    <input
      placeholder="{methodeName}"
      :class="['pds-u-body--2', 'pds-c-api--second']"
      :value="value"
      @input="emitModel"
      ref="sinput"
    />
  </div>
</template>

<script lang="ts">
/** vue related */
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SendEmailComponent extends Vue {
  @Prop() value: unknown | string;

  public focused() {
    (this.$refs.sinput as HTMLInputElement).focus();
  }

  @Emit("update-input")
  emitModel(event: any) {
    return event.target.value;
  }
}
</script>

<style scoped lang="scss">
@import "./ApiCall.component.scss";
</style>