<template>
  <pup-variable-mapper-group
    :settings="settings"
    :value="variableMapperValue"
    leftInputPlaceholder="Insert variable"
    rightInputPlaceholder="Insert variable or value"
    :leftColumnVariables="documentVariables"
    :rightColumnVariables="processVariables"
    :isLeftInputOneVariableOnly="true"
    :hideCreateLeftInput="true"
    leftColumnLabel="Document Variable"
    rightColumnLabel="Process Variable"
    :parent="parent"
    :autoInit="false"
    :infoText="tooltip"
    :disabled="disabled"
    @update-input="onMapperGroupValueUpdate"
    @remove-row="onRowRemove"
  />
</template>
<script lang="ts">
import VariableMapperGroup, {
  VariableMapperValue,
} from "@/modules/ProcessDesigner/components/Controls/VariableMapper/Group.component.vue";
import {
  DocumentMapperSetting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { hasVariable } from "@/modules/ProcessDesigner/Variables/Utils";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { store } from "@/store";
import { PdsTypes } from "@procesio/procesio-design-system";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DocumentMapperValue } from "./DocumentMapper.model";

@Component({
  components: {
    "pup-variable-mapper-group": VariableMapperGroup,
  },
})
export default class DocumentMapperBuilderComponent extends Vue {
  @Prop() settings!: DocumentMapperSetting;

  @Prop() parent!: Node;

  @Prop() label!: string;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() tooltip?: string;

  @Prop() value!: Array<DocumentMapperValue>;

  @Prop() required!: boolean;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  variableMapperValue: VariableMapperValue[] = [];

  @Watch("value", { immediate: true, deep: true })
  onValueUpdate(value: DocumentMapperValue[] | null) {
    if (!value) {
      this.variableMapperValue = [];
      return;
    }

    let val = [...value];

    if (store.getters.isExtraVariablesByNodeIdDefined(this.parent.id)) {
      val = val.filter(
        (item) =>
          !item.document || (item.document && hasVariable(item.document))
      );
    }

    this.variableMapperValue = val.map((val) => ({
      id: val.id,
      left: val.document,
      right: val.process,
    }));
  }

  get documentVariables() {
    return store.getters.getExtraVariablesByNodeId(this.parent.id);
  }

  @Watch("documentVariables", { immediate: true })
  onDocumentVariablesUpdate() {
    let value = Array.isArray(this.value) ? [...this.value] : [];

    if (store.getters.isExtraVariablesByNodeIdDefined(this.parent.id)) {
      value = value.filter(
        (item) =>
          !item.document || (item.document && hasVariable(item.document))
      );
    }

    this.settings.value = value;
  }

  onMapperGroupValueUpdate(value: VariableMapperValue[] | null) {
    // map VariableMapperValue to value
    let documentBuilderValue = [{ id: 0, document: "", process: "" }];

    if (value && Array.isArray(value)) {
      documentBuilderValue = value.map((val) => ({
        id: val.id,
        document: val.left,
        process: val.right,
      }));
    }

    const settingValues = this.settings.value;
    if (!settingValues) {
      this.settings.value = documentBuilderValue;
      return;
    }

    documentBuilderValue.forEach((val) => {
      const settingValue = settingValues.find(
        (value: DocumentMapperValue) => value.id === val.id
      );

      if (settingValue) {
        settingValue.document = val.document;
        settingValue.process = val.process;
      } else {
        settingValues.push(val);
      }
    });
  }

  onRowRemove(index: number) {
    if (this.settings.value) {
      this.settings.value.splice(index, 1);
    }
  }
}
</script>
