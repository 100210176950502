<template>
  <div class="pup-c-date-time" @keydown="onKeyDownHandler" ref="input">
    <pds-control-label
      :label="label"
      :required="required"
      :ignoreFormatting="ignoreLabelFormatting"
    />
    <div
      v-if="isVariable || isVariablePlaceholder"
      class="pup-c-date-time--input-group pup-c-side-panel--children"
    >
      <slot name="prepend" />

      <pds-badge
        v-if="isVariable"
        v-tooltip="{
          content: getTooltipDataType(value),
          placement: 'top',
          container: 'body',
          boundariesElement: boundaries,
        }"
        :label="parseVariableIdToName(value, false, processVariables)"
        @labelClick="openVariable"
        @removeClick="clearInput"
        :color="getColor(value)"
        class="pds-u-m--l--4"
        :closable="!disabled"
      />
      <span
        v-if="isVariablePlaceholder"
        @click="openVariableSelector"
        style="cursor: pointer"
      >
        <pds-badge label="..." color="invalid" />
      </span>
    </div>

    <pds-date-picker
      v-else
      :value="value"
      :hasDropdownButton="false"
      mode="dateTime"
      :formatter="formatter"
      @update-input="updateInput"
      :disabled="disabled"
    >
      <slot name="prepend" slot="prepend" />
    </pds-date-picker>

    <pup-add-variable
      class="pup-c-date-time--add-var"
      @click="openVariableSelector"
      :controlType="controlType"
      v-if="!disabled"
    />

    <pds-validation-message
      :status="status"
      @statusClassChanged="(e) => (statusClass = e)"
      :showMessage="showValidationMessage"
    />

    <pup-data-model-selector
      v-if="isDataModelSelectorVisible && !disabled"
      :parentId="parent && parent.parentId"
      :processVariables="processVariables"
      :expectedDataModelId="settings ? settings.dataTypeId : null"
      :isListExpected="settings ? settings.isList : false"
      class="pup-c-input-data-model-selector--acordeon pup-c-input-data-model-selector--acordeon--right pds-u-m--t--24"
      @pickElement="selectVariable"
      @closed="closeDataModelSelector"
    />
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";

import { Component, Prop, Watch } from "vue-property-decorator";

import AddVariableButtonComponent from "@/modules/ProcessDesigner/components/AddVariableButton/AddVariableButton.component.vue";

import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { VariableParser } from "@/modules/ProcessDesigner/Variables/Utils/VariableParser";

import DataModelSelector from "@/modules/ProcessDesigner/components/DataModelSelector/DataModelSelector.component.vue";

import { Setting } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

import { SettingValidation } from "@/modules/ProcessDesigner/Validation/SettingValidation";

import { getValueDataTypes } from "@/modules/ProcessDesigner/Values/ValueDataTypesHelper";

import { NonPrimitives, Primitives } from "@/utils/dataTypeMapper";

import {
  DatePicker,
  IconComponent,
  BadgeStatusComponent,
  ValidationMessage,
  ControlLabelComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";
import { TEMPLATE_VARIABLE_PLACEHOLDER } from "@/services/templates/Templates.model";
import { DataModel } from "@/services/datamodel/DataModel.model";
import { toLocalISOString } from "@/utils/type/date";
import { isHotKeyMatched, HotKeys } from "@/utils/keyboard/hotkeys";
import { mapGetters } from "vuex";
import { getVariable } from "@/modules/ProcessDesigner/Variables/Utils";
import { EventBus, Events } from "@/utils/eventBus";

/**
 * Before using this component, consider it emits value in local ISO format (see toLocalISOString)
 */
@Component({
  components: {
    "pds-date-picker": DatePicker,
    "pds-icon": IconComponent,
    "pup-add-variable": AddVariableButtonComponent,
    "pup-data-model-selector": DataModelSelector,
    "pds-badge": BadgeStatusComponent,
    "pds-validation-message": ValidationMessage,
    "pds-control-label": ControlLabelComponent,
  },
  model: {
    event: "update-input",
  },
  computed: {
    ...mapGetters({
      keyboardPressedCodes: "keyboardPressedCodes",
    }),
  },
})
export default class DateTimeComponent extends mixins(
  VariableParser,
  Variable
) {
  @Prop() value!: Date | string;

  @Prop() settings!: Setting;

  @Prop({ default: () => [] }) processVariables!: ProcessVariable[];

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: true }) showValidationMessage!: boolean;

  @Prop() label?: string;

  @Prop({ default: false }) required!: boolean;

  @Prop({ default: false, type: Boolean }) ignoreLabelFormatting!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  isDataModelSelectorVisible = false;

  boundaries: Element | null = null;

  get isVariable() {
    if (this.value instanceof Date) {
      return false;
    }

    return (
      this.hasVariable(this.value) && !!this.getVariableDataType(this.value)
    );
  }

  get isVariablePlaceholder() {
    return (
      this.value &&
      typeof this.value === "string" &&
      this.value.trim() === TEMPLATE_VARIABLE_PLACEHOLDER
    );
  }

  get controlType() {
    const dataType: DataModel = this.$store.getters.dataTypes.find(
      (el: DataModel) => el.id === this.settings.dataTypeId
    );

    if (this.settings.isList) {
      return "list &lt;" + dataType?.displayName + "&gt;";
    }

    return dataType?.displayName;
  }

  @Watch("value")
  onValueUpdate(value: Date | string) {
    if (typeof value === "string") {
      this.validateInternalValue(value);
    }
  }

  @Watch("status", { immediate: true })
  onStatusUpdate() {
    if (typeof this.value === "string") {
      this.validateInternalValue(this.value);
    }
  }

  @Watch("processVariables", { deep: true })
  onProcessVariablesUpdate() {
    if (typeof this.value === "string") {
      this.validateInternalValue(this.value);
    }
  }

  mounted() {
    this.boundaries =
      document.querySelector(".pup-c-side-panel--controls") ||
      document.querySelector(".tabs--body");
  }

  formatter(date: Date) {
    const day = date.getDate();

    const month = date.toLocaleString("default", {
      month: "long",
    });

    const year = date.getFullYear();

    const hours = date.getHours();

    const minutes = date.getMinutes();

    return `${day} ${month} ${year}, ${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }

  openVariableSelector() {
    this.isDataModelSelectorVisible = !this.isDataModelSelectorVisible;
  }

  onKeyDownHandler(e: KeyboardEvent) {
    if (e.key === "Backspace" || e.key === "Delete") {
      this.updateInput("");
    }
  }

  @Watch("keyboardPressedCodes")
  onKeyboardPressedCodes(codes: string[], oldCodes: string[]) {
    if (codes.length < oldCodes.length) {
      return;
    }

    if (
      !this.$refs.input ||
      !(this.$refs.input as HTMLElement).contains(document.activeElement)
    ) {
      return;
    }

    if (isHotKeyMatched(HotKeys.TOGGLE_DATA_MODEL_SELECTOR, codes)) {
      this.openVariableSelector();
    }
  }

  closeDataModelSelector() {
    this.isDataModelSelectorVisible = false;
  }

  selectVariable(variable: string) {
    this.updateInput(variable);

    this.isDataModelSelectorVisible = false;
  }

  validateInternalValue(value: string) {
    if (!this.settings) {
      return;
    }

    if (!value) {
      value = this.value as string;
    }

    // validate data type
    const errorMessage = "Error: data type mismatch";
    let messages = [...(this.settings.status?.message || [])];
    const messageIndex = Array.isArray(messages)
      ? messages.indexOf(errorMessage)
      : -1;

    if (
      !SettingValidation.validateDataType(
        getValueDataTypes(value),
        this.settings
      )
    ) {
      messageIndex === -1 && (messages = [errorMessage]);
    } else {
      messageIndex !== -1 && messages.splice(messageIndex, 1);
    }

    if (this.settings.status) {
      this.settings.status.message = messages;
    } else {
      this.settings.status = {
        type: PdsTypes.StatusType.ERROR,
        message: messages,
      };
    }
  }

  getColor(guid: string) {
    const dataType = this.getVariableDataType(guid);

    if (dataType)
      switch (dataType.id) {
        case Primitives.BOOLEAN:
          return "accent3";
        case Primitives.STRING:
          return "primary";
        case Primitives.INTEGER:
          return "accent1";
        case Primitives.FLOAT:
        case Primitives.DOUBLE:
          return "float";
        case Primitives.TIME:
        case Primitives.DATE:
        case Primitives.DATETIME:
          return "warning";
        case NonPrimitives.FILE:
          return "info";
        case Primitives.GUID:
          return "warning2";
        default:
          return "accent2";
      }
  }

  openVariable() {
    if (this.disabled || typeof this.value !== "string") {
      return;
    }

    const variable = getVariable(this.value);
    if (
      variable &&
      !variable.isReadonly &&
      (!variable.scopesId || !variable.scopesId.length)
    ) {
      EventBus.$emit(Events["VARIABLE:OPEN_FORM"], variable);
    }
  }

  clearInput() {
    this.updateInput("");
  }

  getTooltipDataType(guid: string) {
    const dataType = this.getVariableDataType(guid);
    const isList = this.isVariableList(guid);

    if (!dataType) {
      return "";
    }

    return `${dataType.isProcesio ? "" : "Data model:"} ${
      isList ? "list &lt;" : ""
    }${dataType?.name}${isList ? "&gt;" : ""}`;
  }

  updateInput(value: string | Date) {
    if (value instanceof Date) {
      value = toLocalISOString(value);
    }
    this.$emit("update-input", value);
  }
}
</script>

<style lang="scss">
@import "./DateTime.component.scss";
</style>
