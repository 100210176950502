<template>
  <pds-badge-status
    v-if="label"
    :label="label"
    :color="color"
    ignoreDefaultCases
  />
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { BadgeStatusComponent } from "@procesio/procesio-design-system";
import { NonPrimitives, Primitives } from "@/utils/dataTypeMapper";

@Component({
  components: {
    "pds-badge-status": BadgeStatusComponent,
  },
})
export default class DataTypeBadgeComponent extends Vue {
  @Prop({ default: null }) dataType!: {
    dataTypeId: string | undefined;
    isList: boolean;
    name: string;
    displayName?: string;
  };

  label = "";

  @Watch("dataType", { immediate: true })
  setLabel() {
    if (!this.dataType) {
      return;
    }

    const dataType = this.$store.getters.getDataTypeById(
      this.dataType.dataTypeId
    );

    if (!dataType) {
      return;
    }

    this.label = this.dataType.isList
      ? `list<${dataType.displayName}>`
      : dataType.displayName;
  }

  get color() {
    if (!this.dataType) {
      return null;
    }

    switch (this.dataType.dataTypeId) {
      case Primitives.BOOLEAN:
        return "accent3";
      case Primitives.STRING:
        return "primary";
      case Primitives.INTEGER:
        return "accent1";
      case Primitives.FLOAT:
      case Primitives.DOUBLE:
        return "float";
      case Primitives.TIME:
      case Primitives.DATE:
      case Primitives.DATETIME:
        return "warning";
      case NonPrimitives.FILE:
        return "info";
      case Primitives.GUID:
        return "warning2";
      default:
        return "accent2";
    }
  }
}
</script>
