export interface TokenBody {
  email: string;
  groups: string[];
  name: string; // full name
  family_name: string;
  given_name: string;
  workspace: string;
  sub: string; // user id
  created_timestamp: number;
}

const parseJwt = (token: string | null): TokenBody | null => {
  if (!token) {
    return null;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export { parseJwt };
