import Vue from "vue";
import { ErrorContent } from "@/services/Rest.service";

export enum ErrorCode {
  INACTIVE_TOKEN = 448,
  DATA_MODEL_PUBLISH_NOT_ALLOWED = 353,
  RECURSIVE_DATA_MODEL_NOT_ALLOWED = 354,
  EXPIRED_SCHEDULE_TOGGLE = 490,
  USED_CREDENTIAL_DELETE_NOT_ALLOWED = 111,
  FORM_NOT_ALLOWED = 501,
}

export const displayToasts = (errorContent: ErrorContent[] | string) => {
  const showToast = (message: string) =>
    (Vue as any).$toast.error(message, {
      duration: 100000,
      pauseOnHover: true,
      onClick: () => {
        const textArea = document.createElement("textarea");

        const valueToCopy = message;

        textArea.value = valueToCopy;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }

        (Vue as any).$toast.success("Copied to clipboard!");

        textArea.parentNode?.removeChild(textArea);
      },
    });

  if (Array.isArray(errorContent)) {
    errorContent?.forEach((err) => {
      showToast(`${err.statusCode} - ${err.value} </br> ${err.target}`);
    });
  } else if (typeof errorContent === "string") {
    showToast(errorContent);
  }
};
