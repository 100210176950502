import router, { RouteName } from "@/router";
import ls from "@/services/localStorage";
import { store } from "@/store";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";

export default class UserLocalStorage {
  static readonly TOKEN: string = "token";
  static readonly REFRESH: string = "refresh_token";
  static readonly TOKEN_EXPIRES_IN: string = "expires_in";
  static readonly REFRESH_TOKEN_EXPIRES_IN: string = "refresh_expires_in";

  static getToken() {
    return ls(this.TOKEN);
  }

  static setToken(token: string) {
    ls(this.TOKEN, token);
  }

  static removeToken() {
    ls.remove(this.TOKEN);
  }

  /** refresh token */
  static getRefreshToken() {
    return ls(this.REFRESH);
  }

  static setRefreshToken(token: string) {
    ls(this.REFRESH, token);
  }

  static removeRefreshToken() {
    ls.remove(this.REFRESH);
  }

  static getTokenExpiresIn() {
    return ls(this.TOKEN_EXPIRES_IN);
  }

  static setTokenExpiresIn(timestamp: number) {
    ls(this.TOKEN_EXPIRES_IN, timestamp);
  }

  static removeTokenExpiresIn() {
    ls.remove(this.TOKEN_EXPIRES_IN);
  }

  static getRefreshTokenExpiresIn() {
    return ls(this.REFRESH_TOKEN_EXPIRES_IN);
  }

  static setRefreshTokenExpiresIn(timestamp: number) {
    ls(this.REFRESH_TOKEN_EXPIRES_IN, timestamp);
  }

  static removeRefreshTokenExpiresIn() {
    ls.remove(this.REFRESH_TOKEN_EXPIRES_IN);
  }
}

window.addEventListener("storage", () => {
  const token = UserLocalStorage.getToken();
  const storedToken = store.getters.token;
  const tokenExpiresIn = UserLocalStorage.getTokenExpiresIn();

  if (
    token &&
    token !== storedToken &&
    tokenExpiresIn > store.getters.tokenExpiresIn
  ) {
    store.dispatch(UserActionTypes.SET_TOKEN_EXPIRES_IN, tokenExpiresIn);
    store.dispatch(
      UserActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN,
      UserLocalStorage.getRefreshTokenExpiresIn()
    );
    store.dispatch(UserActionTypes.SET_TOKEN, token);
    store.dispatch(
      UserActionTypes.SET_REFRESH_TOKEN,
      UserLocalStorage.getRefreshToken()
    );
    store.dispatch(UserActionTypes.LOAD_USER_ID);

    // if (!storedToken) {
    //   router.push({ name: RouteName.DASHBOARD });
    // }
  }
  // else if (storedToken && !token) {
  //   // on logout
  //   router.push({ name: RouteName.LOGIN });
  // }
});
