<template>
  <div class="pup-c-tabs--tab">
    <component
      :is="getSettingComponent(tab.value[0].type)"
      :key="tab.id"
      :keyProp="tab.id"
      :config="{
        columns: tab.value[0].columns,
        items: tab.value[0].value,
      }"
      :value="tab.value[0].value"
      :lang="tab.value[0].lang"
      :parent="parent"
      :processVariables="processVariables"
      :multilineEditingRowId="multilineEditingRowId"
      :disabled="disabled"
    >
      <template v-slot:actions-per-row="props">
        <div class="pup-c-tabs--tab--actions">
          <pds-icon
            icon="icon-arrow-expand"
            @click="toggleEditor(props.row)"
            :class="[
              'pds-u-m--r--8 pds-u-m--l--8',
              'pup-c-tabs--tab--editor',
              props.row.id === multilineEditingRowId &&
                'pup-c-tabs--tab--editor--active',
            ]"
            v-tooltip="{
              content: disabled
                ? ''
                : props.row.id === multilineEditingRowId
                ? 'Activate for single line editing'
                : 'Activate for multiline editing',
            }"
          />
          <pds-icon
            v-if="!disabled"
            icon="clear"
            @click="deleteRow(props.index)"
            class="pup-c-tabs--tab--delete"
          />
        </div>
      </template>
    </component>
  </div>
</template>

<script lang="ts">
/** VUE RELATED */
import Vue from "vue";
import { Component, Model, Prop, Watch } from "vue-property-decorator";

/** DESIGN SYSTEM */
import {
  ButtonComponent,
  IconComponent,
  InputComponent,
} from "@procesio/procesio-design-system";
import {
  SettingType,
  TabSetting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import TInput from "@/modules/ProcessDesigner/components/Controls/TableInputOld/TInput.component.vue";

import Editor from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.component.vue";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";

@Component({
  components: {
    "pds-table": TInput,
    "pds-button": ButtonComponent,
    "pds-icon": IconComponent,
    "pup-editor": Editor,
    "pds-input": InputComponent,
  },
})
export default class Tabs extends Vue {
  @Model("update-tab") tab!: TabSetting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  multilineEditingRowId: string | null = null;

  @Watch("tab", { immediate: true, deep: true })
  onTabChanged(tab: TabSetting, oldTab: TabSetting | undefined) {
    if (!oldTab || tab.id !== oldTab.id) {
      this.multilineEditingRowId = null;
    }
  }

  deleteRow(index: number) {
    if (this.tab.value[0] && this.tab.value[0].value) {
      this.tab.value[0].value.splice(index, 1);
    }
  }

  getSettingComponent(type: SettingType) {
    switch (type) {
      case SettingType.TABLE:
        return "pds-table";

      case SettingType.BUTTON:
        return "pds-button";

      case SettingType.CODE_EDITOR:
        return "pup-editor";

      default:
        return "pds-input";
    }
  }

  toggleEditor(row: { id: string }) {
    this.multilineEditingRowId =
      this.multilineEditingRowId === row.id ? null : row.id;
  }
}
</script>

<style scoped lang="scss">
@import "./Tabs.component.scss";
</style>
