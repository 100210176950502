import { escapeString } from "@/utils/type/string";
import { Route } from "vue-router";

export const defaultTitle = "PROCESIO No-Code Technology";

export const DefaultTitleSuffix = "- PROCESIO";

export const getTitleByRoute = (route: Route) => {
  let title = route.meta?.title;

  if (!title) {
    const matchedTitles = [
      ...new Set(
        route.matched.reduce((titles: string[], r) => {
          if (r.meta && r.meta.title) {
            titles.push(r.meta.title);
          }
          return titles;
        }, [])
      ),
    ];
    title = matchedTitles.length === 0 ? defaultTitle : matchedTitles[0];
  }

  return title;
};

export const setTitle = (title: string) => {
  if (!title) {
    title = defaultTitle;
  }

  document.title = escapeString(title);
};
