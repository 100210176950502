var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-collapsable",class:{ 'pup-c-collapsable--collapsed': _vm.collapsed }},[_c('div',{staticClass:"pup-c-collapsable--header"},[_c('p',{class:['pds-u-subtitle--2', 'pup-c-collapsable--header--text']},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),_c('pds-icon',{staticClass:"pup-c-collapsable--header--point",attrs:{"icon":_vm.collapsed ? 'expand_more' : 'expand_less'},on:{"click":_vm.changeCollapse}})],1),_vm._v(" "),(!_vm.collapsed)?_c('div',{staticClass:"pup-c-collapsable--content"},[(
        _vm.values.length === 0 ||
        (_vm.values.length === 1 &&
          _vm.values[0] &&
          _vm.values[0].type === 'table' &&
          Array.isArray(_vm.values[0].value) &&
          _vm.values[0].value.length === 0)
      )?_c('div',{staticClass:"pup-c-collapsable--empty"},[_vm._v("\n      You did not use any variable in your action configuration\n    ")]):_c('div',[(_vm.tableValue)?_c('pup-table-input',{staticClass:"pup-c-collapsable--table",attrs:{"config":{
          columns: _vm.tableValue.columns,
          items: _vm.tableValue.value,
        }},scopedSlots:_vm._u([{key:"key",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.key))])]}},{key:"value",fn:function({ row }){return [(
              row.typeRef &&
              row.typeRef.id === '10c6ac59-3929-49e6-99dc-121212121219'
            )?_c('pup-upload-control',{key:row.id,attrs:{"isMultiple":row.isList},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}):[_c('pup-fullscreen-input',{attrs:{"isTextOnBlur":true,"fullScreenTitle":row.key,"placeholder":"Insert value"},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})]]}}],null,false,4043681918)}):_vm._l((_vm.values),function(value){return _c('div',{key:value.id},[(value.dataTypeId === _vm.nonPrimitives.FILE)?_c('pds-upload',{attrs:{"label":value.label,"files":_vm.getFilesValue(value),"isMultiple":value.isList,"downloadable":"","disabled":"","ignoreLabelFormatting":""},on:{"download":function($event){return _vm.downloadFile(value, $event)}}}):_c('pup-fullscreen-input',{staticClass:"pds-u-m--b--16",attrs:{"ignoreLabelFormatting":"","label":value.label,"fullScreenTitle":value.label,"value":value.value,"type":[_vm.settingTypes.TEXT_AREA, _vm.settingTypes.TEXT_AREA_BASIC].includes(
                value.type
              )
                ? 'textarea'
                : 'text',"readonly":""}})],1)})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }