import RestService from "../Rest.service";
import RestUrls from "../Rest.paths";

export default class VersionService {
  static getCurrentVersion() {
    return RestService.get<{
      version: number;
      authApiVersion: number;
      baseApiVersion: number;
    }>({
      url: RestUrls.GUI.VERSION,
      isAuth: true,
      workspaced: false,
    });
  }
}
