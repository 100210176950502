import { ActionTree, ActionContext } from "vuex";
import ActionListService from "@/services/actionlist/ActionList.service";
import ActionListlService from "@/services/actionlist/ActionList.service";
import { cloneDeep } from "lodash";
import { ProcessState } from "./Process.store";
import { Mutations, MutationTypes } from "./Process.mutations";
import { Variable } from "@/services/crud/Orchestration.service";
import { WarningI } from "@/modules/ProcessDesigner/Validation/Validation";
import { escapeString } from "@/utils/type/string";
import { TestActionModel } from "@/modules/ProcessDesigner/components/Controls/TestAction/TestAction.model";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";

export enum ActionTypes {
  LOAD_VARIABLE = "LOAD_VARIABLE",
  SET_VARIABLE = "SET_VARIABLE",
  SET_VARIABLE_DELETED_STATE = "SET_VARIABLE_DELETED_STATE",
  LOAD_ACTION_LIST = "LOAD_ACTION_LIST",
  LOAD_GROUP_LIST = "LOAD_GROUP_LIST",
  SET_SIDEPANEL_ID = "SET_SIDEPANEL_ID",
  SET_EXTRA_VARIABLES_BY_KEY = "SET_EXTRA_VARIABLES_BY_KEY",
  RESET_EXTRA_VARIABLES = "RESET_EXTRA_VARIABLES",
  LOAD_GROUP_DETAILS = "LOAD_GROUP_DETAILS",
  LOAD_OPERAND_LIST = "LOAD_OPERAND_LIST",
  SET_ID = "SET_ID",
  SET_TITLE = "SET_TITLE",
  SET_DESCRIPTION = "SET_DESCRIPTION",
  SET_ACTIVE_STATE = "SET_ACTIVE_STATE",
  SET_LOADED_STATE = "SET_LOADED_STATE",
  SET_VALIDATED_STATE = "SET_VALIDATED_STATE",
  SET_VALIDATING_STATE = "SET_VALIDATING_STATE",
  SET_SAVED_STATE = "SET_SAVED_STATE",
  SET_SAVING_STATE = "SET_SAVING_STATE",
  SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS",
  DELETE_VALIDATION_ERRORS = "DELETE_VALIDATION_ERRORS",
  SET_LAST_RUN_PROCESS_ID = "SET_LAST_RUN_PROCESS_ID",
  SET_LAST_RUN_INSTANCE_ID = "SET_LAST_RUN_INSTANCE_ID",
  SET_INSTANCE_ID = "SET_INSTANCE_ID",
  SET_INSTANCE_VARIABLES = "SET_INSTANCE_VARIABLES",
  SET_INSTANCE_UPDATE_DATE = "SET_INSTANCE_UPDATE_DATE",
  RESET_PROCESS_DATA = "RESET_PROCESS_DATA",
  GET_SEARCH_DETAILS = "GET_SEARCH_DETAILS",
  SET_TEST_ACTION_MODEL = "SET_TEST_ACTION_MODEL",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<ProcessState, ProcessState>, "commit">;

export interface Actions {
  [ActionTypes.LOAD_VARIABLE](
    { commit, getters }: AugmentedActionContext,
    payload: {
      data: Variable[];
      context: {
        // process, which variables were loaded for;
        // note: it may be different when process is opened when previous process was not loaded before
        processId: string | null;
      };
    }
  ): void;

  [ActionTypes.SET_VARIABLE](
    { commit }: AugmentedActionContext,
    payload: Variable
  ): void;

  [ActionTypes.SET_VARIABLE_DELETED_STATE](
    { commit }: AugmentedActionContext,
    payload: { variable: Variable; isDeleted: boolean }
  ): void;

  [ActionTypes.LOAD_ACTION_LIST]({ commit }: AugmentedActionContext): void;

  [ActionTypes.SET_SIDEPANEL_ID](
    { commit }: AugmentedActionContext,
    sidepanelId: string
  ): void;

  [ActionTypes.SET_EXTRA_VARIABLES_BY_KEY](
    { commit }: AugmentedActionContext,
    { nodeId, variables }: { nodeId: string; variables: Variable[] }
  ): void;

  [ActionTypes.RESET_EXTRA_VARIABLES]({ commit }: AugmentedActionContext): void;

  [ActionTypes.LOAD_GROUP_LIST]({ commit }: AugmentedActionContext): void;

  [ActionTypes.LOAD_OPERAND_LIST]({ commit }: AugmentedActionContext): void;

  [ActionTypes.LOAD_GROUP_DETAILS](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [ActionTypes.SET_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ActionTypes.SET_TITLE](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ActionTypes.SET_DESCRIPTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ActionTypes.SET_ACTIVE_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_LOADED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_VALIDATED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_VALIDATING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_SAVED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_SAVING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_VALIDATION_ERRORS](
    { commit }: AugmentedActionContext,
    payload: WarningI[]
  ): void;

  [ActionTypes.DELETE_VALIDATION_ERRORS](
    { commit }: AugmentedActionContext,
    id: string | null
  ): void;

  [ActionTypes.SET_LAST_RUN_PROCESS_ID](
    { commit }: AugmentedActionContext,
    id: string | null
  ): void;

  [ActionTypes.SET_LAST_RUN_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    id: string | null
  ): void;

  [ActionTypes.SET_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    id: string | null
  ): void;

  [ActionTypes.SET_INSTANCE_VARIABLES](
    { commit }: AugmentedActionContext,
    variables: Variable[]
  ): void;

  [ActionTypes.SET_INSTANCE_UPDATE_DATE](
    { commit }: AugmentedActionContext,
    date: string | null
  ): void;

  [ActionTypes.RESET_PROCESS_DATA]({ commit }: AugmentedActionContext): void;

  [ActionTypes.GET_SEARCH_DETAILS](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [ActionTypes.SET_TEST_ACTION_MODEL](
    { commit }: AugmentedActionContext,
    model: TestActionModel | null
  ): void;
}

export const actions: ActionTree<ProcessState, ProcessState> & Actions = {
  [ActionTypes.LOAD_VARIABLE]({ commit, getters }, { data, context }) {
    if (
      context &&
      typeof context.processId !== "undefined" &&
      context.processId !== getters["process/id"]
    ) {
      return;
    }

    commit(MutationTypes.LOAD_VARIABLES, data);
  },

  [ActionTypes.SET_VARIABLE](
    { commit, state }: AugmentedActionContext,
    variable: Variable
  ) {
    commit(MutationTypes.SET_VARIABLE, variable);

    // update child variables data type
    state.variables
      .filter((vrb: Variable) => vrb?.parentVariableId === variable.id)
      .forEach((vrb: Variable) =>
        commit(MutationTypes.SET_VARIABLE, {
          ...vrb,
          dataType: variable.dataType,
        })
      );
  },

  [ActionTypes.SET_VARIABLE_DELETED_STATE](
    { commit }: AugmentedActionContext,
    { variable, isDeleted }
  ) {
    commit(MutationTypes.SET_VARIABLE_DELETED_STATE, { variable, isDeleted });
  },

  async [ActionTypes.LOAD_ACTION_LIST]({ commit }: AugmentedActionContext) {
    const isProcessDesignerPermitted = UserPermissionsGuard.isPermitted(
      ProcesioEntityType.ProcessDesigner,
      RoleType.Read
    );

    const response = await ActionListService[
      isProcessDesignerPermitted ? "getActionList" : "getActionRestrictedList"
    ]();

    if (response.content) {
      commit(
        MutationTypes.SET_ACTION_LIST,
        response.content?.actions.map((action) => ({
          ...action,
          internalId: action.actionId,
        }))
      );
      commit(MutationTypes.SET_GROUP_LIST, response.content?.grouping);

      if (isProcessDesignerPermitted) {
        const completeActions = await ActionListService.getActionByGroup();
        const completeCustomActions = await ActionListService.getActionByGroup(
          true
        );

        completeActions.content?.actions.forEach((action) =>
          commit(MutationTypes.SET_ACTION_CONFIGURATION, {
            ...action,
            internalId: action.actionId,
          })
        );

        completeCustomActions.content?.actions.forEach((action) =>
          commit(MutationTypes.SET_ACTION_CONFIGURATION, {
            ...action,
            internalId: action.actionId,
          })
        );
      }
    }

    return response;
  },

  [ActionTypes.SET_SIDEPANEL_ID](
    { commit }: AugmentedActionContext,
    sidepanelId: string
  ) {
    commit(MutationTypes.SET_SIDEPANEL_ID, sidepanelId);
  },

  [ActionTypes.SET_EXTRA_VARIABLES_BY_KEY](
    { commit }: AugmentedActionContext,
    { nodeId, variables }: { nodeId: string; variables: Variable[] }
  ) {
    commit(MutationTypes.SET_EXTRA_VARIABLES_BY_KEY, { nodeId, variables });
  },

  [ActionTypes.RESET_EXTRA_VARIABLES]({ commit }: AugmentedActionContext) {
    commit(MutationTypes.RESET_EXTRA_VARIABLES);
  },

  async [ActionTypes.LOAD_GROUP_LIST]({ commit }: AugmentedActionContext) {
    const response = await ActionListService.getActionList();

    if (response.content) {
      commit(MutationTypes.SET_GROUP_LIST, response.content?.grouping);
    }
  },

  async [ActionTypes.LOAD_OPERAND_LIST]({ commit }: AugmentedActionContext) {
    const resp = await ActionListlService.getOperatorList();

    if (resp.content) {
      commit(MutationTypes.LOAD_OPERAND_LIST, resp.content);
    }
  },

  async [ActionTypes.LOAD_GROUP_DETAILS](
    { commit }: AugmentedActionContext,
    groupId: string
  ) {
    const groupDetails = await ActionListService.getActionByGroup(groupId);

    groupDetails.content?.prototypes.forEach((proto) => {
      groupDetails.content?.actions.push({
        actionId: proto.actionTemplate.templateId,
        internalId: proto.actionTemplate.id,
        canDelete: proto.actionTemplate.customData.canDelete as boolean,
        canDuplicate: proto.actionTemplate.customData.canDuplicate as boolean,
        caption: proto.actionTemplate.customData.description,
        description: proto.actionTemplate.customData.description,
        configuration: cloneDeep(proto.actionTemplate.customData.configuration),
        icon: proto.icon || proto.actionTemplate.customData.icon,
        title: proto.name,
        inputPorts: proto.actionTemplate.customData.inputPorts,
        outputPorts: proto.actionTemplate.customData.outputPorts,
        isTestable: proto.actionTemplate.customData.isTestable as boolean,
        permissions: {
          canDelete: proto.actionTemplate.customData.canDelete as boolean,
          canDuplicate: proto.actionTemplate.customData.canDuplicate as boolean,
          canAddFromToolbar: true,
          canSaveActionAsTemplate: proto.actionTemplate.customData
            .canSaveActionAsTemplate as boolean,
        },
        name: proto.name,
        shape: proto.actionTemplate.customData.type,
        tooltip: proto.name,
        //
        submitedBy: "",
        updatedOn: "",
        isCustom: false,
        isProcesioAction: proto.isProcesio || true,
        id: proto.id,
      });
    });

    // TODO: refactor all this garbage

    groupDetails.content?.actions.forEach((action) => {
      commit(MutationTypes.SET_ACTION_CONFIGURATION, {
        ...action,
        internalId: action.internalId ? action.internalId : action.actionId,
      });
    });
  },

  [ActionTypes.SET_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_ID, payload);
  },

  [ActionTypes.SET_TITLE](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_TITLE, escapeString(payload));
    commit(MutationTypes.SET_SAVED_STATE, false);
  },

  [ActionTypes.SET_DESCRIPTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_DESCRIPTION, payload);
    commit(MutationTypes.SET_SAVED_STATE, false);
  },

  [ActionTypes.SET_ACTIVE_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_ACTIVE_STATE, payload);
  },

  [ActionTypes.SET_LOADED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_LOADED_STATE, payload);
  },

  [ActionTypes.SET_VALIDATED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_VALIDATED_STATE, payload);
  },

  [ActionTypes.SET_VALIDATING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_VALIDATING_STATE, payload);
  },

  [ActionTypes.SET_SAVED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_SAVED_STATE, payload);
  },

  [ActionTypes.SET_SAVING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_SAVING_STATE, payload);
  },

  [ActionTypes.SET_VALIDATION_ERRORS](
    { commit }: AugmentedActionContext,
    payload: WarningI[]
  ) {
    commit(MutationTypes.SET_VALIDATION_ERRORS, payload);
  },

  [ActionTypes.DELETE_VALIDATION_ERRORS](
    { commit }: AugmentedActionContext,
    payload: string | null = null
  ) {
    commit(MutationTypes.DELETE_VALIDATION_ERRORS, payload);
  },

  [ActionTypes.SET_LAST_RUN_PROCESS_ID](
    { commit }: AugmentedActionContext,
    payload: string | null = null
  ) {
    commit(MutationTypes.SET_LAST_RUN_PROCESS_ID, payload);
  },

  [ActionTypes.SET_LAST_RUN_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    payload: string | null = null
  ) {
    commit(MutationTypes.SET_LAST_RUN_INSTANCE_ID, payload);
  },

  [ActionTypes.SET_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    payload: string | null = null
  ) {
    commit(MutationTypes.SET_INSTANCE_ID, payload);
  },

  [ActionTypes.SET_INSTANCE_VARIABLES](
    { commit }: AugmentedActionContext,
    payload: Variable[]
  ) {
    commit(MutationTypes.SET_INSTANCE_VARIABLES, payload);
  },

  [ActionTypes.SET_INSTANCE_UPDATE_DATE](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_INSTANCE_UPDATE_DATE, payload);
  },

  [ActionTypes.RESET_PROCESS_DATA]({ dispatch }: AugmentedActionContext) {
    dispatch(ActionTypes.LOAD_VARIABLE, { data: [] });
    dispatch(ActionTypes.RESET_EXTRA_VARIABLES);
    dispatch(ActionTypes.SET_ID, null);
    dispatch(ActionTypes.SET_TITLE, "");
    dispatch(ActionTypes.SET_DESCRIPTION, "");
    dispatch(ActionTypes.SET_ACTIVE_STATE, true);
    dispatch(ActionTypes.SET_LOADED_STATE, false);
    dispatch(ActionTypes.SET_VALIDATED_STATE, false);
    dispatch(ActionTypes.SET_SAVED_STATE, false);
    dispatch(ActionTypes.SET_SAVING_STATE, false);
    dispatch(ActionTypes.SET_VALIDATION_ERRORS, []);
    dispatch(ActionTypes.SET_LAST_RUN_PROCESS_ID, null);
    dispatch(ActionTypes.SET_LAST_RUN_INSTANCE_ID, null);
    dispatch(ActionTypes.SET_INSTANCE_ID, null);
    dispatch(ActionTypes.SET_INSTANCE_VARIABLES, []);
    dispatch(ActionTypes.SET_INSTANCE_UPDATE_DATE, null);
  },

  async [ActionTypes.GET_SEARCH_DETAILS](
    { commit }: AugmentedActionContext,
    searchKeyword: string
  ) {
    const groupDetails = await ActionListService.search(searchKeyword);

    groupDetails.content?.prototypes.forEach((proto) => {
      groupDetails.content?.actions.push({
        actionId: proto.actionTemplate.templateId,
        internalId: proto.actionTemplate.id,
        canDelete: proto.actionTemplate.customData.canDelete as boolean,
        canDuplicate: proto.actionTemplate.customData.canDuplicate as boolean,
        caption: proto.actionTemplate.customData.description,
        description: proto.actionTemplate.customData.description,
        configuration: cloneDeep(proto.actionTemplate.customData.configuration),
        icon: proto.actionTemplate.customData.icon,
        title: proto.name,
        inputPorts: proto.actionTemplate.customData.inputPorts,
        outputPorts: proto.actionTemplate.customData.outputPorts,
        isTestable: proto.actionTemplate.customData.isTestable as boolean,
        permissions: {
          canDelete: proto.actionTemplate.customData.canDelete as boolean,
          canDuplicate: proto.actionTemplate.customData.canDuplicate as boolean,
          canAddFromToolbar: true,
          canSaveActionAsTemplate: proto.actionTemplate.customData
            .canSaveActionAsTemplate as boolean,
        },
        name: proto.name,
        shape: proto.actionTemplate.customData.type,
        tooltip: proto.name,
        //
        submitedBy: "",
        updatedOn: "",
        isCustom: false,
        isProcesioAction: proto.isProcesio || true,
        id: proto.id,
      });
    });

    // TODO: refactor all this garbage

    groupDetails.content?.actions.forEach((action) => {
      commit(MutationTypes.SET_ACTION_CONFIGURATION, {
        ...action,
        internalId: action.internalId ? action.internalId : action.actionId,
      });
    });
  },

  [ActionTypes.SET_TEST_ACTION_MODEL](
    { commit }: AugmentedActionContext,
    payload: TestActionModel | null
  ) {
    commit(MutationTypes.SET_TEST_ACTION_MODEL, payload);
  },
};
