import { FormControl } from './FormControl';
import { ValidatorFn } from './Models';

export class FormBuilder {
  public controls: Record<string, FormControl> = {};

  /**
   * An object containing any errors generated by failing validation,
   * or {} if there are no errors.
   */
  public get errors() {
    const errors: Record<string, string[]> = {};

    for (const key in this.controls) {
      if (this.controls[key].errors !== null) {
        errors[key] = this.controls[key].errors;
      }
    }

    return errors;
  }

  /**
   * A form has errors if at least one control has them
   *
   * @returns `True` if there is a controller with errors, `false` otherwise
   */
  public get hasErrors() {
    let errCount = 0;

    for (const key in this.controls) {
      if (
        this.controls[key].errors !== null &&
        this.controls[key].errors.length > 0
      ) {
        errCount += 1;
      }
    }

    return !!errCount;
  }

  /**
   * A map of the form as a FormData
   *
   * @returns A FormData instance of the form
   */
  public get formData() {
    const formData = new FormData();

    for (const key in this.controls) {
      formData.append(key, this.controls[key].value as any);
    }

    return formData;
  }

  /**
   * A map of the form as a object
   *
   * @returns An object where the key is the name of the control
   * and the value it's value
   */
  public get json() {
    const formJson: Record<string, unknown> = {};

    for (const key in this.controls) {
      formJson[key] = this.controls[key].value;
    }

    return formJson;
  }

  /**
   * @description
   * Run all validators on every form control
   */
  public validate() {
    for (const key in this.controls) {
      this.controls[key].validate();
    }

    return this;
  }

  /**
   * @description
   * Construct a new FormControl instance with a given default and validators
   *
   * @param name The key name for the controls map
   *
   * @param defValue Initializes the control with an initial default value
   *
   * @param validator A synchronouse validator function, or an array of such functions
   *
   * @returns A reference to the newly created instance of the FormControl
   */
  public control(
    name: string,
    defValue: unknown,
    validator: ValidatorFn | ValidatorFn[]
  ) {
    this.controls[name] = new FormControl(defValue, validator);

    return this.controls[name];
  }

  public reset() {
    for (const key in this.controls) {
      if (Object.prototype.hasOwnProperty.call(this.controls, key)) {
        const element = this.controls[key];

        element.reset();
      }
    }
  }

  public update(objectForm: Record<string, any>) {
    for (const key in objectForm) {
      if (Object.prototype.hasOwnProperty.call(this.controls, key)) {
        this.controls[key].value = objectForm[key];
      }
    }
  }
}
