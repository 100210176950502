import { actions } from "./UI.actions";
import { mutations } from "./UI.mutations";
import getters from "./UI.getters";
import { BannerType, BannerUnit } from "@/components/Banner/banner.model";
import { AnnouncementData } from "@/services/announcements";
import { CampaignData } from "@/services/campaign";
import {
  getDefaultUserPreferences,
  UserPreferences,
} from "@/services/application/userPreferences/UserPreferences.model";

export const LsMessageKey = "msg";
export const LsAnnouncementsKey = "announcements";
export const LsCampaignKey = "campaign";

export interface Loader {
  id: string;
}

export interface UIState {
  isLoading: boolean;
  loaders: Loader[];
  isDisabled: boolean;
  bannerTypes: { [key in BannerUnit]: BannerType | null };
  announcements: AnnouncementData[];
  campaign: CampaignData | null;
  keyboardPressedCodes: string[];
  userPreferences: UserPreferences;
}

const getInitialState = () => {
  return {
    isLoading: false,
    loaders: [],
    isDisabled: false,
    bannerTypes: {},
    announcements: [],
    campaign: null,
    keyboardPressedCodes: [],
    userPreferences: getDefaultUserPreferences(),
  };
};

export const UIStore = {
  state: getInitialState(),
  actions,
  mutations,
  getters,
};
