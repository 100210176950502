import RestService, { PaginatedResponse } from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import {
  AnalyticsDetails,
  IntervalFilterValue,
  ProcessAnalytics,
  Resources,
  UsersAnalytics,
  WorkspaceAnalytics,
  WorkspaceUsage,
} from "./Resource.model";

const dateToUTCDateString = (date: Date) => {
  if (!date || typeof date.toISOString !== "function") {
    date = new Date();
  }

  return date.toISOString().replace(".000Z", "");
};

const getIntervalQueryParams = (interval: IntervalFilterValue) => {
  // todo handle case when interval is null
  return (
    "startDate=" +
    dateToUTCDateString(interval.startDate) +
    "&endDate=" +
    dateToUTCDateString(interval.endDate)
  );
};

export default class ResourceService {
  // resources
  static getCurrentPlanResources(
    interval: IntervalFilterValue,
    workspaceId: string | undefined = undefined
  ) {
    return RestService.get<Resources>({
      url: RestUrls.API.RESOURCES.USED(getIntervalQueryParams(interval)),
      isAuth: true,
      workspaced: true,
      customWorkspace: workspaceId,
    });
  }

  // analytics
  static getProcessAnalytics(
    interval: IntervalFilterValue,
    pageNumber = 0,
    pageItemCount = 0,
    workspaceId: string | null = null
  ) {
    return RestService.get<PaginatedResponse<ProcessAnalytics>>({
      url: RestUrls.API.RESOURCES.ANALYTICS.PROCESSES.PAGINATED_LIST(
        getIntervalQueryParams(interval),
        pageNumber,
        pageItemCount,
        workspaceId
      ),
      isAuth: true,
      workspaced: true,
    });
  }

  // analytics details
  static getProcessAnalyticsDetails(
    processId: string,
    interval: IntervalFilterValue
  ) {
    return RestService.get<AnalyticsDetails[]>({
      url: RestUrls.API.RESOURCES.ANALYTICS.PROCESSES.DETAILS(
        processId,
        getIntervalQueryParams(interval)
      ),
      isAuth: true,
      workspaced: true,
    });
  }

  static getInstanceAnalyticsDetails(instanceId: string) {
    return RestService.get<AnalyticsDetails[]>({
      url: RestUrls.API.RESOURCES.ANALYTICS.INSTANCES.DETAILS(instanceId),
      isAuth: true,
      workspaced: true,
    });
  }

  static getSubworkspaceUsage(interval: IntervalFilterValue) {
    return RestService.get<WorkspaceUsage[]>({
      url: RestUrls.API.RESOURCES.SUB_WS_USED(getIntervalQueryParams(interval)),
      isAuth: true,
      workspaced: true,
    });
  }
}
