export enum DelayType {
  WAIT_FOR = 1,
  WAIT_UNTIL = 2,
}

export enum WaitForInterval {
  SECOND = 1,
  MINUTE = 2,
  HOUR = 3,
  DAY = 4,
  WEEK = 5,
  MONTH = 6,
  YEAR = 7,
}
export interface Definition {
  value: number | Date | null;
  interval: WaitForInterval | null;
}
