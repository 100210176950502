export const ProcessInputAllowedExtensions = [
  ".txt",
  ".doc",
  ".docx",
  ".odt",
  ".one",
  ".pages",
  ".csv",
  ".xls",
  ".xlsx",
  ".xlsm",
  ".ods",
  ".numbers",
  ".ppt",
  ".pptx",
  ".odp",
  ".key",
  ".pdf",
  ".xps",
  ".html",
  ".htm",
  ".html5",
  ".xhtml",
  ".css",
  ".jpg",
  ".jpeg",
  ".bmp",
  ".png",
  ".gif",
  ".img",
  ".tiff",
  ".tif",
  ".svg",
  ".vsd",
  ".log",
  ".json",
  ".xml",
  ".xsd",
  ".yaml",
  ".zip",
  ".zipx",
  ".rar",
  ".tar",
  ".jar",
  ".7z",
  ".s7z",
  ".msg",
  ".mp3",
  ".m4a",
  ".wma",
  ".aac",
  ".wav",
  ".flac",
  ".aiff",
  ".mp4",
  ".avi",
  ".mpeg",
  ".mpg",
  ".mov",
  ".wmv",
  ".avchd",
  ".flv",
  ".f4v",
  ".swf",
  ".mkv",
  ".webm",
  ".iso",
  ".ima",
  ".sql",
  ".adp",
  ".cs",
  ".asp",
  ".java",
  ".php",
  ".ical",
  ".ics",
  ".ifb",
  ".icalendar",
];

export const FTPCredentialAllowedExtensions = [".ppk", ".pem"];

export const ImportAllowedExtensions = [".procesio"];

export const FileCleanupInterval = 7; // 7 days

export const getExtensions = (file: File) =>
  file.name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

export const hasAllowedExtension = (file: File, extensions: string[]) => {
  const fileExtensions = getExtensions(file);

  if (!fileExtensions) {
    return true;
  }

  let hasExtension = false;

  fileExtensions.forEach((ext) => {
    if (
      !hasExtension &&
      extensions.map((item) => item.toLowerCase()).includes(ext.toLowerCase())
    ) {
      hasExtension = true;
    }
  });

  return hasExtension;
};

export const downloadBlob = (blob: Blob | any, fileName: string) => {
  // convert text to json
  if (!(blob instanceof Blob)) {
    const str = JSON.stringify(blob);
    const bytes = new TextEncoder().encode(str);
    blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
  }

  const url = window.URL || window.webkitURL;
  const link = url.createObjectURL(blob);
  const element = document.createElement("a");
  element.setAttribute("download", fileName);
  element.setAttribute("href", link);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const blobToFile = (blob: Blob, fileName: string) =>
  new File([blob], fileName);
