import { Variable } from "@/services/crud/Orchestration.service";
import { MutationTree } from "vuex";
import { DocumentState } from "./Document.store";

export enum MutationTypes {
  SET_TITLE = "SET_TITLE",
  SET_VARIABLES = "SET_VARIABLES",
  RESET_DOCUMENT_DATA = "RESET_DOCUMENT_DATA",
}

export type Mutations<S = DocumentState> = {
  [MutationTypes.SET_TITLE](state: S, payload: string | null): void;
  [MutationTypes.SET_VARIABLES](state: S, payload: Variable[]): void;
  [MutationTypes.RESET_DOCUMENT_DATA](state: S): void;
};

export const mutations: MutationTree<DocumentState> & Mutations = {
  [MutationTypes.SET_TITLE](state, payload) {
    state.title = payload;
  },

  [MutationTypes.SET_VARIABLES](state, payload) {
    state.variables = payload;
  },

  [MutationTypes.RESET_DOCUMENT_DATA](state) {
    state.title = "";
    state.variables = [];
  },
};
