<template>
  <div>
    <div
      @click="clickHandler" @keydown.enter="clickHandler"
      tabindex="0"
      v-tooltip="{
        content: controlType
          ? 'Add Variable <br /> type=' + controlType
          : 'Add Variable',
      }"
      :class="[`pup-c-input-data-model-selector--add pds-u-input-label`]"
    >
      <pds-icon class="pup-c-input-data-model-selector--add--icon" icon="add" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { IconComponent } from "@procesio/procesio-design-system";

@Component({
  components: {
    "pds-icon": IconComponent,
  },
})
export default class AddVariableButtonComponent extends Vue {
  @Prop() controlType!: string;

  @Emit("click")
  clickHandler(event: MouseEvent) {
    return event;
  }
}
</script>

