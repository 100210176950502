import moment from "moment";

export const formatter = (date: Date) => {
  const day = date.getDate();

  const month = date.toLocaleString("default", {
    month: "long",
  });

  const year = date.getFullYear();

  const hours = date.getHours();

  const minutes = date.getMinutes();

  return `${day} ${month} ${year}, ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

/**
 * returns ISO date without timezone info
 * e.g. 2022-04-21T08:45:00Z
 */
export const toLocalISOString = (date: Date) => {
  // `utc(true)` to omit timezone
  return moment(date)
    .utc(true)
    .format()
    .replace("Z", "");
};

export const convertDateFromUTC = (date: Date | string) => {
  date = new Date(date);

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};
