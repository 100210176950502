type GUID = string;

export type Control =
  | TextControl
  | OauthRedirectUrl
  | OauthButton
  | NumberControl
  | SelectControl
  | RadioControl
  | CheckboxControl
  | PasswordControl
  | UploadControl
  | FTPUriControl
  | PillsControl
  | CollapsableControl
  | TableControl;

export enum ControlId {
  ACCESS_TOKEN_URL = "10101010-0001-0016-0002-cccccccccccc",
  REFRESH_TOKEN_URL = "10101010-0001-0016-0003-cccccccccccc",
  REST_API_AUTHENTICATION_METHOD = "10101010-0001-0004-0000-bbbbbbbbbbbb",
}

interface GenericControl {
  id: GUID | ControlId;
  label: string;
  value: unknown;
  type: ControlType;
  order: number;
  validations: Validations;
  condition?: Condition;
  layout?: Layout;
  tooltip?: string;
  message?: string;
  isTest?: boolean;
  disabled?: boolean;
  be_id?: string;
  configurations?: { defaultValue?: any };
}

interface TextControl extends GenericControl {
  type: ControlType.TEXT;
  value: string;
  placeholder: string;
}

interface OauthRedirectUrl extends GenericControl {
  type: ControlType.OAUTH_REDIRECT_URL;
  value: string;
  placeholder: string;
}

export interface OauthButton extends GenericControl {
  type: ControlType.OAUTH_BUTTON | ControlType.GOOGLE_OAUTH_BUTTON;
  value: string;
  placeholder: string;
}

interface PillsControl extends GenericControl {
  type: ControlType.PILLS;
  value: string[];
  placeholder: string;
}

export interface CollapsableControl extends GenericControl {
  type: ControlType.COLLAPSABLE | ControlType.COLLAPSABLE_OPTIONAL;
  pill: string;
  value: Control[];
}

export interface TableControl extends GenericControl {
  type: ControlType.TABLE;
  value: TableControlValue;
}

interface NumberControl extends GenericControl {
  type: ControlType.NUMBER;
  value: number;
  placeholder: string;
}

interface CheckboxControl extends GenericControl {
  type: ControlType.CHECKBOX;
  value: boolean;
}

export interface SelectControl extends GenericControl {
  type: ControlType.SELECT;
  value: GUID;
  options: Array<Option<GUID>>;
}

interface RadioControl extends GenericControl {
  type: ControlType.RADIO;
  value: GUID;
  options: Array<Option<GUID>>;
}

interface PasswordControl extends GenericControl {
  type: ControlType.PASSWORD;
  value: GUID;
}

// interface MultiSelectControl extends GenericControl {

interface UploadControl extends GenericControl {
  type: ControlType.UPLOAD;
  value: UploadControlValue;
}

interface FTPUriControl extends GenericControl {
  type: ControlType.FTP_URI;
  value: string;
  placeholder: string;
}

export interface UploadControlValue {
  fileId: string;
  fileName: string;
  file?: File;
}

export enum ControlType {
  TEXT = "text",
  OAUTH_REDIRECT_URL = "oauth-redirect-url",
  OAUTH_BUTTON = "oauth-auth-button",
  GOOGLE_OAUTH_BUTTON = "google-auth-button",
  NUMBER = "number",
  SELECT = "select",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  MULTISELECT = "multiselect",
  PASSWORD = "password",
  UPLOAD = "upload",
  FTP_URI = "ftp-uri",
  PILLS = "pills",
  COLLAPSABLE = "collapsable",
  COLLAPSABLE_OPTIONAL = "collapsable-optional",
  TABLE = "table",
}

export enum ValidationType {
  NONE = 0,
  URI = 1,
  HOSTNAME = 2,
}

interface Validations {
  isRequired: boolean;
  isUri?: boolean;
  isServer?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  expects?: ValidationType;
}

interface Option<T> {
  name: string;
  value: T;
}

export interface Condition {
  dependencyId: GUID;
  operator: Operator;
  value: unknown;
}

export enum Operator {
  EQUALS = "equals",
}

export enum ColumnNumber {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  ELEVEN = 11,
  TWELVE = 12,
}

interface Layout {
  /**
   * TODO: columns should be a map where each key is a device size
   * and the value the nr of cols on said device, for responsivess
   */
  columns?: ColumnNumber;
}

export interface TableControlValue {
  header: TableControlColumn[];
  rows: TableControlRow[];
}

export interface TableControlColumn {
  key: TableControlColumnKey;
  type: ControlType;
  be_id: string;
  label: string;
  order: number;
  placeholder?: string;
  tooltip?: string;
  options?: TableControlColumnOption[];
}

export interface TableControlRow {
  enabled?: boolean;
  key?: string;
  value?: string;
  order: number;
  header: boolean;
  query: boolean;
  canDelete: boolean;
  configurations: { column?: string; isActive?: boolean }[];
}

export enum TableControlColumnKey {
  enabled = "enabled",
  key = "key",
  value = "value",
  type = "type",
  header = "header",
  query = "query",
}

interface TableControlColumnOption {
  be_value: string;
  name: string;
  value: string;
}

export const restApiMock: Control[] = [
  {
    id: "24444e7c-a321-41b0-85bb-346940265f55",
    label: "URL",
    placeholder: "Insert URL",
    value: "",
    type: ControlType.TEXT,
    validations: {
      isRequired: true,
    },
  },
  {
    id: "5e284c5b-2ee0-4e65-8a1e-1b92a443cf4c",
    label: "Method",
    value: "d8c57aac-e476-4694-9910-da019c565578",
    type: ControlType.SELECT,
    options: [
      {
        name: "GET",
        value: "d8c57aac-e476-4694-9910-da019c565578",
      },
      {
        name: "POST",
        value: "2908d3ce-543d-4aa1-be45-8af4299fb43c",
      },
    ],
    validations: {
      isRequired: true,
    },
    layout: {
      columns: ColumnNumber.TWO,
    },
  },
  {
    id: "af098302-ebe9-4645-93d8-95bd375c0fff",
    label: "Test endpoint",
    placeholder: "Insert endpoint to test it",
    value: "",
    type: ControlType.TEXT,
    validations: {
      isRequired: true,
    },
    layout: {
      columns: ColumnNumber.TEN,
    },
  },
  {
    id: "65647a65-d8b6-4023-a4e9-80a496d6eecd",
    label: "Authentication method",
    type: ControlType.RADIO,
    value: "b6c85a7c-9076-45b1-96aa-20f3f1ae280a",
    options: [
      {
        name: "No authentication",
        value: "1fae5b84-0b69-4212-b2e0-9a8589b46f25",
      },
      {
        name: "Basic authentication",
        value: "b6c85a7c-9076-45b1-96aa-20f3f1ae280a",
      },
      {
        name: "API key authentication",
        value: "67654d81-af7b-4c1c-b0fa-1920d041b1fc",
      },
    ],
    validations: {
      isRequired: false,
    },
  },
  {
    id: "cdbfda69-3136-45da-9d9e-1fea09971c11",
    label: "Name",
    placeholder: "Insert username",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS, // how to compare the value of the dependency with the value of this condition
      value: "b6c85a7c-9076-45b1-96aa-20f3f1ae280a", //id of the basic authentication option
    },
  },
  {
    id: "92f473fc-76a2-4ec2-ad1a-d8c64a8f9f97",
    label: "Password",
    placeholder: "Insert password",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS,
      value: "b6c85a7c-9076-45b1-96aa-20f3f1ae280a", //id of the basic authentication option
    },
  },
  {
    id: "55a11b72-8b74-4baf-a9d5-85e03aefd29e",
    label: "Key",
    placeholder: "id",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS,
      value: "67654d81-af7b-4c1c-b0fa-1920d041b1fc", //id of the api key authentication option
    },
  },
  {
    id: "89b3e1c3-bda8-4df0-85a4-094e0ac148ea",
    label: "Value",
    placeholder: "{{id_value}}",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS,
      value: "67654d81-af7b-4c1c-b0fa-1920d041b1fc", //id of the api key authentication option
    },
  },
  {
    id: "da1e6629-0284-4dc5-af32-340dddff417e",
    label: "Header",
    value: true,
    type: ControlType.CHECKBOX,
    validations: {
      isRequired: false,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS,
      value: "67654d81-af7b-4c1c-b0fa-1920d041b1fc", //id of the api key authentication option
    },
  },
  {
    id: "cc5acc30-1e20-4474-8ee5-ec671cce4b7e",
    label: "Query Parameters",
    value: false,
    type: ControlType.CHECKBOX,
    validations: {
      isRequired: false,
    },
    condition: {
      dependencyId: "65647a65-d8b6-4023-a4e9-80a496d6eecd", // id of the authentication method radio
      operator: Operator.EQUALS,
      value: "67654d81-af7b-4c1c-b0fa-1920d041b1fc", //id of the api key authentication option
    },
  },
];

export const ftpMock: Control[] = [
  {
    id: "06fb4657-3e6c-4933-a09c-ff84975e6406",
    label: "Hostname/IP",
    placeholder: "Insert hostname/IP ",
    value: "",
    type: ControlType.FTP_URI,
    validations: {
      isRequired: true,
    },
  },
  {
    id: "91c8fb9d-67be-4569-9e16-50d1eba2c45c",
    label: "Port number",
    placeholder: "Insert number",
    value: 21,
    type: ControlType.NUMBER,
    validations: {
      isRequired: true,
    },
  },
  {
    id: "aab476b1-52dc-41fd-a8e7-719bc1f1353a",
    label: "Protocol type",
    type: ControlType.RADIO,
    value: "c2cea466-ceab-40f3-b6bb-79dbdd99e5ad",
    options: [
      {
        name: "FTP",
        value: "c2cea466-ceab-40f3-b6bb-79dbdd99e5ad",
      },
      {
        name: "SFTP",
        value: "b73a8aa2-710a-43aa-b551-faae57ede12a",
      },
    ],
    validations: {
      isRequired: true,
    },
  },
  {
    id: "a6027152-27eb-4313-9d40-104bf0144de6",
    label: "Root folder path",
    placeholder: "Insert path",
    value: "",
    type: ControlType.TEXT,
    validations: {
      isRequired: true,
    },
  },
  {
    id: "2b4fa7a4-f7ab-4479-bd95-23095c8d6699",
    label: "Authentication method",
    type: ControlType.RADIO,
    value: "d8935eaa-ed95-4b95-98f9-8acfc62e3245",
    options: [
      {
        name: "Basic authentication",
        value: "d8935eaa-ed95-4b95-98f9-8acfc62e3245",
      },
      {
        name: "Public key",
        value: "2b10372d-c23b-432b-b6e7-b65c6f8f5a66",
      },
    ],
    validations: {
      isRequired: false,
    },
  },
  {
    id: "dbf6f2a4-ad0f-42ca-b050-bf69305ef45e",
    label: "Username",
    placeholder: "Insert username",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "2b4fa7a4-f7ab-4479-bd95-23095c8d6699", // id of the authentication method radio
      operator: Operator.EQUALS, // how to compare the value of the dependency with the value of this condition
      value: "d8935eaa-ed95-4b95-98f9-8acfc62e3245", //id of the basic authentication option
    },
  },
  {
    id: "7d329969-3073-439e-ae50-e974ef7394d7",
    label: "Password",
    placeholder: "Insert password",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "2b4fa7a4-f7ab-4479-bd95-23095c8d6699", // id of the authentication method radio
      operator: Operator.EQUALS, // how to compare the value of the dependency with the value of this condition
      value: "d8935eaa-ed95-4b95-98f9-8acfc62e3245", //id of the basic authentication option
    },
  },
  {
    id: "e8089516-007e-4f02-86bc-b33f338022a2",
    label: "Public key",
    placeholder: "Insert key",
    type: ControlType.TEXT,
    value: "",
    validations: {
      isRequired: true,
    },
    condition: {
      dependencyId: "2b4fa7a4-f7ab-4479-bd95-23095c8d6699", // id of the authentication method radio
      operator: Operator.EQUALS, // how to compare the value of the dependency with the value of this condition
      value: "2b10372d-c23b-432b-b6e7-b65c6f8f5a66", //id of the basic authentication option
    },
  },
];
