import JSON5 from "json5";

export const parseArray = (arrayString: string) => {
  if (typeof arrayString !== "string") {
    return null;
  }

  let parsed = null;
  try {
    // JSON5 is used here because it allows single quotes
    parsed = JSON5.parse(arrayString);
  } catch (_) {
    return null;
  }

  return parsed;
};

export const isValidArray = (value: any) => {
  if (typeof value === "string") {
    value = parseArray(value);
  }

  return Array.isArray(value);
};


