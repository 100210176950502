import { actions } from "./Resource.actions";
import { mutations } from "./Resource.mutations";
import getters from "./Resource.getters";
import { ResourceTime } from "@/services/resources/Resource.model";

export interface ResourceState {
  time: ResourceTime;
  expirationDate: Date | null;
  autoRenewable: boolean;
}

const getInitialState = () => {
  return {
    time: {
      consumed: 0,
      limit: {
        soft: 0,
        hard: 0,
      },
    },
    expirationDate: null,
    autoRenewable: false,
  };
};

export const ResourceStore = {
  state: getInitialState(),
  actions,
  mutations,
  getters,
};
