/**
 * A regular expression that matches valid e-mail addresses.
 * Reference: https://emailregex.com/
 */
// Email regex which allows alias (e.g. <name surname>test@mail.com)
// lets keep it here just in case
// export const EMAIL_REGEXP = /^((?:[a-z0-9!#/$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|.*\<(?:[a-z0-9!#/$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])\>)$/;

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_DELIMITERS = [";", ","];

export const isValidEmail = (email: string) => {
  if (typeof email !== "string" || email.length === 0) {
    return false;
  }

  email = email.trim();

  const matches = email.match(EMAIL_REGEXP);
  if (!matches) {
    return false;
  }

  const matchIndex = matches.index;
  return matchIndex === 0;
};

export const splitEmails = (email: string) => {
  return EMAIL_DELIMITERS.reduce((emails, delimiter) => {
    emails = emails.reduce((splittedEmails: string[], value: string) => {
      splittedEmails.push(...value.split(delimiter).map((str) => str.trim()).filter((str) => str));
      return splittedEmails;
    }, [] as string[]);
    return emails;
  }, [email]);
}
