import { ValidationError, ValidatorFn } from "./Models";

export class FormControl {
  public errors: ValidationError[] = [];

  public disabled = false;

  private defaultValue: unknown;

  /**
   * Initialize the FormControl instance.
   *
   * @param value Default value used when instancing the FormControl
   *
   * @param validator The function or array of functions that is used to determine the validity of
   * this control synchronously.
   */
  constructor(
    /**
     * The current value of the control
     */
    public value: unknown,
    /**
     * Synchronous validators as they were provided:
     *  - in `FormControl` constructor
     *  - while calling the setter on the `validator` field (e.g. `control.validator = validatorFn`)
     */
    private validator: ValidatorFn | ValidatorFn[]
  ) {
    this.defaultValue = value;
  }

  /**
   * reset
   *
   * @description Clears the form control
   */
  public reset() {
    this.value = this.defaultValue;
    this.clearErrors();
  }

  /**
   * validate
   *
   * @description Runs the validators on this form control
   */
  public validate() {
    this.errors = [];

    if (this.disabled) {
      return;
    }

    if (!this.validator || !this.validator.length) {
      return;
    }

    if (typeof this.validator === "function") {
      const error = this.validator.call(this, this);

      if (error !== null) {
        this.errors.push(error);
      }
    } else if (Array.isArray(this.validator)) {
      this.validator.forEach((validator) => {
        const error = validator.call(this, this);

        if (error !== null) {
          this.errors.push(error);
        }
      });
    }
  }

  /**
   * clearErrors
   *
   * @description Clears the form control errors
   */
   public clearErrors() {
    this.errors = [];
  }
}
