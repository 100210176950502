var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pup-c-variable-mapper--row pds-u-m--t--16 pds-u-m--r--8"},[_c('div',{class:[
      'pup-c-variable-mapper--item pup-c-variable-mapper--item--left pds-u-m--l--8 pds-u-m--r--8',
      _vm.disabled && 'pup-c-variable-mapper--item--disabled',
    ]},[_c('generic-text',{attrs:{"settings":_vm.settings,"isProperty":true,"placeholder":_vm.leftInputPlaceholder,"processVariables":_vm.leftColumnVariables,"variableScopes":_vm.leftColumnVariableScopes,"parent":_vm.parent,"hideCreate":_vm.hideCreateLeftInput,"oneVariableOnly":_vm.isLeftInputOneVariableOnly,"disabled":_vm.disabled,"fullScreenTitle":_vm.parent && _vm.settings ? _vm.parent.name + ' | ' + _vm.settings.label : ''},model:{value:(_vm.value.left),callback:function ($$v) {_vm.$set(_vm.value, "left", $$v)},expression:"value.left"}})],1),_vm._v(" "),_c('pds-icon',{staticClass:"pup-c-variable-mapper--icon pds-u-m--r--8",attrs:{"icon":_vm.direction === _vm.directions.RIGHT ? 'arrow_forward' : 'arrow_back'}}),_vm._v(" "),_c('div',{class:[
      'pup-c-variable-mapper--item',
      _vm.disabled && 'pup-c-variable-mapper--item--disabled',
    ]},[_c('generic-text',{attrs:{"settings":_vm.settings,"isProperty":true,"placeholder":_vm.rightInputPlaceholder,"processVariables":_vm.rightColumnVariables,"variableScopes":_vm.rightColumnVariableScopes,"parent":_vm.parent,"hideCreate":_vm.hideCreateRightInput,"oneVariableOnly":_vm.isRightInputOneVariableOnly,"disabled":_vm.disabled,"fullScreenTitle":_vm.parent && _vm.settings ? _vm.parent.name + ' | ' + _vm.settings.label : ''},model:{value:(_vm.value.right),callback:function ($$v) {_vm.$set(_vm.value, "right", $$v)},expression:"value.right"}})],1),_vm._v(" "),(!_vm.disabled)?_c('div',{staticClass:"pup-c-variable-mapper--remove pds-u-m--l--16",on:{"click":_vm.removeItem}},[_c('pds-icon',{attrs:{"icon":"delete","isOutlined":true}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }