import { store } from "@/store";
import { getKeyLabel, KeyboardCode } from "@/utils/keyboard";
import { getPlatform, Platform } from "@/utils/platform";

const DataModelSelectorHotKeys = {
  TOGGLE_DATA_MODEL_SELECTOR: "TOGGLE_DATA_MODEL_SELECTOR",
};

const InputHotKeys = {
  ENLARGE_INPUT: "ENLARGE_INPUT",
  COLLAPSE_INPUT: "COLLAPSE_INPUT",
};

const EditorHotKeys = {
  FORMAT_EDITOR_CONTENT: "FORMAT_EDITOR_CONTENT",
};

export const HotKeys = {
  ...DataModelSelectorHotKeys,
  ...InputHotKeys,
  ...EditorHotKeys,
};

export const HotKeysLabels = {
  [HotKeys.ENLARGE_INPUT]: "Enlarge input box",
  [HotKeys.COLLAPSE_INPUT]: "Close input box",
  [HotKeys.TOGGLE_DATA_MODEL_SELECTOR]: "Open variables selector",
  [HotKeys.FORMAT_EDITOR_CONTENT]: "Beautify content in editor",
};

export const HotKeyGifs = {
  [HotKeys.ENLARGE_INPUT]: "/static/gif/enlargeCollapseEditor.gif",
  [HotKeys.COLLAPSE_INPUT]: "/static/gif/enlargeCollapseEditor.gif",
  [HotKeys.TOGGLE_DATA_MODEL_SELECTOR]: "/static/gif/toggleSelector.gif",
  [HotKeys.FORMAT_EDITOR_CONTENT]: "/static/gif/format.gif",
};

export const getDefaultHotKeys = () => {
  const platform = getPlatform();

  let hotKeys = {
    [HotKeys.ENLARGE_INPUT]: [KeyboardCode.ESC],
    [HotKeys.COLLAPSE_INPUT]: [KeyboardCode.ESC],
    [HotKeys.FORMAT_EDITOR_CONTENT]: [
      KeyboardCode.SHIFT,
      KeyboardCode.ALT,
      KeyboardCode.F,
    ],
  };

  if (platform === Platform.MAC) {
    hotKeys = {
      ...hotKeys,
      [HotKeys.TOGGLE_DATA_MODEL_SELECTOR]: [KeyboardCode.META, KeyboardCode.I],
    };
  } else {
    hotKeys = {
      ...hotKeys,
      [HotKeys.TOGGLE_DATA_MODEL_SELECTOR]: [KeyboardCode.CTRL, KeyboardCode.I],
    };
  }

  return hotKeys;
};

export const isHotKeyMatched = (hotKey: string, keyCodes: string[]) => {
  const shorcutCodes = store.getters.hotKeys[hotKey];
  if (!Array.isArray(shorcutCodes) || shorcutCodes.length !== keyCodes.length) {
    return false;
  }

  return [...shorcutCodes].sort().join(" ") === [...keyCodes].sort().join(" ");
};

export const hotKeyToString = (hotKey: string) => {
  const shorcutCodes = store.getters.hotKeys[hotKey];
  if (!Array.isArray(shorcutCodes)) {
    return "";
  }

  return [...shorcutCodes].map((code) => getKeyLabel(code)).join(" + ");
};
