<template>
  <div class="pup-c-delay-definition">
    <label class="pup-c-delay-definition--label pds-u-m--b--16">{{
      label
    }}</label>
    <component
      :is="definitionComponent"
      class="pup-c-delay-definition--controls"
      :options="options"
      :value="value"
      :disabled="disabled"
      @update-input="$emit('update-input', $event)"
    />
    <pds-validation-message :status="status" />
    <pds-message
      class="pds-u-m--t--16"
      :text="infoMessage"
      v-if="infoMessage && !disabled"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import {
  DatePicker,
  TimePicker,
  ValidationMessage,
  MessageComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import { Component, Prop, Watch } from "vue-property-decorator";

import { Definition, DelayType, WaitForInterval } from "../Delay.model";
import WaitFor from "./WaitFor/WaitFor.component.vue";
import WaitUntil from "./WaitUntil/WaitUntil.component.vue";
import { DELAY_TYPE_SETTING } from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Settings";

import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

@Component({
  components: {
    "pds-date-picker": DatePicker,
    "pds-time-picker": TimePicker,
    "pds-validation-message": ValidationMessage,
    "pds-message": MessageComponent,
  },
  model: {
    event: "update-input",
  },
})
export default class DelayDefinition extends Vue {
  @Prop() value!: Definition;

  @Prop({ default: [] }) options!: { value: WaitForInterval; name: string }[];

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() parent!: Node;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  internalValue: Definition | null = null;

  @Watch("value", { immediate: true, deep: true })
  onValueChange(value: Definition | null) {
    let internalValue = {
      value: null,
      interval: null,
    } as Definition;

    if (value) {
      internalValue = value;
    }

    this.internalValue = internalValue;
  }

  @Watch("delayType")
  onDelayTypeChange() {
    this.$emit("update-input", {
      value: null,
      interval: null,
    });
  }

  get definitionComponent() {
    if (this.delayType === DelayType.WAIT_FOR) {
      return WaitFor;
    }

    return WaitUntil;
  }

  get delayType() {
    let type = DelayType.WAIT_FOR;

    const settings: Setting[] = [];
    const getSettings = (setting: Setting) => {
      if (typeof setting.id !== "undefined") {
        settings.push(setting);
        if (Array.isArray(setting.value)) {
          setting.value.forEach((setting: Setting) => {
            getSettings(setting);
          });
        }
      }
    };

    // get settings recursively
    this.parent.configuration.forEach((config) => {
      config.settings.forEach((setting: Setting) => {
        getSettings(setting);
      });
    });

    const delayTypeSetting = settings.find((s) => s.id === DELAY_TYPE_SETTING);
    if (delayTypeSetting) {
      type = +delayTypeSetting.value;
    }

    return type;
  }

  get label() {
    if (this.delayType === DelayType.WAIT_FOR) {
      return "Define duration interval for action to be paused:";
    }

    return "Define date and time until the action to be paused:";
  }

  get infoMessage() {
    if (!this.isDefinitionValid) {
      return null;
    }

    let message = "Next action will be processed";

    if (this.delayType === DelayType.WAIT_FOR) {
      const definitionValue = this.value as Definition;
      const interval = this.options.find(
        (i) => i.value === definitionValue.interval
      );
      if (interval) {
        message +=
          " with delay of " +
          definitionValue.value +
          " " +
          interval.name.toLowerCase() +
          ".";
      }
    } else if (this.delayType === DelayType.WAIT_UNTIL) {
      const formatDateTimeFilter = this.$options.filters.formatDateTime;
      message += " on " + formatDateTimeFilter(this.value.value as Date) + ".";
    }

    return message;
  }

  get isDefinitionValid() {
    if (this.delayType === DelayType.WAIT_FOR) {
      const definitionValue = this.value as Definition;
      return (
        definitionValue && definitionValue.value && definitionValue.interval
      );
    }

    return !!(this.value && this.value.value);
  }
}
</script>

<style lang="scss">
@import "./DelayDefinition.component.scss";
</style>
